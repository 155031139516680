import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getProductsList } from 'actions/asyncActions';

const ProductFilter = ({ multiple }) => (
    <Field
        name={multiple ? 'products' : 'product'}
        component={SearchSelect}
        placeholder='Продукция'
        multiple={multiple}
        allowClear={true}
        action={getProductsList}
        filter={({ brand, brands, productGroup, productGroups, productDepartment, productDepartments, productCategory, productCategories }) => ({
            [brands && (brands.length > 1) ? 'productGroups' : 'brand']:
                brand || (brands && (brands.length === 1) ? brands[0] :  brands),
            [productGroups && (productGroups.length > 1) ? 'productGroups' : 'productGroup']:
                productGroup || (productGroups && (productGroups.length === 1) ? productGroups[0] :  productGroups),
            [productDepartments && (productDepartments.length > 1) ? 'productDepartments' : 'productDepartment']:
                productDepartment || (productDepartments && (productDepartments.length === 1) ? productDepartments[0] :  productDepartments),
            [productCategories && (productCategories.length > 1) ? 'productCategories' : 'productCategory']:
                productCategory || (productCategories && (productCategories.length === 1) ? productCategories[0] :  productCategories),
        })}
        param='filter' />
)

export default ProductFilter;
