const validationLocale = {
    mixed: {
        required: 'Это обязательное поле',
        notType: ({ path, type, value }) => {
            if (value === null) {
                return 'Значение не должно быть равно null';
            }

            switch (type) {
                case 'number':
                    return 'Значение должно быть числом';
                default:
                    return 'Неверный формат';
            }
        }
    },
    string: {
        email: 'Неверный формат email',
        required: 'Это обязательное поле',
    },
    number: {
        positive: 'Число должно быть положительным',
        default: 'Значение должно быть числом',
        required: 'Это обязательное поле',
    },
    date: {
        default: 'Неверная дата',
        required: 'Это обязательное поле',
    }
}

export default validationLocale;
