import React, { Component } from 'react';

import ListTable from 'components/common/ListTable';
import { getSalePlansStat } from 'actions/asyncActions';
import { defaultPromoterPlansFilter, PromoterPlansFilter } from 'filters/PlansFilter';
import planColumns from '../planColumns';
import PersonLink from '../../../common/cards/person/PersonLink';
import CacheDate from './CacheDate';

@planColumns
export default class PromoterPlans extends Component {
    getColumns() {
        return [
            {
                title: 'ФИО',
                key: 'name',
                dataIndex: ['_embedded', 'promoter'],
                fixed: 'left',
                width: 150,
                render: promoter => <PersonLink person={promoter} type='promoter' />
            }, {
                title: 'Проект',
                key: 'productDepartment',
                fixed: 'left',
                width: 150,
                dataIndex: ['_embedded', 'productDepartment', 'name']
            },
            ...this.props.planColumns
        ];
    }

    render() {
        const { saga, setDate, planDate } = this.props;

        return (
            <ListTable
                tableColumns={this.getColumns()}
                action={getSalePlansStat}
                filterForm={PromoterPlansFilter}
                initialFilters={{
                    ...defaultPromoterPlansFilter,
                    date: planDate
                }}
                scroll={{ x: 'max-content' }}
                className='th-multi'
                saga={saga}
                setDate={setDate}
                setProductDepartments={this.props.setProductDepartments}
                staticPayload={{ limit: 0 }}
                hidePagination
                bordered
                footer={({ _meta }) => <CacheDate meta={_meta} /> }
            />
        );
    }
}
