import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';
import moment from 'moment';

import DatePicker from './DatePicker';
import { getSalePlansPromoterStatus, formAutoOptions } from 'actions/asyncActions';
import withRole from '../withRole';

const SALE_DATE_ERROR = 'На этот день нет плана в табеле';

@withRole
@withAsyncActions({
    getSalePlansPromoterStatus: getSalePlansPromoterStatus
        .withSuccessHandler(({ getSalePlansPromoterStatus: { data, meta }, form: { setFieldValue, setFieldError }}) => {
            const shop = path(['shop', 'id'], data);
            const department = path(['productDepartment', 'id'], data);

            if (!shop || !department) {
                setFieldError('date', SALE_DATE_ERROR);
            } else {
                setFieldValue('department', department);
                setFieldValue('_embedded', { department: data.productDepartment });
                setFieldValue('shop', shop);
                setFieldError('date', null);
            }
        })
        .withErrorHandler(({ form: { setFieldValue, setFieldError }}) => {
            setFieldError('date', SALE_DATE_ERROR);
        })
}, formAutoOptions)
export default class SaleDatePicker extends Component {
    componentDidMount() {
        const date = path(['value'], this.props.field);

        if (date) {
            this.onChange(date, path(['values', 'promoter'], this.props.form));
        }
    }

    onChange = (date, promoter) => {
        const { getSalePlansPromoterStatus, form: { setFieldValue, values }} = this.props;

        setFieldValue('shop', null);
        setFieldValue('department', null);

        if (date) {
            getSalePlansPromoterStatus.dispatch({ q: { date: date ? moment.utc(date).startOf('day') : null, promoter: promoter || values.promoter }});
        }
    }

    componentWillReceiveProps(next) {
        const { form: { errors, setFieldError, values: { shop, date, promoter }}} = next;
        const { form } = this.props;

        if (form.errors.date && !errors.date && date && !shop) {
            setFieldError('date', SALE_DATE_ERROR);
        }

        if (form.values.promoter !== promoter && promoter) {
            this.onChange(date, promoter);
        }
    }

    render() {
        const { value } = this.props.field;

        return <DatePicker
            {...this.props}
            onChange={date => this.onChange(date)}
            showError={!!value}
            disabledDate={date => date.isAfter(moment())} />;
    }
}
