import React from 'react';
import { Col } from 'antd'

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import UserStatusFilter from './fields/UserStatusFilter';
import { CLIENTS } from 'constants/urls';
import ExcelDownload from 'components/common/ExcelDownload';

function ClientFilter({ values }) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={values} url={CLIENTS} />
        </Col>
    );

    return (
        <BaseSearchFilter
            controls={controls}
            searchFieldName={'search'}
            searchFieldPlaceholder={'Пользователь'}
            disableMobileCollapse={true}>
            <Col sm={4} xs={12}>
                <UserStatusFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(ClientFilter);

export const defaultClientFilter = {
    enabled: true
};
