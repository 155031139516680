import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

export const toolbar = {
    options: ['inline', 'blockType', 'list', 'link', 'image', 'embedded', 'remove', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline'],
    },
    blockType: {
        options: ['Normal', 'H1', 'H2', 'H3'],
    },
    list: {
        options: ['unordered', 'ordered'],
    },
    history: {
        options: ['undo', 'redo'],
    }
};

const fromHTML = (value) => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
}

@withFormItem
export class TextEditor extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        placeholder: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            editorState: props.value ? fromHTML(props.value) : EditorState.createEmpty()
        };
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });

        this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    render() {
        const { editorState } = this.state;

        return (
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                toolbar={toolbar}
                localization={{
                    locale: 'ru'
                }}
            />
        );
    }
}

export default withFormikField(TextEditor);
