import React from 'react';
import { Col } from 'antd';

import ExcelDownload from 'components/common/ExcelDownload';
import { PRODUCTS } from 'constants/urls';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import BrandFilter from './fields/BrandFilter';
import ProductCategoryFilter from './fields/ProductCategoryFilter';
import ProductGroupFilter from './fields/ProductGroupFilter';
import ProductDepartmentFilter from './fields/ProductDepartmentFilter';
import ItemStatusFilter from './fields/ItemStatusFilter';

function ProductFilter(props) {
    const controls = (
        <Col sm={2} xs={24} className="controls-block">
            <ExcelDownload query={props.values} url={PRODUCTS} />
        </Col>
    );

    return (
        <BaseSearchFilter controls={controls}>
            <Col sm={3} xs={24}>
                <BrandFilter />
            </Col>
            <Col sm={3} xs={9}>
                <ProductDepartmentFilter />
            </Col>
            <Col sm={3} xs={12}>
                <ProductGroupFilter />
            </Col>
            <Col sm={3} xs={12}>
                <ProductCategoryFilter />
            </Col>
            <Col sm={3} xs={12}>
                <ItemStatusFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(ProductFilter);
