import React from 'react';
import { isEmpty } from 'ramda';
import { withAsyncActions } from 'react-async-client';

import { getUser } from 'actions/asyncActions';

import UserLayout from './user/UserLayout';
import GuestLayout from './guest/GuestLayout';
import Spinner from 'components/common/Spinner';

const checkUser = (user, meta) => {
    if (meta.pending) {
        return null;
    }

    return !isEmpty(user);
};

const SecureLayout = ({ getUser: { data, meta }, ...props }) => {
    const isAuthenticated = checkUser(data, meta);

    if (isAuthenticated === null) {
        return <Spinner />;
    }

    return isAuthenticated ? <UserLayout {...props} /> : <GuestLayout {...props} />;
};

export default withAsyncActions({ getUser })(SecureLayout);
