import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { getTasks } from 'actions/asyncActions';
import ListTable from 'components/common/ListTable';
import Date from 'components/common/cards/Date';
import TextEditorView from 'components/common/cards/TextEditorView';
import ShopTasksFilter, { defaultShopTasksFilter } from 'filters/ShopTasksFilter';

export default class ShopTasks extends Component {
    columns = [
        {
            title: 'Задача',
            dataIndex: 'text',
            key: 'text',
            render: (text, { id }) =>
                <Link className='task-table-text' to={`/task/${id}`}>
                    <TextEditorView content={text} />
                </Link>
        }, {
            title: 'Магазин',
            dataIndex: ['_embedded', 'shop', 'name'],
            key: 'shop'
        }, {
            title: 'Сеть',
            dataIndex: ['_embedded', 'network', 'name'],
            key: 'network'
        }, {
            title: 'Регион',
            dataIndex: ['_embedded', 'region', 'name'],
            key: 'region'
        }, {
            title: 'Город',
            dataIndex: ['_embedded', 'city', 'name'],
            key: 'city'
        }, {
            title: 'Дата начала',
            dataIndex: 'startDate',
            key: 'startDate',
            render: date => <Date date={date} />
        }, {
            title: 'Выполнить до',
            dataIndex: 'endDate',
            key: 'endDate',
            render: date => <Date date={date} />
        }
    ];

    render() {
        return (
            <div>
                <ListTable
                    tableColumns={this.columns}
                    action={getTasks}
                    filterForm={ShopTasksFilter}
                    initialFilters={defaultShopTasksFilter}
                    rowClassName={({ priority }) => priority} />
            </div>
        );
    }
}
