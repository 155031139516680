import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import RangeMonthFilter from './fields/RangeMonthFilter';

function SalesSettingsFilter({ additionalControls }) {
    return (
        <BaseSearchFilter disableSearch>
            <Col sm={8} xs={24}>
                <RangeMonthFilter />
            </Col>
            <Col sm={16} xs={24}>
                { additionalControls }
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(SalesSettingsFilter);
