import React from 'react';
import { Col } from 'antd';

import withRole from 'components/common/withRole';
import ExcelDownload from 'components/common/ExcelDownload';
import { SHOPS } from 'constants/urls';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import RegionFilter from './fields/RegionFilter';
import CityFilter from './fields/CityFilter';
import NetworkFilter from './fields/NetworkFilter';
import SupervisorFilter from './fields/SupervisorFilter';

const ShopFilter = param => withRole(
    ({ isMarketingCoordinator, values }) => {
        const controls = param ? undefined : (
            <Col sm={2} xs={24} className="controls-block">
                <ExcelDownload query={values} url={SHOPS} />
            </Col>
        );

        return (
            <BaseSearchFilter controls={controls}>
                { !isMarketingCoordinator &&
                    <Col sm={4} xs={12}>
                        <RegionFilter param={param} />
                    </Col>
                }
                <Col sm={4} xs={12}>
                    <CityFilter param={param} />
                </Col>
                <Col sm={4} xs={12}>
                    <NetworkFilter param={param} />
                </Col>
                <Col sm={4} xs={12}>
                    <SupervisorFilter param={param} />
                </Col>
            </BaseSearchFilter>
        );
    }
);

export default withFilterForm(ShopFilter());
export const ShopFilterWithParam = param => withFilterForm(ShopFilter(param));
