export const TIMETABLE_STATUSES = [
    {
        status: 'work',
        value: 'Работал',
        short: 'Р',
        color: 'green',
        hexColor: '#A0CD63'
    },
    {
        status: 'holiday',
        value: 'Выходной',
        short: 'В',
        color: 'cyan',
        hexColor: '#ecccb2'
    },
    {
        status: 'vacation',
        value: 'Отпуск',
        short: 'ОТ',
        color: 'gold',
        hexColor: '#F6C242'
    },
    {
        status: 'vacation_without_pay',
        value: 'День за свой счет',
        short: 'ДО',
        color: 'orange',
        hexColor: '#EB3224',
        fontColor: '#fff'
    },
    {
        status: 'absence',
        value: 'Отсутствовал',
        short: 'ПР',
        color: 'black',
        hexColor: '#5C2825',
        fontColor: '#fff'
    },
    {
        status: 'business_trip',
        value: 'Командировка',
        short: 'К',
        color: 'beige',
        hexColor: '#BFC0BF'
    },
    {
        status: 'training',
        value: 'Обучение',
        short: 'Т',
        color: 'geekblue',
        hexColor: '#4BAEEB',
        fontColor: '#fff'
    },
    {
        status: 'sick_leave',
        value: 'Больничный',
        short: 'б/л',
        color: 'yellow',
        hexColor: '#FFFD54',
    },
    {
        status: 'maternity_leave',
        value: 'Декрет',
        short: 'Д',
        color: 'dred',
        hexColor: '#D5722D',
        fontColor: '#fff'
    },
    {
        status: 'dismissal',
        value: 'Увольнение',
        short: 'У',
        color: 'purple',
        hexColor: '#68389A',
        fontColor: '#fff'
    },
];

export default TIMETABLE_STATUSES;
