import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions } from 'react-async-client';
import { Select } from 'antd';
import { find, any, is, path, pathOr, propOr, propEq, isEmpty, filter, includes } from 'ramda';
import debounce from 'debounce';

import { debounceDelay } from 'constants/debounce';
import { fullAutoOptions } from 'constants/asyncClient';
import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

export const getSelectedEmbedded = (name, props) => pathOr({}, ['form', 'values', '_embedded', name], props);
export const getCurrentEmbedded = pathOr({}, ['form', 'values', '_embedded']);

const getFilter = ({ filter, form, values }) => {
    if (is(Function, filter)) {
        return filter(propOr(values || {}, 'values', form));
    } else {
        return filter;
    }
};

@withFormItem
@withAsyncActions(props => ({
    asyncAction: props.action
        .withParams({
            name: props.param || 'dict',
            disableRelations: pathOr(true, ['disableRelations'], props),
        })
        .withPayload(props => props.getActionPayload ? props.getActionPayload(props.form) : ({
            q: getFilter(props),
            limit: 0,
        }))
}), fullAutoOptions)
export class SearchSelect extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        disabled: PropTypes.bool,
        allowClear: PropTypes.bool,
        action: PropTypes.func,
        itemsProp: PropTypes.string,
        namePath: PropTypes.array,
        filter: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.func,
        ]),
    };

    static defaultProps = {
        filter: {},
        ignored: [],
    };

    getName(item) {
        const { namePath } = this.props;

        return namePath.reduce((str, path) => item[path] ? `${str}${item[path]} ` : str, '');
    }

    renderOption = (item) => {
        const { namePath, renderItem } = this.props;
        const name = namePath ? this.getName(item) : item.name;

        return (
            <Select.Option key={item.id} value={item.id} name={name}>
                { renderItem ? renderItem(item) : name }
            </Select.Option>
        );
    }

    renderOptions() {
        const name = path(['field', 'name'], this.props);
        const selected = getSelectedEmbedded(name, this.props);
        const { value, asyncAction: { data }, multiple, ignored, itemsProp = 'items' } = this.props;
        const items = data[itemsProp] || [];

        const options = filter(item => !includes(item.id, ignored), items).map(this.renderOption);

        const notFoundValue = value && (multiple ? !any(item => includes(item.id, value), items) : !any(propEq('id', value), items));

        return selected && !isEmpty(selected) && !items.length
            ? (multiple ? selected.map(this.renderOption) : [this.renderOption(selected)])
            : notFoundValue && selected && items.length
                ? [
                    ...(multiple ? selected.map(this.renderOption) : [this.renderOption(selected)]),
                    ...options
                ]
                : options;
    }

    onSearch = name => {
        const { asyncAction: { dispatch } } = this.props;
        const filter = getFilter(this.props);
        const payload = this.props.getActionPayload ? this.props.getActionPayload(this.props.form) : ({
            q: { name, ...filter },
            limit: 0,
        });

        dispatch(payload);
    }

    onChange = (value, label) => {
        const { asyncAction: { data: { items = [] }}, field: { name }, multiple } = this.props;
        const currentEmbedded = getCurrentEmbedded(this.props);
        const selected = multiple ? filter(item => any(v => v === item.id, value), items) : find(propEq('id', value), items);

        this.props.form.setFieldValue('_embedded', {
            ...currentEmbedded,
            [name]: selected,
        });

        this.props.onChange(value, items);
    }

    render() {
        const { placeholder, disabled, allowClear, field: { value }, asyncAction: { meta }, multiple } = this.props;

        return (
            <Select
                value={value || undefined}
                placeholder={placeholder}
                showSearch={true}
                allowClear={allowClear}
                onChange={this.onChange}
                onSearch={debounce(this.onSearch, debounceDelay)}
                filterOption={false}
                optionLabelProp='name'
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 'auto', maxWidth: '400px' }}
                getPopupContainer={trigger => trigger.parentNode}
                loading={meta.pending}
                disabled={disabled}
                mode={multiple ? 'multiple' : null}
                maxTagCount={multiple ? 'responsive' : undefined}
                showArrow
            >
                { this.renderOptions() }
            </Select>
        );
    }
}

export default withFormikField(SearchSelect);
