import {
    AppstoreOutlined,
    BarsOutlined,
    CalendarOutlined,
    CheckOutlined,
    CopyOutlined,
    EnvironmentOutlined,
    EyeOutlined,
    FileOutlined,
    GroupOutlined,
    HomeOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    SolutionOutlined,
    TeamOutlined,
    WalletOutlined,
    CameraOutlined,
} from '@ant-design/icons';
import { noPromoter, PROMOTER, ADMIN, CLIENT, MARKETING_COORDINATOR, SUPERVISOR } from './roles';
import { getHasReports } from '../utils/photoReports';

const LEFT_MENU_ITEMS = [
    {
        name: 'Главная',
        icon: <HomeOutlined />,
        route: '/',
        matchOptions: {
            exact: true
        },
        roles: [PROMOTER, SUPERVISOR]
    },
    {
        name: 'Чекин',
        icon: <CheckOutlined />,
        route: '/checkin',
        roles: [PROMOTER]
    },
    {
        name: 'Визиты',
        icon: <EyeOutlined />,
        route: '/visits',
        roles: [PROMOTER]
    },
    {
        name: 'Пользователи',
        icon: <TeamOutlined />,
        route: '/users',
        roles: [ADMIN, CLIENT, MARKETING_COORDINATOR]
    },
    {
        name: 'Магазины и сети',
        icon: <ShopOutlined />,
        route: '/shops',
        routes: ['/networks', '/shop'],
        roles: [ADMIN, CLIENT, MARKETING_COORDINATOR]
    },
    {
        name: 'География',
        icon: <EnvironmentOutlined />,
        route: '/regions',
        routes: ['/cities'],
        roles: [ADMIN, CLIENT]
    },
    {
        name: 'Продукция',
        icon: <ShoppingCartOutlined />,
        route: '/products',
        routes: ['/categories', '/groups', '/departments', '/brands'],
        roles: [ADMIN, CLIENT, MARKETING_COORDINATOR]
    },
    {
        name: 'Продажи',
        icon: <WalletOutlined />,
        route: '/sales'
    },
    {
        name: 'Фотоотчеты',
        icon: <CameraOutlined />,
        route: '/photo',
        hide: !getHasReports(),
        roles: [ADMIN, PROMOTER]
    },
    {
        name: 'Планы',
        icon: <CalendarOutlined />,
        route: '/plans',
        roles: noPromoter
    },
    {
        name: 'Фокусные линейки',
        icon: <GroupOutlined />,
        route: '/focus',
        roles: noPromoter
    },
    {
        name: 'Чекины',
        icon: <CheckOutlined />,
        route: '/checkins',
        roles: noPromoter
    }, {
        name: 'Табель',
        icon: <AppstoreOutlined />,
        route: '/timetable',
        roles: noPromoter
    },
    {
        name: 'Задачи',
        icon: <BarsOutlined />,
        route: '/tasks',
        routes: ['/task'],
        roles: noPromoter
    },
    {
        name: 'Задачи',
        icon: <BarsOutlined />,
        route: '/reports',
        routes: ['/report'],
        roles: [PROMOTER]
    },
    {
        name: 'Новости',
        route: '/news',
        icon: <CopyOutlined />
    },
    {
        name: 'Документы',
        route: '/documents',
        icon: <FileOutlined />
    },
    {
        name: 'Распоряжения',
        route: '/instructions',
        icon: <SolutionOutlined />,
        roles: [ADMIN]
    },
    {
        name: 'Настройки продаж',
        route: '/settings',
        icon: <SettingOutlined />,
        roles: [ADMIN]
    }
];

export default LEFT_MENU_ITEMS;
