import React from 'react';
import { Field } from 'formik';

import Select from 'components/common/form/Select';
import { TASK_PRIORITIES } from 'constants/tasks';

const TaskPriorityFilter = () => (
    <Field
        name='priority'
        component={Select}
        placeholder='Приоритет'
        options={TASK_PRIORITIES}
        allowClear={true}
        path='value' />
)

export default TaskPriorityFilter;
