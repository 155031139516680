import React from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons';

const ProductName = ({ product = {} }) => {
    const { name } = product;
    const { ean } = product;

    return name ? (
        <span className='product-element'>
            <ShoppingCartOutlined />
            <span className="text-bold">{name}</span><br/>
            {ean &&
                <small>#{ean}</small>
            }
        </span>
    ) : null;
}

export default ProductName;
