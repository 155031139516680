import React from 'react';

const InfoBlock = ({ iconType, title, children, hide }) => !hide && (
  <div>
      <div className="info-head">
          <h3>{iconType}{title}</h3>
      </div>
      <div className="info-body">
          {children}
      </div>
  </div>
);

export default InfoBlock;
