import React from 'react';

import TextEditorView from '../TextEditorView';

const TaskText = ({ text }) => (
    <div className='task-text-block'>
        <TextEditorView content={text} />
    </div>
);

export default TaskText;
