import React from 'react';

import InstructionsBase from './InstructionsBase';
import { PlannedInstructionFilter, defaultPlannedInstructionFilter } from 'filters/InstructionFilter';

const PlannedInstructions = () => (
    <InstructionsBase
        filterForm={PlannedInstructionFilter}
        initialFilters={defaultPlannedInstructionFilter} />
);

export default PlannedInstructions;
