import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Field } from 'formik';
import { Button, Row, Col, Popconfirm } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { withAsyncActions } from 'react-async-client';
import { connect } from 'react-redux';
import { append, filter, without } from 'ramda';
import * as yup from 'yup';
import moment from 'moment';

import PageForm from 'components/common/PageForm';
import TextEditor from 'components/common/form/TextEditor';
import DocumentDownload from 'components/scenes/documents/DocumentDownload';
import { openDocumentAttachModal } from 'actions/modalActions';
import { postTaskReport } from 'actions/asyncActions';
import { formAutoOptions } from 'constants/asyncClient';

const validationSchema = yup.object().shape({
    text: yup.string().trim().required()
});

@withRouter
@connect(null, { openDocumentAttachModal })
@withAsyncActions({ postTaskReport }, formAutoOptions)
export default class TaskReportForm extends Component {
    static propTypes = {
        task: PropTypes.object.isRequired,
        checkin: PropTypes.object.isRequired
    };

    constructor() {
        super();

        this.state = {
            files: [],
        };
    }

    onSubmitSuccess = () => {
        const { push } = this.props.history;

        push('/checkin');
    }

    onAdd = (file, formProps) => {
        this.setState({ files: append(file, this.state.files || []) });
        formProps.setFieldValue('files', append(file.id, formProps.values.files || []));
    }

    onRemove = (id, formProps) => {
        this.setState({ files: filter(file => file.id === id, this.state.files || []) });
        formProps.setFieldValue('files', without(id, formProps.values.files || []));
    }

    renderForm = ({ isSubmitting, handleSubmit, openDocumentAttachModal, ...props }) => {
        const { files } = this.state;

        return (
            <div>
                <Field
                    name='text'
                    component={TextEditor}
                    label='Текст:'
                />
                <Row gutter={10}>
                    <Col span={12}>
                        {files.map((file) => (
                            <p key={file.id}>
                                <DocumentDownload id={file.id}>{file.name}{' '}</DocumentDownload>
                                <Popconfirm
                                    arrowPointAtCenter
                                    placement='topRight'
                                    title='Вы действительно хотите открепить документ?'
                                    onConfirm={() => this.onRemove(file.id, props)}>
                                    <DeleteOutlined className='action-icon' />
                                </Popconfirm>
                            </p>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button icon={<PlusOutlined />} onClick={() => openDocumentAttachModal({ onAdd: file => this.onAdd(file, props) })}>Загрузить файл</Button>
                    </Col>
                    <Col xs={12} className='text-right'>
                        <Button htmlType='submit' onClick={handleSubmit} type='primary' disabled={isSubmitting}>Отправить</Button>
                    </Col>
                </Row>
            </div>
        );
    }

    getInitialValues() {
        const { task: { id }, checkin: { shop }} = this.props;
        const date = moment.utc().toDate();

        return { task: id, date, shop };
    }

    render() {
        const { postTaskReport, openDocumentAttachModal } = this.props;

        return (
            <PageForm
                initialValues={this.getInitialValues()}
                formAction={postTaskReport}
                renderForm={this.renderForm}
                openDocumentAttachModal={openDocumentAttachModal}
                onSubmitSuccess={this.onSubmitSuccess}
                validationSchema={validationSchema} />
        );
    }
}
