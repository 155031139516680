import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import RegionFilter from './fields/RegionFilter';
import NetworkFilter from './fields/NetworkFilter';
import TaskStatusFilter from './fields/TaskStatusFilter';

function TaskProgressFilter() {
    return (
        <BaseSearchFilter>
            <Col sm={4} xs={12}>
                <RegionFilter />
            </Col>
            <Col sm={4} xs={12}>
                <NetworkFilter />
            </Col>
            <Col sm={4} xs={24}>
                <TaskStatusFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(TaskProgressFilter);
