import React from 'react';
import { Col } from 'antd';

import ExcelDownload from 'components/common/ExcelDownload';
import { NETWORKS } from 'constants/urls';
import withRole from 'components/common/withRole';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';

export default withFilterForm(withRole(props => {
    const controls = (
        <Col sm={2} xs={24} className="controls-block">
            <ExcelDownload query={props.values} url={NETWORKS} />
        </Col>
    );

    return (
        <BaseSearchFilter {...props} controls={controls} disableMobileCollapse={true} />
    );
}));
