import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { Route } from 'react-router-dom';
import { path } from 'ramda';

import PromoterInfoCard from './promoter/PromoterInfoCard';
import PromoterShopsCard from './promoter/PromoterShopsCard';

import { getPromoter } from 'actions/asyncActions';
import { openPromoterModal, openDismissalModal } from 'actions/modalActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { PUT_PROMOTER } from 'constants/actionTypes';
import createPutSaga from 'utils/putSagaCreator';
import UserHeadBar from 'components/common/cards/user/UserHeadBar';

@connect(null, { openPromoterModal, openDismissalModal })
@withAsyncActions({
    getPromoter: getPromoter
        .withPayload(path(['match', 'params']))
        .withOptions(fullAutoOptions)
        .withSaga(createPutSaga(PUT_PROMOTER, 'getPromoter'))
})
export default class PromoterCard extends Component {
    render() {
        const { getPromoter, getPromoter: { data }, openPromoterModal, openDismissalModal } = this.props;

        return (
            <div className="detail-card user-card">
                <UserHeadBar
                    actionResult={getPromoter}
                    tagText="Консультант"
                    tagColor="blue"
                    edit={() => openPromoterModal(data)}
                    del={() => openDismissalModal(data)}
                />
                <Route path='/users/promoter/:id' exact render={props => <PromoterInfoCard {...props} action={getPromoter} /> } />
                <Route path={`/users/promoter/:id/shops`} component={PromoterShopsCard} />
            </div>
        );
    }
}
