import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import ProductDepartmentFilter from './fields/ProductDepartmentFilter';
import ItemStatusFilter from './fields/ItemStatusFilter';

function GroupFilter() {
    return (
        <BaseSearchFilter>
            <Col sm={4} xs={21}>
                <ProductDepartmentFilter />
            </Col>
            <Col sm={4} xs={12}>
                <ItemStatusFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(GroupFilter);
