import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import UserRoute from 'components/common/UserRoute';
import TodayInstructions from './TodayInstructions';
import PlannedInstructions from './PlannedInstructions';
import AllInstructions from './AllInstructions';

const Instructions = () => (
    <Switch>
        <UserRoute path='/instructions/today' component={TodayInstructions} />
        <UserRoute path='/instructions/planned' component={PlannedInstructions} />
        <UserRoute path='/instructions/all' component={AllInstructions} />
        <Route render={() => <Redirect to='/instructions/today' />} />
    </Switch>
);

export default Instructions;
