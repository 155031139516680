import React, { Component } from 'react';
import moment from 'moment';

export default class CheckinTimeSpent extends Component {
    render() {
        const { checkin } = this.props;

        if (checkin.in && checkin.out) {
            const inDate = moment(checkin.in.date);
            const outDate = moment(checkin.out.date);
            const duration = moment.duration(outDate.diff(inDate));
            const minutes = Math.round(duration.asMinutes());
            const hours = Math.floor(minutes / 60);

            return (
                <span>
                    {`${hours}ч ${minutes - (hours * 60)}м`}
                </span>
            );
        }

        return null;
    }
}
