import React from 'react';
import { Upload } from 'antd';

import { getJWTToken } from 'utils/token';
import { InboxOutlined } from '@ant-design/icons';

const UploadFile = ({ onChangeUpload, url, name }) => (
    <div className="input-file-block">
        <Upload.Dragger
            name={name}
            onChange={onChangeUpload}
            multiple={false}
            action={url}
            headers={{ Authorization : getJWTToken()}}>
            <p className="ant-upload-drag-icon">
               <InboxOutlined />
            </p>
            <p className="ant-upload-text">Кликните или перетащите сюда файл чтобы загрузить его</p>
            <p className="ant-upload-hint">Поддержка word, excel, ppt, jpg, png</p>
        </Upload.Dragger>
    </div>
);

export default UploadFile;
