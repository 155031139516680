import React from 'react';
import { Col, Row } from 'antd';
import { filter } from 'ramda';

import { mapIndexed } from 'utils/ramdaAdditions';
import withRole from 'components/common/withRole';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import ShopFilter from './fields/ShopFilter';
import RegionFilter from './fields/RegionFilter';
import CityFilter from './fields/CityFilter';
import PromoterFilter from './fields/PromoterFilter';
import SupervisorFilter from './fields/SupervisorFilter';
import PhotoReportFilter from './fields/PhotoReportFilter';
import RangeFilter from './fields/RangeFilter';
import NetworkFilter from './fields/NetworkFilter';

const extraFilterSize = {
    5: 12,
    6: 8,
};

function PhotoReportsFilter({ user, isMarketingCoordinator, isSupervisor, isPromoter, values, additionalControls, isMobile }) {
    const shopFilter = {
        supervisor: isSupervisor ? user.id : null,
    };

    const rolesFilters = filter(Boolean, [
        !isMarketingCoordinator && !isSupervisor && (
            <RegionFilter multiple />
        ),
        <CityFilter multiple />,
        <NetworkFilter multiple />,
        <ShopFilter filter={shopFilter} multiple />,
    ]);

    return (
        <BaseSearchFilter controls={null} disableSearch={true} additionalControls={additionalControls} disableMobileCollapse={isMobile}>
            { !isPromoter && mapIndexed((filter, index) => filter && (
                <Col key={index} sm={index < 3 ? 6 : extraFilterSize[rolesFilters.length] || 6} xs={24}>
                    {filter}
                </Col>
            ), rolesFilters)}
            <Col xs={24}>
                <Row gutter={5}>
                    {!isPromoter && (
                        <Col sm={5} xs={12}>
                            <SupervisorFilter multiple />
                        </Col>
                    )}
                    {!isPromoter && (
                        <Col sm={5} xs={12}>
                            <PromoterFilter multiple />
                        </Col>
                    )}
                    <Col sm={6} xs={12}>
                        <PhotoReportFilter multiple />
                    </Col>
                    { !isMobile &&
                        <Col sm={8} xs={24}>
                            <RangeFilter />
                        </Col>
                    }
                </Row>
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(
    withRole(PhotoReportsFilter)
);
