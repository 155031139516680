import React from 'react';
import { Col } from 'antd';
import moment from 'moment';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import InstructionStatusFilter from './fields/InstructionStatusFilter';
import InstructionTypeFilter from './fields/InstructionTypeFilter';
import RangeFilter from './fields/RangeFilter';

function TodayInstructionFilterComponent() {
    return (
        <BaseSearchFilter disableSearch={true}>
            <Col sm={5} xs={12}>
                <InstructionTypeFilter />
            </Col>
        </BaseSearchFilter>
    );
}

function PlannedInstructionFilterComponent() {
    return (
        <BaseSearchFilter disableSearch={true}>
            <Col sm={5} xs={12}>
                <InstructionTypeFilter />
            </Col>
            <Col sm={5} xs={9}>
                <RangeFilter />
            </Col>
        </BaseSearchFilter>
    );
}

function InstructionFilterComponent() {
    return (
        <BaseSearchFilter disableSearch={true}>
            <Col sm={5} xs={12}>
                <InstructionTypeFilter />
            </Col>
            <Col sm={5} xs={12}>
                <RangeFilter />
            </Col>
            <Col sm={5} xs={21}>
                <InstructionStatusFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export const TodayInstructionFilter = withFilterForm(TodayInstructionFilterComponent);
export const PlannedInstructionFilter = withFilterForm(PlannedInstructionFilterComponent);
export const InstructionFilter = withFilterForm(InstructionFilterComponent);

export const defaultTodayInstructionFilter = {
    date: moment(),
    status: 'success'
};

export const defaultPlannedInstructionFilter = {
    status: 'abstain'
};
