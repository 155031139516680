import React from 'react';
import { Tooltip } from 'antd';

import { isMobile } from 'constants/screen';
import { ShopOutlined } from '@ant-design/icons';

const CheckinShopStatus = ({ status }) => {
    const title = 'Магазин не привязан к консультанту';
    const incorrect = status === 'incorrect';

    return incorrect ? (
        <Tooltip arrowPointAtCenter placement="topRight" title={title}>
            <span className='no-shop-icon'>
                <ShopOutlined />&nbsp;
                { isMobile() && title }
            </span>
        </Tooltip>
    ) : null;
}

export default CheckinShopStatus;
