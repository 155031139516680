import React from 'react';
import { Button, Tag, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';
import { DeleteOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';

import withRole from '../../withRole';
import HeadBlock from '../HeadBlock';
import PersonName from '../person/PersonName';
import PersonPhoto from '../person/PersonPhoto';
import Spinner from '../../Spinner';
import { putRestoreUser, formAutoOptions } from 'actions/asyncActions';

export default withAsyncActions({
    putRestoreUser: putRestoreUser
        .withErrorHandler(() => message.error('Не удалось восстановить пользователя'))
        .withSuccessHandler(props => {
            if (props.actionResult) {
                props.actionResult.refresh();
            }
            message.success('Пользователь восстановлен, привяжите его к магазину и супервайзеру');
        })
        .withPayload(path(['data', 'id']))
        .withOptions(formAutoOptions)
})(withRole(({ actionResult = {}, tagText, additionalTagText, edit, del, tagColor, isAdmin, putRestoreUser, ...props }) => {
    const { data, meta = {} } = actionResult;

    if (meta.pending) {
        return <div className="head-spinner"><Spinner/></div>;
    }

    const avatar = <PersonPhoto size='large' person={data} />;
    const title = <PersonName person={data} />;
    const inscription = <span>
        <Tag color={tagColor}>{tagText}</Tag>
        { additionalTagText && <Tag>{additionalTagText}</Tag>}
        { !data.enabled && <Tag className="color-red">Уволен</Tag> }
    </span>;

    const controls = isAdmin ? (
        data.enabled ? (
            <div>
                { del && <Button type='danger' onClick={del}><DeleteOutlined />Уволить</Button>}
                { edit && <Button icon={<EditOutlined />} onClick={edit}>Редактировать</Button> }
            </div>
        ) : data.id && (
            <div>
                <Button onClick={() => putRestoreUser.dispatch(data.id)}><RollbackOutlined />Вернуть</Button>
            </div>
        )
    ) : null;

    return <HeadBlock title={title} avatar={avatar} inscription={inscription} controls={controls} {...props} />;
}));
