import React, { Component } from 'react';
import { Layout } from 'antd';
import { YMaps } from 'react-yandex-maps';

import { YMAPS_TOKEN } from 'constants/yandexMap';
import { isMobile } from 'constants/screen';
import LeftMenu from './LeftMenu';
import Modals from '../../modals/Modals';
import { UserRoutes } from '../../Routes';

export default class UserLayout extends Component {
    constructor() {
        super();

        this.state = {
            collapsed: isMobile(),
        };
    }

    handleCollapse = (state) => {
        this.setState(prev => (state ? state : { collapsed: !prev.collapsed }));
    }

    render() {
        const { collapsed } = this.state;

        return (
            <YMaps query={{ apikey: YMAPS_TOKEN, coordorder: 'longlat', load: ['control.ZoomControl', 'geoObject.addon.balloon'] }}>
                <Layout className='user-layout ant-layout-has-sider'>
                    <LeftMenu handleCollapse={this.handleCollapse} collapsed={collapsed} />
                    <Layout className='layout-wrapper'>
                        <Layout.Content>
                            <div className='content-wrapper'>
                                <UserRoutes />
                            </div>
                        </Layout.Content>
                    </Layout>
                    <Modals />
                </Layout>
            </YMaps>
        );
    }
}
