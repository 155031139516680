import { append, remove, assocPath } from 'ramda';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
    LOGOUT,
    CLOSE_MODAL,
    HIDE_MODAL,
    SHOW_MODAL,
    OPEN_SHOP_MODAL,
    OPEN_NETWORK_MODAL,
    OPEN_PRODUCT_MODAL,
    OPEN_PRODUCT_CATEGORY_MODAL,
    OPEN_PRODUCT_GROUP_MODAL,
    OPEN_PRODUCT_DEPARTMENT_MODAL,
    OPEN_CLIENT_MODAL,
    OPEN_MARKETING_COORDINATOR_MODAL,
    OPEN_SUPERVISOR_MODAL,
    OPEN_PROMOTER_MODAL,
    OPEN_SALE_EDIT_MODAL,
    OPEN_SALES_PRODUCT_MODAL,
    OPEN_CHECKIN_MODAL,
    OPEN_CITY_MODAL,
    OPEN_REGION_MODAL,
    OPEN_COORDINATES_LOADER,
    OPEN_TIMETABLE_MODAL,
    OPEN_TIMETABLE_SYNC_MODAL,
    OPEN_TIMETABLE_PERSON_SHOPS_MODAL,
    OPEN_NEWS_MODAL,
    OPEN_DOCUMENT_MODAL,
    OPEN_DOCUMENT_ATTACH_MODAL,
    OPEN_PROMOTER_SUPERVISOR_BIND_MODAL,
    OPEN_SHOP_SUPERVISOR_BIND_MODAL,
    OPEN_SHOP_PROMOTER_BIND_MODAL,
    OPEN_SUPERVISOR_SHOP_BIND_MODAL,
    OPEN_PROMOTER_SHOP_BIND_MODAL,
    OPEN_DISMISSAL_MODAL,
    OPEN_UNBIND_MODAL,
    OPEN_PREVIEW_MODAL,
    OPEN_IMPORT_MODAL,
    OPEN_CROP_IMAGE_MODAL,
    OPEN_SHOP_MAP_MODAL,
    OPEN_BRAND_MODAL,
    OPEN_MAP_MODAL,
    OPEN_ADMIN_MODAL,
    OPEN_GRADE_MODAL,
    OPEN_FOCUS_CATEGORIES_PROJECT_MODAL,
    OPEN_PLAN_PROMOTER_MODAL,
    OPEN_FOCUS_CATEGORIES_COPY_MODAL,
    OPEN_SALES_SETTINGS_MODAL,
    OPEN_PLAN_COMMENT_MODAL,
    OPEN_PHOTO_REPORT_MODAL,
} from 'constants/actionTypes';

const modals = (state = [], action) => {
    switch (action.type) {
        case OPEN_SHOP_MODAL:
        case OPEN_NETWORK_MODAL:
        case OPEN_PRODUCT_MODAL:
        case OPEN_PRODUCT_CATEGORY_MODAL:
        case OPEN_PRODUCT_GROUP_MODAL:
        case OPEN_PRODUCT_DEPARTMENT_MODAL:
        case OPEN_CLIENT_MODAL:
        case OPEN_MARKETING_COORDINATOR_MODAL:
        case OPEN_SUPERVISOR_MODAL:
        case OPEN_PROMOTER_MODAL:
        case OPEN_SALE_EDIT_MODAL:
        case OPEN_SALES_PRODUCT_MODAL:
        case OPEN_CHECKIN_MODAL:
        case OPEN_CITY_MODAL:
        case OPEN_REGION_MODAL:
        case OPEN_COORDINATES_LOADER:
        case OPEN_TIMETABLE_MODAL:
        case OPEN_TIMETABLE_SYNC_MODAL:
        case OPEN_TIMETABLE_PERSON_SHOPS_MODAL:
        case OPEN_NEWS_MODAL:
        case OPEN_DOCUMENT_MODAL:
        case OPEN_DOCUMENT_ATTACH_MODAL:
        case OPEN_PROMOTER_SUPERVISOR_BIND_MODAL:
        case OPEN_SHOP_SUPERVISOR_BIND_MODAL:
        case OPEN_SHOP_PROMOTER_BIND_MODAL:
        case OPEN_DISMISSAL_MODAL:
        case OPEN_UNBIND_MODAL:
        case OPEN_PREVIEW_MODAL:
        case OPEN_IMPORT_MODAL:
        case OPEN_CROP_IMAGE_MODAL:
        case OPEN_SHOP_MAP_MODAL:
        case OPEN_SUPERVISOR_SHOP_BIND_MODAL:
        case OPEN_PROMOTER_SHOP_BIND_MODAL:
        case OPEN_BRAND_MODAL:
        case OPEN_MAP_MODAL:
        case OPEN_ADMIN_MODAL:
        case OPEN_GRADE_MODAL:
        case OPEN_FOCUS_CATEGORIES_PROJECT_MODAL:
        case OPEN_PLAN_PROMOTER_MODAL:
        case OPEN_FOCUS_CATEGORIES_COPY_MODAL:
        case OPEN_SALES_SETTINGS_MODAL:
        case OPEN_PLAN_COMMENT_MODAL:
        case OPEN_PHOTO_REPORT_MODAL:
            const modal = {
                type: action.type,
                params: action.payload,
                visible: true
            };

            return append(modal, state);
        case CLOSE_MODAL:
            return remove(action.payload, 1, state);
        case HIDE_MODAL:
            return assocPath([action.payload, 'visible'], false, state);
        case SHOW_MODAL:
            return assocPath([action.payload, 'visible'], true, state);
        case LOGOUT:
        case LOCATION_CHANGE:
            return [];
        default:
            return state;
    }
};

export default modals;
