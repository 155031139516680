import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import UserItemStatusFilter from './fields/UserItemStatusFilter';

function GradesFilter(props) {
    return (
        <BaseSearchFilter searchFieldName='term'>
            <Col sm={4} xs={12}>
                <UserItemStatusFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(GradesFilter);

export const defaultFilter = {
    status: 'active'
};
