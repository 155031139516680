import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import withRole from 'components/common/withRole';
import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import { productMenuItems } from 'menus';
import { getProductGroups } from 'actions/asyncActions';
import GroupFilter from 'filters/GroupFilter';
import { openProductGroupModal } from 'actions/modalActions';
import { PlusOutlined } from '@ant-design/icons';
import { DELETE_PRODUCT_GROUP, POST_PRODUCT_GROUP, PUT_PRODUCT_GROUP } from 'constants/actionTypes';

@withRole
@connect(null, { openProductGroupModal })
export default class Groups extends Component {
    getColumns = isAdmin => [
        {
            title: 'Группа',
            dataIndex: 'name',
            key: 'group',
            render: (text, item) => isAdmin ? (
                <span className='span-link' onClick={() => this.props.openProductGroupModal(item)}>{text}</span>
            ) : (
                text
            ),
        }, {
            title: 'Департамент',
            dataIndex: ['_embedded', 'productDepartment', 'name'],
            key: 'department'
        }
    ];

    getMenuControls() {
        const { openProductGroupModal, isAdmin } = this.props;

        return (
            <div>
                { isAdmin && (
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => openProductGroupModal()}>Создать</Button>
                )}
            </div>
        );
    }

    render() {
        const { isAdmin } = this.props;

        return (
            <div>
                <Menu
                    items={productMenuItems}
                    controls={this.getMenuControls()} />
                <ListTable
                    tableColumns={this.getColumns(isAdmin)}
                    action={getProductGroups}
                    refreshActions={[POST_PRODUCT_GROUP, PUT_PRODUCT_GROUP, DELETE_PRODUCT_GROUP]}
                    filterForm={GroupFilter} />
            </div>
        );
    }
}
