import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { any, path, pathOr } from 'ramda';

import { getAdmin, postAdminPhoto, deleteAdminPhoto } from 'actions/asyncActions';
import { openAdminModal } from 'actions/modalActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { PUT_ADMIN } from 'constants/actionTypes';
import { SUPER_ADMIN } from 'constants/roles';
import createPutSaga from 'utils/putSagaCreator';
import UserHeadBar from 'components/common/cards/user/UserHeadBar';
import UserInfo from 'components/common/cards/user/UserInfo';
import withRole from 'components/common/withRole';

@withRole
@connect(null, { openAdminModal })
@withAsyncActions({
    getAdmin: getAdmin
        .withPayload(path(['match', 'params']))
        .withOptions(fullAutoOptions)
        .withSaga(createPutSaga(PUT_ADMIN, 'getAdmin'))
})
export default class AdminCard extends Component {
    render() {
        const { getAdmin, getAdmin: { data }, openAdminModal, isAdmin } = this.props;
        const superAdminTag = any(role => role === SUPER_ADMIN, pathOr([], ['roles'], data)) ? 'Супер-администратор' : null;

        return (
            <div className="detail-card user-card">
                <UserHeadBar actionResult={getAdmin} tagText="Администратор" additionalTagText={superAdminTag} tagColor="red" edit={() => openAdminModal(data)} />
                <UserInfo
                    user={data}
                    postPhotoAction={postAdminPhoto}
                    deletePhotoAction={deleteAdminPhoto}
                    refresh={() => getAdmin.refresh()}
                    disabledPhotoEdit={!isAdmin} />
            </div>
        );
    }
}
