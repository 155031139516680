import React from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';

const ArrowButton = ({ history }) => <Button className="arrow-back" type="text" onClick={() => history.goBack()}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.3333 16H5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3335 24L5.3335 16L13.3335 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</Button>;

export default withRouter(ArrowButton);
