import React from 'react';
import { Field } from 'formik';

import Select from 'components/common/form/Select';
import { INSTRUCTION_STATUSES } from 'constants/instructions';

const InstructionStatusFilter = () => (
    <Field
        name='status'
        component={Select}
        placeholder='Статус'
        allowClear={true}
        options={INSTRUCTION_STATUSES}
        path='type' />
);

export default InstructionStatusFilter;
