import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Upload, message, DatePicker, Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';
import moment from 'moment';
import { InboxOutlined } from '@ant-design/icons';

import {
    IMPORT_CITIES,
    IMPORT_SHOPS,
    IMPORT_PRODUCTS,
    IMPORT_SALE_PLANS,
    IMPORT_SUPERVISORS,
    IMPORT_PROMOTERS,
    IMPORT_GRADES,
} from 'constants/actionTypes';
import { formAutoOptions } from 'actions/asyncActions';
import Error from 'components/common/Error';
import { getToken } from 'utils/token';

const IMPORT_EXAMPLES = {
    [IMPORT_CITIES]: 'cities',
    [IMPORT_SHOPS]: 'shops',
    [IMPORT_PRODUCTS]: 'products',
    [IMPORT_SALE_PLANS]: 'plans',
    [IMPORT_SUPERVISORS]: 'supervisors',
    [IMPORT_PROMOTERS]: 'promoters',
    [IMPORT_GRADES]: 'grades',
};

const { MonthPicker } = DatePicker;
const getCorrectValue = (date) => `${(date || moment().add(1, 'month')).format('YYYY-MM')}-01`;

@withAsyncActions(({ params }) => ({
    importAction: params.action
        .withSuccessHandler(({ close }) => {
            message.success('Файл был успешно импортирован.');
            close();
        })
        .withErrorHandler(() =>
            message.error('Не удалось импортировать файл.')
        )
        .withOptions(formAutoOptions)
}))
export default class ImportModal extends Component {
    static propTypes = {
        params: PropTypes.shape({
            action: PropTypes.func.isRequired,
            title: PropTypes.string
        })
    };

    constructor(props) {
        super(props);

        this.state = {
            date: getCorrectValue(props.params.month ? moment(props.params.month) : moment().add(1, 'month')),
            selectedDate: props.params.month ? moment(props.params.month) : null
        }
    }

    beforeUpload = file => {
        const { dispatch } = this.props.importAction;

        const fd = new FormData();
        fd.append('file', file);
        dispatch({file: fd, date: this.state.date});

        return false;
    }

    onChange = (date) => {
        this.setState({ date: getCorrectValue(date), selectedDate: getCorrectValue(date) });
    }

    render() {
        const { modal, params: { title, withoutMonth, month, renderAdditional }, importAction } = this.props;
        const exampleFileName = IMPORT_EXAMPLES[importAction.type];

        return (
            <Modal {...modal} footer={null} title={title || 'Импорт'}>
                { exampleFileName && (
                    <p className='text-center'>
                        <a href={`/api/${exampleFileName}.xlsx?template=1&token=${getToken()}`} target="_blank" rel="noreferrer">
                            Пример файла для импорта
                        </a>
                    </p>
                )}
                { !withoutMonth && <MonthPicker
                    defaultValue={month ? moment(month) : undefined}
                    onChange={this.onChange}
                    placeholder="Выберите месяц"
                    format="MMMM"
                    className="mb15"
                />}
                <Spin spinning={importAction.meta.pending}>
                    <Upload.Dragger
                        showUploadList={false}
                        multiple={false}
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                        beforeUpload={this.beforeUpload}>
                        <p className='ant-upload-drag-icon'><InboxOutlined /></p>
                        <p className='ant-upload-text'>Кликните или перетащите сюда файл в формате excel</p>
                    </Upload.Dragger>
                </Spin>
                { renderAdditional && renderAdditional(this.props, this.state.selectedDate) }
                { importAction.meta.error ? (
                    <div className='p15'>
                        <Error meta={importAction.meta} showError />
                    </div>
                ) : null}
            </Modal>
        );
    }
}
