import React from 'react';
import { path } from "ramda";

const ProductCategory = ({ category = {} }) => {
    const productCategoryName = path(['_embedded', 'productCategory', 'name'], category);
    const productGroupName = path(['_embedded', 'productGroup', 'name'], category);
    const productDepartmentName = path(['_embedded', 'productDepartment', 'name'], category);

    return category ? (
        <span className='product-category'>
            {`${productCategoryName || ''}`} <br/>
            <small>{`${productGroupName || ''} / ${productDepartmentName || ''}`}</small>
        </span>
    ) : null;
}

export default ProductCategory;
