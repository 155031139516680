import React, { Component } from 'react';
import { Field } from 'formik';
import * as yup from 'yup';
import { withAsyncActions } from 'react-async-client';
import { Alert, Form, Button, message, Spin } from 'antd';
import { NavLink } from 'react-router-dom';

import { postReset, getRecovery, formAutoOptions } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import Input from 'components/common/form/Input';
import PageForm from 'components/common/PageForm';
import { UnlockOutlined } from '@ant-design/icons';

const validationSchema = yup.object().shape({
    password: yup.string().required().min(6, 'Пароль должен быть не короче 6 символов'),
});

@withAsyncActions({
    postReset: postReset
        .withOptions(formAutoOptions)
        .withErrorHandler(() => message.error('Не удалось изменить пароль'))
        .withSuccessHandler(({ history }) => {
            message.success('Пароль успешно изменен.');
            history.push('/');
        }),
    getRecovery: getRecovery
        .withPayload(({ match }) => match.params.token)
        .withOptions(fullAutoOptions)
})
export default class ResetView extends Component {
    renderForm = ({ isSubmitting, handleSubmit }) => (
        <div>
            <Field
                name='password'
                htmlType='password'
                component={Input}
                prefix={<UnlockOutlined />}
                placeholder='Пароль'
            />
            <Form.Item>
                <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    disabled={isSubmitting}
                    onClick={handleSubmit}>
                    Отправить
                </Button>
            </Form.Item>
        </div>
    );

    getInitialValues() {
        const { token } = this.props.match.params;

        return { token, password: '' };
    }

    render() {
        const { postReset, getRecovery: { meta } } = this.props;

        return (
            <div>
                <div className='guest-header'>
                    <h2>Восстановление пароля</h2>
                </div>
                { meta.error ? (
                    <div>
                        <Alert
                            className='text-center mb10'
                            message='Ссылка недействительна'
                            type='error'
                        />
                        <NavLink to='/'>
                            Войти
                        </NavLink>
                    </div>
                ) : meta.pending ? (
                    <Spin />
                ) : (
                    <PageForm
                        initialValues={this.getInitialValues()}
                        validationSchema={validationSchema}
                        formAction={postReset}
                        renderForm={this.renderForm}
                    />
                )}
            </div>
        );
    }
}
