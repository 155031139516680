import React, { Component } from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { Button } from 'antd';

import CommonTasks from './CommonTasks';
import ShopTasks from './ShopTasks';
import UserRoute from 'components/common/UserRoute';
import Menu from 'components/common/Menu';
import withRole from 'components/common/withRole';
import { taskMenuItems } from 'menus';
import { noPromoter } from 'constants/roles';
import { PlusOutlined } from '@ant-design/icons';

@withRole
export default class Tasks extends Component {
    getMenuControls() {
        return (
            <Link to='/task/create'>
                <Button type='primary' icon={<PlusOutlined />}>Добавить</Button>
            </Link>
        );
    }

    render() {
        const { match: { url }, isPromoter, isClient } = this.props;

        return (
            <div className='tasks'>
                { (!isClient && !isPromoter) &&
                    <Menu
                        items={taskMenuItems}
                        controls={this.getMenuControls()} />
                }
                <Switch>
                    <UserRoute path={`${url}/common`} component={CommonTasks} />
                    <UserRoute path={`${url}/shops`} component={ShopTasks} access={noPromoter} />
                    <Route component={() => <Redirect to={`${url}/common`}/>} />
                </Switch>
            </div>
        );
    }
}
