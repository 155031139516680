import React, { Component } from 'react';
import { Field } from 'formik';
import * as yup from 'yup';
import { withAsyncActions } from 'react-async-client';
import { Form, Button, Alert, message } from 'antd';
import { NavLink } from 'react-router-dom';

import { postRecovery, formAutoOptions } from 'actions/asyncActions';
import Input from 'components/common/form/Input';
import PageForm from 'components/common/PageForm';
import { MailOutlined } from '@ant-design/icons';

const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
});

const initialValues = {
    email: ''
};

@withAsyncActions({
    postRecovery: postRecovery
        .withErrorHandler(() => message.error('Не удалось запросить восстановление пароля'))
}, formAutoOptions)
export default class RecoveryView extends Component {
    renderForm = ({ isSubmitting, handleSubmit }) => (
        <div>
            <Field
                name='email'
                component={Input}
                htmlType='email'
                prefix={<MailOutlined />}
                placeholder='E-mail'
            />
            <Form.Item>
                <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                >
                    Отправить
                </Button>
            </Form.Item>
        </div>
    );

    render() {
        const { postRecovery } = this.props;
        const { success } = postRecovery.meta;

        return (
            <div>
                <div className='guest-header'>
                    <h2>Восстановление пароля</h2>
                </div>
                { success ? (
                    <Alert
                        className='text-center mb10'
                        message='На Ваш персональный почтовый ящик отправлено сообщение. Перейдите по ссылке в сообщении и следуйте дальнейшим инструкциям для изменения пароля.'
                        type='success'
                    />
                ) : (
                    <PageForm
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        formAction={postRecovery}
                        renderForm={this.renderForm}
                    />
                )}
                <NavLink to='/'>
                    Войти
                </NavLink>
            </div>
        );
    }
}
