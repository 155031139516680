import { Component } from 'react';

import withFormItem from './withFormItem';
import withFormikField from './withFormikField';

@withFormItem
export class ArrayComponent extends Component {
    render() {
        return this.props.button;
    }
}

export default withFormikField(ArrayComponent);
