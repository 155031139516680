import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import withRole from 'components/common/withRole';
import { openProductModal, openImportModal } from 'actions/modalActions';
import { getProductsList, importProducts } from 'actions/asyncActions';
import ListTable from 'components/common/ListTable';
import Menu from 'components/common/Menu';
import ProductFilter from 'filters/ProductFilter';
import { productMenuItems } from 'menus';
import FormattedPrice from 'components/common/cards/FormattedPrice';
import ProductFocus from './ProductFocus';
import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { DELETE_PRODUCT, IMPORT_PRODUCTS, POST_PRODUCT, PUT_PRODUCT, PUT_PRODUCTS_RESTORE } from 'constants/actionTypes';

@withRole
@connect(null, { openProductModal, openImportModal })
export default class Products extends Component {
    getColumns = isAdmin => [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, item) => isAdmin ? (
                <span className='span-link' onClick={() => this.props.openProductModal(item)}>{text}</span>
            ) : (
                text
            ),
        }, {
            title: 'Ean',
            dataIndex: 'ean',
            key: 'ean',
        }, {
            title: 'Бренд',
            dataIndex: ['_embedded', 'brand', 'name'],
            key: 'brand'
        }, {
            title: 'Линейка',
            dataIndex: ['_embedded', 'productCategory', 'name'],
            key: 'category'
        }, {
            title: 'Группа',
            dataIndex: ['_embedded', 'productGroup', 'name'],
            key: 'group'
        }, {
            title: 'Департамент',
            dataIndex: ['_embedded', 'productDepartment', 'name'],
            key: 'department'
        }, {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            render: (price) => <FormattedPrice price={price} />
        }, {
            title: 'Фокус',
            dataIndex: 'focus',
            key: 'focus',
            hide: true,
            render: (focus) => <ProductFocus focus={focus} />
        }
    ];

    getMenuControls() {
        const { openProductModal, openImportModal, isAdmin } = this.props;

        return isAdmin && (
            <div>
                <Button
                    type='success'
                    icon={<FileExcelOutlined />}
                    onClick={() => openImportModal({
                        title: 'Импортировать продукты',
                        action: importProducts,
                        withoutMonth: true
                    })}>
                    Импорт
                </Button>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openProductModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        const { isAdmin } = this.props;

        return <div>
            <Menu
                items={productMenuItems}
                controls={this.getMenuControls()}
            />
            <ListTable
                filterForm={ProductFilter}
                action={getProductsList}
                refreshActions={[POST_PRODUCT, PUT_PRODUCT, DELETE_PRODUCT, IMPORT_PRODUCTS, PUT_PRODUCTS_RESTORE]}
                tableColumns={this.getColumns(isAdmin)}
            />
        </div>;
    }
}
