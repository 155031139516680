import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Upload, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import cx from 'classnames';
import { path } from 'ramda';
import { DeleteOutlined, EyeOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { getPhotoUrl } from 'utils/photo';
import { openPreviewModal, openCropImageModal } from 'actions/modalActions';
import { formAutoOptions } from 'actions/asyncActions';

@connect(null, { openPreviewModal, openCropImageModal })
@withAsyncActions(props => ({
    deletePhoto: props.deleteAction
        .withSuccessHandler(({ refresh }) => {
            message.success('Фотография успешно удалена.');
            refresh && refresh();
        })
        .withErrorHandler(() => message.error('Не удалось удалить фотографию.')),
    postPhoto: props.postAction
        .withParams(props.user.id)
        .withSuccessHandler(({ refresh }) => {
            message.success('Фотография успешно обновлена.');
            refresh && refresh();
        })
        .withErrorHandler((props, action) => {
            const errorCode = path(['payload', 'status'], action);
            message.error(errorCode === 413 ? 'Слишком большой размер файла, попробуйте другой' : 'Не удалось обновить фотографию.')
        })
}), formAutoOptions)
export default class Photo extends Component {
    static propTypes = {
        deleteAction: PropTypes.func.isRequired,
        postAction: PropTypes.func.isRequired,
        photo: PropTypes.object
    };

    static defaultProps = {
        photo: {}
    };

    onRemove = () => {
        const { deletePhoto, user: { id }} = this.props;

        deletePhoto.dispatch(id);
    }

    handleUpload = file => {
        const { postPhoto } = this.props;

        postPhoto.dispatch(file);
    }

    beforeUpload = file => {
        const { openCropImageModal } = this.props;

        openCropImageModal({ file, handleUpload: this.handleUpload });

        return false;
    }

    render() {
        const { openPreviewModal, photo, postPhoto: { meta }, disabled } = this.props;
        const photoUrl = getPhotoUrl(photo.id);

        return (
            <div className='user-photo-uploaded-container'>
                <Upload.Dragger
                    showUploadList={false}
                    className={cx('user-photo-upload', { 'user-photo-uploaded': !!photoUrl })}
                    multiple={false}
                    accept='image/*'
                    beforeUpload={this.beforeUpload}
                    disabled={disabled}
                >
                    { meta.pending ? (
                        <LoadingOutlined />
                    ) : photoUrl ? (
                            <div style={{ backgroundImage: `url(${photoUrl})` }} className='user-photo-image'>
                                <div className='hover-black'></div>
                            </div>
                        )
                        : !disabled && <PlusOutlined />
                    }
                </Upload.Dragger>
                { !!photoUrl && (
                    <div className='user-photo-uploaded-controls'>
                        <span className='span-link' onClick={() => openPreviewModal(photoUrl)}><EyeOutlined /></span>
                        { !disabled && <span className='span-link' onClick={this.onRemove}><DeleteOutlined /></span> }
                    </div>
                )}
            </div>
        );
    }
}
