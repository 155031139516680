import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Field } from 'formik';
import * as yup from 'yup';

import SearchSelect from 'components/common/form/SearchSelect';

import {
    postMarketingCoordinator,
    putMarketingCoordinator,
    formAutoOptions,
    getRegions
} from 'actions/asyncActions';
import UserModal from './UserModal';

@withAsyncActions({
    postAction: postMarketingCoordinator,
    putAction: putMarketingCoordinator,
}, formAutoOptions)
export default class ClientModal extends Component {
    getRegionField() {
        return <Field
            name='regions'
            component={SearchSelect}
            action={getRegions}
            label='Регионы:'
            allowClear={true}
            multiple />;
    }

    getRegionValidation() {
        return {
            regions: yup.array().of(
                yup.string().required()
            ).min(1, 'Это поле обязательно').required()
        };
    }

    render() {
        return <UserModal
            {...this.props}
            createTitle={'Добавить Account Manager'}
            additionalFields={this.getRegionField()}
            additionalValidation={this.getRegionValidation()} />;
    }
}
