import { getSalePlanDepartments, putSalePlanDepartments } from 'actions/asyncActions';
import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import { DatePicker, Button, Tag, Popconfirm, Table } from 'antd';
import moment from 'moment';
import { ArrowsAltOutlined, CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { pathOr } from 'ramda';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { formAutoOptions, fullAutoOptions } from 'constants/asyncClient';
import { openFocusCategoriesProjectModal, openFocusCategoriesCopyModal } from 'actions/modalActions';
import createPutSaga from 'utils/putSagaCreator';
import { PUT_SALE_PLAN_DEPARTMENTS } from 'constants/actionTypes';
import withRole from '../../common/withRole';

const DragHandle = sortableHandle(() => <ArrowsAltOutlined style={{ transform: 'rotate(-45deg)', cursor: 'grab', color: '#999' }} />);
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

@withRole
@withState('date', 'setDate', moment.utc().toDate())
@withState('dataSource', 'setDataSource', {})
@asyncConnect({
    getSalePlanDepartments: getSalePlanDepartments
        .withPayload(({ date }) => date.toISOString())
        .withSaga(createPutSaga(PUT_SALE_PLAN_DEPARTMENTS, 'getSalePlanDepartments'))
        .withSuccessHandler(({ getSalePlanDepartments, setDataSource }) => setDataSource(pathOr([], ['data'], getSalePlanDepartments)))
        .withOptions(fullAutoOptions),
    putSalePlanDepartments: putSalePlanDepartments
        .withSuccessHandler(({ putSalePlanDepartments, setDataSource }) => setDataSource(pathOr([], ['data'], putSalePlanDepartments)))
        .withOptions(formAutoOptions)
}, null, { openFocusCategoriesProjectModal, openFocusCategoriesCopyModal })
export default class FocusCategories extends Component {
    onChange = date => this.props.setDate(date);

    deleteProject = (item) => {
        const { dataSource } = this.props;
        this.props.putSalePlanDepartments.dispatch({
            ...dataSource,
            departmentPlans: dataSource.departmentPlans.filter(plan => plan.id !== item.id)
        });
    }

    getColumns = () => {
        const { isAdmin } = this.props;
        const columns = [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                render: (name, item) => <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='focus-category-color' style={{ background: item.color || '#ccc' }} /> { name }
                </div>
            },
            {
                title: 'Департамент',
                align: 'center',
                key: 'productDepartment',
                dataIndex: ['_embedded', 'productDepartment', 'name']
            },
            {
                title: 'Группы',
                key: 'productGroups',
                dataIndex: ['_embedded', 'productGroups'],
                render: (productGroups, item) => (productGroups || []).map(group => <Tag color="geekblue" key={`${item.id}-${group.id}`}>{ group.name }</Tag>)
            }
        ];

        return isAdmin ? [
            {
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle />,
            },
            ...columns,
            {
                width: 130,
                align: 'right',
                render: (_, item, index) => <div>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить проект?'
                        okText='Да'
                        cancelText='Нет'
                        placement='left'
                        onConfirm={() => this.deleteProject(item)}>
                        <Button type='link' icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                    <Button
                        icon={<EditOutlined />}
                        type='link'
                        onClick={() => this.props.openFocusCategoriesProjectModal({ ...this.props.dataSource, index, edit: true })} />
                </div>
            }
        ] : columns;
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { dataSource } = this.props;

        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(dataSource.departmentPlans || []), oldIndex, newIndex).filter(el => !!el);
            const value = {
                ...dataSource,
                departmentPlans: newData
            };
            this.props.setDataSource(value);
            this.props.putSalePlanDepartments.dispatch(value);
        }
    };

    DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({ className, style, ...restProps }) => {
        const { dataSource } = this.props;
        const index = (dataSource.departmentPlans || []).findIndex(x => x.id === restProps['data-row-key']);

        return <SortableItem index={index} {...restProps} />;
    };

    render() {
        const { date, getSalePlanDepartments: { meta }, openFocusCategoriesProjectModal, isAdmin, dataSource, openFocusCategoriesCopyModal } = this.props;

        return <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 15 }}>
                <DatePicker.MonthPicker
                    value={date ? moment(date) : undefined}
                    format='MMMM'
                    onChange={this.onChange}
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={false} />
                { isAdmin &&
                    <Button icon={<PlusOutlined />} type='primary' onClick={() => openFocusCategoriesProjectModal({ ...dataSource, date: this.props.date.toISOString() })}>
                        Добавить проект
                    </Button>
                }
            </div>
            <Table
                columns={this.getColumns()}
                dataSource={dataSource.departmentPlans}
                loading={meta.pending}
                pagination={false}
                rowKey='id'
                locale={isAdmin && meta.lastSucceedAt ? {
                    emptyText: <div style={{ textAlign: 'center' }}><Button onClick={() => openFocusCategoriesCopyModal(date)} icon={<CopyOutlined />}>Копировать</Button></div>
                } : undefined}
                components={{
                    body: {
                        wrapper: this.DraggableContainer,
                        row: this.DraggableBodyRow,
                    },
                }} />
        </div>;
    }
}
