import React, { Component } from 'react';
import { Field } from 'formik';
import * as yup from 'yup';
import { withAsyncActions } from 'react-async-client';

import ModalForm from 'components/common/ModalForm';
import { deleteSalePlans, getPromotersList, postSalePlans, putSalePlans } from 'actions/asyncActions';
import { formAutoOptions } from 'constants/asyncClient';
import SearchSelect from '../../common/form/SearchSelect';
import { Button, message, Popconfirm } from 'antd';
import { omit } from 'ramda';

@withAsyncActions(() => ({
    postAction: postSalePlans,
    putAction: putSalePlans,
    deleteSalePlans: deleteSalePlans
        .withSuccessHandler(({ close }) => close())
        .withErrorHandler(() => message.error('Не удалось удалить консультанта'))
}), formAutoOptions)
export default class PlanPromoterModal extends Component {
    renderForm = () => {
        const { ignored } = this.props.params;

        return (
            <div>
                <Field
                    name='promoter'
                    component={SearchSelect}
                    label='Консультант:'
                    placeholder='Выберите консультанта'
                    action={getPromotersList}
                    namePath={['lastName', 'firstName', 'middleName']}
                    ignored={ignored} />
            </div>
        );
    }

    getValidationSchema() {
        return yup.object().shape({
            promoter: yup.string().required()
        });
    }

    delete = () => {
        this.props.deleteSalePlans.dispatch(this.props.params.id);
    }

    footerControls = () => {
        return this.props.params.id ?
            <Popconfirm
                title='Вы уверены, что хотите удалить консультанта?'
                okText='Да'
                cancelText='Нет'
                onConfirm={this.delete}
                placement='right'>
                <Button
                    type='danger'>
                    Удалить
                </Button>
            </Popconfirm> : null;
    }

    render() {
        const { params } = this.props;

        return (
            <ModalForm
                {...this.props}
                params={omit(['ignored'], params)}
                createTitle={'Добавить консультанта'}
                editTitle={'Изменить консультанта'}
                validationSchema={this.getValidationSchema()}
                renderForm={this.renderForm}
                footerControls={this.footerControls}
            />
        );
    }
}
