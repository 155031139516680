import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getProductGroups } from 'actions/asyncActions';

const ProductGroupFilter = ({ values = {}, multiple }) => (
    <Field
        name={multiple ? 'productGroups' : 'productGroup'}
        component={SearchSelect}
        placeholder='Группа'
        multiple={multiple}
        allowClear={true}
        action={getProductGroups}
        filter={({ productDepartment, productDepartments }) => ({
            [productDepartments && (productDepartments.length > 1) ? 'productDepartments' : 'productDepartment']:
                productDepartment || (productDepartments && (productDepartments.length === 1) ? productDepartments[0] :  productDepartments),
        })} />
)

export default ProductGroupFilter;
