import React, { Component } from 'react';
import { find, propEq, assocPath } from 'ramda';
import { Tag, Dropdown, Menu, Button } from 'antd';

import TIMETABLE_STATUSES from 'constants/timetableStatuses';

export default class TimetableStatusSelect extends Component {
    getCurrent() {
        const { day, field: { value }} = this.props;

        return find(propEq('status', value[day - 1].status), TIMETABLE_STATUSES);
    }

    onChange(status) {
        const { form: { setFieldValue }, field: { name, value }, day } = this.props;

        setFieldValue(name, assocPath([day - 1, 'status'], status, value));
    }

    clean = () => {
        const { form: { setFieldValue }, field: { name, value }, day } = this.props;

        setFieldValue(name, assocPath([day - 1, 'status'], null, value));
    }

    renderMenu() {
        return <Menu>{ this.renderMenuItems() }</Menu>;
    }

    renderMenuItems() {
        return TIMETABLE_STATUSES.map(({ value, status }) =>
            <Menu.Item key={status} onClick={() => this.onChange(status)}>
                <span className='span-link'>{ value }</span>
            </Menu.Item>
        );
    }

    render() {
        const current = this.getCurrent();

        return (
            <div>
                { current && <Tag color={current.color}>{current.value}</Tag> }
                <Dropdown overlay={this.renderMenu()} trigger={['click']}>
                    <span className='span-link'>Изменить</span>
                </Dropdown>
                { current && <Button onClick={this.clean} type='link' danger>Очистить</Button> }
            </div>
        );
    }
}
