import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import MonthFilter from './fields/MonthFilter';
import UserTypeFilter from './fields/UserTypeFilter';
import SupervisorFilter from './fields/SupervisorFilter';
import RegionFilter from './fields/RegionFilter';
import CityFilter from './fields/CityFilter';
import NetworkFilter from './fields/NetworkFilter';
import ShopFilter from './fields/ShopFilter';

function TimetableMonthFilter(props) {
    return (
        <BaseSearchFilter controlsSpan={24} searchSpan={3} additionalControls={props.additionalControls && props.additionalControls(props)}>
            <Col span={4}>
                <MonthFilter field='minDate' allowClear={false} />
            </Col>
            <Col span={2}>
                <UserTypeFilter />
            </Col>
            <Col span={3}>
                <SupervisorFilter multiple />
            </Col>
            <Col span={3}>
                <RegionFilter multiple />
            </Col>
            <Col span={3}>
                <CityFilter multiple />
            </Col>
            <Col span={3}>
                <NetworkFilter multiple />
            </Col>
            <Col span={3}>
                <ShopFilter multiple />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(TimetableMonthFilter);

export const defaultFilter = {};
