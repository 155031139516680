import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message, DatePicker, Spin, Row, Col, Button, Form } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { range } from 'ramda';
import moment from 'moment';
import { withState } from 'recompose';

import { getTimetableSyncStatus, putTimetableSync, formAutoOptions, fullAutoOptions } from 'actions/asyncActions';
import Error from 'components/common/Error';

const { YearPicker } = DatePicker;

@withState('selectedDate', 'setSelectedDate', props => props.params.date ? moment(props.params.date) : null)
@withAsyncActions(() => ({
    getTimetableSyncStatus: getTimetableSyncStatus
        .withPayload(({ selectedDate }) => ({
            minDate: moment(selectedDate).utc().startOf('year'),
            maxDate: moment(selectedDate).utc().endOf('year'),
        }))
        .withOptions(fullAutoOptions),
    putTimetableSync: putTimetableSync
        .withSuccessHandler(({ getTimetableSyncStatus }) => {
            getTimetableSyncStatus.refresh();
            message.success('Cинхронизация прошла успешно');
        })
        .withErrorHandler(() =>
            message.error('Ошибка синхронизации данных')
        )
        .withOptions(formAutoOptions)
}))
export default class TimetableSyncModal extends Component {
    static propTypes = {
        params: PropTypes.shape({
            date: PropTypes.object,
        })
    };

    onChange = (date) => {
        this.props.setSelectedDate(moment(date));
    }

    render() {
        const { modal, selectedDate, putTimetableSync, getTimetableSyncStatus } = this.props;
        const months = getTimetableSyncStatus.data.items || range(1, 12);

        const monthsSyncRows = months.map(item => {
            let synchronizedAt = item.synchronizedAt ? `${moment(item.synchronizedAt).format('DD.MM.YYYY')} в ${moment(item.synchronizedAt).format('HH:mm')}` : ''
            let month = item.date ? moment(item.date).format('MM') : item;
            let date = moment(`${selectedDate.format('YYYY')}-${month}-02`).utc().startOf('month');

            return (
                <Row key={month} className='sync-row mb10'>
                    <Col xs={24}>
                        <b>{ date.format('MMMM') }</b> {synchronizedAt}
                        <Button
                            className='pull-right'
                            onClick={() => this.props.putTimetableSync.dispatch(date.toDate().toISOString())}
                        >
                            Синхронизировать
                        </Button>
                    </Col>
                </Row>

            );
        });

        return (
            <Modal {...modal} footer={null} title='Синхронизация табеля'>
                <Spin spinning={putTimetableSync.meta.pending || getTimetableSyncStatus.meta.pending}>
                    <Form.Item className='form-item-inline' label='Выбор года' labelCol={{ span: 2 }} wrapperCol={{ span: 4 }}>
                        <YearPicker
                            value={selectedDate}
                            onChange={this.onChange}
                            placeholder="Выберите год"
                            format="YYYY"
                            className="mb15"
                            allowClear={false}
                        />
                    </Form.Item>
                    {monthsSyncRows}
                    { putTimetableSync.meta.error ? (
                        <div className='p15'>
                            <Error meta={putTimetableSync.meta} showError />
                        </div>
                    ) : null}
                </Spin>
            </Modal>
        );
    }
}
