import React from 'react';
import { Avatar } from 'antd';
import { head } from 'ramda';

import { getPhotoUrl } from 'utils/photo';

const PersonPhoto = ({ person = {}, size = 'default'}) => {
    const url = getPhotoUrl(person.photo);

    return url ? (
        <Avatar size={size} style={{ backgroundImage: `url(${url})` }} className='person-photo' />
    ) : (
        <Avatar size={size}>{head(person.lastName || '')}</Avatar>
    );
}

export default PersonPhoto;
