import React, { Component } from 'react';
import moment from 'moment';
import { Button, Popconfirm } from 'antd';
import { asyncConnect } from 'react-async-client';

import { deleteSalesSettings, getSalesSettings } from 'actions/asyncActions';
import { DELETE_SALES_SETTINGS, POST_SALES_SETTINGS, PUT_SALES_SETTINGS } from 'constants/actionTypes';
import ListTable from '../../common/ListTable';
import Date from 'components/common/cards/Date';
import { openSalesSettingsModal } from 'actions/modalActions';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import SalesSettingsFilter from 'filters/SalesSettingsFilter';
import { formAutoOptions } from 'constants/asyncClient';

@asyncConnect({
    deleteSalesSettings: deleteSalesSettings
        .withOptions(formAutoOptions)
}, null, { openSalesSettingsModal })
export default class SalesSettings extends Component {
    delete = id => this.props.deleteSalesSettings.dispatch(id);

    getColumns = () => {
        return [
            {
                title: 'Месяц',
                dataIndex: 'month',
                key: 'month',
                render: month => moment(month).format('MMM YYYY')
            },
            {
                title: 'Дата, до которой доступно редактирование продаж',
                dataIndex: 'lastEditDate',
                key: 'lastEditDate',
                render: date => <Date date={date} />
            },
            {
                key: 'controls',
                width: 130,
                align: 'right',
                render: item => <div>
                    <Button icon={<EditOutlined />} type='link' onClick={() => this.props.openSalesSettingsModal(item)} />
                    <Popconfirm
                        title='Вы уверены, что хотите удалить настройки?'
                        okText='Да'
                        cancelText='Нет'
                        placement='left'
                        onConfirm={() => this.delete(item.id)}>
                        <Button type='link' icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                </div>
            }
        ]
    }

    getFilterControls() {
        const { openSalesSettingsModal } = this.props;

        return <div className='pull-right'>
            <Button type='primary' icon={<PlusOutlined />} onClick={() => openSalesSettingsModal()}>Добавить</Button>
        </div>;
    }

    render() {
        return <div className='table-mobile'>
            <ListTable
                filterForm={SalesSettingsFilter}
                filterControls={this.getFilterControls()}
                action={getSalesSettings}
                refreshActions={[POST_SALES_SETTINGS, PUT_SALES_SETTINGS, DELETE_SALES_SETTINGS]}
                tableColumns={this.getColumns()}
                staticPayload={{
                    sort_by: 'date',
                    sort_order: 'desc'
                }} />
        </div>
    }
}
