import React from 'react';
import { Alert } from 'antd';

const AlreadyLogged = () => (
    <div className='p15'>
        <Alert type='error' message='Вы уже авторизованы' />
    </div>
);

export default AlreadyLogged;
