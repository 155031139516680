import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import * as yup from 'yup';

import withRole from 'components/common/withRole';
import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import DatePicker from 'components/common/form/DatePicker';
import {
    formAutoOptions,
    putSalesSettings,
    deleteSalesSettings,
    postSalesSettings
} from 'actions/asyncActions';
import MonthPicker from '../../common/form/MonthPicker';

@withRole
@withRouter
@withAsyncActions({
    postAction: postSalesSettings
        .withSuccessHandler(() => message.success('Настройки успешно сохранены.'))
        .withOptions(formAutoOptions),
    putAction: putSalesSettings
        .withSuccessHandler(() => message.success('Настройки успешно сохранены.'))
        .withOptions(formAutoOptions),
    deleteAction: deleteSalesSettings
})
export default class SalesEditModal extends Component {
    static propTypes = modalFormPropTypes;

    renderForm = (props) => {
        return (
            <div>
                <Field
                    name='month'
                    component={MonthPicker}
                    label='Месяц:'
                    onChange={this.onChangeMonth}
                />
                { props.values.month ?
                    <Field
                        name='lastEditDate'
                        component={DatePicker}
                        label='Дата, до которой доступно редактирование продаж:'
                    /> : null
                }
            </div>
        );
    }

    getValidationSchema() {
        return yup.object().shape({
            month: yup.string().required(),
            lastEditDate: yup.string().required()
        });
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                createTitle='Настройки доступа продаж'
                editTitle='Настройки доступа продаж'
                validationSchema={this.getValidationSchema()}
                renderForm={this.renderForm}
                deleteBtnText='Удалить'
                deletePopconfirm='Вы уверены, что хотите удалить настройки?'
                successButtonTitle='Сохранить'
            />
        );
    }
}
