import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import ItemStatusFilter from './fields/ItemStatusFilter';

function ProductsSearchFilter(props) {
    return (
        <BaseSearchFilter>
            <Col sm={4} xs={12}>
                <ItemStatusFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(ProductsSearchFilter);

export const defaultFilter = {
    status: 'active'
};
