import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import RegionFilter from './fields/RegionFilter';

function CitiesFilter() {
    return (
        <BaseSearchFilter>
            <Col sm={4} xs={21}>
                <RegionFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(CitiesFilter);
