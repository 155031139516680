import React from 'react';
import { head, pathOr } from 'ramda';

import Address from './Address';

const renderPerson = p => {
    return p ?
        <span>{p.lastName} {head(p.firstName)}.{p.middleName ? `${head(p.middleName)}.` : ''} ({p.email}{p.phone ? `, ${p.phone}` : ''})</span>
        : null;
}

const renderShop = shop => <span>{shop.name} <Address item={shop} /></span>;

const Instruction = ({ instruction }) => {
    const { promoter, supervisor, shop, employee } = pathOr({}, ['_embedded'], instruction);
    const shopString = `${instruction.unbind ? 'от магазина' : 'к магазину'}`;
    const superviserString = supervisor ? 'к супервайзеру' : null;

    switch (instruction.type) {
        case 'dismissal':
            return <span>{renderPerson(employee)}</span>;
        case 'supervisorShop':
            return <span>{renderPerson(supervisor)} {shopString} {renderShop(shop)}</span>;
        case 'promoterShop':
            return <span>{renderPerson(promoter)} {shopString} {renderShop(shop)}</span>;
        case 'promoterSupervisor':
            return <span>{renderPerson(promoter)} {superviserString} {renderPerson(supervisor)}</span>;
        default:
            return null;
    }
}

export default Instruction;
