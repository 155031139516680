import React, { Component } from 'react';
import { Row, Col, Table } from 'antd';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import moment from 'moment';

import ModalForm from 'components/common/ModalForm';
import withRole from 'components/common/withRole';
import PersonLink from 'components/common/cards/person/PersonLink';
import PersonName from 'components/common/cards/person/PersonName';
import ShopLink from 'components/common/cards/shop/ShopLink';
import CheckinTimeInfo from 'components/common/cards/checkin/CheckinTimeInfo';
import CheckinTime from 'components/common/cards/checkin/CheckinTime';
import CheckinShopStatus from 'components/common/cards/checkin/CheckinShopStatus';
import TimetableStatusSelect from 'components/common/form/TimetableStatusSelect';
import { putTimetable, getCheckins, formAutoOptions } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { EyeOutlined } from '@ant-design/icons';

@withRole
@withAsyncActions({
    putAction: putTimetable.withOptions(formAutoOptions),
    getCheckins: getCheckins
        .withPayload(({ params: { promoter, day, month, year }}) => {
            const date = moment.utc([year, month - 1, day]);
            return {
                q: {
                    date,
                    promoter
                }
            };
        })
        .withOptions(fullAutoOptions)
})
export default class TimetableModal extends Component {
    visitsColumns = [
        {
            title: 'Магазин',
            dataIndex: ['_embedded', 'shop'],
            key: 'shop',
            render: shop => <ShopLink shop={shop} />
        }, {
            title: 'Адрес',
            dataIndex: ['_embedded', 'shop', 'address'],
            key: 'adress'
        }, {
            title: 'Супервайзер',
            dataIndex: ['_embedded', 'supervisor'],
            key: 'supervisor',
            render: supervisor => <PersonLink type='supervisor' person={supervisor} />,
            hide: this.props.isSupervisor
        }, {
            title: 'Время захода',
            key: 'in',
            render: checkin => <CheckinTimeInfo checkin={checkin} />
        }, {
            title: 'Время выхода',
            render: checkin => <CheckinTime checkin={checkin} out={true} />
        }, {
            key: 'correct',
            dataIndex: 'shopStatus',
            render: status => <CheckinShopStatus status={status} />
        }
    ];

    renderForm = () => {
        const { params, getCheckins: { data: { items = [] }}} = this.props;
        const filteredVisitsColumns = this.visitsColumns.filter(column => !column.hide);

        return (
            <div>
                <Row>
                    <Col sm={6}>Статус:</Col>
                    <Col sm={14}>
                    <Field
                        name='dayReports'
                        component={TimetableStatusSelect}
                        day={params.day} />
                    </Col>
                </Row>
                { !!items.length &&
                    <div>
                        <Row>
                            <Col xs={24}>
                                <h3 style={{ marginTop: `15px` }}><EyeOutlined /> Визиты</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24}>
                                <Table
                                    className='table-visits'
                                    columns={filteredVisitsColumns}
                                    dataSource={items}
                                    pagination={false}
                                    rowKey='id' />
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }

    renderTitle() {
        const { day, month, _embedded: { promoter }} = this.props.params;

        return <span>Табель на {moment([day, month], ['DD', 'MM']).format('DD MMMM')} <PersonName person={promoter} /></span>;
    }

    render() {
        const { id, dayReports } = this.props.params;

        return (
            <ModalForm
                {...this.props}
                editTitle={this.renderTitle()}
                params={{ id, dayReports }}
                renderForm={this.renderForm} />
        );
    }
}
