import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import MonthFilter from './fields/MonthFilter';
import NetworkFilter from './fields/NetworkFilter';
import RegionFilter from './fields/RegionFilter';
import ProductDepartmentFilter from './fields/ProductDepartmentFilter';
import ShopFilter from './fields/ShopFilter';
import PromoterFilter from './fields/PromoterFilter';
import SupervisorFilter from './fields/SupervisorFilter';
import ExcelDownload from 'components/common/ExcelDownload';
import { SALE_PLANS } from 'constants/urls';
import moment from 'moment';
import withRole from 'components/common/withRole';

function CommonPlansFilterComponent(props) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={props.values} url={SALE_PLANS} />
        </Col>
    );

    return (
        <BaseSearchFilter disableSearch controls={controls}>
            <Col span={4}>
                <MonthFilter />
            </Col>
            <Col span={4}>
                <ProductDepartmentFilter multiple />
            </Col>
        </BaseSearchFilter>
    );
}

function NetworkPlansFilterComponent(props) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={props.values} url={SALE_PLANS} />
        </Col>
    );

    return (
        <BaseSearchFilter disableSearch controls={controls}>
            <Col span={4}>
                <MonthFilter />
            </Col>
            <Col span={4}>
                <ProductDepartmentFilter multiple />
            </Col>
            <Col span={4}>
                <NetworkFilter multiple />
            </Col>
            <Col span={4}>
                <RegionFilter />
            </Col>
        </BaseSearchFilter>
    );
}

function BasePlansFilterComponent(props) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={props.values} url={SALE_PLANS} />
        </Col>
    );

    return (
        <BaseSearchFilter disableSearch controls={controls}>
            <Col span={4}>
                <MonthFilter />
            </Col>
            <Col span={4}>
                <ProductDepartmentFilter multiple />
            </Col>
        </BaseSearchFilter>
    );
}

function PromoterPlansFilterComponent(props) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={props.values} url={SALE_PLANS} />
        </Col>
    );

    return (
        <BaseSearchFilter disableSearch controls={controls}>
            <Col span={4}>
                <MonthFilter />
            </Col>
            <Col span={4}>
                <ProductDepartmentFilter multiple />
            </Col>
            <Col span={4}>
                <RegionFilter />
            </Col>
            <Col span={3}>
                <NetworkFilter multiple />
            </Col>
        </BaseSearchFilter>
    );
}

function RegionPlansFilterComponent(props) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={props.values} url={SALE_PLANS} />
        </Col>
    );

    return (
        <BaseSearchFilter disableSearch controls={controls}>
            <Col span={4}>
                <MonthFilter />
            </Col>
            <Col span={4}>
                <ProductDepartmentFilter multiple />
            </Col>
            <Col span={4}>
                <RegionFilter />
            </Col>
        </BaseSearchFilter>
    );
}

function ExpandedPlansFilterComponent(props) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={props.values} url={SALE_PLANS} />
        </Col>
    );

    return (
        <BaseSearchFilter additionalControls={props.additionalControls} controls={controls} disableSearch>
            <Col span={4}>
                <MonthFilter />
            </Col>
            <Col span={4}>
                <ProductDepartmentFilter multiple />
            </Col>
            <Col span={4}>
                <NetworkFilter multiple />
            </Col>
            <Col span={4}>
                <RegionFilter />
            </Col>
            { !props.isSupervisor &&
                <Col span={4}>
                    <SupervisorFilter />
                </Col>
            }
        </BaseSearchFilter>
    );
}

function ShopsPlansFilterComponent(props) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={props.values} url={SALE_PLANS} />
        </Col>
    );

    return (
        <BaseSearchFilter disableSearch controls={controls}>
            <Col span={4}>
                <MonthFilter />
            </Col>
            <Col span={4}>
                <ProductDepartmentFilter multiple />
            </Col>
            <Col span={4}>
                <ShopFilter multiple />
            </Col>
            <Col span={4}>
                <RegionFilter />
            </Col>
            <Col span={3}>
                <NetworkFilter multiple />
            </Col>
        </BaseSearchFilter>
    );
}

function ShopPlansFilterComponent(props) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={props.values} url={SALE_PLANS} />
        </Col>
    );

    return (
        <BaseSearchFilter additionalControls={props.additionalControls} controls={controls} disableSearch>
            <Col span={3}>
                <MonthFilter />
            </Col>
            <Col span={3}>
                <ProductDepartmentFilter multiple />
            </Col>
            <Col span={3}>
                <NetworkFilter multiple />
            </Col>
            <Col span={3}>
                <RegionFilter />
            </Col>
            <Col span={3}>
                <ShopFilter multiple />
            </Col>
            <Col span={3}>
                <PromoterFilter multiple />
            </Col>
        </BaseSearchFilter>
    );
}

export const CommonPlansFilter = withFilterForm(CommonPlansFilterComponent);
export const NetworkPlansFilter = withFilterForm(NetworkPlansFilterComponent);
export const BasePlansFilter = withFilterForm(BasePlansFilterComponent);
export const PromoterPlansFilter = withFilterForm(PromoterPlansFilterComponent);
export const RegionPlansFilter = withFilterForm(RegionPlansFilterComponent);
export const ShopsPlansFilter = withFilterForm(ShopsPlansFilterComponent);
export const ExpandedPlansFilter = withRole(withFilterForm(ExpandedPlansFilterComponent));
export const ShopPlansFilter = withFilterForm(ShopPlansFilterComponent);

export const defaultCommonPlansFilter = {
    date: moment.utc().toDate(),
    type: 'total'
};

export const defaultRegionPlansFilter = {
    date: moment.utc().toDate(),
    type: 'network_region'
};

export const defaultNetworkPlansFilter = {
    date: moment.utc().toDate(),
    type: 'network'
};

export const defaultPlansFilter = {
    date: moment.utc().toDate(),
    type: 'promoter_shop'
};

export const defaultSupervisorPlansFilter = {
    date: moment.utc().toDate(),
    type: 'supervisor'
};

export const defaultShopPlansFilter = {
    date: moment.utc().toDate(),
    type: 'shop'
};

export const defaultPromoterPlansFilter = {
    date: moment.utc().toDate(),
    type: 'promoter'
};
