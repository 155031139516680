import React, { Component } from 'react';
import { Modal } from 'antd';

import Map from 'components/common/Map';
import { getInfoWindow } from 'components/common/cards/Address';

export default class ShopMapModal extends Component {
    render() {
        const { modal, params: { coordinates: { latitude, longitude }, params: shop, name } } = this.props;

        return (
            <Modal {...modal} title={name} footer={null}>
                <Map lng={longitude} lat={latitude} infoWindow={getInfoWindow(shop)} />
            </Modal>
        );
    }
}
