import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import withFormItem from 'components/common/form/withFormItem';
import withFormikField, { formFieldPropTypes } from 'components/common/form/withFormikField';

@withFormItem
export class SearchInput extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        placeholder: PropTypes.string,
    };

    onSearch = value => {
        this.props.onChange(value);
    }

    render() {
        const { placeholder } = this.props;

        return (
            <Input.Search placeholder={placeholder || 'Поиск'} onSearch={this.onSearch} />
        );
    }
}

export default withFormikField(SearchInput);
