import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { pathOr, isEmpty } from 'ramda';

import InfoBlock from 'components/common/cards/InfoBlock';
import { getShop } from 'actions/asyncActions';
import { openShopMapModal } from 'actions/modalActions';
import { GlobalOutlined, InfoCircleOutlined, SolutionOutlined } from '@ant-design/icons';

const stateToProps = state => ({
    data: getShop.selectData(state),
    meta: getShop.selectMeta(state)
});

@connect(stateToProps, { openShopMapModal })
export default class ShopInfoTab extends Component {
    render() {
        const { data, meta, data: { coordinates }, openShopMapModal } = this.props;

        const network = pathOr('', ['_embedded', 'network', 'name'], data);
        const city = pathOr('', ['_embedded', 'city', 'name'], data);
        const region = pathOr('', ['_embedded', 'region', 'name'], data);

        return meta.success && (
            <div className='info'>
                <InfoBlock iconType={<InfoCircleOutlined />} title="Основная информация" >
                    <Row>
                        <Col span={5}>Название</Col>
                        <Col span={19}>{data.name}</Col>
                    </Row>
                    <Row>
                        <Col span={5}>Код ТТ</Col>
                        <Col span={19}>{data.code}</Col>
                    </Row>
                    <Row>
                        <Col span={5}>Торговая сеть</Col>
                        <Col span={19}>{network}</Col>
                    </Row>
                </InfoBlock>
                <InfoBlock iconType={<SolutionOutlined />} title="Контакты" >
                    <Row>
                        <Col span={5}>Город</Col>
                        <Col span={19}>{city} {city !== region && region}</Col>
                    </Row>
                    <Row>
                        <Col span={5}>Адрес</Col>
                        <Col span={19}>
                            {data.address}
                            {data.addressComment && ` (${data.addressComment})`}
                        </Col>
                    </Row>
                    { !isEmpty(coordinates) && coordinates &&
                        <Row>
                            <Col span={5}>Координаты</Col>
                            <Col span={19}>
                                {`${coordinates.latitude}, ${coordinates.longitude}`}
                                <GlobalOutlined className="view-coordinates-icon" onClick={() => openShopMapModal(data)} />
                            </Col>
                        </Row>
                    }
                </InfoBlock>
            </div>
        );
    }
}
