import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import { prop } from 'ramda';

import withFormItem from './withFormItem';
import withFormikField from './withFormikField';

export const Swatch = styled.div`
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: ${({ pointer }) => pointer ? 'pointer' : 'default'};
    margin-left: 1px;
    margin-top: 4px;
`;

export const Color = styled.div`
    width: 36px;
    height: 22px;
    border-radius: 2px;
    background: ${prop('color')};
`;

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
`;

const Popover = styled.div`
    position: absolute;
    z-index: 1000;
    bottom: 40px;
    left: 1px;
    .chrome=picker {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px, rgba(0, 0, 0, 0.1) 0px 4px 8px;
    }
`;

@withFormItem
class ColorPicker extends Component {
    state = {
        open: false
    };

    onChange = ({ rgb }) => {
        this.props.onChange(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`);
    }

    toggle = () => this.setState(prev => ({ open: !prev.open }));

    close = () => this.setState({ open: false });

    render() {
        const { value } = this.props;

        return <div>
            <Swatch onClick={this.toggle} pointer>
                <Color color={value} />
            </Swatch>
            { this.state.open &&
                <div>
                    <Cover onClick={this.close} />
                    <Popover>
                        <ChromePicker color={value || ''} onChangeComplete={this.onChange} />
                    </Popover>
                </div>
            }
        </div>;
    }
}

export default withFormikField(ColorPicker);
