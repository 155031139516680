import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';
import { Button } from 'antd';
import { connect } from 'react-redux';

import withRole from 'components/common/withRole';
import { fullAutoOptions } from 'constants/asyncClient';
import HeadBlock from 'components/common/cards/HeadBlock';
import TextEditorView from 'components/common/cards/TextEditorView';
import { getNews, deleteNews } from 'actions/asyncActions';
import { openNewsModal } from 'actions/modalActions';
import { PUT_NEWS } from 'constants/actionTypes';
import createPutSaga from 'utils/putSagaCreator';

const getParams = path(['match', 'params']);

@withRole
@connect(null, { openNewsModal })
@withAsyncActions({
    getNews: getNews
        .withPayload(getParams)
        .withOptions(fullAutoOptions)
        .withSaga(createPutSaga(PUT_NEWS, 'getNews')),
    deleteNews: deleteNews.withSuccessHandler((props) => props.history.push('/news'))
})
export default class News extends Component {
    render() {
        const { isAdmin, getNews: { data, meta }, match: { params }, openNewsModal } = this.props;

        const title = <span className="news-full-header-text">{data.name}</span>;
        const controls = isAdmin && (
            <Button className="news-edit-button"
                    type="secondary"
                    onClick={() => openNewsModal(params)}>
                Редактировать новость
            </Button>
        );

        return ( meta.success &&
            <div className="detail-card">
                <HeadBlock title={title} controls={controls} />
                <div className="news-text">
                    <TextEditorView content={data.description} />
                </div>
            </div>
        );
    }
}
