import React from 'react';
import { Col } from 'antd'

import withRole from 'components/common/withRole';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import UserStatusFilter from './fields/UserStatusFilter';
import CityFilter from './fields/CityFilter';
import RegionFilter from './fields/RegionFilter';
import NetworkFilter from './fields/NetworkFilter';
import SupervisorFilter from './fields/SupervisorFilter';
import { PROMOTERS } from 'constants/urls';
import ExcelDownload from 'components/common/ExcelDownload';

const PromoterFilter = param => ({ isClient, isMarketingCoordinator, values }) => {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={values} url={PROMOTERS} />
        </Col>
    );

    return (
        <BaseSearchFilter controls={isClient ? null : controls} searchFieldName={'search'} searchFieldPlaceholder={'Пользователь'}>
            <Col sm={3} xs={12}>
                <UserStatusFilter />
            </Col>
            { !isMarketingCoordinator && (
                <Col sm={3} xs={12}>
                    <RegionFilter param={param} />
                </Col>
            )}
            <Col sm={3} xs={12}>
                <CityFilter param={param} />
            </Col>
            <Col sm={3} xs={12}>
                <NetworkFilter param={param} />
            </Col>
            <Col sm={3} xs={12}>
                <SupervisorFilter param={param} />
            </Col>
        </BaseSearchFilter>
    );
}

export const PromoterFilterWithParam = param => withFilterForm(
    withRole(PromoterFilter(param))
);

export default PromoterFilterWithParam();

export const defaultPromoterFilter = {
    enabled: true
};
