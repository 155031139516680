import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { withRouter } from 'react-router-dom';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import OrderDateForm from 'components/common/form/OrderDateForm';
import { postInstruction, formAutoOptions } from 'actions/asyncActions';
import { SUPERVISOR } from 'constants/roles';

@withRouter
@withAsyncActions({
    postAction: postInstruction
}, formAutoOptions)
export default class DismissalModal extends Component {
    static propTypes = modalFormPropTypes;

    successHandler = () => {
        const { params, history } = this.props;

        history.push(params.roles[0] === SUPERVISOR ? '/users/supervisors' : '/users/promoters');
    }

    renderForm = formProps => {
        return <OrderDateForm {...formProps} />;
    }

    getInitialValues() {
        const { id } = this.props.params;

        return {
            employee: id,
            type: 'dismissal'
        };
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                createTitle='Увольнение'
                renderForm={this.renderForm}
                initialValues={this.getInitialValues()}
                onSubmitSuccess={this.successHandler} />
        );
    }
}
