import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import {
    postClient,
    putClient,
    formAutoOptions,
} from 'actions/asyncActions';
import UserModal from './UserModal';

@withAsyncActions({
    postAction: postClient,
    putAction: putClient
}, formAutoOptions)
export default class ClientModal extends Component {
    render() {
        return <UserModal {...this.props} createTitle={'Добавить клиента'} />;
    }
}
