import React from 'react';
import { Field } from 'formik';

import Select from 'components/common/form/Select';
import USER_STATUSES from 'constants/userStatuses';

const UserStatusFilter = () => (
    <Field
        name='enabled'
        component={Select}
        placeholder='Статус'
        allowClear={true}
        options={USER_STATUSES}
        path='value' />
);

export default UserStatusFilter;
