import React, { Component } from 'react';
import { isEmpty } from 'ramda';
import { withAsyncActions } from 'react-async-client';
import { Button } from 'antd';

import Address from 'components/common/cards/Address';
import City from 'components/common/cards/City';
import ListTable from 'components/common/ListTable';
import { getShops, postCheckin } from 'actions/asyncActions';
import DistanceTag from 'components/common/cards/DistanceTag';
import moment from 'moment';

const getInitialFilters = ({ supervisor, coordinates }) => ({
    supervisor, coordinates: !isEmpty(coordinates) ? coordinates : null
});

const getParams = (id, coordinates) => ({
    date: moment.utc().toDate(),
    shop: id,
    in: {
        date: moment.utc().toDate(),
        coordinates: isEmpty(coordinates) || !coordinates ? null : coordinates
    }
});

@withAsyncActions({ postCheckin })
export default class ShopCheckinTable extends Component {
    columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: 'Торговая сеть',
            dataIndex: ['_embedded', 'network', 'name'],
            key: 'network'
        }, {
            title: 'Город',
            dataIndex: ['_embedded', 'city'],
            key: 'city',
            render: city => <City city={city} />
        }, {
            title: 'Адрес',
            key: 'address',
            render: shop => <Address item={shop} />
        }, {
            title: 'Дистанция',
            key: 'distance',
            dataIndex: 'distance',
            render: distance => <DistanceTag distance={distance} />
        }, {
            title: '',
            key: 'checkin',
            render: ({ id, coordinates }) => (
                <Button onClick={() => this.props.postCheckin.dispatch(getParams(id, coordinates))} type="primary">Отметиться</Button>
            )
        },
    ]

    render() {
        return <ListTable
            action={getShops}
            param="anotherShop"
            className='table-mobile'
            initialFilters={getInitialFilters(this.props)}
            tableColumns={this.columns}
        />
    }
}
