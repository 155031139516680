import React from 'react';
import { Field } from 'formik';

import DatePicker from 'components/common/form/DatePicker';

const BeforeDateFilter = () => (
    <Field
        name='endDate'
        component={DatePicker}
        placeholder='Выполнить до' />
);

export const StartDateFilter = () => (
    <Field
        name='startDate'
        component={DatePicker}
        placeholder='Дата начала' />
);

export default BeforeDateFilter;
