import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getPromotersList } from 'actions/asyncActions';

const PromoterFilter = ({ values = {}, multiple }) => (
    <Field
        name={multiple ? 'promoters' : 'promoter'}
        component={SearchSelect}
        placeholder='Консультант'
        multiple={multiple}
        allowClear={true}
        action={getPromotersList}
        namePath={['lastName', 'firstName', 'middleName']}
        filter={({ shop, shops }) => ({
            [shops && (shops.length > 1) ? 'shops' : 'shop']: shop || (shops && (shops.length === 1) ? shops[0] :  shops),
        })}
        param='filter' />
)

export default PromoterFilter;
