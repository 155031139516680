import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import { ErrorText } from './Error';

export const tablePropTypes = {
    action: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    scroll: PropTypes.object,
    expandedRowRender: PropTypes.func,
    rowSelection: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.oneOf([false])
    ]),
    rowClassName: PropTypes.func,
    parseData: PropTypes.func
};

export default class TableComponent extends Component {
    static propTypes = {
        ...tablePropTypes,
        columns: PropTypes.array,
        setOffset: PropTypes.func,
    };

    getPaginationConfig() {
        const { action: { data: { _meta }}, setOffset, hidePagination } = this.props;

        if (!_meta || _meta.count <= _meta.limit || hidePagination || !_meta.limit) {
            return false;
        }

        return {
            total: _meta.count,
            pageSize: _meta.limit,
            current: (_meta.offset / _meta.limit) + 1,
            defaultCurrent: 0,
            onChange: (page, pageSize) => setOffset((page - 1) * pageSize),
            showSizeChanger: false
        };
    }

    render() {
        const pagination = this.getPaginationConfig();
        const {
            columns,
            scroll,
            expandedRowRender,
            rowSelection,
            rowClassName,
            parseData,
            loading,
            action: { data, meta, meta: { error, pending }, refresh },
            footer,
            bordered,
            showHeader,
            expandable,
            dataSource,
            rowKey
        } = this.props;

        return (
            <Fragment>
                <Table
                    columns={columns}
                    dataSource={error ? null : dataSource || (parseData ? parseData(data.items) : data.items)}
                    pagination={error ? null : pagination}
                    loading={pending || loading}
                    rowKey={rowKey || 'id'}
                    scroll={scroll || { x: 600 }}
                    expandedRowRender={expandedRowRender}
                    rowSelection={rowSelection}
                    rowClassName={rowClassName}
                    footer={error ? () => <Fragment>
                        <ErrorText meta={meta} /> <Button icon={<SyncOutlined />} onClick={() => refresh()} />
                    </Fragment> : (footer ? () => footer(data) : null)}
                    bordered={bordered}
                    showHeader={showHeader}
                    expandable={expandable}
                />
            </Fragment>
        );
    }
}
