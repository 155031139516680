import React, { Component } from 'react';
import { Alert, Modal, Row, Col, Button } from 'antd';
import Cropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';

import Spinner from 'components/common/Spinner';
import { isMobile } from 'constants/screen';

const MAX_MB_SIZE = 8;

export default class CropImageModal extends Component {
    constructor() {
        super();

        this.state = {
            src: null,
            fd: null,
            cropper: null,
            error: null
        };
    }

    componentWillMount() {
        const { file } = this.props.params;
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({ src: reader.result });
        };
    }

    getFileData(file) {
        const fd = new FormData();

        fd.append('file', file);
        return fd;
    }

    handleUpload = () => {
        this.setState({ error: null });

        const { params: { handleUpload }, close } = this.props;

        const canvas = this.state.cropper.getCroppedCanvas();

        canvas.toBlob(blob => {
            const fd = this.getFileData(blob);

            if (blob.size < MAX_MB_SIZE * 1000 * 1000) {
                handleUpload(fd);
                close();
            } else {
                this.setState({
                    error: `Слишком большой размер файла - ${(blob.size / 1000 / 1000).toFixed(1)}Mb (максимум ${MAX_MB_SIZE}Mb)`
                })
            }
        }, 'image/jpeg', 0.95);
    }

    renderFooter() {
        return (
            <Button type='primary' onClick={this.handleUpload} disabled={!this.state.cropper}>
                Сохранить
            </Button>
        );
    }

    render() {
        const { modal } = this.props;
        const { src, error } = this.state;

        return (
            <Modal {...modal} title='Загрузка фотографии' footer={this.renderFooter()} className='crop-image-modal'>
                <div>
                    { error ? (
                        <p>
                            <Alert type='error' message={error} />
                        </p>
                    ) : null}
                    <p className='crop-image-description'>Выбранная область будет сохранена.</p>
                    { src ?
                        <Row type='flex' align='middle'>
                            <Col sm={16} xs={22}>
                                <Cropper
                                    onInitialized={instance => this.setState({ cropper: instance })}
                                    src={src}
                                    aspectRatio={1}
                                    zoomable={false}
                                    draggable={false}
                                    viewMode={1}
                                    preview='.cropped-preview'
                                    style={{ height: isMobile() ? 300 : 500 }} />
                            </Col>
                            <Col sm={8} xs={22}>
                                <div className='cropped-preview' />
                            </Col>
                        </Row>
                        : <Spinner />
                    }
                </div>
            </Modal>
        );
    }
}
