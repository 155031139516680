import React, { useEffect, useState, useRef } from 'react';
import { withYMaps, Map, SearchControl, Placemark } from 'react-yandex-maps';
import { Button, Row, Col } from 'antd';

const defaultCoords = [37.6155600, 55.7522200];

const containerStyle = {
    width: '570px',
    height: '400px'
};

const checkCorrectness = (coords) => coords ?
    !isNaN(parseFloat(coords.latitude)) && !isNaN(parseFloat(coords.longitude)) : false;

const getExistingCoordinates = (props) => checkCorrectness(props.coordinates) ?
    [parseFloat(props.coordinates.longitude), parseFloat(props.coordinates.latitude)] : null;

const parseGeoObject = geoData => {
    const geoObject = geoData.geoObjects.get(0);
    const location = geoObject && geoObject.geometry.getCoordinates();
    const address = geoObject && geoObject.properties.get('text');

    return {
        location,
        address,
    };
};

const SearchMap = props => {
    const { ymaps, searchBoxValue } = props;
    const map = useRef(null);
    const coordinates = getExistingCoordinates(props);
    const [searchPosition, setSearchPosition] = useState(null);
    const [marker, setMarker] = useState(coordinates);

    useEffect(() => {
        if (ymaps && ymaps.geocode && searchBoxValue) {
            ymaps.geocode(searchBoxValue).then(data => {
                const geoObject = parseGeoObject(data);
                setSearchPosition(searchBoxValue);
                map.current.setCenter(geoObject.location);
            });
        }
    }, [ymaps, searchBoxValue]);

    const addMark = event => {
        const location = event.get('coords');
        setMarker(location);

        props.ymaps.geocode(location).then(data => {
            const { address } = parseGeoObject(data);

            setSearchPosition(address);
        });
    };

    const onSave = () => {
        props.onChange({
            latitude: marker[1],
            longitude: marker[0]
        });
        props.closeMap();
    }

    const onCancel = () => {
        props.closeMap();
    }

    return (
        <div>
            <Map
                instanceRef={map}
                style={containerStyle}
                defaultState={{
                    center: marker || searchPosition || defaultCoords,
                    zoom: 14,
                    controls: ['zoomControl']
                }}
                options={{
                    suppressMapOpenBlock: true,
                    yandexMapDisablePoiInteractivity: true,
                }}
                onClick={addMark}
            >
                <SearchControl
                    options={{
                        noPlacemark: true,
                    }}
                    instanceRef={instance => {
                        if (instance) {
                            instance.events.add('resultselect', () => {
                                instance.getResult(instance.getSelectedIndex()).then(e => {
                                    setSearchPosition(e.geometry._coordinates);
                                });
                            });
                        }
                    }}
                />
                { marker && <Placemark geometry={marker} /> }
            </Map>
            <Row className="map-buttons">
                <Col xs={8}>
                    <div className="text-right ml5">
                        <Button.Group>
                            <Button type="primary" onClick={onSave} disabled={!marker}>Ок</Button>
                            <Button type="secondary" onClick={onCancel}>Отмена</Button>
                        </Button.Group>
                    </div>
                </Col>
                <Col xs={16}>
                    <div className="pull-right ml5">
                        {searchPosition || ''}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default withYMaps(SearchMap, false, ['suggest', 'geocode']);
