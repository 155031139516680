import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Cascader } from 'antd';
import { assoc, path, all, isNil, is, toLower, map, uniq, last, dropLast, reverse, prop, find, propEq, forEach } from 'ramda';
import { withAsyncActions } from 'react-async-client';

import withFormItem from './withFormItem';
import { fullAutoOptions } from 'constants/asyncClient';
import ListCascadeSelect from './ListCascadeSelect';
import { isMobile } from 'constants/screen';

export const parseForCascade = (parent, child, path) => parent.map(p => ({
    ...p,
    value: p.id,
    label: p.name,
    children: child.length ? child.filter(c => c[path] === p.id) : null
}));

@withFormItem
@withAsyncActions(props => ({
    getDict: last(props.fields).action
        .withParams('cascade')
        .withPayload(() => ({ limit: 0 }))
        .withOptions(fullAutoOptions)
}))
export class CascadeSelect extends Component {
    static propTypes = {
        label: PropTypes.string,
        placeholder: PropTypes.string,
        allowClear: PropTypes.bool,
        fields: PropTypes.arrayOf(PropTypes.object)
    };

    constructor(props) {
        super(props);

        this.state = {
            values: [
                ...this.getFields().map(field => path(field.split('.'), props.form.values)),
                props.field.value
            ]
        };
    }

    componentWillReceiveProps(next) {
        const { field } = this.props;
        const { value } = next.field;

        if (value !== field.value && is(Array, value)) {
            this.onChange(value);
        }
    }

    onChange = values => {
        const { form: { setFieldValue, values: formValues }, field: { name }, onChange } = this.props;
        const { getDict: { data: { items = [] }} } = this.props;
        const index = this.getFields().length;

        const valueEntity = find((item) => item.id === values[index], items);
        const _embedded = assoc(name, valueEntity, prop('_embedded', formValues) || {});

        if (values[index] || !values.length) {
            setFieldValue(name, values[index]);
            this.setState({ values });
            onChange && onChange(values[index], this.props.form, valueEntity);
            setFieldValue('_embedded', _embedded);
        }
    }

    getFields() {
        const { fields } = this.props;

        return dropLast(1, map(prop('name'), fields));
    }

    getOptions() {
        const { getDict: { data: { items = [] }}} = this.props;
        const fields = reverse(this.getFields());
        const dictionaries = fields.map(field => {
            return uniq(map(item => {
                let value = path(['_embedded', field], item);
                const copyFields = path(['copyFields'], find(propEq('name', field), this.props.fields));

                if (copyFields) {
                    forEach(copyField => {
                        value = assoc(copyField, item[copyField], value);
                    }, copyFields);
                }

                return value;
            }, items));
        });

        return [items, ...dictionaries].reduce((res, cur, i) => parseForCascade(cur, res, fields[i - 1]), []);
    }

    getSearchOptions() {
        const { searchBy } = this.props;

        return {
            filter: (value, filterPath) => filterPath.some(option => toLower(option.label).includes(toLower(value)) ||
                (searchBy && path([searchBy], option) && path([searchBy], option).includes(value))),
            render: (inputValue, filterPath, prefixCls) => filterPath.map(({ label }, index) => index === 0 ? label : [' / ', label])
        };
    }

    render() {
        const { placeholder, allowClear } = this.props;
        const { values } = this.state;

        return (
            <Cascader
                value={all(isNil, values) ? [] : values}
                options={this.getOptions()}
                onChange={this.onChange}
                placeholder={placeholder}
                allowClear={allowClear}
                showSearch={this.getSearchOptions()}
                getPopupContainer={trigger => trigger.parentNode} />
        );
    }
}

const CascadeSelectComponent = props =>
    isMobile() ? <ListCascadeSelect {...props} /> : <CascadeSelect {...props} />;

export default CascadeSelectComponent;
