import React from 'react';
import { Alert, Form } from 'antd';
import { path, keys } from 'ramda';

import { ERRORS, FIELDS, INTERNAL_SERVER_ERROR, INVALID_ROW } from 'constants/serverMessages';

const showMessage = (message, showError, defaultError = ERRORS[INTERNAL_SERVER_ERROR]) => (
    ERRORS[message] || (showError ? message || defaultError : defaultError)
);
const showAttiributes = attributes => attributes ? (
    attributes.missed ? (
        <div>
            Пропущенные: {attributes.missed}
            { attributes.missed !== attributes.required ? (
                <div>Обязательные: {attributes.required}</div>
            ) : null}
        </div>
    ) : keys(attributes).map(row =>
            keys(attributes[row]).map(key => {
                const error = attributes[row][key].replace(/".+"/gi, ':name');
                const match = attributes[row][key].match(/".+"/gi);

                return (
                    <div key={row}>{`${row}: ${showMessage(error, true, ERRORS[INVALID_ROW]).replace(/:name/, path([0], match) || '').replace(/\s\s/gi, ' ')}`}</div>
                );
            })
        )
) : null;

export const ErrorText = ({ meta = {}, showError }) =>
    meta.error ? (
        <span className='error-message'>
            { showMessage(path(['error', 'data', 'message'], meta), showError, ERRORS[INTERNAL_SERVER_ERROR]) }
            { showAttiributes(path(['error', 'data', 'attributes'], meta)) }
        </span>
    ) : null;

const Error = ({ meta = {}, closable, showError }) =>
    meta.error ? (
        <Form.Item>
            { path(['error', 'data', 'errors'], meta) ?
                (keys(meta.error.data.errors)).map(key => {
                    const match = key.match(/\[\d+\]/gi);
                    const matchField = key.match(/\]\.(.+)/i);
                    const row = (path([0], match) || '').replace('[', '').replace(']', '');
                    const field = (path([1], matchField) || '');

                    return <p key={key}>
                        <Alert
                            type='error'
                            closable={closable}
                            className='text-center'
                            message={
                                `${Number(row || 0) + 1}: ${showMessage(path([key, 0, 'message'], meta.error.data.errors) || '', showError).replace(/:field/, FIELDS[field])}`
                            }
                        />
                    </p>
                })
                : <Alert
                    className='text-center'
                    type='error'
                    closable={closable}
                    message={<div>
                        <ErrorText meta={meta} showError={showError} />
                    </div>} />
            }
        </Form.Item>
    ) : null;

export default Error;
