import React, { Component } from 'react';
import { Popconfirm, Row, Col, Button } from 'antd';
import { Field } from 'formik';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { withRouter } from 'react-router-dom';
import { append, filter, path, without } from 'ramda';
import * as yup from 'yup';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import PageForm from 'components/common/PageForm';
import Select from 'components/common/form/Select';
import SearchSelect from 'components/common/form/SearchSelect';
import DatePicker from 'components/common/form/DatePicker';
import TextEditor from 'components/common/form/TextEditor';
import { RadioGroupComponent } from 'components/common/form/RadioGroup';
import DocumentDownload from 'components/scenes/documents/DocumentDownload';
import { renderShopItem } from 'filters/fields/ShopFilter';
import { TASK_TYPES, TASK_PRIORITIES } from 'constants/tasks';
import { openDocumentAttachModal } from 'actions/modalActions';
import { postTask, putTask, getNetworks, getShops, getCities, getRegions, formAutoOptions } from 'actions/asyncActions';

const validationSchema = yup.object().shape({
    priority: yup.string().required(),
    startDate: yup.date().required(),
    text: yup.string().trim().required()
});

@withRouter
@connect(null, { openDocumentAttachModal })
@withAsyncActions({ postTask, putTask }, formAutoOptions)
export default class TaskForm extends Component {
    constructor(props) {
        super();

        this.state = {
            common: path(['data', 'shop'], props) ? false : true,
            files: path(['data', '_embedded', 'files'], props) || [],
        };
    }

    onSubmitSuccess = ({ values: { type, id }}) => {
        const { push } = this.props.history;
        const { common } = this.state;

        if (!id) {
            return common ? push('/tasks/common') : push('/tasks/shops');
        }

        push(`/task/${id}`);
    }

    onToggleType = (value, formProps) => {
        this.setState({ common: value });

        formProps.setFieldValue('city', null);
        formProps.setFieldValue('network', null);
        formProps.setFieldValue('shop', null);
    }

    onAdd = (file, formProps) => {
        this.setState({ files: append(file, this.state.files || []) });
        formProps.setFieldValue('files', append(file.id, formProps.values.files || []));
    }

    onRemove = (id, formProps) => {
        this.setState({ files: filter(file => file.id === id, this.state.files || []) });
        formProps.setFieldValue('files', without(id, formProps.values.files || []));
    }

    renderForm = ({ isSubmitting, openDocumentAttachModal, ...props }) => {
        const { common, files } = this.state;

        return (
            <div>
                <Row gutter={10}>
                    <Col span={6}>
                        <RadioGroupComponent
                            label='Тип'
                            path='value'
                            value={common}
                            options={TASK_TYPES}
                            onChange={value => this.onToggleType(value, props)}
                        />
                    </Col>
                    <Col span={6}>
                        <Field
                            name='priority'
                            component={Select}
                            label='Приоритет:'
                            placeholder='Выберите приоритет'
                            options={TASK_PRIORITIES}
                            allowClear={true}
                            path='value' />
                    </Col>
                    <Col span={6}>
                        <Field
                            name='startDate'
                            component={DatePicker}
                            label='Дата начала:' />
                    </Col>
                    <Col span={6}>
                        <Field
                            name='endDate'
                            component={DatePicker}
                            label='Выполнить до:' />
                    </Col>
                </Row>
                { common &&
                    <Row gutter={10}>
                        <Col span={6}>
                            <Field
                                name='region'
                                component={SearchSelect}
                                label='Регион:'
                                placeholder='Регион'
                                action={getRegions}
                                allowClear={true} />
                        </Col>
                        <Col span={6}>
                            <Field
                                name='city'
                                component={SearchSelect}
                                label='Город:'
                                placeholder='Город'
                                action={getCities}
                                filter={values => ({ region: values.region })}
                                allowClear={true} />
                        </Col>
                        <Col span={6}>
                            <Field
                                name='network'
                                component={SearchSelect}
                                label='Сеть:'
                                placeholder='Сеть'
                                action={getNetworks}
                                allowClear={true} />
                        </Col>
                    </Row>
                }
                { !common &&
                    <Row gutter={10}>
                        <Col span={12}>
                            <Field
                                name='shop'
                                component={SearchSelect}
                                label='Магазин:'
                                placeholder='Магазин'
                                action={getShops}
                                renderItem={renderShopItem}
                                allowClear={true} />
                        </Col>
                    </Row>
                }
                <Field
                    name='text'
                    component={TextEditor}
                    label='Текст:'
                    placeholder='Введите текст задачи' />
                <Row gutter={10}>
                    <Col span={12}>
                        {files.map((file) => (
                            <p key={file.id}>
                                <DocumentDownload id={file.id}>{file.name}{' '}</DocumentDownload>
                                <Popconfirm
                                    arrowPointAtCenter
                                    placement='topRight'
                                    title='Вы действительно хотите открепить документ?'
                                    onConfirm={() => this.onRemove(file.id, props)}>
                                    <DeleteOutlined className='action-icon' />
                                </Popconfirm>
                            </p>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button icon={<PlusOutlined />} onClick={() => openDocumentAttachModal({ onAdd: file => this.onAdd(file, props) })}>Загрузить файл</Button>
                    </Col>
                    <Col xs={12} className='text-right'>
                        <Button htmlType='submit' onClick={props.handleSubmit} type='primary' disabled={isSubmitting}>Отправить</Button>
                    </Col>
                </Row>
            </div>
        );
    };

    render() {
        const { data, putTask, postTask, openDocumentAttachModal } = this.props;

        return (
            <div className='task-form'>
                <PageForm
                    initialValues={data}
                    validationSchema={validationSchema}
                    formAction={path(['id'], data) ? putTask : postTask}
                    renderForm={this.renderForm}
                    openDocumentAttachModal={openDocumentAttachModal}
                    onSubmitSuccess={this.onSubmitSuccess} />
            </div>
        );
    }
}
