import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';

import { getRegions, importCities } from 'actions/asyncActions';

import { openRegionModal, openImportModal } from 'actions/modalActions';
import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import { geographyMenuItems } from 'menus';
import SearchFilter from 'filters/SearchFilter';
import { PlusOutlined, FileExcelOutlined } from '@ant-design/icons';
import { DELETE_REGIONS, POST_REGIONS, PUT_REGIONS } from 'constants/actionTypes';

@withRole
@connect(null, { openRegionModal, openImportModal })
export default class Regions extends Component {
    getColumns = isAdmin => [
        {
            title: 'Название',
            dataIndex: ['name'],
            key: 'name',
            render: (text, item) => isAdmin ? (
                <span className='span-link' onClick={() => this.props.openRegionModal(item)}>{item.name}</span>
            ) : text
        }
    ];

    getMenuControls() {
        const { isAdmin, openRegionModal, openImportModal } = this.props;

        return isAdmin && (
            <div>
                <Button
                    type='success'
                    icon={<FileExcelOutlined />}
                    onClick={() => openImportModal({
                        title: 'Импортировать города',
                        action: importCities,
                        withoutMonth: true
                    })}>
                    Импорт
                </Button>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openRegionModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Menu items={geographyMenuItems} controls={this.getMenuControls()}  />
                <ListTable
                    tableColumns={this.getColumns(this.props.isAdmin)}
                    action={getRegions}
                    filterForm={SearchFilter}
                    refreshActions={[POST_REGIONS, PUT_REGIONS, DELETE_REGIONS]}
                />
            </div>
        );
    }
}
