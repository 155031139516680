import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { UserAddOutlined, FileExcelOutlined } from '@ant-design/icons';

import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import PromoterFilter, { defaultPromoterFilter } from 'filters/PromoterFilter';
import { usersMenuItems, filterClientColumns } from 'menus';
import { getPromotersList, getUser, importPromoters } from 'actions/asyncActions';
import { openPromoterModal, openImportModal } from 'actions/modalActions';
import { ADMIN, MARKETING_COORDINATOR } from 'constants/roles';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import PersonLink from 'components/common/cards/person/PersonLink';
import { POST_PROMOTER, PUT_PROMOTER, IMPORT_PROMOTERS } from 'constants/actionTypes';

const stateToProps = state => ({
    user: getUser.selectData(state)
});

@withRole
@connect(stateToProps, { openPromoterModal, openImportModal })
export default class Promoters extends Component {
    columns = [
        {
            key: 'icon',
            width: 40,
            render: item => <PersonPhoto person={item} />
        },
        {
            title: 'ФИО',
            key: 'fullName',
            render: item => <PersonLink person={item} />
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }, {
            title: 'Регион',
            dataIndex: ['_embedded', 'region', 'name'],
            key: 'region'
        }, {
            title: 'Город',
            dataIndex: ['_embedded', 'city', 'name'],
            key: 'city'
        },
        {
            title: 'Должность',
            dataIndex: ['_embedded', 'grade', 0, 'name'],
            key: 'grade'
        },
    ];

    getFilter() {
        const { user } = this.props;
        const [ role ] = user.roles;

        return role === MARKETING_COORDINATOR ? { region: user.region } : {};
    }

    getMenuControls() {
        const { openPromoterModal, openImportModal, user } = this.props;
        const [ role ] = user.roles;

        return role === ADMIN && (
            <div>
                <Button
                    type='success'
                    icon={<FileExcelOutlined />}
                    onClick={() => openImportModal({
                        title: 'Импортировать консультантов',
                        action: importPromoters,
                        withoutMonth: true
                    })}>
                    Импорт
                </Button>
                <Button type='primary' icon={<UserAddOutlined />} onClick={() => openPromoterModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Menu
                    items={usersMenuItems(this.props.accessToFinances)}
                    controls={this.getMenuControls()} />
                <ListTable
                    filterForm={PromoterFilter}
                    initialFilters={{ ...defaultPromoterFilter, ...this.getFilter()}}
                    action={getPromotersList}
                    refreshActions={[POST_PROMOTER, PUT_PROMOTER, IMPORT_PROMOTERS]}
                    tableColumns={filterClientColumns(this.props.isClient, this.columns)} />
            </div>
        );
    }
}
