import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

const withFormikField = WrappedComponent => {
    return class extends Component {
        static propTypes = {
            onChange: PropTypes.func,
            form: PropTypes.object.isRequired,
            field: PropTypes.object.isRequired,
        };

        componentWillReceiveProps({ field: { name, value }, form }) {
            if (this.props.onChange && (this.props.field.value !== value)) {
                const item = path(['values', '_embedded', name], form);
                this.props.onChange(value, item);
            }
        }

        onChange = value => {
            const { form: { setFieldValue }, field: { name } } = this.props;

            setFieldValue(name, value);
        }

        render() {
            const { field: { value } } = this.props;

            return (
                <WrappedComponent
                    {...this.props}
                    value={value}
                    onChange={this.onChange}
                />
            );
        }
    }
};

export const formFieldPropTypes = {
    form: PropTypes.object,
    field: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default withFormikField;
