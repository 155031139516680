import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import { postInstructions, postInstruction, formAutoOptions } from 'actions/asyncActions';
import OrderDateForm from 'components/common/form/OrderDateForm';

@withAsyncActions(({ params }) => ({
    postAction: params.items ? postInstructions : postInstruction
}), formAutoOptions)
export default class UnbindModal extends Component {
    static propTypes = modalFormPropTypes;

    renderForm = formProps => {
        return <OrderDateForm {...formProps} />;
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                createTitle='Отвязать'
                renderForm={this.renderForm}
                successButtonTitle='Отвязать'
            />
        );
    }
}
