import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormItem
export class NumberInput extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        placeholder: PropTypes.string,
        size: PropTypes.string,
        min: PropTypes.number,
    };

    render() {
        const { value, onChange, size, min, placeholder, disabled } = this.props;

        return (
            <InputNumber
                value={value}
                size={size}
                min={min}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled} />
        );
    }
}

export default withFormikField(NumberInput);
