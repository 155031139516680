import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import { productMenuItems } from 'menus';
import { getBrands } from 'actions/asyncActions';
import { openBrandModal } from 'actions/modalActions';
import ProductsSearchFilter from 'filters/ProductsSearchFilter';
import { PlusOutlined } from '@ant-design/icons';
import { DELETE_BRANDS, POST_BRANDS, PUT_BRANDS } from 'constants/actionTypes';

@connect(null, { openBrandModal })
export default class Brands extends Component {
    columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, item) => <span className='span-link' onClick={() => this.props.openBrandModal(item)}>{text}</span>
        }
    ];

    getMenuControls() {
        const { openBrandModal } = this.props;

        return (
            <div>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openBrandModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Menu
                    items={productMenuItems}
                    controls={this.getMenuControls()} />
                <ListTable
                    tableColumns={this.columns}
                    action={getBrands}
                    filterForm={ProductsSearchFilter}
                    refreshActions={[POST_BRANDS, PUT_BRANDS, DELETE_BRANDS]} />
            </div>
        );
    }
}
