import React, { Fragment } from 'react';
import { Dropdown, Menu, Tooltip } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import { isEmpty, range,  without } from 'ramda';
import pluralize from 'pluralize-ru';

import TIMETABLE_STATUSES from 'constants/timetableStatuses';

const TimetableStatusMenu = props => {
    const { day, changeComment, changeTimetable, item, row, data, dayReports, children } = props;

    const menu = <Menu>
        {(isEmpty(data) ? TIMETABLE_STATUSES : [
            ...TIMETABLE_STATUSES,
            { status: 'clean', value: 'Очистить' },
            { status: 'comment', value: <div><CommentOutlined /> Комментарий</div> }
        ]).map(item => item.status === 'work' || item.status === 'sick_leave' ?
            <Menu.SubMenu
                title={<span><span className='shop-plans-timetable-day-bullet' style={{ backgroundColor: item.hexColor }}></span>{item.value}</span>}
                key={item.status}>
                <Menu.Item key={`${item.status}-8-hours`} onClick={() => changeTimetable(row, day - 1, item.status, 8)}>
                    8 часов
                </Menu.Item>
                <Menu.Item key={`${item.status}-11-hours`} onClick={() => changeTimetable(row, day - 1, item.status, 11)}>
                    11 часов
                </Menu.Item>
                <Menu.Divider />
                { without([8, 11], range(1, 13)).map(hour =>
                    <Menu.Item key={`${item.status}-${hour}-hours`} onClick={() => changeTimetable(row, day - 1, item.status, hour)}>
                        { hour } { pluralize(hour, '', 'час', 'часа', 'часов') }
                    </Menu.Item>
                )}
                <Menu.Divider />
                <Menu.Item key={`${item.status}-without-time`} onClick={() => changeTimetable(row, day - 1, item.status, null)}>
                    без времени
                </Menu.Item>
            </Menu.SubMenu> : item.status === 'comment' ?
            <Fragment key={item.status}>
                <Menu.Divider />
                <Menu.Item key='comment' onClick={() => changeComment(row, day - 1, dayReports[day - 1].comment)}>
                    { item.value }
                </Menu.Item>
            </Fragment> :
            <Menu.Item key={item.status} onClick={() => changeTimetable(row, day - 1, item.status)}>
                {item.status === 'clean' ? <strong style={{ marginRight: 8, marginLeft: 2, color: 'rgb(255, 59, 48)' }}>-</strong> : <div className='shop-plans-timetable-day-bullet' style={{ backgroundColor: item.hexColor }}></div>}{item.value}
            </Menu.Item>
        )}
    </Menu>;

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            {item.comment ? <Tooltip title={item.comment}>{ children }</Tooltip> : children}
        </Dropdown>
    );
};

export default TimetableStatusMenu;
