import React from 'react';
import { Col } from 'antd'

import withRole from 'components/common/withRole';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter, { SearchField } from './base/BaseSearchFilter';
import UserStatusFilter from './fields/UserStatusFilter';
import CityFilter from './fields/CityFilter';
import RegionFilter from './fields/RegionFilter';
import NetworkFilter from './fields/NetworkFilter';

const PromoterSupervisorFilter = param => ({ isMarketingCoordinator }) => {
    return (
        <BaseSearchFilter searchFieldName={'search'} searchFieldPlaceholder={'Пользователь'}>
            <Col sm={3} xs={12}>
                <SearchField searchFieldName='lastName' placeholder='Фамилия' param={param} />
            </Col>
            <Col sm={3} xs={12}>
                <UserStatusFilter />
            </Col>
            { !isMarketingCoordinator && (
                <Col sm={3} xs={12}>
                    <RegionFilter param={param} />
                </Col>
            )}
            <Col sm={3} xs={12}>
                <CityFilter  param={param} />
            </Col>
            <Col sm={3} xs={21}>
                <NetworkFilter param={param} />
            </Col>
        </BaseSearchFilter>
    );
}

export const PromoterSupervisorFilterWithParam = param => withFilterForm(
    withRole(PromoterSupervisorFilter(param))
);

export default PromoterSupervisorFilterWithParam();

export const defaultPromoterSupervisorFilter = {
    enabled: true
};
