import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';

import withRole from 'components/common/withRole';
import UserInfo from 'components/common/cards/user/UserInfo';
import UserHeadBar from 'components/common/cards/user/UserHeadBar';
import { getUser, postUserPhoto, deleteUserPhoto } from 'actions/asyncActions';
import { ROLES } from 'constants/roles';

const stateToProps = state => ({
    user: getUser.selectData(state)
});

@withRole
@connect(stateToProps)
@withAsyncActions({ getUser })
export default class Profile extends Component {
    render() {
        const { user, role, getUser } = this.props;
        const roleInfo = ROLES[role];

        return (
            <div className="detail-card">
                <UserHeadBar actionResult={{ data: user }} tagText={roleInfo.name} tagColor={roleInfo.color} hideBack={true} />
                <UserInfo
                    user={user}
                    postPhotoAction={postUserPhoto}
                    deletePhotoAction={deleteUserPhoto}
                    refresh={() => getUser.refresh()} />
            </div>
        );
    }
}
