import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import ListTable from 'components/common/ListTable';
import Date from 'components/common/cards/Date';
import TextEditorView from 'components/common/cards/TextEditorView';
import withRole from 'components/common/withRole';
import { getNewsList } from 'actions/asyncActions';
import SearchFilter from 'filters/SearchFilter';
import { openNewsModal } from 'actions/modalActions';
import { DELETE_NEWS, POST_NEWS, PUT_NEWS } from 'constants/actionTypes';

@withRole
@connect(null, { openNewsModal })
export default class NewsList extends Component {
    columns = [
        {
            title: '',
            dataIndex: ['items', 'name'],
            key: 'news-item',
            render: (text, item) => (
                <div className='news-item'>
                    <Link to={`/news/${item.id}`}>{item.name}</Link>
                    <TextEditorView className='w-b-normal' content={item.description} />
                </div>
            )
        },
        {
            title: '',
            dataIndex: ['items', 'name'],
            key: 'news-date',
            render: (text, item) => (
                <div className='news-date'>
                    <Date date={item.publishedAt} />
                </div>
            )
        }
    ]

    getMenuControls() {
        const { openNewsModal } = this.props;

        return (
            <div className='pull-right'>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openNewsModal()}>Добавить</Button>
            </div>
        );
    }

    render() {
        const { isAdmin } = this.props;

        const controls = isAdmin && this.getMenuControls();

        return (
            <div className="layout-wrap">
                <div className='table-mobile table-no-header table-news'>
                    <ListTable
                        tableColumns={this.columns}
                        action={getNewsList}
                        filterForm={SearchFilter}
                        filterControls={controls}
                        refreshActions={[POST_NEWS, PUT_NEWS, DELETE_NEWS]}
                    />
                </div>
            </div>
        );
    }
}
