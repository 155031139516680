import React from 'react';
import { path } from 'ramda';
import { Tooltip } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';

import { isMobile } from 'constants/screen';

const CheckinGeopositionInfo = ({ data, out }) => {
    const noCoordinatesIn = !path(['in', 'coordinates'], data);
    const noCoordinatesOut = !path(['out', 'coordinates'], data);
    const finished = data.status === 'finished';

    const title =
        noCoordinatesOut && out && finished ?
            <span>Выход был выполнен без геопозиции</span> :
        noCoordinatesIn && !out ?
            <span>Чекин был выполнен без геопозиции</span> :
        null;

    return title ? (
        <Tooltip arrowPointAtCenter placement='topRight' title={title}>
            <span className='no-geo-icon'>
                <EnvironmentOutlined />
                { isMobile() && title }
            </span>
        </Tooltip>
    ) : null;
}

export default CheckinGeopositionInfo;
