import { KJUR, b64utoutf8 } from 'jsrsasign';
import moment from 'moment';

import { addDefaultHeader, removeDefaultHeader } from './http';

export function clearTokens() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expiredDate');
}

export function handleTokenChange(token, refreshToken) {
    if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('refresh_token', refreshToken);
        localStorage.setItem('expiredDate', expiredDate(token));
        addDefaultHeader('Authorization', `Bearer ${token}`);
    } else {
        clearTokens();
        removeDefaultHeader('Authorization');
    }
}

export function getToken() {
    return localStorage.getItem('token');
}

export function getRefreshToken() {
    return localStorage.getItem('refresh_token');
}

export function isTokenExpired() {
    const expiredDate = localStorage.getItem('expiredDate');

    return expiredDate ? moment().isAfter(moment(new Date(expiredDate))) : false;
}

export function getJWTToken() {
    return `Bearer ${getToken()}`;
}

function expiredDate(token) {
    const payload = b64utoutf8(token.split('.')[1]);
    const tokenInfo = KJUR.jws.JWS.readSafeJSONString(payload);

    return moment.unix(tokenInfo.exp);
}
