import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getBrands } from 'actions/asyncActions';

const BrandFilter = ({ multiple }) => (
    <Field
        name={multiple ? 'brands' : 'brand'}
        component={SearchSelect}
        placeholder='Бренд'
        multiple={multiple}
        allowClear={true}
        action={getBrands} />
)

export default BrandFilter;
