import React, { Component } from 'react';

import ReportDateContext from '../../contexts/ReportDateContext';

const withReportDate = WrappedComponent =>
    class extends Component {
        render() {
            return <ReportDateContext.Consumer>
                { ({ setReportDate, reportDate, reportDateMax }) => <WrappedComponent {...this.props} setReportDate={setReportDate} reportDate={reportDate} reportDateMax={reportDateMax} /> }
            </ReportDateContext.Consumer>;
        }
    }

export default withReportDate;
