import React, { Component } from 'react';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import * as yup from 'yup';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import Input from 'components/common/form/Input';
import CascadeSelect from 'components/common/form/CascadeSelect';
import {
    postProductCategory,
    putProductCategory,
    deleteProductCategory,
    getProductGroups,
    getProductDepartments,
    formAutoOptions,
} from 'actions/asyncActions';

const validationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    productGroup: yup.string().required()
});

@withAsyncActions({
    postAction: postProductCategory,
    putAction: putProductCategory,
    deleteAction: deleteProductCategory,
}, formAutoOptions)
export default class NetworkModal extends Component {
    static propTypes = modalFormPropTypes;

    getCascaderFields() {
        return [
            { name: 'productDepartment', action: getProductDepartments, placeholder: 'Выберите департамент', label: 'Департамент:' },
            { name: 'productGroup', action: getProductGroups, placeholder: 'Выберите группу', label: 'Группа:' }
        ];
    }

    renderForm = formProps => {
        return (
            <div>
                <Field
                    name='name'
                    component={Input}
                    label='Название:'
                    placeholder='Введите название' />
                <Field
                    name='productGroup'
                    component={CascadeSelect}
                    label='Департамент/Группа:'
                    placeholder='Выберите Группу'
                    allowClear={true}
                    fields={this.getCascaderFields()} />
            </div>
        );
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                createTitle={'Добавить линейку'}
                editTitle={'Редактировать линейку'}
                validationSchema={validationSchema}
                renderForm={this.renderForm}
            />
        );
    }
}
