import React from 'react';
import { Tooltip } from 'antd';
import { pathOr } from 'ramda';

const City = ({ city }) => {
    const region = pathOr('', ['_embedded', 'region', 'name'], city);

    return (
        <Tooltip title={city !== region && region}>
            {pathOr('', ['name'], city)}
        </Tooltip>
    );
};

export default City;
