import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';

import { getMarketingCoordinator, postMarketingCoordinatorPhoto, deleteMarketingCoordinatorPhoto } from 'actions/asyncActions';
import { openMarketingCoordinatorModal } from 'actions/modalActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { PUT_MARKETING_COORDINATOR } from 'constants/actionTypes';
import createPutSaga from 'utils/putSagaCreator';
import UserHeadBar from 'components/common/cards/user/UserHeadBar';
import UserInfo from 'components/common/cards/user/UserInfo';
import withRole from 'components/common/withRole';

@withRole
@connect(null, { openMarketingCoordinatorModal })
@withAsyncActions({
    getMarketingCoordinator: getMarketingCoordinator
        .withPayload(path(['match', 'params']))
        .withOptions(fullAutoOptions)
        .withSaga(createPutSaga(PUT_MARKETING_COORDINATOR, 'getMarketingCoordinator'))
})
export default class MarketingCoordinatorCard extends Component {
    render() {
        const { getMarketingCoordinator: { data }, getMarketingCoordinator, openMarketingCoordinatorModal, isAdmin } = this.props;

        return (
            <div className="detail-card user-card">
                <UserHeadBar
                    actionResult={getMarketingCoordinator}
                    tagText="Account Manager"
                    tagColor="orange"
                    edit={() => openMarketingCoordinatorModal(data)} />
                <UserInfo
                    user={data}
                    postPhotoAction={postMarketingCoordinatorPhoto}
                    deletePhotoAction={deleteMarketingCoordinatorPhoto}
                    refresh={() => getMarketingCoordinator.refresh()}
                    disabledPhotoEdit={!isAdmin} />
            </div>
        );
    }
}
