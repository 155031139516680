import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button } from 'antd';
import { head, contains } from 'ramda';

import ListTable from 'components/common/ListTable';
import PersonLink from 'components/common/cards/person/PersonLink';
import SupervisorFilter, { defaultSupervisorFilter } from 'filters/SupervisorFilter';
import { getSupervisorsList, getShop } from 'actions/asyncActions';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';

const stateToProps = state => ({
    shop: getShop.selectData(state)
});

@connect(stateToProps)
export default class PromoterBindTable extends Component {
    columns = [
        {
            title: 'Привязать',
            key: 'bind',
            width: 100,
            dataIndex: 'id',
            render: id => {
                const { shop } = this.props;
                const binded = contains(id, shop.supervisors);

                return <Button
                            shape='circle'
                            icon={binded ? <CheckOutlined /> : <PlusOutlined />}
                            onClick={() => this.props.select(id)}
                            disabled={binded} />
            }
        },
        {
            dataIndex: 'lastName',
            key: 'icon',
            width: 40,
            render: text => <Avatar>{head(text)}</Avatar>
        },
        {
            title: 'ФИО',
            key: 'fullName',
            render: supervisor => <PersonLink type='supervisor' person={supervisor} />
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }
    ];

    render() {
        return (
            <div className='bind-table'>
                <ListTable
                    filterForm={SupervisorFilter}
                    initialFilters={defaultSupervisorFilter}
                    action={getSupervisorsList}
                    tableColumns={this.columns}
                    param='bindTable' />
            </div>
        );
    }
}
