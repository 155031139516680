import React, { Component } from 'react';
import { Field } from 'formik';
import * as yup from 'yup';
import { withAsyncActions } from 'react-async-client';
import { append, assocPath } from 'ramda';
import { Spin } from 'antd';

import ModalForm from 'components/common/ModalForm';
import { getProductDepartments, getProductGroups, putSalePlanDepartments } from 'actions/asyncActions';
import { formAutoOptions, fullAutoOptions } from 'constants/asyncClient';
import SearchSelect from '../../common/form/SearchSelect';
import Input from '../../common/form/Input';
import Checkboxes from '../../common/form/Checkboxes';
import ColorPicker from '../../common/form/ColorPicker';
import NumberInput from '../../common/form/NumberInput';
import SwitchInput from '../../common/form/SwitchInput';

@withAsyncActions({
    getProductGroups: getProductGroups
        .withPayload(({ productDepartment }) => ({
            q: {
                productDepartment
            },
            limit: 0
        }))
        .withOptions(fullAutoOptions)
})
class GroupsField extends Component {
    render() {
        const { getProductGroups: { data, meta }} = this.props;

        return meta.pending ? <Spin size='small' /> : <Field
            name='productGroups'
            component={Checkboxes}
            label='Группы:'
            options={(data.items || []).map(item => ({
                label: item.name,
                value: item.id
            }))} />;
    }
}

@withAsyncActions(() => ({
    putAction: putSalePlanDepartments
}), formAutoOptions)
export default class FocusCategoriesProjectModal extends Component {
    renderForm = formProps => {
        return (
            <div>
                <Field
                    name='name'
                    component={Input}
                    label='Название:' />
                <Field
                    name='color'
                    component={ColorPicker}
                    label='Цвет:' />
                <Field
                    name='productDepartment'
                    component={SearchSelect}
                    label='Проект:'
                    placeholder='Выберите проект'
                    onChange={() => formProps.setFieldValue('productGroups', undefined)}
                    action={getProductDepartments} />
                { !!formProps.values.productDepartment && <GroupsField productDepartment={formProps.values.productDepartment} /> }
                <Field
                    name='salePlanBonusOptions.enabled'
                    component={SwitchInput}
                    label='Бонусный интервал:' />
                { formProps.values.salePlanBonusOptions && formProps.values.salePlanBonusOptions.enabled ? (
                    <Field
                        name='salePlanBonusOptions.percents'
                        component={NumberInput}
                        label='Бонусный процент:' />
                ) : null }
            </div>
        );
    }

    getValidationSchema() {
        return yup.object().shape({
            name: yup.string().trim().required(),
            productDepartment: yup.string().required()
        });
    }

    mapBeforeSubmit = values => {
        const { edit, index, ...data } = this.props.params;

        return edit ? {
            ...data,
            departmentPlans: assocPath([index], values, data.departmentPlans),
            salePlanBonusOptions: values.salePlanBonusOptions,
        } : {
            ...data,
            departmentPlans: append(values, data.departmentPlans),
            salePlanBonusOptions: values.salePlanBonusOptions,
        }
    }

    render() {
        const { params } = this.props;

        return (
            <ModalForm
                {...this.props}
                params={params.edit ? params.departmentPlans[params.index] : {}}
                mapBeforeSubmit={this.mapBeforeSubmit}
                editTitle={params.edit ? 'Редактировать проект' : 'Добавить проект'}
                validationSchema={this.getValidationSchema()}
                renderForm={this.renderForm}
                isEdit
            />
        );
    }
}
