import React, { Component } from 'react';
import { Row, Col, Button, Typography, message } from 'antd';
import { DeleteOutlined, NumberOutlined } from '@ant-design/icons';
import { Field, FieldArray } from 'formik';
import { asyncConnect, withAsyncActions } from 'react-async-client';
import { withRouter } from 'react-router-dom';
import { findIndex, path, propEq } from 'ramda';
import * as yup from 'yup';
import { InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';

import withRole from 'components/common/withRole';
import HeadBlock from 'components/common/cards/HeadBlock';
import FormattedPrice from 'components/common/cards/FormattedPrice';
import ArrayButton from 'components/common/form/ArrayButton';
import SearchSelect from 'components/common/form/SearchSelect';
import NumberInput from 'components/common/form/NumberInput';
import SaleDatePicker from 'components/common/form/SaleDatePicker';
import PageForm from 'components/common/PageForm';
import ArrowBack from 'components/common/ArrowBack';
import { UNABLE_ADD_SALE_WITHOUT_CHECKIN } from 'constants/serverMessages';
import { checkError } from 'utils/error';
import { getNeedBlock } from 'utils/sales';
import { openSalesProductModal } from 'actions/modalActions';
import {
    postSales,
    getProductsList,
    getProductDepartments,
    getPromotersList,
    formAutoOptions,
    getSalesList,
    getUser,
} from 'actions/asyncActions';

const calcProduct = product => parseInt(product.quantity || 0, 10) * parseFloat(product.price || 0);
const calcTotal = products => {
    return products.reduce((total, product) => total += calcProduct(product), 0);
};

const calcTotalAmount = products => {
    return products.reduce((total, product) => total += (product.quantity || 0), 0);
};

const stateToProps = state => ({
    user: getUser.selectData(state)
});

@connect(stateToProps)
@withAsyncActions({
    getSalesList: getSalesList
        .withPayload(({ user, product, date, promoter }) => ({
            q: {
                promoter: promoter || user.id,
                product,
                minDate: moment(date).startOf('day'),
                maxDate: moment(date).endOf('day')
            }
        }))
        .withParams(({ product }) => `sales-${product}`)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})
class ProductSaleInfo extends Component {
    render() {
        const { getSalesList: { data } } = this.props;

        return !!(data.items || []).length &&
            <small className="alert-text"><InfoCircleOutlined /> в выбранную дату уже было внесено {data.items.reduce((res, cur) => res + cur.quantity, 0)} шт. данного продукта</small>;
    }
}

@withRole
@withRouter
@asyncConnect({
    postAction: postSales.withOptions(formAutoOptions),
    getProductsList: getProductsList
        .withParams('dict')
}, null, { openSalesProductModal })
export default class SalesForm extends Component {
    constructor(props) {
        super();
    }

    onSubmitSuccess = () => {
        message.success('Продажа успешно обновлена.');

        if (this.getDate()) {
            this.props.history.push('/checkin');
        } else {
            this.props.history.push('/sales');
        }
    }

    onTogglePromoter = (value, formProps) => {
        if (!value) {
            formProps.setFieldValue('date', null);
            formProps.setFieldValue('products', []);
        }
    }

    renderForm = formProps => {
        const { isSupervisor, isPromoter, openSalesProductModal } = this.props;
        const isBlockSale = isPromoter && getNeedBlock();

        return (
            <div>
                {isSupervisor &&
                    <Field
                        name='promoter'
                        component={SearchSelect}
                        action={getPromotersList}
                        placeholder='Выберите консультанта'
                        allowClear={true}
                        namePath={['lastName', 'firstName', 'middleName']}
                        onChange={value => this.onTogglePromoter(value, formProps)} />
                }
                {(isPromoter || (isSupervisor && formProps.values.promoter)) &&
                    <Field
                        name='date'
                        component={SaleDatePicker}
                        disabled={this.getDate() && isPromoter}
                    />
                }
                <Field
                    name='department'
                    component={SearchSelect}
                    action={getProductDepartments}
                    placeholder='Департамент'
                    disabled
                    allowClear={true}
                />
                <FieldArray name='products' render={({ form, push, remove }) => {
                    const hasProducts = (form.values.products && form.values.products.length) || null;
                    const onSelect = item => {
                        const index = findIndex(propEq('id', item.id), form.values.products);
                        if (index >= 0) {
                            formProps.setFieldValue(`products[${index}].quantity`, parseInt(form.values.products[index].quantity || 0, 10) + 1);
                        } else {
                            push(item);
                        }
                    };

                    return (
                        <div>
                            <div className='sales-form-product-list'>
                                {hasProducts && (
                                    <Row>
                                        <b>Продукты</b>
                                    </Row>
                                )}
                                {hasProducts && form.values.products.map((product, index) => (
                                    <Row key={`product-${product.id}`}>
                                        <Col span={24} className='product-title'>
                                            {product.name}
                                            <br /> <small>{path(['_embedded', 'productCategory', 'name'], product)}</small>
                                        </Col>
                                        <Col span={24} className='product-other'>
                                            <span>
                                                <NumberOutlined /> {product.ean}
                                                {product.originalPrice && (
                                                    <small> (<FormattedPrice price={product.price} showRub />)</small>
                                                )}
                                            </span>
                                            <span className='pull-right'>
                                                <Field
                                                    name={`products[${index}].price`}
                                                    component={NumberInput}
                                                    min={0}
                                                    size='small'
                                                    hideText
                                                    disabled={isBlockSale}
                                                /> ₽
                                            </span>
                                        </Col>
                                        <Col span={24}>
                                            <Field
                                                name={`products[${index}].quantity`}
                                                component={NumberInput}
                                                min={1}
                                                size='small'
                                                hideText
                                            />
                                            {product.quantity > 1 && (
                                                <Typography.Text>
                                                    <b> = <FormattedPrice price={calcProduct(product)} showRub /></b>
                                                </Typography.Text>
                                            )}
                                            <Button type="link" danger className='product-remove pull-right' onClick={() => remove(index)}>
                                                <DeleteOutlined />
                                            </Button>
                                        </Col>
                                        { (isPromoter || isSupervisor) && (
                                            <ProductSaleInfo
                                                promoter={formProps.values.promoter}
                                                product={product.id}
                                                date={formProps.values.date}
                                            />
                                        )}
                                    </Row>
                                ))}
                                {hasProducts && (
                                    <Row className='sales-form-product-list-total'>
                                        <Col span={24}>
                                            <b>Итого: {calcTotalAmount(form.values.products)} шт. <span className='pull-right'>
                                                <FormattedPrice price={calcTotal(form.values.products)} showRub />
                                            </span></b>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                            <Field
                                name={`products`}
                                component={ArrayButton}
                                button={(
                                    <Button
                                        className='primary-dashed'
                                        type='dashed'
                                        block
                                        disabled={!form.values.department}
                                        onClick={() => openSalesProductModal({ onSelect, department: form.values.department })}
                                    >
                                        {hasProducts ? 'Добавить еще продукт' : 'Добавить продукт'}
                                    </Button>
                                )}
                            />
                        </div>
                    );
                }} />
                <Button
                    className='pull-right'
                    type='primary'
                    htmlType='submit'
                    disabled={formProps.isSubmitting}
                    onClick={formProps.handleSubmit}
                >
                    Сохранить
                </Button>
                <div className='pull-clear' />
            </div>
        );
    };
    getValidationSchema() {
        const { isSupervisor } = this.props;

        return yup.object().shape({
            promoter: isSupervisor ? yup.string().nullable().required() : yup.string().nullable(),
            shop: yup.string().nullable().required(),
            department: yup.string().nullable().required(),
            date: yup.date().nullable().required(),
            products: yup.array().of(
                yup.object().shape({
                    id: yup.string().nullable().required(),
                    price: yup.number().nullable().positive().required(),
                    quantity: yup.number().nullable().positive().required()
                })
            ).min(1, 'Выберите хотя бы один продукт'),
        });
    }

    getDate() {
        return path(['match', 'params', 'date'], this.props);
    }

    getInitialValues() {
        const { isPromoter } = this.props;
        const date = this.getDate();

        return isPromoter ? {
            date: date ? date : null,
            products: [],
        } : {
            date: null,
            products: [],
        };
    }

    render() {
        const { postAction } = this.props;

        return (
            <div className='sales-form'>
                <ArrowBack />
                <HeadBlock title='Добавить продажу' hideBack />
                <PageForm
                    initialValues={this.getInitialValues()}
                    validationSchema={this.getValidationSchema()}
                    formAction={postAction}
                    renderForm={this.renderForm}
                    onSubmitSuccess={this.onSubmitSuccess}
                    onSubmitFail={checkError('date', UNABLE_ADD_SALE_WITHOUT_CHECKIN)}
                />
            </div>
        );
    }
}
