import React from 'react';

import InstructionsBase from './InstructionsBase';
import { TodayInstructionFilter, defaultTodayInstructionFilter } from 'filters/InstructionFilter';

const TodayInstructions = () => (
    <InstructionsBase
        hideDate={true}
        hideDelete={true}
        filterForm={TodayInstructionFilter}
        initialFilters={defaultTodayInstructionFilter} />
);

export default TodayInstructions;
