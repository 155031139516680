import React, { Component } from 'react';

import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import CheckinsFilter from 'filters/CheckinsFilter';
import Date from 'components/common/cards/Date';
import CheckinTime from 'components/common/cards/checkin/CheckinTime';
import CheckinShopStatus from 'components/common/cards/checkin/CheckinShopStatus';
import PersonLink from 'components/common/cards/person/PersonLink';
import ShopLink from 'components/common/cards/shop/ShopLink';
import { getCheckins } from 'actions/asyncActions';
import CheckinTimeInfo from 'components/common/cards/checkin/CheckinTimeInfo';
import { path } from 'ramda';

@withRole
export default class Checkins extends Component {
    columns = [
        {
            title: 'Консультант',
            dataIndex: ['_embedded', 'promoter'],
            key: 'promoter',
            render: promoter => <PersonLink person={promoter} />
        },
        {
            title: 'Магазин',
            dataIndex: ['_embedded', 'shop'],
            key: 'shop',
            render: shop => <ShopLink shop={shop} />
        }, {
            title: 'Адрес',
            dataIndex: ['_embedded', 'shop', 'address'],
            key: 'address'
        }, {
            title: 'Супервайзер',
            dataIndex: ['_embedded', 'supervisor'],
            key: 'supervisor',
            render: supervisor => <PersonLink person={supervisor} type='supervisor' />,
            hide: this.props.isSupervisor
        }, {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (date, item) => <Date timezoneOffset={path(['_embedded', 'promoter', '_embedded', 'city', 'timeZoneOffset'], item)} date={date} />
        }, {
            title: 'Время захода',
            key: 'in',
            render: (checkin, item) => <CheckinTimeInfo timezoneOffset={path(['_embedded', 'promoter', '_embedded', 'city', 'timeZoneOffset'], item)} checkin={checkin} />
        }, {
            title: 'Время выхода',
            render: (checkin, item) => <CheckinTime timezoneOffset={path(['_embedded', 'promoter', '_embedded', 'city', 'timeZoneOffset'], item)} checkin={checkin} out={true} />
        }, {
            key: 'correct',
            dataIndex: 'shopStatus',
            render: status => <CheckinShopStatus status={status} />
        }
    ];

    render() {
        return (
            <div>
                <ListTable
                    tableColumns={this.columns}
                    filterForm={CheckinsFilter}
                    action={getCheckins}
                    staticPayload={{
                        sort_by: 'date',
                        sort_order: 'desc'
                    }}
                />
            </div>
        );
    }
}
