import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getShops } from 'actions/asyncActions';
import { getAddressString } from 'components/common/cards/Address';

export const renderShopItem = (item) => (
    <div className='shop-select-option' title={getAddressString(item)}>
        <div className='shop-select-name'>
            <span className="font-small">{item.name}</span>
        </div>
        <em className="font-small">
            {item.code}
        </em>
    </div>
);

const ShopFilter = ({ filter = {}, multiple, values = {} }) => (
    <Field
        name={multiple ? 'shops' : 'shop'}
        component={SearchSelect}
        placeholder='Магазин'
        multiple={multiple}
        allowClear={true}
        action={getShops}
        filter={({ network, networks, city, cities, region, regions}) => ({
            ...filter,
            [regions && (regions.length > 1) ? 'regions' : 'region']: region || (regions && (regions.length === 1) ? regions[0] :  regions),
            [networks && (networks.length > 1) ? 'networks' : 'network']: network || (networks && (networks.length === 1) ? networks[0] :  networks),
            [cities && (cities.length > 1) ? 'cities' : 'city']: city || (cities && (cities.length === 1) ? cities[0] :  cities),
        })}
        renderItem={renderShopItem}
        param='filter' />
)

export default ShopFilter;
