import React, { Component } from 'react';
import { Checkbox } from 'antd';

import withFormItem from './withFormItem';
import withFormikField from './withFormikField';

@withFormItem
export class Checkboxes extends Component {
    render() {
        const { value, options, onChange } = this.props;

        return (
            <Checkbox.Group
                defaultValue={value}
                onChange={onChange}
                options={options} />
        );
    }
}

export default withFormikField(Checkboxes);
