import React from 'react';

const PersonName = ({ person, short }) => {
    const { lastName, firstName, middleName } = person || {};

    return person ? (
        <span>
            {`${lastName || ''} ${firstName || ''} ${short ? '' : (middleName || '')}`}
        </span>
    ) : '';
};

export default PersonName;
