import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col } from 'antd';
import { path } from 'ramda';

import PersonName from 'components/common/cards/person/PersonName';
import Date from 'components/common/cards/Date';

export default class TimetablePersonShopsModal extends Component {
    static propTypes = {
        params: PropTypes.shape({
            employee: PropTypes.object,
            shops: PropTypes.object,
            minDate: PropTypes.string,
            maxDate: PropTypes.string,
        })
    };

    render() {
        const { modal, params } = this.props;

        return (
            <Modal {...modal} footer={null} title={<PersonName person={params.employee} />}>
                <div className='mb10'>
                    Магазины за период: <Date date={params.minDate} format='MM.YYYY' /> - <Date date={params.maxDate} format='MM.YYYY' />
                </div>
                { params.shops && params.shops.length ? params.shops.map(shop => (
                    <Row className='mb10 row-table'>
                        <Col xs={10}>
                            <strong>{shop.name}</strong>
                            <span>{path(['_embedded', 'network', 'name'], shop)}</span>
                        </Col>
                        <Col xs={12}>
                            {shop.address}
                        </Col>
                        <Col xs={2}>
                            {shop.code}
                        </Col>
                    </Row>
                )) : 'Нет привязанных магазинов'}
            </Modal>
        );
    }
}
