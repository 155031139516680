import React from 'react';
import FormattedPrice from './FormattedPrice';
import { WalletOutlined } from '@ant-design/icons';

const Price = ({ sale = {} }) => {
    const { price, quantity } = sale;

    return quantity ? (
        <span className='price-element'>
            <WalletOutlined />
            {quantity} - <FormattedPrice price={price * quantity} />
            {quantity > 1 && <span> (<FormattedPrice price={price} /> за ед.)</span> }
        </span>
    ) : null;
}

export default Price;
