import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toSuccess, withAsyncActions } from 'react-async-client';
import { takeEvery } from '@redux-saga/core/effects';
import { omit } from 'ramda';

import Table, { tablePropTypes } from './Table';

export const tableRefreshHandler = ({ action }) => action.refresh();

export const rowEvenOddClassName = (item, index) => index % 2 ? 'table-row-odd' : 'table-row-even';

export default class ListTable extends Component {
    static propTypes = {
        ...tablePropTypes,
        filterForm: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
            PropTypes.node,
        ]),
        filterControls: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
            PropTypes.node,
        ]),
        filterMainControls: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
            PropTypes.node,
        ]),
        tableColumns: PropTypes.array,
        className: PropTypes.string,
    };

    static defaultProps = {
        staticPayload: {}
    };

    constructor(props) {
        super(props);

        this.state = {
            q: props.initialFilters,
            offset: 0
        };

        const action = this.props.action
            .withDefaultPayload(({ state }) => ({ ...state, ...props.staticPayload }))
            .withParams(this.props.param || 'listTable')
            .withSuccessHandler(({ onSuccess }) => onSuccess && onSuccess())
            .withSaga(function* (getProps) {
                const { refreshActions = [] } = getProps();
                const successActions = refreshActions.map(toSuccess);

                yield takeEvery(successActions, () => {
                    getProps().action.refresh();
                });

                if (getProps().saga) {
                    yield getProps().saga(getProps);
                }
            })
            .withOptions({
                autoFetch: true,
                autoUpdate: true,
                autoReset: true
            });
        this.component = withAsyncActions({
            action,
            ...this.props.asyncActions
        })(ListTableStateless);
    }

    setOffset = offset => {
        this.setState({ offset });
    }

    setFilters = q => {
        this.setState({ q: q ? omit(['_embedded'], q) : null, offset: 0 });
    }

    render() {
        const Component = this.component;

        return <Component
            {...this.props}
            setFilters={this.setFilters}
            setOffset={this.setOffset}
            state={this.state}
        />;
    }
}

class ListTableStateless extends React.Component {
    getColumns() {
        const { tableColumns } = this.props;

        return tableColumns.filter(column => !column.hide);
    }

    render() {
        const {
            filterForm: FilterForm,
            filterControls,
            filterMainControls,
            action,
            loading,
            setFilters,
            setOffset,
            initialFilters,
            scroll,
            expandedRowRender,
            rowSelection,
            rowClassName,
            className,
            parseData,
            footer,
            bordered,
            lined,
            showHeader,
            expandable,
            hidePagination,
            dataSource,
            rowKey
        } = this.props;

        return (
            <div className={className}>
                { FilterForm && (
                    <FilterForm setFilters={setFilters} initialValues={initialFilters} controls={filterMainControls} additionalControls={filterControls} />
                )}
                <Table
                    columns={this.getColumns()}
                    action={action}
                    loading={loading}
                    setOffset={setOffset}
                    scroll={scroll}
                    expandedRowRender={expandedRowRender}
                    rowSelection={rowSelection}
                    rowClassName={lined ? rowEvenOddClassName : rowClassName}
                    parseData={parseData}
                    footer={footer}
                    bordered={bordered}
                    showHeader={showHeader}
                    expandable={expandable}
                    hidePagination={hidePagination}
                    dataSource={dataSource}
                    rowKey={rowKey}
                />
            </div>
        );
    }
}
