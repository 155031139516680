import React from 'react';
import { Col } from 'antd'

import withRole from 'components/common/withRole';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import UserStatusFilter from './fields/UserStatusFilter';
import CityFilter from './fields/CityFilter';
import RegionFilter from './fields/RegionFilter';
import NetworkFilter from './fields/NetworkFilter';
import { SUPERVISORS } from 'constants/urls';
import ExcelDownload from 'components/common/ExcelDownload';

function SupervisorFilter({ isClient, isMarketingCoordinator, values }) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={values} url={SUPERVISORS} />
        </Col>
    );

    return (
        <BaseSearchFilter controls={isClient ? null : controls} searchFieldName={'search'} searchFieldPlaceholder={'Пользователь'}>
            <Col sm={4} xs={12}>
                <UserStatusFilter />
            </Col>
            { !isMarketingCoordinator && (
                <Col sm={4} xs={12}>
                    <RegionFilter />
                </Col>
            )}
            <Col sm={4} xs={12}>
                <CityFilter />
            </Col>
            <Col sm={4} xs={12}>
                <NetworkFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(
    withRole(SupervisorFilter)
);

export const defaultSupervisorFilter = {
    enabled: true
};
