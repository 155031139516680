import axios from 'axios';
import { omit } from 'ramda';

import { BASE_URL } from 'constants/urls';

export function setBaseUrl(base = BASE_URL) {
    axios.defaults.baseURL = base;
}

export function addDefaultHeader(header, value) {
    axios.defaults.headers.common[header] = value;
};

export function removeDefaultHeader(header) {
    axios.defaults.headers.common = omit([header], axios.defaults.headers.common);
};

export function getActionUrl(url) {
    return axios.defaults.baseURL + url.stringify();
};
