import React, { Component } from 'react';

// import Error from './Error';
import BaseForm from './form/BaseForm';

export default class PageForm extends Component {
    render() {
        return (
            <BaseForm {...this.props} />
        );
    }
}
