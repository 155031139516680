import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormItem
export class SelectComponent extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        placeholder: PropTypes.string,
        allowClear: PropTypes.bool,
        options: PropTypes.array,
        path: PropTypes.string
    };

    static defaultProps = {
        options: [],
        path: 'id'
    };

    renderOptions() {
        const { options, path } = this.props;

        return options.map((option, index) =>
            <Select.Option key={index} value={JSON.stringify(option[path])}>
                { option.name }
            </Select.Option>
        );
    }

    onChange = value => this.props.onChange(value !== undefined ? JSON.parse(value) : undefined);

    render() {
        const { value, placeholder, allowClear, disabled } = this.props;

        return (
            <Select
                value={JSON.stringify(value)}
                disabled={disabled}
                onChange={this.onChange}
                placeholder={placeholder}
                allowClear={allowClear}
                dropdownStyle={{ width: '100%' }}
                getPopupContainer={trigger => trigger.parentNode}>
                { this.renderOptions() }
            </Select>
        );
    }
}

export default withFormikField(SelectComponent);
