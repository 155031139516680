import React, { Component } from 'react';

import ListTable from 'components/common/ListTable';
import Date from 'components/common/cards/Date';
import CheckinTimeInfo from 'components/common/cards/checkin/CheckinTimeInfo';
import CheckinTimeSpent from 'components/common/cards/checkin/CheckinTimeSpent';
import CheckinShopStatus from 'components/common/cards/checkin/CheckinShopStatus';
import Address from 'components/common/cards/Address';
import { getCheckins } from 'actions/asyncActions';
import PromoterVisitsFilter from 'filters/PromoterVisitsFilter';

export default class PromoterVisits extends Component {
    columns = [
        {
            title: 'Магазин',
            dataIndex: ['_embedded', 'shop', 'name'],
            key: 'name',
            className: 'td-shop'
        }, {
            title: 'Адрес',
            dataIndex: ['_embedded', 'shop', 'address'],
            key: 'address',
            render: address => <Address item={{ address }}/>,
            className: 'td-address'
        }, {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: date => <Date date={date} />,
            className: 'td-inline td-date'
        }, {
            title: 'Затраченное время',
            key: 'time',
            render: checkin => <CheckinTimeSpent checkin={checkin} />,
            className: 'td-used'
        }, {
            title: 'Время захода',
            key: 'in',
            render: checkin => <CheckinTimeInfo checkin={checkin} />,
            className: 'td-inline td-other td-start'
        }, {
            title: 'Время выхода',
            key: 'out',
            render: checkin => <CheckinTimeInfo checkin={checkin} out={true} />,
            className: 'td-inline td-other td-finish'
        }, {
            key: 'correct',
            dataIndex: 'shopStatus',
            render: status => <CheckinShopStatus status={status} />,
            className: 'td-status'
        }
    ];

    render() {
        return (
            <div className="layout-wrap">
                <div className='table-mobile table-visits'>
                    <ListTable
                        filterForm={PromoterVisitsFilter}
                        tableColumns={this.columns}
                        action={getCheckins}
                        staticPayload={{
                            sort_by: 'date',
                            sort_order: 'desc'
                        }} />
                </div>
            </div>
        );
    }
}
