import React, { Component } from 'react';
import { Field } from 'formik';
import * as yup from 'yup';
import { withAsyncActions } from 'react-async-client';
import { Form, Button, message } from 'antd';
import { NavLink } from 'react-router-dom';

import { login, formAutoOptions } from 'actions/asyncActions';
import Input from 'components/common/form/Input';
import PageForm from 'components/common/PageForm';
import { UnlockOutlined, UserOutlined } from '@ant-design/icons';

const validationSchema = yup.object().shape({
    username: yup.string().trim().required(),
    password: yup.string().required(),
});

const initialValues = {
    username: '',
    password: ''
};

@withAsyncActions({
    login: login
        .withErrorHandler(() => message.error('Не удалось авторизоваться'))
}, formAutoOptions)
export default class LoginView extends Component {
    renderForm = ({ isSubmitting, handleSubmit }) => (
        <div>
            <Field
                name='username'
                component={Input}
                htmlType='email'
                prefix={<UserOutlined />}
                placeholder='Логин'
            />
            <Field
                name='password'
                htmlType='password'
                component={Input}
                prefix={<UnlockOutlined />}
                placeholder='Пароль'
            />
            <Form.Item>
                <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                >
                    Войти
                </Button>
            </Form.Item>
        </div>
    );

    render() {
        const { login } = this.props;

        return (
            <div>
                <div className='guest-header'>
                    <h2>Вход</h2>
                </div>
                <PageForm
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    formAction={login}
                    renderForm={this.renderForm}
                />
                <NavLink to='/recovery'>
                    Забыли пароль?
                </NavLink>
            </div>
        );
    }
}
