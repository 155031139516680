import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { path } from 'ramda';

import ListTable from 'components/common/ListTable';
import Date from 'components/common/cards/Date';
import Address from 'components/common/cards/Address';
import TextEditorView from 'components/common/cards/TextEditorView';
import TaskReportsFilter from 'filters/TaskReportsFilter';
import { getTaskReports } from 'actions/asyncActions';

export default class TaskReports extends Component {
    columns = [
        {
            title: 'Задача',
            dataIndex: ['_embedded', 'task', 'text'],
            key: 'text',
            render: (text, { id }) =>
                <Link className='task-table-text' to={`/report/${id}`}>
                    <TextEditorView content={text} />
                </Link>
        }, {
            title: 'Магазин',
            dataIndex: ['_embedded', 'shop', 'name'],
            key: 'shop'
        }, {
            title: 'Сеть',
            dataIndex: ['_embedded', 'network', 'name'],
            key: 'network'
        }, {
            title: 'Адрес',
            dataIndex: ['_embedded', 'shop'],
            key: 'address',
            render: shop => <Address item={shop} />
        }, {
            title: 'Дата выполнения',
            dataIndex: 'date',
            key: 'date',
            render: date => <Date date={date} />
        }
    ];

    render() {
        return (
            <div className='table-mobile tasks'>
                <ListTable
                    tableColumns={this.columns}
                    action={getTaskReports}
                    filterForm={TaskReportsFilter}
                    rowClassName={report => path(['_embedded', 'task', 'priority'], report)} />
            </div>
        );
    }
}
