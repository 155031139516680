import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';

import TaskHeadBlock from 'components/common/cards/task/TaskHeadBlock';
import InfoBlock from 'components/common/cards/InfoBlock';
import TaskText from 'components/common/cards/task/TaskText';
import TaskFiles from 'components/common/cards/task/TaskFiles';
import TaskReportForm from './TaskReportForm';
import Spinner from 'components/common/Spinner';
import { getTask, getCurrentCheckin, getTaskReports } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { MessageOutlined } from '@ant-design/icons';
import moment from 'moment';

@withAsyncActions({
    getCurrentCheckin: getCurrentCheckin
        .withOptions(fullAutoOptions),
    getTaskReports: getTaskReports
        .withPayload(({ match }) => ({
            q: {
                task: path(['params', 'id'], match),
                startDate: moment.utc().toDate(),
                endDate: moment.utc().toDate()
            }
        }))
        .withOptions(fullAutoOptions),
    getTask: getTask
        .withPayload(path(['match', 'params', 'id']))
        .withOptions(fullAutoOptions)
})
export default class AddTaskReport extends Component {
    render() {
        const {
            getTask: { data : task, meta: { success: taskSuccess }},
            getCurrentCheckin: { data: checkin, meta: { success: checkinSuccess }},
            getTaskReports: { data: reports, meta: { pending: reportsPending }}
        } = this.props;

        const report = path(['items', 0], reports);
        const files = path(['_embedded', 'files'], report);
        const taskFiles = path(['_embedded', 'files'], task);

        return (
            <div className='detail-card'>
                <TaskHeadBlock task={task} />
                <TaskText text={task.text} />
                <TaskFiles files={taskFiles} />

                <InfoBlock iconType={<MessageOutlined />} title='Результат задачи'>
                    {
                        !taskSuccess || !checkinSuccess || reportsPending
                            ? <Spinner />
                        : report
                            ? (
                                <div>
                                    <TaskText text={report.text} />
                                    <TaskFiles files={files} />
                                </div>
                            )
                            : <TaskReportForm task={task} checkin={checkin} />
                    }
                </InfoBlock>
            </div>
        );
    }
}
