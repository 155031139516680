import React from 'react';

import AccessLink from '../AccessLink';
import { userLinks } from 'constants/roles';

const ShopLink = ({ shop = {} }) => (
    <AccessLink to={`/shop/${shop.id}`} access={userLinks}>{shop.name}</AccessLink>
);

export default ShopLink;
