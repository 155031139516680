import { Button, Input, Modal } from 'antd';
import React, { Component } from 'react';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
    max-width: 400px;
    .ant-modal-body {
        padding: 10px;
    }
`;

class PlanCommentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.params.value
        }
    }

    onChange = e => this.setState({ value: e.target.value });

    save = () => {
        this.props.params.onSubmit(this.state.value);
        this.props.close();
    }

    render() {
        const { modal, close } = this.props;

        return <StyledModal
            {...modal}
            title={null}
            footer={null}
            destroyOnClose
            centered
            closable={false}>
            <Input.TextArea rows={4} value={this.state.value || ''} onChange={this.onChange} />
            <div style={{ marginTop: 10 }}>
                <Button type='primary' onClick={this.save} style={{ marginRight: 10 }}>СОХРАНИТЬ</Button>
                <Button onClick={close}>ОТМЕНА</Button>
            </div>
        </StyledModal>;
    }
}

export default PlanCommentModal;
