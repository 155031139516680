import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { equals, omit } from 'ramda';

import BaseForm from 'components/common/form/BaseForm';

class FilterForm extends Component {
    static propTypes = {
        WrappedComponent: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
            PropTypes.node,
            PropTypes.object,
        ]).isRequired,
        initialValues: PropTypes.object
    };

    componentWillReceiveProps({ values, submitForm }) {
        if (values && !equals(this.props.values, values)) {
            submitForm();
        }
    }

    render() {
        const { WrappedComponent, ...props } = this.props;

        return <WrappedComponent {...props} />;
    }
}

export default function withFilterForm(WrappedComponent) {
    return class extends Component {
        static propTypes = {
            setFilters: PropTypes.func,
            initialValues: PropTypes.object
        };

        renderForm = formProps => {
            return (
                <div className='filters-bar'>
                    <FilterForm
                        WrappedComponent={WrappedComponent}
                        {...this.props}
                        {...formProps}
                    />
                </div>
            );
        }

        setFilters = filters => {
            this.props.setFilters(filters ? omit(['_embedded'], filters) : null);
        }

        render() {
            return (
                <BaseForm
                    initialValues={this.props.initialValues}
                    formAction={this.setFilters}
                    renderForm={this.renderForm}
                />
            );
        }
    }
}
