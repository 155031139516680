import React from 'react';
import { Field } from 'formik';

import MonthPicker from 'components/common/form/MonthPicker';

const MonthFilter = ({ field = 'date' }) => (
    <Field
        name={field}
        component={MonthPicker}
        placeholder='Месяц'
        allowClear={false} />
);

export default MonthFilter;
