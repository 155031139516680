import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import TaskPriorityFilter from './fields/TaskPriorityFilter';
import BeforeDateFilter, { StartDateFilter } from './fields/BeforeDateFilter';
import PromoterShopFilter from './fields/PromoterShopFilter';

function TaskReportsFilter() {
    return (
        <BaseSearchFilter searchFieldName='text'>
            <Col sm={4} xs={12}>
                <TaskPriorityFilter />
            </Col>
            <Col sm={4} xs={12}>
                <StartDateFilter />
            </Col>
            <Col sm={4} xs={12}>
                <BeforeDateFilter />
            </Col>
            <Col sm={4} xs={12}>
                <PromoterShopFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(TaskReportsFilter);
