import React from 'react';
import { Col } from 'antd'

import withRole from 'components/common/withRole';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import UserStatusFilter from './fields/UserStatusFilter';
import RegionFilter from './fields/RegionFilter';
import { MARKETING_COORDINATORS } from 'constants/urls';
import ExcelDownload from 'components/common/ExcelDownload';

function MarketingCoordinator({ isClient, values }) {
    const controls = (
        <Col sm={2} xs={6} className="controls-block">
            <ExcelDownload query={values} url={MARKETING_COORDINATORS} />
        </Col>
    );

    return (
        <BaseSearchFilter controls={isClient ? null : controls} searchFieldName={'search'} searchFieldPlaceholder={'Пользователь'}>
            <Col sm={4} xs={12}>
                <UserStatusFilter />
            </Col>
            <Col sm={4} xs={12}>
                <RegionFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(
    withRole(MarketingCoordinator)
);

export const defaultMarketingCoordinatorFilter = {
    enabled: true
};
