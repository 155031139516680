import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { omit, prop, pickBy, propEq, path } from 'ramda';

import { getCurrentEmbedded, SearchSelect } from './SearchSelect';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormikField
export default class ListCascadeSelect extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                action: PropTypes.func.isRequired,
                allowClear: PropTypes.bool,
                placeholder: PropTypes.string,
                label: PropTypes.string
            })
        )
    };

    constructor(props) {
        super(props);

        this.state = {
            filter: pickBy((val, key) => props.fields.some(propEq('name', key)), props.form.values)
        };
    }

    onChange = (value, name, index, items) => {
        const currentEmbedded = getCurrentEmbedded(this.props);
        const { fields, form: { setFieldValue }} = this.props;
        const item = items.find(propEq('id', value)) || {};
        setFieldValue(name, prop('id', item));

        let filter = { ...this.state.filter, [name]: value };
        let embedded = { ...currentEmbedded, [name]: item };

        fields.forEach(({ name }, i) => {
            if (i > index) {
                setFieldValue(name, null);
                filter[name] = null;
                embedded[name] = null;
            }

            if (i < index && value) {
                setFieldValue(name, item[name]);
                embedded[name] = path(['_embedded', name], item);
            }
        });

        setFieldValue('_embedded', embedded);
        this.setState({ filter });
    }

    getFilter(index) {
        const { fields } = this.props;
        const { filter } = this.state;

        return omit(fields.filter((item, i) => i >= index).map(prop('name')), filter);
    }

    renderSelects() {
        const { fields } = this.props;

        return fields.map((field, index) =>
            <Field
                {...field}
                key={index}
                component={SearchSelect}
                allowClear={true}
                onChange={(value, items) => this.onChange(value, field.name, index, items)}
                filter={this.getFilter(index)}
                param='cascade'
                disableRelations={false} />
        );
    }

    render() {
        return <div>{this.renderSelects()}</div>;
    }
}
