import React from 'react';
import ReactDOM from 'react-dom';
import { locale } from 'moment';
import 'moment/locale/ru';

import 'classlist-polyfill';
import 'babel-polyfill';

import 'utils/yup';
import 'actions/asyncActions';

import App from './App';

import './styles/styles.less';

import { provideResponseInterceptors, provideRequestInterceptors } from 'utils/httpInterceptors';
import { setBaseUrl } from 'utils/http';
import { configureStore } from './store/configureStore';

provideResponseInterceptors();
provideRequestInterceptors();
setBaseUrl('/api');
locale('ru');

export const Store = configureStore();

if (process.env.NODE_ENV === 'production') {
    const initRollbar = require('utils/rollbar').default;
    initRollbar(Store);
}

ReactDOM.render(
    <App store={Store} />,
    document.getElementById('root')
);
