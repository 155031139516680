import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { asyncConnect } from 'react-async-client';
import styled from 'styled-components';
import { CalendarOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { path } from 'ramda';

import { getTasks, getTaskReports } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import DateField from 'components/common/cards/Date';
import TextEditorView from 'components/common/cards/TextEditorView';

const PRIORITY_COLORS = {
    high: '#F41E59',
    medium: '#FFCC00',
    low: '#C2FE00'
};

const TaskWrapper = styled.div`
    padding: 16px 8px;
    background: #324153;
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #F0F2F5;
        opacity: ${({ complete }) => complete ? .6 : 0};
    }
`;

const TaskPriority = styled.div`
    min-width: 6px;
    margin-right: 10px;
    height: 100%;

    &:before {
        content: '';
        border-radius: 40px;
        width: 6px;
        position: absolute;
        top: 8px;
        bottom: 8px;
        background: ${({ priority }) => PRIORITY_COLORS[priority] }
    }
`;

const TaskTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 5px;
    p {
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        margin-bottom: 0;
    }
`;

const TaskDate = styled.div`
    font-size: 13px;
    color: #8F8F8F;
`;

@asyncConnect(({ task }) => ({
    getTaskReports: getTaskReports
        .withParams(task.id)
        .withPayload(({ task }) => ({ q: { task: task.id }}))
        .withOptions(fullAutoOptions),
}))
class CheckinTask extends Component {
    render() {
        const { getTaskReports: { data }, task } = this.props;
        const hasResult = !!(data.items && data.items.length);

        return <TaskWrapper complete={hasResult}>
            <TaskPriority priority={task.priority} />
            <div>
                <TaskTitle className='task-table-text' style={hasResult ? { textDecoration: 'line-through' } : null}>
                    <TextEditorView content={task.text} />
                </TaskTitle>
                <TaskDate><CalendarOutlined /> <DateField hideIcon date={task.startDate} /> - <DateField hideIcon date={task.endDate} /></TaskDate>
            </div>
        </TaskWrapper>;
    }
}

@asyncConnect({
    getTasks: getTasks
        .withPayload(({ checkin }) => ({
            q: {
                shop: checkin.shop
            },
            limit: 0
        }))
        .withOptions(fullAutoOptions)
})
export default class CheckinTasks extends Component {
    render() {
        const { getTasks: { data, meta }, checkin } = this.props;

        return <div className='mt15'>
            { path(['items', 'length'], data) ? (
                <h2 className='title'>Задачи</h2>
            ) : null}
            {meta.pending ? <Spin /> : (
                <div>
                    { (data.items || []).map(task =>
                        <Link key={task.id} to={`/report/add/${task.id}`}>
                            <CheckinTask task={task} checkin={checkin} />
                        </Link>
                    )}
                </div>
            )}
        </div>;
    }
}
