import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import UserRoute from 'components/common/UserRoute';
import { ADMIN, CLIENT, MARKETING_COORDINATOR, SUPERVISOR } from 'constants/roles';
import CommonPlans from './lists/CommonPlans';
import NetworkPlans from './lists/NetworkPlans';
import RegionPlans from './lists/RegionPlans';
import SupervisorPlans from './lists/SupervisorPlans';
import PlansView from './lists/Plans';
import Menu from 'components/common/Menu';
import { planMenuItems } from 'menus';
import { getUser } from 'actions/asyncActions';
import { openImportModal } from 'actions/modalActions';
import PromoterPlans from './lists/PromoterPlans';
import ShopPlans from './lists/ShopPlans';
import PlanDateContext from '../../contexts/PlanDateContext';
import moment from 'moment';

const defaultUrls = {
    [MARKETING_COORDINATOR]: '/regions',
    [SUPERVISOR]: '/supervisors'
};

const stateToProps = state => ({
    user: getUser.selectData(state)
});

@connect(stateToProps, { openImportModal })
export default class Plans extends Component {
    state = {
        date: moment.utc().toDate()
    };

    setPlanDate = date => this.setState({ date });

    render() {
        const { match: { url }, user } = this.props;
        const [role] = user.roles;
        const redirect = `${url}${defaultUrls[role] || ''}`;

        return (
            <PlanDateContext.Provider value={{ planDate: this.state.date, setPlanDate: this.setPlanDate }}>
                <div>
                    <Menu items={planMenuItems(url)} />
                    <Switch>
                        <UserRoute
                            path={url}
                            exact
                            redirect={redirect}
                            component={CommonPlans}
                            access={[ADMIN, CLIENT]}
                        />
                        <UserRoute
                            path={`${url}/networks`}
                            redirect={redirect}
                            component={NetworkPlans}
                            access={[ADMIN, CLIENT]}
                        />
                        <UserRoute
                            path={`${url}/regions`}
                            redirect={redirect}
                            component={RegionPlans}
                            access={[ADMIN, CLIENT, MARKETING_COORDINATOR]}
                        />
                        <Route path={`${url}/supervisors`} component={SupervisorPlans} />
                        <Route path={`${url}/promoters`} component={PromoterPlans} />
                        <Route path={`${url}/shops`} component={ShopPlans} />
                        <Route path={`${url}/plans`} component={PlansView} />

                        <Route component={() => <Redirect to={redirect} />} />
                    </Switch>
                </div>
            </PlanDateContext.Provider>
        );
    }
}
