import React, { Component } from 'react';

import ShopBindTable from '../tables/ShopBindTable';
import BindModal from '../BindModal';

export default class ShopSupervisorBindModal extends Component {
    getInitialValues() {
        const { params: { id }} = this.props;

        return {
            supervisor: id,
            type: 'supervisorShop'
        };
    }

    render() {
        return (
            <BindModal
                {...this.props}
                title='Добавить магазин'
                initialValues={this.getInitialValues()}
                steps={[
                    { title: 'Выбор магазина', table: ShopBindTable, path: 'shop' }
                ]}
            />
        );
    }
}
