import { takeEvery } from 'redux-saga/effects';

import createAsyncAction from 'utils/createAsyncAction';
import * as handlers from './handlers';
import * as actions from 'constants/actionTypes';

export * from 'constants/asyncClient';

export const login = createAsyncAction(actions.LOGIN, handlers.postLoginHandler);
export const postRecovery = createAsyncAction(actions.POST_RECOVERY, handlers.postRecoveryHandler);
export const getRecovery = createAsyncAction(actions.GET_RECOVERY, handlers.getRecoveryHandler);
export const postReset = createAsyncAction(actions.POST_RESET, handlers.postResetHandler);
export const getUser = createAsyncAction(actions.GET_USER, handlers.getUserHandler);
export const putRestoreUser = createAsyncAction(actions.PUT_RESTORE_USER, handlers.putRestoreUserUserHandler);

export const getShops = createAsyncAction(actions.GET_SHOPS, handlers.getShopsHandler, {}, takeEvery);
export const getShop = createAsyncAction(actions.GET_SHOP, handlers.getShopHandler);
export const postShop = createAsyncAction(actions.POST_SHOP, handlers.postShopHandler);
export const putShop = createAsyncAction(actions.PUT_SHOP, handlers.putShopHandler);
export const deleteShop = createAsyncAction(actions.DELETE_SHOP, handlers.deleteShopHandler);

export const getNetworks = createAsyncAction(actions.GET_NETWORKS, handlers.getNetworksHandler);
export const postNetwork = createAsyncAction(actions.POST_NETWORK, handlers.postNetworkHandler);
export const putNetwork = createAsyncAction(actions.PUT_NETWORK, handlers.putNetworkHandler);
export const deleteNetwork = createAsyncAction(actions.DELETE_NETWORK, handlers.deleteNetworkHandler);

export const getRegions = createAsyncAction(actions.GET_REGIONS, handlers.getRegionsHandler);
export const postRegion = createAsyncAction(actions.POST_REGIONS, handlers.postRegionHandler);
export const putRegion = createAsyncAction(actions.PUT_REGIONS, handlers.putRegionHandler);
export const deleteRegion = createAsyncAction(actions.DELETE_REGIONS, handlers.deleteRegionHandler);

export const getCities = createAsyncAction(actions.GET_CITIES, handlers.getCitiesHandler);
export const postCity = createAsyncAction(actions.POST_CITIES, handlers.postCityHandler);
export const putCity = createAsyncAction(actions.PUT_CITIES, handlers.putCityHandler);
export const deleteCity = createAsyncAction(actions.DELETE_CITIES, handlers.deleteCityHandler);

export const getProductsList = createAsyncAction(actions.GET_PRODUCTS_LIST, handlers.getProductsListHandler);
export const postProduct = createAsyncAction(actions.POST_PRODUCT, handlers.postProductHandler);
export const putProduct = createAsyncAction(actions.PUT_PRODUCT, handlers.putProductHandler);
export const deleteProduct = createAsyncAction(actions.DELETE_PRODUCT, handlers.deleteProductHandler);

export const getProductCategories = createAsyncAction(actions.GET_PRODUCT_CATEGORIES, handlers.getProductCategoriesHandler, []);
export const postProductCategory = createAsyncAction(actions.POST_PRODUCT_CATEGORY, handlers.postProductCategoryHandler);
export const putProductCategory = createAsyncAction(actions.PUT_PRODUCT_CATEGORY, handlers.putProductCategoryHandler);
export const deleteProductCategory = createAsyncAction(actions.DELETE_PRODUCT_CATEGORY, handlers.deleteProductCategoryHandler);

export const getProductGroups = createAsyncAction(actions.GET_PRODUCT_GROUPS, handlers.getProductGroupsHandler, []);
export const postProductGroup = createAsyncAction(actions.POST_PRODUCT_GROUP, handlers.postProductGroupHandler);
export const putProductGroup = createAsyncAction(actions.PUT_PRODUCT_GROUP, handlers.putProductGroupHandler);
export const deleteProductGroup = createAsyncAction(actions.DELETE_PRODUCT_GROUP, handlers.deleteProductGroupHandler);

export const getProductDepartments = createAsyncAction(actions.GET_PRODUCT_DEPARTMENTS, handlers.getProductDepartmentsHandler, []);
export const postProductDepartment = createAsyncAction(actions.POST_PRODUCT_DEPARTMENT, handlers.postProductDepartmentHandler);
export const putProductDepartment = createAsyncAction(actions.PUT_PRODUCT_DEPARTMENT, handlers.putProductDepartmentHandler);
export const deleteProductDepartment = createAsyncAction(actions.DELETE_PRODUCT_DEPARTMENT, handlers.deleteProductDepartmentHandler);

export const getBrands = createAsyncAction(actions.GET_BRANDS, handlers.getBrandsHandler);
export const postBrand = createAsyncAction(actions.POST_BRANDS, handlers.postBrandHandler);
export const putBrand = createAsyncAction(actions.PUT_BRANDS, handlers.putBrandHandler);
export const deleteBrand = createAsyncAction(actions.DELETE_BRANDS, handlers.deleteBrandHandler);

export const getClientsList = createAsyncAction(actions.GET_CLIENTS_LIST, handlers.getClientsListHandler);
export const postClient = createAsyncAction(actions.POST_CLIENT, handlers.postClientHandler);
export const putClient = createAsyncAction(actions.PUT_CLIENT, handlers.putClientHandler);
export const getClient = createAsyncAction(actions.GET_CLIENT, handlers.getClientHandler);

export const getAdminsList = createAsyncAction(actions.GET_ADMINS_LIST, handlers.getAdminsListHandler);
export const postAdmin = createAsyncAction(actions.POST_ADMIN, handlers.postAdminHandler);
export const putAdmin = createAsyncAction(actions.PUT_ADMIN, handlers.putAdminHandler);
export const getAdmin = createAsyncAction(actions.GET_ADMIN, handlers.getAdminHandler);;

export const getGradesList = createAsyncAction(actions.GET_GRADES, handlers.getGradesHandler);
export const postGrade = createAsyncAction(actions.POST_GRADE, handlers.postGradeHandler);
export const putGrade = createAsyncAction(actions.PUT_GRADE, handlers.putGradeHandler);
export const putGradeRestore = createAsyncAction(actions.PUT_GRADE_RESTORE, handlers.putGradeRestoreHandler);
export const deleteGrade = createAsyncAction(actions.DELETE_GRADE, handlers.deleteGradeHandler);
export const getGrade = createAsyncAction(actions.GET_GRADE, handlers.getGradeHandler);

export const getMarketingCoordinatorsList = createAsyncAction(actions.GET_MARKETING_COORDINATORS_LIST, handlers.getMarketingCoordinatorsListHandler);
export const postMarketingCoordinator = createAsyncAction(actions.POST_MARKETING_COORDINATOR, handlers.postMarketingCoordinatorHandler);
export const putMarketingCoordinator = createAsyncAction(actions.PUT_MARKETING_COORDINATOR, handlers.putMarketingCoordinatorHandler);
export const getMarketingCoordinator = createAsyncAction(actions.GET_MARKETING_COORDINATOR, handlers.getMarketingCoordinatorHandler);

export const getSupervisorsList = createAsyncAction(actions.GET_SUPERVISORS_LIST, handlers.getSupervisorsListHandler);
export const postSupervisor = createAsyncAction(actions.POST_SUPERVISOR, handlers.postSupervisorHandler);
export const putSupervisor = createAsyncAction(actions.PUT_SUPERVISOR, handlers.putSupervisorHandler);
export const getSupervisor = createAsyncAction(actions.GET_SUPERVISOR, handlers.getSupervisorHandler);

export const getPromotersList = createAsyncAction(actions.GET_PROMOTERS_LIST, handlers.getPromotersListHandler);
export const postPromoter = createAsyncAction(actions.POST_PROMOTER, handlers.postPromoterHandler);
export const putPromoter = createAsyncAction(actions.PUT_PROMOTER, handlers.putPromoterHandler);
export const getPromoter = createAsyncAction(actions.GET_PROMOTER, handlers.getPromoterHandler);

export const getSalesList = createAsyncAction(actions.GET_SALES_LIST, handlers.getSalesListHandler);
export const postSale = createAsyncAction(actions.POST_SALE, handlers.postSaleHandler);
export const putSale = createAsyncAction(actions.PUT_SALE, handlers.putSaleHandler);
export const deleteSale = createAsyncAction(actions.DELETE_SALE, handlers.deleteSaleHandler);
export const postSales = createAsyncAction(actions.POST_SALES, handlers.postSalesHandler);
export const deleteSales = createAsyncAction(actions.DELETE_SALES, handlers.deleteSalesHandler);

export const postCheckin = createAsyncAction(actions.POST_CHECKIN, handlers.postCheckinHandler);
export const getCheckins = createAsyncAction(actions.GET_CHECKINS, handlers.getCheckinsHandler);
export const getCurrentCheckin = createAsyncAction(actions.GET_CURRENT_CHECKIN, handlers.getCurrentCheckinHandler);
export const putCheckin = createAsyncAction(actions.PUT_CHECKIN, handlers.putCheckinHandler);

export const getTasks = createAsyncAction(actions.GET_TASKS, handlers.getTasksHandler);
export const postTask = createAsyncAction(actions.POST_TASK, handlers.postTaskHandler);
export const getTask = createAsyncAction(actions.GET_TASK, handlers.getTaskHandler);
export const putTask = createAsyncAction(actions.PUT_TASK, handlers.putTaskHandler);

export const getTaskReports = createAsyncAction(actions.GET_TASK_REPORTS, handlers.getTaskReportsHandler, {}, takeEvery);
export const getTaskReport = createAsyncAction(actions.GET_TASK_REPORT, handlers.getTaskReportHandler);
export const postTaskReport = createAsyncAction(actions.POST_TASK_REPORT, handlers.postTaskReportHandler);

export const getPhotoReports = createAsyncAction(actions.GET_PHOTO_REPORTS, handlers.getPhotoReportsHandler, {}, takeEvery);
export const getPhotoReport = createAsyncAction(actions.GET_PHOTO_REPORT, handlers.getPhotoReportHandler);
export const postPhotoReport = createAsyncAction(actions.POST_PHOTO_REPORT, handlers.postPhotoReportHandler);
export const putPhotoReport = createAsyncAction(actions.PUT_PHOTO_REPORT, handlers.putPhotoReportHandler);

export const getSalePlans = createAsyncAction(actions.GET_SALE_PLANS, handlers.getSalePlansHandler);
export const postSalePlans = createAsyncAction(actions.POST_SALE_PLANS, handlers.postSalePlansHandler);
export const putSalePlans = createAsyncAction(actions.PUT_SALE_PLANS, handlers.putSalePlansHandler);
export const putSalePlansWithoutRefresh = createAsyncAction(actions.PUT_SALE_PLANS_WITHOUT_REFRESH, handlers.putSalePlansHandler);
export const deleteSalePlans = createAsyncAction(actions.DELETE_SALE_PLANS, handlers.deleteSalePlansHandler);

export const getTimetable = createAsyncAction(actions.GET_TIMETABLE, handlers.getTimetableHandler);
export const getHolydaysCalendar = createAsyncAction(actions.GET_HOLYDAYS_CALENDAR, handlers.getHolydaysCalendarHandler);
export const getTimetableMonths = createAsyncAction(actions.GET_TIMETABLE_MONTHS, handlers.getTimetableMonthsHandler, {}, takeEvery);
export const putTimetable = createAsyncAction(actions.PUT_TIMETABLE, handlers.putTimetableHandler, {}, takeEvery);
export const getTimetableSyncStatus = createAsyncAction(actions.GET_TIMETABLE_SYNC_STATUS, handlers.getTimetableSyncStatusHandler);
export const putTimetableSync = createAsyncAction(actions.PUT_TIMETABLE_SYNC, handlers.putTimetableSyncHandler);

export const getNewsList = createAsyncAction(actions.GET_NEWS_LIST, handlers.getNewsListHandler);
export const getNews = createAsyncAction(actions.GET_NEWS, handlers.getNewsHandler);
export const postNews = createAsyncAction(actions.POST_NEWS, handlers.postNewsHandler);
export const putNews = createAsyncAction(actions.PUT_NEWS, handlers.putNewsHandler);
export const deleteNews = createAsyncAction(actions.DELETE_NEWS, handlers.deleteNewsHandler);

export const getDocuments = createAsyncAction(actions.GET_DOCUMENTS, handlers.getDocumentsHandler);
export const postDocument = createAsyncAction(actions.POST_DOCUMENT, handlers.postDocumentHandler);
export const deleteDocument = createAsyncAction(actions.DELETE_DOCUMENT, handlers.deleteDocumentHandler);

export const postInstruction = createAsyncAction(actions.POST_INSTRUCTION, handlers.postInstructionHandler);
export const getInstructions = createAsyncAction(actions.GET_INSTRUCTIONS, handlers.getInstructionsHandler);
export const putInstruction = createAsyncAction(actions.PUT_INSTRUCTION, handlers.putInstructionHandler);
export const postInstructions = createAsyncAction(actions.POST_INSTRUCTIONS, handlers.postInstructionsHandler);
export const deleteInstruction = createAsyncAction(actions.DELETE_INSTRUCTION, handlers.deleteInstructionHandler);

export const deleteFile = createAsyncAction(actions.DELETE_FILE, handlers.deleteFileHandler);
export const postFile = createAsyncAction(actions.POST_FILE, handlers.postFileHandler);

export const importSalePlans = createAsyncAction(actions.IMPORT_SALE_PLANS, handlers.importSalePlansHandler);
export const importProducts = createAsyncAction(actions.IMPORT_PRODUCTS, handlers.importProductsHandler);
export const importShops = createAsyncAction(actions.IMPORT_SHOPS, handlers.importShopsHandler);
export const importCities = createAsyncAction(actions.IMPORT_CITIES, handlers.importCitiesHandler);
export const importClients = createAsyncAction(actions.IMPORT_CLIENTS, handlers.importClientsHandler);
export const importMarketingCoordinators = createAsyncAction(actions.IMPORT_MARKETING_COORDINATORS, handlers.importMarketingCoordinatorsHandler);
export const importSupervisors = createAsyncAction(actions.IMPORT_SUPERVISORS, handlers.importSupervisorsHandler);
export const importPromoters = createAsyncAction(actions.IMPORT_PROMOTERS, handlers.importPromotersHandler);
export const importSales = createAsyncAction(actions.IMPORT_SALES, handlers.importSalesHandler);
export const importPlans = createAsyncAction(actions.IMPORT_PLANS, handlers.importPlansHandler);
export const importTimetable = createAsyncAction(actions.IMPORT_TIMETABLE, handlers.importTimetableHandler);
export const importGrades = createAsyncAction(actions.IMPORT_GRADES, handlers.importGradesHandler);

export const postUserPhoto = createAsyncAction(actions.POST_USER_PHOTO, handlers.postUserPhotoHandler);
export const postClientPhoto = createAsyncAction(actions.POST_CLIENT_PHOTO, handlers.postClientPhotoHandler);
export const postMarketingCoordinatorPhoto = createAsyncAction(actions.POST_MARKETING_COORDINATOR_PHOTO, handlers.postMarketingCoordinatorPhotoHandler);
export const postSupervisorPhoto = createAsyncAction(actions.POST_SUPERVISOR_PHOTO, handlers.postSupervisorPhotoHandler);
export const postPromoterPhoto = createAsyncAction(actions.POST_PROMOTER_PHOTO, handlers.postPromoterPhotoHandler);
export const postAdminPhoto = createAsyncAction(actions.POST_ADMIN_PHOTO, handlers.postAdminPhotoHandler);

export const deleteUserPhoto = createAsyncAction(actions.DELETE_USER_PHOTO, handlers.deleteUserPhotoHandler);
export const deleteClientPhoto = createAsyncAction(actions.DELETE_CLIENT_PHOTO, handlers.deleteClientPhotoHandler);
export const deleteMarketingCoordinatorPhoto = createAsyncAction(actions.DELETE_MARKETING_COORDINATOR_PHOTO, handlers.deleteMarketingCoordinatorPhotoHandler);
export const deleteSupervisorPhoto = createAsyncAction(actions.DELETE_SUPERVISOR_PHOTO, handlers.deleteSupervisorPhotoHandler);
export const deletePromoterPhoto = createAsyncAction(actions.DELETE_PROMOTER_PHOTO, handlers.deletePromoterPhotoHandler);
export const deleteAdminPhoto = createAsyncAction(actions.DELETE_ADMIN_PHOTO, handlers.deleteAdminPhotoHandler);

export const getSalePlanDepartments = createAsyncAction(actions.GET_SALE_PLAN_DEPARTMENTS, handlers.getSalePlanDepartmentsHandler, {}, takeEvery);
export const putSalePlanDepartments = createAsyncAction(actions.PUT_SALE_PLAN_DEPARTMENTS, handlers.putSalePlanDepartmentsHandler);
export const deleteSalePlanDepartments = createAsyncAction(actions.DELETE_SALE_PLAN_DEPARTMENTS, handlers.deleteSalePlanDepartmentsHandler);

export const getSalePlansStat = createAsyncAction(actions.GET_SALE_PLANS_STAT, handlers.getSalePlansStatHandler, {}, takeEvery);
export const getSalePlansPromoterStatus = createAsyncAction(actions.GET_SALE_PLANS_PROMOTER_STATUS, handlers.getSalePlansPromoterStatusHandler, {}, takeEvery);

export const getSalesSettings = createAsyncAction(actions.GET_SALES_SETTINGS, handlers.getSalesSettingsHandler);
export const postSalesSettings = createAsyncAction(actions.POST_SALES_SETTINGS, handlers.postSalesSettingsHandler);
export const putSalesSettings = createAsyncAction(actions.PUT_SALES_SETTINGS, handlers.putSalesSettingsHandler);
export const deleteSalesSettings = createAsyncAction(actions.DELETE_SALES_SETTINGS, handlers.deleteSalesSettingsHandler);

export const putProductsRestore = createAsyncAction(actions.PUT_PRODUCTS_RESTORE, handlers.putProductsRestoreHandler);
