import { put, takeEvery } from 'redux-saga/effects';
import { toSuccess } from 'react-async-client';

const createPutSaga = (PUT_ACTION, getActionName) => {
    return function* (getProps) {
        const getAction = getProps()[getActionName];

        yield takeEvery(toSuccess(PUT_ACTION), function* putSaga(action) {
            yield put(getAction.success(action.payload));
        });
    }
};

export default createPutSaga;
