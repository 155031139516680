import React from 'react';
import { Button } from 'antd';

const Fab = ({ icon, onClick }) => (
    <Button
        className='fab-btn'
        type='primary'
        shape='circle'
        icon={icon}
        size='large'
        onClick={onClick} />
);

export default Fab;
