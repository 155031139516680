import React, { Component } from 'react';
import { connect } from 'react-redux';

import UserInfo from 'components/common/cards/user/UserInfo';
import { getSupervisor, postSupervisorPhoto, deleteSupervisorPhoto } from 'actions/asyncActions';
import { supervisorMenuItems } from 'menus';
import Menu from 'components/common/Menu';
import withRole from 'components/common/withRole';

const stateToProps = state => ({
    supervisor: getSupervisor.selectData(state)
});

@withRole
@connect(stateToProps)
export default class SupervisorInfoCard extends Component {
    render() {
        const { supervisor, supervisor: { enabled }, match: { params }, action, isAdmin } = this.props;

        return (
            <div>
                { enabled && <Menu items={supervisorMenuItems(params.id)} />}
                <UserInfo
                    user={supervisor}
                    postPhotoAction={postSupervisorPhoto}
                    deletePhotoAction={deleteSupervisorPhoto}
                    refresh={() => action.refresh()}
                    disabledPhotoEdit={!isAdmin} />
            </div>
        );
    }
}
