import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import ClientFilter, { defaultClientFilter } from 'filters/ClientFilter';
import { usersMenuItems } from 'menus';
import { getClientsList } from 'actions/asyncActions';
import { openClientModal } from 'actions/modalActions';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import PersonLink from 'components/common/cards/person/PersonLink';
import { POST_CLIENT, PUT_CLIENT, IMPORT_CLIENTS } from 'constants/actionTypes';

@withRole
@connect(null, { openClientModal })
export default class Clients extends Component {
    columns = [
        {
            key: 'icon',
            width: 40,
            render: item => <PersonPhoto person={item} />
        }, {
            title: 'ФИО',
            key: 'fullName',
            render: item => <PersonLink type='client' person={item} />
        }, {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }
    ];

    getMenuControls() {
        const { openClientModal } = this.props;

        return (
            <div>
                <Button type='primary' icon={<UserAddOutlined />} onClick={() => openClientModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Menu
                    items={usersMenuItems(this.props.accessToFinances)}
                    controls={this.getMenuControls()} />
                <ListTable
                    filterForm={ClientFilter}
                    initialFilters={defaultClientFilter}
                    action={getClientsList}
                    refreshActions={[POST_CLIENT, PUT_CLIENT, IMPORT_CLIENTS]}
                    tableColumns={this.columns} />
            </div>
        );
    }
}
