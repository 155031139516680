import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getNetworks } from 'actions/asyncActions';

const NetworkFilter = ({ param, multiple }) => (
    <Field
        name={multiple ? 'networks' : 'network'}
        component={SearchSelect}
        placeholder='Сеть'
        multiple={multiple}
        allowClear={true}
        action={getNetworks}
        param={param} />
)

export default NetworkFilter;
