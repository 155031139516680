import React, { Component } from 'react';
import moment from 'moment';
import { Button, Radio, message } from 'antd';
import { assocPath, compose, flatten, find, findIndex, path, pathOr, propEq, range, equals } from 'ramda';
import { asyncConnect, toSuccess } from 'react-async-client';
import cx from 'classnames';
import { withState } from 'recompose';
import { takeEvery } from '@redux-saga/core/effects';
import { EditOutlined, EyeOutlined, FileExcelOutlined } from '@ant-design/icons';

import ListTable from 'components/common/ListTable';
import PersonLink from 'components/common/cards/person/PersonLink';
import PersonName from 'components/common/cards/person/PersonName';
import withRole from 'components/common/withRole';
import ExcelDownload from 'components/common/ExcelDownload';
import TimetableStatusMenu from 'components/common/form/table/TimetableStatusMenu';
import EditInput from 'components/common/form/table/EditInput';
import FinanceFilter, { defaultFilter } from 'filters/FinanceFilter';
import { getSalePlans, getTimetableMonths, getUser, importTimetable, putTimetable, putTimetableSync, getHolydaysCalendar } from 'actions/asyncActions';
import { formAutoOptions, fullAutoOptions } from 'constants/asyncClient';
import { openTimetablePersonShopsModal, openImportModal, openPlanCommentModal } from 'actions/modalActions';
import { GET_TIMETABLE_MONTHS, PUT_TIMETABLE_SYNC } from 'constants/actionTypes';
import TIMETABLE_STATUSES from 'constants/timetableStatuses';
import { TIMETABLE, TIMETABLE_MONTHS } from 'constants/urls';
import withReportDate from '../withReportDate';

const shouldCellUpdate = (nextRecord, record) => !equals(record, nextRecord);

export const dayOfTheWeek = (day, month, year) => {
    return new Date(year, month, day).toLocaleString('ru-RU', {
        weekday: 'short'
    });
};

const isWeekend = (date, month, year, holydaysCalendar) => {
    const day = `${year}-${('0' + (month + 1)).slice(-2)}-${('0' + date).slice(-2)}`;
    return findIndex(holyday => holyday === day, holydaysCalendar) + 1;
};

export const renderComplete = (departmentPlans, item, factNumber) => {
    const planNumber = pathOr(null, ['amount'], find(propEq('id', item.id), departmentPlans || []));

    return planNumber ? `${(factNumber * 100 / planNumber).toFixed(2)} %` : null;
};

const CalendarDiffCell = ({ fact, calendar }) => (
    <div>
        { calendar }
        { calendar && (fact !== calendar) && (
            <div className={`td-${fact > calendar ? 'over' : 'diff'}`}>
                { fact > calendar ? '+' : '' }
                { fact - calendar }
            </div>
        )}
    </div>
);

const stateToProps = state => ({
    departmentPlans: pathOr([], ['items', 0, 'departmentPlans'], getSalePlans.withParams('listTable').selectData(state)),
    shops: pathOr([], ['_embedded', 'shops'], getUser.selectData(state)),
    user: getUser.selectData(state),
});

@withRole
@withState('timetable', 'setTimetable', [])
@withReportDate
@asyncConnect({
    getHolydaysCalendar: getHolydaysCalendar
        .withPayload(props => ({
            minDate: props.reportDate,
            maxDate: props.reportDate,
        }))
        .withOptions(fullAutoOptions),
    putTimetableSync,
    putTimetable: putTimetable
        .withErrorHandler(() => message.error('Ошибка сохранения данных'))
        .withSuccessHandler((props, action) => {
            const oldItem = action.requestAction.payload.old;
            const item = action.payload;
            const employeeIndex = findIndex(propEq('employee', item.employee), props.timetable);
            const statisticPlanWorkingDays = (
                path([employeeIndex, '_embedded', 'statistic', 'planWorkingDays'], props.timetable)
                - oldItem.planWorkingDays
                + item.planWorkingDays
            );
            const statisticPlanWorkingHours = (
                path([employeeIndex, '_embedded', 'statistic', 'planWorkingHours'], props.timetable)
                - oldItem.planWorkingHours
                + item.planWorkingHours
            );

            props.setTimetable(compose(
                assocPath([employeeIndex, '_embedded', 'statistic', 'planWorkingDays'], statisticPlanWorkingDays),
                assocPath([employeeIndex, '_embedded', 'statistic', 'planWorkingHours'], statisticPlanWorkingHours),
            )(props.timetable));
        })
        .withOptions(formAutoOptions),
    putTimetableFact: putTimetable
        .withParams('fact')
        .withErrorHandler(() => message.error('Ошибка сохранения данных'))
        .withSuccessHandler((props, action) => {
            const oldItem = action.requestAction.payload.old;
            const item = action.payload;
            const employeeIndex = findIndex(propEq('employee', item.employee), props.timetable);
            const monthIndex = action.requestAction.payload.monthIndex;
            const statisticCountWorkingDays = (
                path([employeeIndex, '_embedded', 'statistic', 'countWorkingDays'], props.timetable)
                - oldItem.statistic.countWorkingDays
                + item.statistic.countWorkingDays
            );
            const statisticCountWorkingHours = (
                path([employeeIndex, '_embedded', 'statistic', 'countWorkingHours'], props.timetable)
                - oldItem.statistic.countWorkingHours
                + item.statistic.countWorkingHours
            );

            props.setTimetable(compose(
                assocPath([employeeIndex, 'reports', monthIndex, 'statistic'], item.statistic),
                assocPath([employeeIndex, '_embedded', 'statistic', 'countWorkingDays'], statisticCountWorkingDays),
                assocPath([employeeIndex, '_embedded', 'statistic', 'countWorkingHours'], statisticCountWorkingHours),
            )(props.timetable));
        })
        .withOptions(formAutoOptions),
}, stateToProps, { openTimetablePersonShopsModal, openImportModal, openPlanCommentModal })
export default class TimetableMonth extends Component {
    state = {
        showTimetable: false,
        mode: 'preview'
    };

    componentDidUpdate() {
        if (!this.state.showTimetable && this.props.timetable.length) {
            this.setState({ showTimetable: {} });
        }
    }

    toggleTimetable = (date) => {
        this.setState(prev => ({ showTimetable: {
            ...prev.showTimetable,
            [date]: !prev.showTimetable[date],
        }}));
    }

    changeTimetable = (monthIndex, item, day, status, hours) => {
        const employeeIndex = findIndex(propEq('employee', item.employee), this.props.timetable);
        const monthReport = path(['reports', monthIndex], item) || {};
        const value = {
            ...monthReport,
            old: monthReport,
            dayReports: assocPath([day], status === 'clean' ? {} : { status, hours }, monthReport.dayReports || []),
            monthIndex,
        };

        this.props.setTimetable(assocPath([employeeIndex, 'reports', monthIndex], value, this.props.timetable));
        this.props.putTimetableFact.dispatch(value);
    }

    changeComment = (monthIndex, item, day, text) => {
        const employeeIndex = findIndex(propEq('employee', item.employee), this.props.timetable);
        const monthReport = path(['reports', monthIndex], item) || {};

        this.props.openPlanCommentModal({
            value: text,
            onSubmit: comment => {
                const value = {
                    ...monthReport,
                    old: monthReport,
                    dayReports: assocPath([day], { ...pathOr({}, [day], monthReport.dayReports || []), comment }, monthReport.dayReports || [])
                };

                this.props.setTimetable(assocPath([employeeIndex, 'reports', monthIndex], value, this.props.timetable));
                this.props.putTimetable.dispatch(value);
            }
        });
    }

    editField = (item, field, fieldValue, monthIndex) => {
        const employeeIndex = findIndex(propEq('employee', item.employee), this.props.timetable);
        const value = {
            ...item,
            old: item,
            [field]: fieldValue,
            monthIndex,
        };

        this.props.setTimetable(assocPath([employeeIndex, 'reports', monthIndex], value, this.props.timetable));
        this.props.putTimetable.dispatch(value);
    }

    getColumns = isClient => {
        const calendar = this.props.getHolydaysCalendar.data || {};
        const holydaysCalendar = calendar.holidays || [];

        const start = moment(this.props.reportDate).utc().startOf('month');
        const end = moment(this.props.reportDate).utc().endOf('month');
        const dates = [];
        for (var date = moment(start).utc(); date.isSameOrBefore(end, 'month'); date.add(1, 'month')) {
            dates.push(date.toDate());
        }

        const startRange = start.format('MMMM');
        const endRange = end.format('MMMM');
        const tableRange = startRange === endRange ? startRange : `${startRange}-${endRange}`;

        const isEdit = this.state.mode === 'edit';
        const isLoading = this.props.putTimetable.meta.pending;
        const isFactLoading = this.props.putTimetableFact.meta.pending;

        const getDaysHoursColumns = index => [
            {
                title: 'Кол-во дней',
                key: `days-${index}`,
                children: [
                    {
                        title: 'План',
                        key: `days-plan-${index}`,
                        dataIndex: ['reports', index],
                        width: isEdit ? 100 : 60,
                        align: 'center',
                        shouldCellUpdate,
                        render: item => {
                            if (item && item.employee) {
                                return isEdit ?
                                    <EditInput
                                        number
                                        value={item.planWorkingDays}
                                        onChange={(...args) => this.editField(...args, index)}
                                        item={item}
                                        field='planWorkingDays' /> :
                                    item.planWorkingDays;
                            }

                            return null;
                        }
                    },
                    {
                        title: 'Факт',
                        key: `days-fact-${index}`,
                        dataIndex: ['reports', index, 'statistic', 'countWorkingDays'],
                        shouldCellUpdate,
                        width: 60,
                        align: 'center',
                        className: `th-timetable border-right ${isFactLoading && 'cell-loading'}`,
                    }
                ]
            },
            {
                title: 'Кол-во часов',
                key: `hours-${index}`,
                children: [
                    {
                        title: 'План',
                        key: `hours-plan-${index}`,
                        dataIndex: ['reports', index],
                        width: isEdit ? 100 : 60,
                        align: 'center',
                        shouldCellUpdate,
                        render: item => {
                            if (item && item.employee) {
                                return isEdit ?
                                    <EditInput
                                        number
                                        value={item.planWorkingHours}
                                        onChange={(...args) => this.editField(...args, index)}
                                        item={item}
                                        field='planWorkingHours' /> :
                                    item.planWorkingHours;
                            }

                            return null;
                        }
                    },
                    {
                        title: 'Факт',
                        key: `hours-fact-${index}`,
                        className: isFactLoading && 'cell-loading',
                        dataIndex: ['reports', index, 'statistic', 'countWorkingHours'],
                        shouldCellUpdate,
                        width: 60,
                        align: 'center',
                    }
                ]
            }
        ];

        const renderMonthColumns = (date, index) => {
            const year = date.getFullYear();
            const month = date.getMonth();
            const monthDays = new Date(year, month + 1, 0).getDate();

            const monthTimetable = [
                {
                    title: <div className='timetable-columns'>
                        <div className='btn-timetable hide-timetable' onClick={() => this.toggleTimetable(date)}>
                            скрыть план за {moment(date).format('MMMM')}
                        </div>
                    </div>,
                    key: `timetable-${index}`,
                    shouldCellUpdate,
                    children: [
                        ...getDaysHoursColumns(index),
                        ...(range(1, monthDays + 1).map(day => ({
                            title: <div className='timetable-columns' style={{ textAlign: 'center', fontWeight: 600 }}>
                                <div className='day-of-num'>{day}</div>
                                <div className='day-of-week'>{dayOfTheWeek(day, month, year)}</div>
                            </div>,
                            width: 35,
                            key: `day-${day}-${index}`,
                            dataIndex: ['reports', index, `dayReports`, day - 1],
                            className: isWeekend(day, month, year, holydaysCalendar) ? 'weekend-column timetable-day-column' : 'timetable-day-column',
                            shouldCellUpdate,
                            render: (item, props) => {
                                if (!path(['reports', index, 'id'], props)) {
                                    return null;
                                }
                                const data = find(propEq('status', path(['status'], item)), TIMETABLE_STATUSES) || {};

                                const cell = (
                                    <div className={cx('timetable-day-cell', { 'filled': !!(item.hours || data.short), 'has-comment': !!item.comment })} style={{ backgroundColor: data.hexColor, color: data.fontColor }}>
                                        {item.hours || data.short}
                                    </div>
                                );

                                return !isClient ? (
                                    <TimetableStatusMenu
                                        data={data}
                                        item={item}
                                        row={props}
                                        cell={data}
                                        day={day}
                                        dayReports={path(['reports', index, 'dayReports'], props)}
                                        changeComment={(...args) => this.changeComment(index, ...args)}
                                        changeTimetable={(...args) => this.changeTimetable(index, ...args)}
                                    >
                                        {cell}
                                    </TimetableStatusMenu>
                                ) : cell;
                            }
                        }))),
                    ]
                }
            ];

            const monthHours = [
                {
                    title: <div className='timetable-columns'>
                        <div className='btn-timetable show-timetable' onClick={() => this.toggleTimetable(date)}>
                            показать план за {moment(date).format('MMMM')}
                        </div>
                    </div>,
                    key: `time-${index}`,
                    children: [
                        ...getDaysHoursColumns(index),
                    ]
                },
            ];

            return !this.state.showTimetable ? [...monthTimetable, ...monthHours] : [
                ...(this.state.showTimetable[date] ? monthTimetable : monthHours ),
            ];
        };

        const columns = [
            {
                title: 'ФИО',
                dataIndex: ['_embedded', 'employee'],
                key: 'employee',
                fixed: 'left',
                width: 310,
                shouldCellUpdate,
                render: (employee, item) => {
                    const grade = path(['_embedded', 'employee', '_embedded', 'grade', 0, 'name'], item);

                    return <div>
                        <PersonLink person={employee} /><br />
                        {path(['_embedded', 'employee', '_embedded', 'city', 'name'], item)}{grade ? `, ${grade}` : ''}
                    </div>;
                }
            },
            {
                title: 'SV',
                dataIndex: ['_embedded', 'supervisors'],
                key: 'supervisor',
                width: 180,
                shouldCellUpdate,
                render: supervisors => supervisors && supervisors.length ? supervisors.map(supervisor => (
                    <PersonName key={supervisor.id} person={supervisor} short />
                )) : ''
            },
            {
                title: 'Магазин',
                dataIndex: ['_embedded', 'shops'],
                key: 'shop',
                width: 300,
                className: 'td-shop',
                shouldCellUpdate,
                onCell: (record, rowIndex) => {
                    return {
                        onClick: () => {
                            this.props.openTimetablePersonShopsModal({
                                employee: path(['_embedded', 'employee'], record),
                                shops: path(['_embedded', 'shops'], record),
                                minDate: this.props.reportDate,
                                maxDate: this.props.reportDate,
                            })
                        },
                    };
                },
                render: shops => <div>
                    {pathOr('', [0, 'name'], shops)}<br />
                    {pathOr('', [1, 'name'], shops)}
                    {path(['length'], shops) > 1 ? (
                        <div className='td-shop-additional'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.25 14.25L13.75 14.25C14.0266 14.25 14.25 14.0266 14.25 13.75L14.25 2.25C14.25 1.97344 14.0266 1.75 13.75 1.75H8.125C8.05625 1.75 8 1.80625 8 1.875V2.75C8 2.81875 8.05625 2.875 8.125 2.875H13.125L13.125 13.125L2.875 13.125V8.125C2.875 8.05625 2.81875 8 2.75 8H1.875C1.80625 8 1.75 8.05625 1.75 8.125V13.75C1.75 14.0266 1.97344 14.25 2.25 14.25ZM5.79219 6.63437L4.97656 7.45C4.96004 7.4666 4.94851 7.48751 4.94328 7.51035C4.93805 7.53318 4.93934 7.55702 4.94699 7.57916C4.95464 7.6013 4.96835 7.62085 4.98656 7.63558C5.00477 7.65031 5.02675 7.65964 5.05 7.6625L7.85312 7.99063C7.93281 8 8.00156 7.93281 7.99219 7.85156L7.66406 5.04844C7.65156 4.94531 7.525 4.90156 7.45156 4.975L6.63281 5.79375L2.62969 1.79062C2.58125 1.74219 2.50156 1.74219 2.45313 1.79062L1.79062 2.45312C1.74219 2.50156 1.74219 2.58125 1.79062 2.62969L5.79219 6.63437Z" fill="#B4B4B4"/>
                            </svg>
                        </div>
                    ) : ''}
                </div>
            },
            ...flatten(dates.map((date, index) => renderMonthColumns(date, index))),
            {
                title: `Суммированный учет за ${tableRange}`,
                className: 'border-left',
                key: 'sum',
                children: [
                    {
                        title: 'Произв. календарь',
                        dataIndex: 'amount',
                        key: 'calendar',
                        className: 'border-left',
                        children: [
                            {
                                title: 'раб. дней',
                                dataIndex: ['statistic', 'countWorkingDays'],
                                key: 'calendar-days',
                                align: 'center',
                                className: `${isLoading && 'cell-loading'}`,
                                width: 80,
                                shouldCellUpdate,
                                render: days => <CalendarDiffCell fact={days} calendar={calendar.countWorkingDays} />,
                            },
                            {
                                title: 'раб. часов',
                                dataIndex: ['statistic', 'countWorkingHours'],
                                key: 'calendar-hours',
                                align: 'center',
                                className: `${isLoading && 'cell-loading'}`,
                                width: 80,
                                shouldCellUpdate,
                                render: hours => <CalendarDiffCell fact={hours} calendar={calendar.countWorkingHours} />,
                            },
                        ]
                    },
                ]
            }
        ];

        return columns;
    }

    toggleMode = e => this.setState({ mode: e.target.value });

    renderTimetableTemplateBtn = (_, date) => {
        return <div style={{ textAlign: 'center', marginTop: 15 }}>
            { date ?
                <ExcelDownload block query={{ ...defaultFilter, date }} params={{ template: 1 }} url={TIMETABLE} title='Скачать шаблон' /> :
                'Выберите месяц для получения шаблона'
            }
        </div>;
    }

    getDataSource = () => {
        return this.props.timetable;
    }

    render() {
        const { isClient } = this.props;

        const filterControls = ({ values }) => !isClient && (
            <div className='pull-right mobile-filter-button' style={{ display: 'flex' }}>
                <ExcelDownload
                    className='hide'
                    query={values}
                    url={TIMETABLE_MONTHS}
                />
                <Button
                    className='hide'
                    icon={<FileExcelOutlined />}
                    onClick={() => this.props.openImportModal({
                        title: 'Импортировать табель',
                        action: importTimetable,
                        month: this.props.date,
                        renderAdditional: this.renderTimetableTemplateBtn
                    })}>
                    Импорт
                </Button>
                <Radio.Group
                    value={this.state.mode}
                    onChange={this.toggleMode}
                    options={[
                        { label: <EyeOutlined />, value: 'preview' },
                        { label: <EditOutlined />, value: 'edit' }
                    ]}
                    optionType='button' />
            </div>
        );

        const saga = function* (getProps) {
            yield takeEvery([GET_TIMETABLE_MONTHS], () => {
                getProps().setDate(
                    moment(getProps().state.q.minDate).toDate(),
                    moment(getProps().state.q.minDate).utc().endOf('month').toDate()
                );
            });

            yield takeEvery([toSuccess(GET_TIMETABLE_MONTHS)], () => {
                getProps().setTimetable(getProps().action.data.items || []);
            });
        };

        const dataSource = this.getDataSource();

        return (
            <div className='time-report-table'>
                <ListTable
                    tableColumns={this.props.timetable.length ? this.getColumns(isClient) : []}
                    action={getTimetableMonths}
                    loading={this.props.putTimetableSync.meta.pending}
                    filterForm={FinanceFilter}
                    initialFilters={{
                        ...defaultFilter,
                        minDate: this.props.reportDate,
                        maxDate: this.props.reportDate,
                    }}
                    scroll={{ x: 'max-content', y: 'calc(100vh - 330px)' }}
                    className='th-multi plan-table'
                    filterControls={filterControls}
                    setDate={this.props.setReportDate}
                    dataSource={dataSource}
                    refreshActions={[PUT_TIMETABLE_SYNC]}
                    saga={saga}
                    setTimetable={this.props.setTimetable}
                    rowKey='id'
                    lined
                    bordered
                />
            </div>
        );
    }
}
