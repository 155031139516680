import React, { Component } from 'react';

import PlanDateContext from '../../contexts/PlanDateContext';

const withPlanDate = WrappedComponent =>
    class extends Component {
        render() {
            return <PlanDateContext.Consumer>
                { ({ setPlanDate, planDate }) => <WrappedComponent {...this.props} setPlanDate={setPlanDate} planDate={planDate} /> }
            </PlanDateContext.Consumer>;
        }
    }

export default withPlanDate;
