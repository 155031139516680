import React from 'react';
import { Field } from 'formik';

import Select from 'components/common/form/Select';
import { USER_TYPES } from 'constants/roles';

const UserTypeFilter = () => (
    <Field
        name='type'
        component={Select}
        placeholder='Роль'
        allowClear={true}
        options={USER_TYPES}
        path='value' />
);

export default UserTypeFilter;
