import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import moment from 'moment';

import Menu from 'components/common/Menu';
import { reportMenuItems } from 'menus';
import { getUser } from 'actions/asyncActions';
import { openImportModal } from 'actions/modalActions';
import Timetable from './lists/Timetable';
import Finance from './lists/Finance';
import ReportDateContext from '../../contexts/ReportDateContext';
import withRole from '../../common/withRole';

const stateToProps = state => ({
    user: getUser.selectData(state)
});

@connect(stateToProps, { openImportModal })
@withRole
export default class Plans extends Component {
    state = {
        minDate: moment().add(0, 'months').utc().startOf('month').toDate(),
        maxDate: moment().add(0, 'months').utc().endOf('month').toDate(),
    };

    setReportDate = (minDate, maxDate) => this.setState({ minDate, maxDate });

    render() {
        const { match: { url }, accessToFinances } = this.props;
        const redirect = `${url}/table`;

        return (
            <ReportDateContext.Provider value={{ reportDate: this.state.minDate, reportDateMax: this.state.maxDate, setReportDate: this.setReportDate }}>
                <div>
                    <Menu items={reportMenuItems(url, accessToFinances)} />
                    <Switch>
                        <Route path={`${url}/table`} component={Timetable} />
                        { accessToFinances && <Route path={`${url}/finance`} component={Finance} /> }

                        <Route component={() => <Redirect to={redirect} />} />
                    </Switch>
                </div>
            </ReportDateContext.Provider>
        );
    }
}
