import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import RegionFilter from './fields/RegionFilter';
import CityFilter from './fields/CityFilter';
import NetworkFilter from './fields/NetworkFilter';

const BindShopFilter = param => () => {
    return (
        <BaseSearchFilter>
            <Col sm={4} xs={12}>
                <RegionFilter param={param} />
            </Col>
            <Col sm={4} xs={12}>
                <CityFilter param={param} />
            </Col>
            <Col sm={4} xs={12}>
                <NetworkFilter param={param} />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(BindShopFilter());
export const BindShopFilterWithParam = param => withFilterForm(BindShopFilter(param));
