import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0), 50);
    }

    componentDidUpdate(prev) {
        if (prev.location.pathname !== this.props.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
