import React, { Component } from 'react';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';
import * as yup from 'yup';
import { Button, message } from 'antd';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import Input from 'components/common/form/Input';
import NumberInput from 'components/common/form/NumberInput';
import SearchSelect from 'components/common/form/SearchSelect';
import CascadeSelect from 'components/common/form/CascadeSelect';
import RadioGroup from 'components/common/form/RadioGroup';
import {
    postProduct,
    putProduct,
    deleteProduct,
    getProductCategories,
    getProductDepartments,
    getProductGroups,
    getBrands,
    formAutoOptions,
    putProductsRestore
} from 'actions/asyncActions';

const FOCUS_DICT = [
    { value: true, name: 'Да' },
    { value: false, name: 'Нет'}
];

const validationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    price: yup.number().positive().nullable(),
    ean: yup.string(),
    code: yup.string(),
    brand: yup.string().required(),
    productCategory: yup.string().required()
});

@withAsyncActions({
    postAction: postProduct,
    putAction: putProduct,
    deleteAction: deleteProduct,
    putProductsRestore: putProductsRestore
        .withSuccessHandler(({ close }) => {
            message.success('Продукт успешно перемещен из архива');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось переместить продукт из архива'))
        .withOptions({ resetOnUnmount: true })
}, formAutoOptions)
export default class ProductModal extends Component {
    static propTypes = modalFormPropTypes;

    getCascaderFields() {
        return [
            { name: 'productDepartment', action: getProductDepartments, placeholder: 'Выберите департамент', label: 'Департамент:' },
            { name: 'productGroup', action: getProductGroups, placeholder: 'Выберите группу', label: 'Группа:' },
            { name: 'productCategory', action: getProductCategories, placeholder: 'Выберите линейку', label: 'Линейка:' }
        ];
    }

    renderForm = formProps => {
        return (
            <div>
                <Field
                    name='name'
                    component={Input}
                    label='Название:'
                    placeholder='Введите название' />
                <Field
                    name='price'
                    component={NumberInput}
                    label='Цена:' />
                <Field
                    name='ean'
                    component={Input}
                    label='Ean:' />
                <Field
                    name='code'
                    component={Input}
                    label='Код:' />
                <Field
                    name='brand'
                    component={SearchSelect}
                    label='Бренд:'
                    action={getBrands} />
                <Field
                    name='productCategory'
                    component={CascadeSelect}
                    label='Департамент/Группа/Линейка:'
                    placeholder='Выберите линейку'
                    allowClear={true}
                    fields={this.getCascaderFields()} />
                { formProps.showFocus && (
                    <Field
                        name="focus"
                        path="value"
                        options={FOCUS_DICT}
                        className="mw250"
                        component={RadioGroup}
                        label="Фокус"
                    />
                )}
            </div>
        );
    }

    restore = () => {
        this.props.putProductsRestore.dispatch(this.props.params.id);
    }

    renderFooterControls = () => {
        return path(['status'], this.props.params) === 'archive' &&
            <Button onClick={this.restore}>
                Разархивировать
            </Button>;
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                createTitle={'Добавить продукт'}
                editTitle={'Редактировать продукт'}
                validationSchema={validationSchema}
                initialValues={this.props.params || {
                    focus: false,
                }}
                renderForm={this.renderForm}
                footerControls={this.renderFooterControls}
                hideSubmit={path(['status'], this.props.params) === 'archive'}
            />
        );
    }
}
