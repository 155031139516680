import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { getTimeZoneOffsetString } from '../../scenes/geography/Cities';

const Date = ({ date, timezoneOffset, format = 'DD.MM.YYYY' }) => {
    return date ? (
        timezoneOffset ? <Tooltip title={`Часовой пояс: ${getTimeZoneOffsetString(timezoneOffset)}`}>
            <span className='date-element'>
                {moment(date).utc().add(timezoneOffset, 'minutes').format(format)}
            </span>
        </Tooltip> : <span className='date-element'>
            {moment(date).format(format)}
        </span>
    ) : null;
}

export default Date;
