import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import { usersMenuItems } from 'menus';
import { getAdminsList } from 'actions/asyncActions';
import { openAdminModal } from 'actions/modalActions';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import PersonLink from 'components/common/cards/person/PersonLink';
import { UserAddOutlined } from '@ant-design/icons';
import { POST_ADMIN, PUT_ADMIN } from 'constants/actionTypes';
import AdminFilter, { defaultAdminFilter } from 'filters/AdminFilter';

@withRole
@connect(null, { openAdminModal })
export default class Admins extends Component {
    columns = [
        {
            key: 'icon',
            width: 40,
            render: item => <PersonPhoto person={item} />
        }, {
            title: 'ФИО',
            key: 'fullName',
            render: item => <PersonLink type='admin' person={item} />
        }, {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }
    ];

    getMenuControls() {
        const { openAdminModal } = this.props;

        return (
            <div>
                <Button type='primary' icon={<UserAddOutlined />} onClick={() => openAdminModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Menu
                    items={usersMenuItems(this.props.accessToFinances)}
                    controls={this.getMenuControls()} />
                <ListTable
                    filterForm={AdminFilter}
                    initialFilters={defaultAdminFilter}
                    action={getAdminsList}
                    refreshActions={[POST_ADMIN, PUT_ADMIN]}
                    tableColumns={this.columns} />
            </div>
        );
    }
}
