import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { getPromoter, getShops } from 'actions/asyncActions';
import { promoterMenuItems } from 'menus';
import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import Spinner from 'components/common/Spinner';
import Address from 'components/common/cards/Address';
import City from 'components/common/cards/City';
import withSelectedRows from 'components/common/withSelectedRows';
import withRole from 'components/common/withRole';
import BindShopFilter from 'filters/BindShopFilter';
import { openShopPromoterBindModal, openUnbindModal } from 'actions/modalActions';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { POST_INSTRUCTION, POST_INSTRUCTIONS } from 'constants/actionTypes';

const stateToProps = state => ({
    promoter: getPromoter.selectData(state),
    success: getPromoter.selectMeta(state).success
});

@withRole
@withSelectedRows
@connect(stateToProps, { openShopPromoterBindModal, openUnbindModal })
export default class PromoterShopsCard extends Component {
    columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (name, { id }) => <Link to={`/shop/${id}`}>{name}</Link>,
        }, {
            title: 'Торговая сеть',
            dataIndex: ['_embedded', 'network', 'name'],
            key: 'network'
        }, {
            title: 'Город',
            dataIndex: ['_embedded', 'city'],
            key: 'city',
            render: city => <City city={city} />
        }, {
            title: 'Адрес',
            key: 'address',
            render: shop => <Address item={shop} />
        }
    ];

    getUnbindOptins = () => {
        const { selected, promoter } = this.props;

        return {
            items: selected.map(item => ({
                promoter: promoter.id,
                shop: item.id,
                unbind: true,
                type: 'promoterShop'
            }))
        };
    }

    renderControls() {
        const { openShopPromoterBindModal, openUnbindModal, promoter, selected } = this.props;

        return (
            <div>
                <Button type='danger' onClick={() => openUnbindModal(this.getUnbindOptins())} disabled={!selected.length}><MinusOutlined /> Отвязать</Button>
                <Button type='primary' onClick={() => openShopPromoterBindModal(promoter)}><PlusOutlined /> Привязать</Button>
            </div>
        );
    }

    render() {
        const { match: { params }, rowSelection, promoter, success, resetSelected, isAdmin } = this.props;

        return (
            <div>
                <Menu
                    items={promoterMenuItems(params.id)}
                    controls={isAdmin && this.renderControls()} />
                { success ?
                    <ListTable
                        tableColumns={this.columns}
                        action={getShops}
                        rowSelection={isAdmin && rowSelection}
                        refreshActions={[POST_INSTRUCTION, POST_INSTRUCTIONS]}
                        initialFilters={{ promoter: promoter.id }}
                        filterForm={BindShopFilter}
                        onSuccess={resetSelected} />
                    : <Spinner />
                }
            </div>
        );
    }
}
