export const INSTRUCTION_STATUSES = [
    { type: 'success', name: 'Успешно' },
    { type: 'running', name: 'В процессе' },
    { type: 'abstain', name: 'Ожидание' },
    { type: 'fail', name: 'Не успешно' }
];

export const INSTRUCTION_TYPES = [
    { type: 'dismissal', name: 'Увольнение' },
    { type: 'supervisorShop', name: 'Привязка/Отвязка СВ к магазину' },
    { type: 'promoterShop', name: 'Привязка/Отвязка ПР к магазину' },
    { type: 'promoterSupervisor', name: 'Привязка/Отвязка ПР к СВ' }
];

export const INSTRUCTION_BIND_TYPES = param => ({
    dismissal: 'Увольнение',
    supervisorShop: `${param ? 'Отвязка' : 'Привязка'} СВ ${param ? 'от' : 'к'} магазину`,
    promoterShop: `${param ? 'Отвязка' : 'Привязка'} ПР ${param ? 'от' : 'к'} магазину`,
    promoterSupervisor: `${param ? 'Привязка' : 'Отвязка'} ПР ${param ? 'к' : 'от'} СВ`
});
