import React from 'react';
import { Field } from 'formik';

import Select from 'components/common/form/Select';
import { ITEM_STATUSES } from 'constants/products';

const  ItemStatusFilter = () => (
    <Field
        name='status'
        component={Select}
        placeholder='Доступные'
        allowClear={true}
        options={ITEM_STATUSES}
        path='type' />
);

export default ItemStatusFilter;
