import React from 'react';
import { Form } from 'antd';
import { path, pathOr, has, is } from 'ramda';

const withFormItem = WrappedComponent => ({ label, itemClassName, hideText, ...props }) => {
    const { form = {}, field = {} } = props;
    const isSubmitStarted = path(['status', 'isSubmitStarted'], form);
    const namePath = (field.name || '').replace(/\[/g, '.').replace(/\]\./g, '.');
    const error = path(['errors', ...namePath.split('.')], form);
    const showError = (error && isSubmitStarted) || (error && props.showError) ? 'error' : '';
    const required = has('required', pathOr({}, ['status', 'validationSchema', field.name, '_exclusive'], form));

    return (
        <Form.Item className={itemClassName} label={label} help={showError && is(String, error) ? hideText ? true : error : null} validateStatus={showError} required={required}>
            <WrappedComponent {...props} />
        </Form.Item>
    );
};

export default withFormItem;
