import React, { Component, Fragment, useState, useRef, useEffect, useMemo } from 'react';
import { asyncConnect } from 'react-async-client';
import { Spin, Row, Col, Button, Radio, DatePicker } from 'antd';
import { find, path, propEq, range, any, pathEq, sortBy, filter, prop, pathOr } from 'ramda';
import { withState } from 'recompose';
import { FundProjectionScreenOutlined, LoginOutlined, ShopOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getCurrentCheckin, getSalePlanDepartments, getSalePlans, getSalePlansStat, getUser } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import ShopCheckinCreate from '../main/ShopCheckinCreate';
import { dayOfTheWeek } from '../plans/lists/Plans';
import FormattedPrice, { toPriceFormat } from '../../common/cards/FormattedPrice';
import { renderComplete } from 'constants/plans';

require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/solid-gauge")(Highcharts);

const PlanContainer = styled.div`
    background: #334153;
    border-radius: 8px;
    padding: 16px;
    text-align: center;

    td, th {
        padding: 5px;
    }
`;

const PlanContent = styled.div`
    display: flex;
    align-items: center;
`;

const ActivityRingsCol = styled.div`
    display: flex;
    width: 40%;
    min-width: 130px;
`;

const ActivityRingsBonusCol = styled.div`
    display: flex;
    width: 40%;
    min-width: 180px;
`;

const ActivityRingsWrap = styled.div`
    display: flex;
    width: 130px;
    min-width: 130px;
    position: relative;
    & > div {
        width: 100%;
        height: 100%;
    }
`;

const ActivityRingsBonusWrap = styled.div`
    display: flex;
    width: 180px;
    min-width: 180px;
    position: relative;
    & > div {
        width: 100%;
        height: 100%;
    }
`;

const ActivityLegend = styled.div`
    display: flex;
    width: 60%;
    padding-left: 16px;
    align-items: center;
    flex-direction: column;
`;

const LegendItem = styled.div`
    display: flex;
    margin-bottom: 5px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
`;

const LegendItemTotal = styled(LegendItem)`
    border-top: 1px solid #EBEBEB;
    padding-top: 8px;
    margin-top: 4px;
`;

const DaysWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: grab;
`;

const PlanTotal = styled.span`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #fff;
`;

const PlanTotalBonus = styled.div`
    position: absolute;
    top: 70px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    width: 100% !important;
    height: auto !important;

    & > span {
        display: inline-block;
        margin-bottom: 4px;
    }
`;

const PlanBonusMarkerMin = styled.div`
    position: absolute;
    left: 0;
    top: 10px;
    font-size: 14px;
    font-weight: 300;
`;

const PlanBonusMarkerMax = styled.div`
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 14px;
    font-weight: 300;
`;

const PlanBonusMarker = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    transform: rotate(${({ current }) => Math.min(current * 180 / 100 - 90, 90)}deg);

    &:after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 2px;
        width: 0;
        height: 0;
        border: 2px solid transparent;
        border-top: 0;
        border-bottom: 40px solid #fff;
    }
`;

const DayWrapper = styled.div`
    background: ${({ active }) => active ? '#2A84D4' : '#334053'};
    border-radius: 8px;
    height: 55px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`;

const DayStatus = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${({ work }) => work ? '#C2FE00' : '#FFC961'};
`;

const PlanWrap = styled.div`
    margin: -8px 0 0;
    font-size: 13px;
`;

const MonthWrapper = styled.div`
    display: inline-block;
    margin-left: 8px;
    @media screen and (max-width: 360px) {
        margin-left: 4px;
    }
    input {
        color: #2A84D5;
        font-weight: 500;
        font-size: 20px;
        cursor: pointer;
        width: 140px;
        @media screen and (max-width: 360px) {
            font-size: 18px;
            width: 128px;
        }
    }
    .ant-picker {
        background: transparent;
        border: none;
        padding: 0;
        .ant-picker-suffix {
            display: none;
        }
    }
    .ant-picker-focused {
        box-shadow: none;
    }
`;

const HighchartsReactWrapper = ({ options, currentAmount }) => {
    // eslint-disable-next-line
    const optionsValue = useMemo(() => options, [currentAmount]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={optionsValue}
        />
    )
};

export class Plan extends Component {
    state = {
        opened: false
    };

    toggleOpened = () => this.setState(prev => ({ opened: !prev.opened }));

    open = () => this.setState({ opened: true });

    close = () => this.setState({ opened: false });

    getChartOptions = data => {
        const radius = [115, 90, 65];
        const innerRadius = [98, 73, 48];
        // const y = [80, 65, 50];

        return {
            chart: {
                type: 'solidgauge',
                backgroundColor: '#334153',
                marginLeft: 0,
                marginRight: 0,
                spacingLeft: 0,
                spacingRight: 0,
                width: 130,
                height: 130
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            yAxis: {
                min: 0,
                max: 1,
                lineWidth: 0,
                tickPositions: []
            },
            colors: data.map(prop('color')),
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: data.map((item, index) => ({
                    outerRadius: `${radius[index]}%`,
                    innerRadius: `${innerRadius[index]}%`,
                    borderWidth: 0,
                    backgroundColor: '#303B49'
                }))
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },
            series: data.map((item, index) => ({
                name: item.label,
                data: [{
                    color: item.color ||  Highcharts.color(Highcharts.getOptions().colors[index]).get(),
                    radius: `${radius[index]}%`,
                    innerRadius: `${innerRadius[index]}%`,
                    y: item.value
                }]
            }))
        };
    }

    render() {
        const { departmentPlans, item } = this.props;
        let data = filter(propEq('productDepartment', item.productDepartment), item.departmentPlans || [])
            .map(plan => ({ ...plan, departmentName: path(['name'], find(propEq('id', plan.id), departmentPlans)) }))
            .map((plan, index) => ({
                ...plan,
                label: plan.departmentName,
                value: plan.amount ? plan.salesFact / plan.amount : 0,
                backgroundColor: "#222222",
                color: path(['color'], find(propEq('id', plan.id), departmentPlans)),
                id: plan.id
            }))
            .filter(plan => plan.amount)
        ;
        const order = filter(item => find(propEq('id', item.id), data), departmentPlans).map(prop('id'));
        data = order.map(id => find(propEq('id', id), data));
        const commonAmount = filter(propEq('productDepartment', item.productDepartment), item.departmentPlans || []).reduce((res, cur) => res + (cur.amount || 0), 0);
        const commonSalesFact = filter(propEq('productDepartment', item.productDepartment), item.departmentPlans || []).reduce((res, cur) => res + (cur.salesFact || 0), 0);
        const commonPercent = commonAmount ? Math.round(commonSalesFact / commonAmount * 100) : 0;
        const countWorkingDays = pathOr(item.countWorkingDays, ['statistic', 'countWorkingDays'], item);

        return <PlanContainer onClick={this.toggleOpened}>
            <PlanContent>
                <ActivityRingsCol>
                    <ActivityRingsWrap>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.getChartOptions(data)}
                        />
                        <PlanTotal>{commonPercent >= 100 ? commonPercent : `${commonPercent}%`}</PlanTotal>
                    </ActivityRingsWrap>
                </ActivityRingsCol>
                <ActivityLegend>
                    {data.map((value, i) =>
                        <LegendItem key={`label-${i}-${item.id}`}>
                            <div style={{ display: 'flex', alignItems: 'center' }}><div style={{ background: value.color, width: 10, minWidth: 10, height: 10, borderRadius: '50%', marginRight: 7 }} /> <span style={{ color: '#fff', textAlign: 'left' }}>{value.label}</span></div>
                            <span style={{ color: '#A9A9AA' }}>{`${Math.round(value.value * 100)}%`}</span>
                        </LegendItem>
                    )}
                    <LegendItemTotal>
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 17 }}><span style={{ color: '#fff', textAlign: 'left' }}>Общий</span></div>
                        <span style={{ color: '#fff', fontWeight: 500 }}>{renderComplete(item, 'amount', 'salesFact', true)}%</span>
                    </LegendItemTotal>
                </ActivityLegend>
            </PlanContent>
            {this.state.opened ?
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.61494 4.10248L13.4074 8.89498C13.8469 9.33448 13.8469 10.0535 13.4074 10.4925L13.0074 10.893C12.5689 11.332 11.8494 11.332 11.4104 10.893L7.75294 7.23548L4.09494 10.893C3.65594 11.332 2.93644 11.332 2.49794 10.893L2.09794 10.4925C1.65844 10.0535 1.65844 9.33448 2.09794 8.89498L6.89144 4.10248C7.12694 3.86648 7.44344 3.76148 7.75294 3.77898C8.06244 3.76148 8.37844 3.86648 8.61494 4.10248Z" fill="white" />
                </svg>
                :
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.38506 11.8976L2.59256 7.10508C2.15306 6.66558 2.15306 5.94658 2.59256 5.50758L2.99256 5.10708C3.43106 4.66808 4.15056 4.66808 4.58956 5.10708L8.24706 8.76458L11.9051 5.10708C12.3441 4.66808 13.0636 4.66808 13.5021 5.10708L13.9021 5.50758C14.3416 5.94658 14.3416 6.66558 13.9021 7.10508L9.10856 11.8976C8.87306 12.1336 8.55656 12.2386 8.24706 12.2211C7.93756 12.2386 7.62156 12.1336 7.38506 11.8976Z" fill="white" />
                </svg>
            }
            {this.state.opened &&
                <PlanWrap>
                    <table style={{ color: '#fff', width: '100%' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #EBEBEB' }}>
                                <th style={{ textAlign: 'left' }}>План</th>
                                <th>на день</th>
                                <th>на месяц</th>
                                <th>факт</th>
                            </tr>
                        </thead>
                        <tbody>
                            { data.map(plan =>
                                <tr key={`tr-${plan.id}`}>
                                    <td style={{ textAlign: 'left' }}>{plan.departmentName}</td>
                                    <td>{(!plan.amount || !countWorkingDays) ? 0 : toPriceFormat(plan.amount / countWorkingDays) || 0}</td>
                                    <td>{plan.amount ? toPriceFormat(plan.amount) : 0}</td>
                                    <td>{plan.salesFact ? toPriceFormat(plan.salesFact) : 0}</td>
                                </tr>
                            )}
                            { (item.amount || item.salesFact) ? <tr style={{ borderTop: '1px solid #EBEBEB' }}>
                                <td style={{ textAlign: 'left' }}>Общий</td>
                                <td>{(!item.amount || !countWorkingDays) ? 0 : toPriceFormat(item.amount / countWorkingDays) || 0}</td>
                                <td>{toPriceFormat(item.amount) || 0}</td>
                                <td>{toPriceFormat(item.salesFact) || 0}</td>
                            </tr> : null}
                        </tbody>
                    </table>
                </PlanWrap>
            }
        </PlanContainer>
    }
}

export class PlanBonus extends Component {
    state = {
        opened: false
    };

    toggleOpened = () => this.setState(prev => ({ opened: !prev.opened }));

    open = () => this.setState({ opened: true });

    close = () => this.setState({ opened: false });

    getChartOptions = (data, currentAmount) => {
        return {
            chart: {
                type: 'solidgauge',
                backgroundColor: '#334153',
                marginLeft: 0,
                marginRight: 0,
                spacingLeft: 0,
                spacingRight: 0,
                width: 180,
                height: 180
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            yAxis: {
                min: 0,
                max: 1,
                lineWidth: 0,
                tickPositions: [],
            },
            colors: data.map(prop('color')),
            pane: {
                startAngle: -90,
                endAngle: 90,
                background: data.map((item, index) => ({
                    outerRadius: `${115}%`,
                    innerRadius: `${95}%`,
                    borderWidth: 0,
                    backgroundColor: 'transparent'
                }))
            },
            plotOptions: {
                series: {
                    enableMouseTracking: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                },
                solidgauge: {
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true,
                    dataLabels: {
                        enabled: false,
                    },
                    allowPointSelect: false,
                    enableMouseTracking: false,
                }
            },
            tooltip: {
                enabled: false,
            },
            series: data.map((item, index) => ({
                name: item.label,
                allowPointSelect: false,
                index: 100 - index,
                zIndex: 100 - index,
                data: [{
                    color: item.color || Highcharts.color(Highcharts.getOptions().colors[index]).get(),
                    radius: `${115}%`,
                    innerRadius: `${95}%`,
                    index: 100 - index,
                    zIndex: 100 - index,
                    y: item.amount ? Number((item.amount / currentAmount).toFixed(2)) : 1
                }]
            }))
        };
    }

    render() {
        const { departmentPlans, item } = this.props;
        let data = filter(propEq('productDepartment', item.productDepartment), item.departmentPlans || [])
            .map(plan => ({ ...plan, departmentName: path(['name'], find(propEq('id', plan.id), departmentPlans)) }))
            .map((plan, index) => ({
                ...plan,
                label: plan.departmentName,
                value: plan.amount ? plan.salesFact / plan.amount : 0,
                backgroundColor: "#222222",
                color: path(['color'], find(propEq('id', plan.id), departmentPlans)),
                id: plan.id
            }))
            .filter(plan => plan.amount || plan.minAmount)
        ;
        const order = filter(item => find(propEq('id', item.id), data), departmentPlans).map(prop('id'));
        data = order.map(id => find(propEq('id', id), data));
        const bonusAmount = filter(propEq('productDepartment', item.productDepartment), item.departmentPlans || []).reduce((res, cur) => res + (cur.achievedBonus || 0), 0);
        // const currentPercent = find(plan => propEq('productDepartment', item.productDepartment, plan) && (plan.amount ? item.salesFact > plan.minAmount : (item.salesFact >= plan.minAmount) && (item.salesFact < plan.amount)), item.departmentPlans || []);
        const currentAmount = data.reduce((amount, item, index) => amount + (item.amount ? item.amount - item.minAmount : data[index - 1].amount - data[index - 1].minAmount), 0);
        const currentSales = data.reduce((fact, item) => fact + (item.bonusRelatedAmount || 0), 0);

        return <PlanContainer onClick={this.toggleOpened}>
            <PlanContent>
                <ActivityRingsBonusCol>
                    <ActivityRingsBonusWrap>
                        <HighchartsReactWrapper
                            currentAmount={currentAmount}
                            options={this.getChartOptions(data, currentAmount)}
                        />
                        <PlanTotalBonus>
                            <PlanBonusMarkerMin><FormattedPrice price={data[0].minAmount} showZero /></PlanBonusMarkerMin>
                            <PlanBonusMarkerMax>{ data[data.length - 1].amount ? '' : '∞' }</PlanBonusMarkerMax>
                            <PlanBonusMarker current={currentSales && currentAmount ? (currentSales / currentAmount * 100).toFixed() : 0} />
                            <br />
                            <span><FormattedPrice price={currentSales || 0} showRub showZero /></span>
                            <br />
                            <span style={{ color: '#fff', borderTop: '1px solid #A9A9AA' }}>
                                <span style={{ display: 'inline-block', marginBottom: '4px' }}>Бонус</span><br />
                                { bonusAmount && <span style={{ color: '#C2FE00', fontSize: '18px', fontWeight: 500 }}>
                                    +<FormattedPrice price={bonusAmount} showRub />
                                </span>}
                            </span>
                        </PlanTotalBonus>
                    </ActivityRingsBonusWrap>
                </ActivityRingsBonusCol>
                <ActivityLegend>
                    {data.map((value, i) =>
                        <LegendItem key={`label-${i}-${item.id}`}>
                            <div style={{ display: 'flex', alignItems: 'center' }}><div style={{ background: value.color, width: 10, minWidth: 10, height: 10, borderRadius: '50%', marginRight: 7 }} /> <span style={{ color: '#fff', textAlign: 'left' }}>{value.label}</span></div>
                            <span>
                                <span style={{ color: '#C2FE00' }}><FormattedPrice showZero price={(value.achievedBonus || 0).toFixed()} /></span>
                                <span style={{ color: '#A9A9AA' }}>/{value.amount ? <FormattedPrice price={(value.amount - value.minAmount) * value.bonusPercents / 100} /> : '∞'}</span>
                            </span>
                        </LegendItem>
                    )}
                </ActivityLegend>
            </PlanContent>
            {this.state.opened ?
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.61494 4.10248L13.4074 8.89498C13.8469 9.33448 13.8469 10.0535 13.4074 10.4925L13.0074 10.893C12.5689 11.332 11.8494 11.332 11.4104 10.893L7.75294 7.23548L4.09494 10.893C3.65594 11.332 2.93644 11.332 2.49794 10.893L2.09794 10.4925C1.65844 10.0535 1.65844 9.33448 2.09794 8.89498L6.89144 4.10248C7.12694 3.86648 7.44344 3.76148 7.75294 3.77898C8.06244 3.76148 8.37844 3.86648 8.61494 4.10248Z" fill="white" />
                </svg>
                :
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.38506 11.8976L2.59256 7.10508C2.15306 6.66558 2.15306 5.94658 2.59256 5.50758L2.99256 5.10708C3.43106 4.66808 4.15056 4.66808 4.58956 5.10708L8.24706 8.76458L11.9051 5.10708C12.3441 4.66808 13.0636 4.66808 13.5021 5.10708L13.9021 5.50758C14.3416 5.94658 14.3416 6.66558 13.9021 7.10508L9.10856 11.8976C8.87306 12.1336 8.55656 12.2386 8.24706 12.2211C7.93756 12.2386 7.62156 12.1336 7.38506 11.8976Z" fill="white" />
                </svg>
            }
            {this.state.opened &&
                <PlanWrap>
                    <table style={{ color: '#fff', width: '100%' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid #EBEBEB' }}>
                                <th width='25%' style={{ textAlign: 'left' }}>% от продаж</th>
                                <th width='25%'>от</th>
                                <th width='25%'>до</th>
                                <th width='25%'>факт</th>
                            </tr>
                        </thead>
                        <tbody>
                            { data.map(plan =>
                                <tr key={`tr-${plan.id}`}>
                                    <td style={{ textAlign: 'left' }}> {plan.departmentName}</td>
                                    <td>{plan.minAmount ? toPriceFormat(plan.minAmount) || 0 : 0}</td>
                                    <td>{plan.amount ? toPriceFormat(plan.amount) : '∞'}</td>
                                    <td style={{ textAlign: 'right' }}>{
                                        !plan.bonusRelatedAmount ? 0
                                        : plan.amount
                                            ? (plan.minAmount + plan.bonusRelatedAmount) >= plan.amount
                                                ? <span style={{ color: '#C2FE00' }}>{toPriceFormat(plan.bonusRelatedAmount || 0)}</span>
                                                : <span style={{ color: '#FF8E26' }}>{toPriceFormat(plan.bonusRelatedAmount) || 0}</span>
                                            : (plan.minAmount + plan.bonusRelatedAmount) >= plan.minAmount
                                                ? <span style={{ color: '#FF8E26' }}>{toPriceFormat(plan.bonusRelatedAmount) || 0}</span>
                                                : 0
                                    }</td>
                                </tr>
                            )}
                            { (item.amount || item.salesFact) ? <tr style={{ borderTop: '1px solid #EBEBEB' }}>
                                <td style={{ textAlign: 'left' }}>Общий</td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: 'right' }}>{toPriceFormat(item.salesFact) || 0}</td>
                            </tr> : null}
                        </tbody>
                    </table>
                </PlanWrap>
            }
        </PlanContainer>
    }
}

const Promoter = props => {
    const timeZoneOffset = pathOr(0, ['_embedded', 'city', 'timeZoneOffset'], props.user);
    const [selectedDay, setSelectedDay] = useState(moment().utcOffset(timeZoneOffset).get('date'));
    const [type, setType] = useState('project');
    const scroll = useRef(null);
    const timeout = useRef(null);
    const scrolled = useRef(false);
    const [dayWidth, setDayWidth] = useState(0);

    const scrollToDay = () => {
        if (!scrolled.current && scroll.current) {
            const daysWrapperWidth = scroll.current.clientWidth;
            const daysAmount = Math.floor(((daysWrapperWidth + 17) / (55 + 17)));
            const dayW = (daysWrapperWidth - ((daysAmount - 1) * 17)) / daysAmount;

            setDayWidth(dayW);
            scroll.current.scrollTo((selectedDay - 1) * dayW + (selectedDay - 1) * 17, 0);
            scrolled.current = true;
        }
    }

    timeout.current = setTimeout(() => scrollToDay(), 100);

    useEffect(() => {
        return () => clearTimeout(timeout.current);
    }, []);

    const changeType = e => {
        setType(e.target.value);
    }

    const isWork = day => {
        const { getSalePlans: { data } } = props;

        return any(({ dayReports }) => pathEq([day - 1, 'status'], 'work', dayReports), data.items || []);
    }

    const renderTimetable = (isBonusPlan) => {
        const { date, getSalePlans, getSalePlanDepartments, getCurrentCheckin } = props;
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const monthDays =  new Date(year, month + 1, 0).getDate();
        const checkinData = isWork(selectedDay) ?
            find(pathEq(['dayReports', selectedDay - 1, 'status'], 'work'), getSalePlans.data.items || []) : null;

        return <div style={{ marginTop: 25 }}>
            <h2>Расписание</h2>
            <ScrollContainer style={{ overflowX: 'auto' }} innerRef={el => scroll.current = el}>
                <DaysWrapper style={{ width: monthDays * dayWidth + (monthDays - 1) * 17 }}>
                    {range(1, monthDays + 1).map((day, index) =>
                        <DayWrapper
                            onClick={() => setSelectedDay(day)}
                            key={`day-${day}`}
                            style={{ marginLeft: index === 0 ? 0 : 17, width: dayWidth }}
                            active={day === selectedDay}>
                            <div style={{ color: '#fff', marginTop: 2 }}>{day}</div>
                            <div style={{ color: '#ccc', marginTop: -4 }}>{dayOfTheWeek(day, month, year)}</div>
                            <DayStatus work={isWork(day)} />
                        </DayWrapper>
                    )}
                </DaysWrapper>
            </ScrollContainer>
            {isWork(selectedDay) ?
                <div style={{ marginTop: 25 }}>
                    <div>{path(['_embedded', 'shop', 'name'], checkinData)}</div>
                    <PlanContainer>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            <div style={{ color: '#fff', lineHeight: 1 }}>
                                <div style={{ fontSize: 45 }}>{path(['dayReports', selectedDay - 1, 'hours'], checkinData)}</div>
                                <div style={{ fontSize: 24 }}>часов</div>
                            </div>
                            <ActivityLegend>
                                {filter(propEq('productDepartment', checkinData.productDepartment), checkinData.departmentPlans || []).map(plan =>
                                    <LegendItem key={`plan-${plan.id}`}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ background: path(['color'], find(propEq('id', plan.id), getSalePlanDepartments.data.departmentPlans || [])), width: 10, minWidth: 10, height: 10, borderRadius: '50%', marginRight: 7 }} />
                                            {' '}
                                            <span style={{ color: '#fff', textAlign: 'left' }}>
                                                {path(['name'], find(propEq('id', plan.id), getSalePlanDepartments.data.departmentPlans || []))}
                                            </span>
                                        </div>
                                        <span style={{ color: '#A9A9AA' }}>
                                            {isBonusPlan
                                                    ? plan.amount ? `< ${toPriceFormat(plan.amount)}` : `> ${toPriceFormat(plan.minAmount)}`
                                                    : plan.amount ? toPriceFormat(plan.amount) : null
                                            }
                                        </span>
                                    </LegendItem>
                                )}
                            </ActivityLegend>
                        </div>
                        {day === selectedDay && getCurrentCheckin.meta.lastSucceedAt &&
                            (getCurrentCheckin.data.id ?
                                <Link to='/checkin'><Button type='primary' block style={{ marginTop: 10 }} icon={<LoginOutlined />}>Перейти к чекину</Button></Link> :
                                <ShopCheckinCreate checkin shopId={checkinData.shop} shopCoords={path(['_embedded', 'shop', 'coordinates'], checkinData)} />
                            )
                        }
                    </PlanContainer>
                </div> : null
            }
        </div>;
    }

    const { getSalePlansStat, getSalePlanDepartments, getSalePlansStatPromShop, date, setDate } = props;
    const stat = type === 'project' ? getSalePlansStat : getSalePlansStatPromShop;
    const isBonusPlan = !!path([0, 'salePlanBonusOptions', 'enabled'], getSalePlanDepartments.data.departmentPlans || []);

    return <div className='layout-wrap'>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2 className='plan-head'>
                <span className="plan-title">Планы на</span> <span className='span-link'>
                    <MonthWrapper>
                        <DatePicker.MonthPicker
                            value={date ? moment(date) : undefined}
                            format='MMMM YYYY'
                            allowClear={false}
                            onChange={date => {
                                setDate(date.toDate());
                                setSelectedDay(moment().isSame(date, 'month') ? moment().utcOffset(timeZoneOffset).toDate() : null);
                            }}
                            getPopupContainer={trigger => trigger.parentNode} />
                    </MonthWrapper>
                </span>
            </h2>
            <Radio.Group value={type} onChange={changeType} style={{ width: 90, height: 30 }}>
                <Radio.Button value='project'><FundProjectionScreenOutlined /></Radio.Button>
                <Radio.Button value='shop'><ShopOutlined /></Radio.Button>
            </Radio.Group>
        </div>
        {stat.meta.pending ? <div style={{ textAlign: 'center' }}><Spin /></div> :
            <Fragment>
                <Row gutter={16}>
                    {sortBy(path(['_embedded', 'shop', 'name']), stat.data.items || []).map((item, index) => {
                        const PlanComponent = isBonusPlan ? PlanBonus : Plan;

                        return <Col xs={24} lg={12} key={`plan-${index}`}>
                            <h2 className='title-item'>{path(['_embedded', 'shop', 'name'], item)}</h2>
                            <h3 className='title-item-sub'>{path(['_embedded', 'productDepartment', 'name'], item)}</h3>
                            <PlanComponent item={item} departmentPlans={getSalePlanDepartments.data.departmentPlans || []} />
                        </Col>;
                    })}
                </Row>
                {renderTimetable(isBonusPlan)}
            </Fragment>
        }
    </div>;
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('date', 'setDate', new Date())(asyncConnect({
    getSalePlansStat: getSalePlansStat
        .withPayload(({ date }) => ({
            q: {
                date,
                type: 'promoter'
            },
            limit: 0
        }))
        .withOptions(fullAutoOptions),
    getSalePlans: getSalePlans
        .withPayload(({ date }) => ({
            q: {
                date,
                type: 'shop'
            },
            limit: 0
        }))
        .withParams('promoter')
        .withOptions(fullAutoOptions),
    getSalePlansStatPromShop: getSalePlansStat
        .withPayload(({ date }) => ({
            q: {
                date,
                type: 'promoter_shop'
            },
            limit: 0
        }))
        .withParams('promoter_shop')
        .withOptions(fullAutoOptions),
    getSalePlanDepartments: getSalePlanDepartments
        .withPayload(({ date }) => date.toISOString())
        .withOptions(fullAutoOptions),
    getCurrentCheckin: getCurrentCheckin
        .withOptions(fullAutoOptions),
}, stateToProps)(Promoter));
