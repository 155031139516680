import React, { Component } from 'react';
import { append, reject, eqProps, prop } from 'ramda';

const withSelectedRows = WrappedComponent =>
    class extends Component {
        constructor() {
            super();

            this.state = {
                selected: []
            };
        }

        onSelect = (item, selected) => {
            this.setState(prev => ({
                selected: selected ? append(item, prev.selected) : reject(eqProps('id', item), prev.selected)
            }));
        }

        onSelectAll = (selected, selectedRows) => {
            this.setState(prev => ({
                selected: selected ? selectedRows : []
            }));
        }

        resetSelected = () => {
            this.setState({ selected: [] });
        }

        render() {
            const { selected } = this.state;

            return <WrappedComponent
                {...this.props}
                selected={selected}
                resetSelected={this.resetSelected}
                rowSelection={{
                    onSelect: this.onSelect,
                    selectedRowKeys: selected.map(prop('id')),
                    onSelectAll: this.onSelectAll
                }} />;
        }
    }

export default withSelectedRows;
