import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import withRole from 'components/common/withRole';
import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import { productMenuItems } from 'menus';
import { getProductDepartments } from 'actions/asyncActions';
import { openProductDepartmentModal } from 'actions/modalActions';
import ProductsSearchFilter from 'filters/ProductsSearchFilter';
import { PlusOutlined } from '@ant-design/icons';
import { DELETE_PRODUCT_DEPARTMENT, POST_PRODUCT_DEPARTMENT, PUT_PRODUCT_DEPARTMENT } from 'constants/actionTypes';

@withRole
@connect(null, { openProductDepartmentModal })
export default class Departments extends Component {
    getColumns = isAdmin => [
        {
            title: 'Департамент',
            dataIndex: 'name',
            key: 'department',
            render: (text, item) => isAdmin ? (
                <span className='span-link' onClick={() => this.props.openProductDepartmentModal(item)}>{text}</span>
            ) : (
                text
            ),
        }
    ];

    getMenuControls() {
        const { openProductDepartmentModal, isAdmin } = this.props;

        return (
            <div>
                { isAdmin && (
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => openProductDepartmentModal()}>Создать</Button>
                )}
            </div>
        );
    }

    render() {
        const { isAdmin } = this.props;

        return (
            <div>
                <Menu
                    items={productMenuItems}
                    controls={this.getMenuControls()} />
                <ListTable
                    tableColumns={this.getColumns(isAdmin)}
                    action={getProductDepartments}
                    refreshActions={[POST_PRODUCT_DEPARTMENT, PUT_PRODUCT_DEPARTMENT, DELETE_PRODUCT_DEPARTMENT]}
                    filterForm={ProductsSearchFilter} />
            </div>
        );
    }
}
