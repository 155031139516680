import React, { Component } from 'react';

import SupervisorBindTable from '../tables/SupervisorBindTable';
import BindModal from '../BindModal';

export default class SupervisorShopBindModal extends Component {
    getInitialValues() {
        const { params: { id }} = this.props;

        return {
            shop: id,
            type: 'supervisorShop'
        };
    }

    render() {
        return (
            <BindModal
                {...this.props}
                title='Привязать супервайзера'
                initialValues={this.getInitialValues()}
                steps={[
                    { title: 'Выбор супервайзера', table: SupervisorBindTable, path: 'supervisor' }
                ]}
            />
        );
    }
}
