import React, { Component } from 'react';

import ListTable from 'components/common/ListTable';
import PersonLink from 'components/common/cards/person/PersonLink';
import ShopLink from 'components/common/cards/shop/ShopLink';
import { getSalePlansStat } from 'actions/asyncActions';
import { ExpandedPlansFilter, defaultSupervisorPlansFilter } from 'filters/PlansFilter';
import planColumns from '../planColumns';
import CacheDate from './CacheDate';

@planColumns
export default class SupervisorPlans extends Component {
    getColumns() {
        return [
            {
                title: 'ФИО',
                key: 'name',
                dataIndex: ['_embedded', 'supervisor'],
                fixed: 'left',
                width: 150,
                render: supervisor => <PersonLink person={supervisor} type='supervisor' />
            }, {
                title: 'Проект',
                key: 'productDepartment',
                fixed: 'left',
                width: 150,
                dataIndex: ['_embedded', 'productDepartment', 'name']
            }, {
                title: 'Магазины',
                dataIndex: ['_embedded', 'supervisor', '_embedded', 'shops'],
                key: 'shops',
                width: 150,
                fixed: 'left',
                render: shops => (shops || []).map(shop =>
                    <p key={shop.id}><ShopLink shop={shop} /></p>
                )
            },
            ...this.props.planColumns
        ];
    }

    render() {
        const { saga, setDate, planDate } = this.props;

        return (
            <ListTable
                tableColumns={this.getColumns()}
                action={getSalePlansStat}
                filterForm={ExpandedPlansFilter}
                initialFilters={{
                    ...defaultSupervisorPlansFilter,
                    date: planDate
                }}
                scroll={{ x: 'max-content' }}
                className='th-multi'
                saga={saga}
                setDate={setDate}
                setProductDepartments={this.props.setProductDepartments}
                staticPayload={{ limit: 0 }}
                hidePagination
                bordered
                footer={({ _meta }) => <CacheDate meta={_meta} /> }
            />
        );
    }
}
