import React from 'react';
import { Field } from 'formik';

import RangePicker from 'components/common/form/RangePicker';

const RangeFilter = () => (
    <Field
        name='minDate'
        to='maxDate'
        component={RangePicker} />
)

export default RangeFilter;
