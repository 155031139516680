import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { pathOr } from 'ramda';

import withRole from 'components/common/withRole';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import PersonLink from 'components/common/cards/person/PersonLink';
import { getShop } from 'actions/asyncActions';
import { openSupervisorShopBindModal, openPromoterShopBindModal, openUnbindModal } from 'actions/modalActions';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const stateToProps = state => ({
    shop: getShop.selectData(state)
});

@withRole
@connect(stateToProps, { openSupervisorShopBindModal, openPromoterShopBindModal, openUnbindModal })
export default class ShopWorkersTab extends Component {
    userColumns = (isAdmin, isPromoter) => [
        {
            key: 'icon',
            width: 40,
            render: person => <PersonPhoto person={person} />
        }, {
            title: 'ФИО',
            key: 'fullName',
            render: person => <PersonLink type={isPromoter ? 'promoter' : 'supervisor'} person={person} />
        }, {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            width: 200,
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 250,
        }, {
            key: 'unbind',
            width: 100,
            render: ({ id }) => isAdmin && <Button type='danger' onClick={() => this.openUnbindModal(id, isPromoter)}><DeleteOutlined /> Отвязать</Button>
        }
    ];

    openUnbindModal(id, isPromoter) {
        const { shop, openUnbindModal } = this.props;
        const common = { shop: shop.id, unbind: true };
        const options = isPromoter
            ? { ...common, promoter: id, type: 'promoterShop' }
            : { ...common, supervisor: id, type: 'supervisorShop' };

        openUnbindModal(options);
    }

    render() {
        const { shop, openSupervisorShopBindModal, openPromoterShopBindModal, isAdmin } = this.props;
        const supervisors = pathOr([], ['_embedded', 'supervisors'], shop);
        const promoters = pathOr([], ['_embedded', 'promoters'], shop);

        return (
            <div>
                <div className='card'>
                    <div className='card-title'>
                        <h3>Супервайзеры</h3>
                        { isAdmin && (
                            <Button
                                className='pull-right'
                                type='primary'
                                onClick={() => openSupervisorShopBindModal({ id: shop.id })}>
                                <PlusOutlined /> Привязать
                            </Button>
                        )}
                    </div>
                    <div className='card-body card-body-no-padding'>
                        <Table
                            dataSource={supervisors}
                            columns={this.userColumns(isAdmin)}
                            rowKey='id'
                            pagination={false} />
                    </div>
                </div>
                <div className='card'>
                    <div className='card-title'>
                        <h3>Консультанты</h3>
                        { isAdmin && (
                            <Button
                                className='pull-right'
                                type='primary'
                                onClick={() => openPromoterShopBindModal({ id: shop.id })}>
                                <PlusOutlined /> Привязать
                            </Button>
                        )}
                    </div>
                    <div className='card-body card-body-no-padding'>
                        <Table
                            dataSource={promoters}
                            columns={this.userColumns(isAdmin, true)}
                            rowKey='id'
                            pagination={false} />
                    </div>
                </div>
            </div>
        );
    }
}
