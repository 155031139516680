import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, path, pathOr, propEq, filter, includes } from 'ramda';
import { withState } from 'recompose';
import { asyncConnect } from 'react-async-client';

import FormattedPrice, { toPriceFormat } from 'components/common/cards/FormattedPrice';
import { getSalePlanDepartments, getSalePlansStat } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { GET_SALE_PLANS_STAT } from 'constants/actionTypes';
import { takeEvery } from '@redux-saga/core/effects';
import moment from 'moment';
import { renderComplete } from 'constants/plans';
import withPlanDate from './withPlanDate';

const stateToProps = state => ({
    departmentPlans: pathOr([], ['items', 0, 'departmentPlans'], getSalePlansStat.withParams('listTable').selectData(state))
});

const renderCompletePlan = (departmentPlans, item) => {
    const planNumber = pathOr(null, ['amount'], find(propEq('id', item.id), departmentPlans || []));
    const factNumber = pathOr(0, ['salesFact'], find(propEq('id', item.id), departmentPlans || []));

    return planNumber ? `${(factNumber * 100 / planNumber).toFixed(2)} %` : null;
}

const planColumns = WrappedComponent => {
    return (
        @withPlanDate
        @withState('productDepartments', 'setProductDepartments', [])
        @asyncConnect({
            getSalePlanDepartments: getSalePlanDepartments
                .withPayload(({ planDate }) => planDate.toISOString())
                .withOptions(fullAutoOptions),
        }, stateToProps)
        @connect(stateToProps)
        class extends Component {
            getPlanColumns() {
                const departmentPlanItems = filter(
                    item => path(['productDepartments', 'length'], this.props) ? includes(item.productDepartment, this.props.productDepartments) : true,
                    this.props.getSalePlanDepartments.data.departmentPlans || []
                );

                return [
                    ...departmentPlanItems.map((item, index) => {
                        return {
                            title: item.name,
                            className: 'border-right border-left',
                            children: [
                                {
                                    title: 'План',
                                    dataIndex: ['departmentPlans'],
                                    key: `${item.name}Plan`,
                                    render: departmentPlans => {
                                        const value = path(['amount'], find(propEq('id', item.id), departmentPlans || []));
                                        return <FormattedPrice price={value} />;
                                    },
                                    align: 'center',
                                    className: 'border-left mw100'
                                }, {
                                    title: 'Факт',
                                    dataIndex: ['departmentPlans'],
                                    key: `${item.name}Fact`,
                                    className: 'mw100',
                                    render: departmentPlans => {
                                        const sum = path(['salesFact'], find(propEq('id', item.id), departmentPlans || []));
                                        return sum ? toPriceFormat(sum) : null;
                                    },
                                    align: 'center'
                                }, {
                                    title: 'Выполнено',
                                    key: `${item.name}Complete`,
                                    render: data => {
                                        return renderCompletePlan(data.departmentPlans, item)
                                    },
                                    align: 'center',
                                    className: 'border-right mw100'
                                }
                            ]
                        };
                    }),
                    {
                        title: 'Общий',
                        className: 'border-left',
                        children: [
                            {
                                title: 'План',
                                dataIndex: 'amount',
                                key: 'commonPlan',
                                className: 'border-left mw100',
                                render: (value) => <FormattedPrice price={value} />
                            }, {
                                title: 'Факт',
                                dataIndex: 'salesFact',
                                key: 'commonFact',
                                className: 'mw100',
                                render: salesFact => salesFact ? toPriceFormat(salesFact) : null
                            }, {
                                title: 'Выполнено',
                                key: 'commonComplete',
                                render: data => renderComplete(data, 'amount', 'salesFact'),
                                className: 'mw100'
                            }
                        ]
                    }
                ];
            }

            render() {
                const saga = function* (getProps) {
                    yield takeEvery([GET_SALE_PLANS_STAT], () => {
                        getProps().setDate(moment(getProps().state.q.date).toDate());
                        getProps().setProductDepartments(getProps().state.q.productDepartments);
                    });
                };

                return <WrappedComponent
                    {...this.props}
                    planColumns={this.getPlanColumns()}
                    setDate={this.props.setPlanDate}
                    setProductDepartments={this.props.setProductDepartments}
                    saga={saga} />
            }
        }
    )
}

export default planColumns;
