import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import MarketingCoordinatorFilter, { defaultMarketingCoordinatorFilter } from 'filters/MarketingCoordinatorFilter';
import { usersMenuItems, filterClientColumns } from 'menus';
import { getMarketingCoordinatorsList } from 'actions/asyncActions';
import { openMarketingCoordinatorModal } from 'actions/modalActions';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import PersonName from 'components/common/cards/person/PersonLink';
import { POST_MARKETING_COORDINATOR, PUT_MARKETING_COORDINATOR, IMPORT_MARKETING_COORDINATORS } from 'constants/actionTypes';

export const renderRegions = regions => (regions || []).reduce((res, cur, index) => `${res}${index === 0 ? '' : ', '}${cur.name}`, '');

@withRole
@connect(null, { openMarketingCoordinatorModal })
export default class MarketingCoordinators extends Component {
    columns = [
        {
            key: 'icon',
            width: 40,
            render: item => <PersonPhoto person={item} />
        }, {
            title: 'ФИО',
            key: 'fullName',
            render: item => <PersonName type='marketing-coordinator' person={item} />
        }, {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }, {
            title: 'Регионы',
            dataIndex: ['_embedded', 'regions'],
            key: 'regions',
            render: renderRegions
        }
    ];

    getMenuControls() {
        const { openMarketingCoordinatorModal } = this.props;

        return (
            <div>
                <Button type='primary' icon={<UserAddOutlined />} onClick={() => openMarketingCoordinatorModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Menu
                    items={usersMenuItems(this.props.accessToFinances)}
                    controls={this.getMenuControls()} />
                <ListTable
                    filterForm={MarketingCoordinatorFilter}
                    initialFilters={defaultMarketingCoordinatorFilter}
                    action={getMarketingCoordinatorsList}
                    refreshActions={[POST_MARKETING_COORDINATOR, PUT_MARKETING_COORDINATOR, IMPORT_MARKETING_COORDINATORS]}
                    tableColumns={filterClientColumns(this.props.isClient, this.columns)} />
            </div>
        );
    }
}
