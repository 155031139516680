import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from '../reducers/rootReducer';
import rootSaga from '../sagas/rootSaga';

export const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
let middlewares = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV !== 'production') {
    const createLogger = require('redux-logger').createLogger;
    const logger = createLogger({collapsed: true});
    middlewares.push(logger);
}

if (process.env.NODE_ENV === 'production') {
    const rollbar = require('./rollbarMiddleware').default;
    middlewares.push(rollbar);
}

export const configureStore = initialState => {
    const store = createStore(
        rootReducer(),
        initialState,
        applyMiddleware(...middlewares),
    );

    sagaMiddleware.run(rootSaga);

    return store;
}
