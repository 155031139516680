import React, { Component } from 'react';
import { InputNumber, Input } from 'antd';
import debounce from 'debounce';

import { debounceDelay } from 'constants/debounce';

export default class EditInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };

        this.saveValue = debounce(this.saveValue, debounceDelay);
    }

    saveValue = value => {
        const { onChange, item, field } = this.props;

        onChange(item, field, value);
    }

    onChange = e => {
        const value = this.props.number ? e : e.target.value;

        this.setState({ value });
        this.saveValue(value);
    }

    render() {
        const { number, text } = this.props;

        return number ?
            <InputNumber size='small' value={this.state.value} onChange={this.onChange} /> :
            text ?
                <Input.TextArea style={{ minHeight: 30, borderRadius: 4 }} size='small' value={this.state.value} onChange={this.onChange} autoSize={{ maxRows: 3 }} /> :
                <Input style={{ height: 30, borderRadius: 4 }} size='small' value={this.state.value} onChange={this.onChange} />;
    }
}
