import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import withRole from '../withRole';

@withRole
export default class AccessLink extends Component {
    isAvailable() {
        const { role, access } = this.props;

        return !access || access.includes(role);
    }

    render() {
        const { to, children } = this.props;

        return this.isAvailable() ? <Link to={to}>{children}</Link> : <span>{children}</span>;
    }
}
