import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import TaskPriorityFilter from './fields/TaskPriorityFilter';
import BeforeDateFilter, { StartDateFilter } from './fields/BeforeDateFilter';
import ShopFilter from './fields/ShopFilter';

function ShopTasksFilter() {
    return (
        <BaseSearchFilter searchFieldName='text'>
            <Col sm={3} xs={24}>
                <TaskPriorityFilter />
            </Col>
            <Col sm={3} xs={12}>
                <StartDateFilter />
            </Col>
            <Col sm={3} xs={12}>
                <BeforeDateFilter />
            </Col>
            <Col sm={4} xs={24}>
                <ShopFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(ShopTasksFilter);

export const defaultShopTasksFilter = {
    common: false
};
