import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path, pathOr } from 'ramda';
import { getDistance } from 'geolib';
import { EnvironmentOutlined } from "@ant-design/icons";

import Time from '../Time';
import CheckinGeopositionInfo from './CheckinGeopositionInfo';
import { getInfoWindow } from 'components/common/cards/Address';
import { openMapModal } from 'actions/modalActions';
import { isCoordsCorrect } from 'utils/coordsValidate';
import { MAX_DISTANCE } from 'constants/geoposition';

@connect(null, { openMapModal })
export default class CheckinTimeInfo extends Component {
    render() {
        const { checkin, out, openMapModal, timezoneOffset } = this.props;

        const time = path([out ? 'out' : 'in', 'date'], checkin);
        const coordinates = path([out ? 'out' : 'in', 'coordinates'], checkin);
        const shopCoords = pathOr({}, ['_embedded', 'shop', 'coordinates'], checkin);
        const isCorrect = isCoordsCorrect(coordinates) && isCoordsCorrect(shopCoords);
        const distance = isCorrect && getDistance(coordinates, shopCoords, 1);

        return time ? (
            <div>
                {coordinates ?
                    (
                        <span className='span-link' onClick={() => openMapModal({
                            ...coordinates,
                            additionalCoords: shopCoords && distance > MAX_DISTANCE && shopCoords.longitude && shopCoords.latitude ?
                                [{ ...shopCoords, infoWindow: getInfoWindow(path(['_embedded', 'shop'], checkin)) }] : [],
                            title: 'Геопозиция'
                        })}>
                            <Time time={time} timezoneOffset={timezoneOffset} />
                        </span>
                    ) : <Time time={time} timezoneOffset={timezoneOffset} />
                }
                <span>
                    <CheckinGeopositionInfo data={checkin} out={out} />
                    <EnvironmentOutlined className="geo-icon" />
                </span>
            </div>
        ) : null;
    }
}
