import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import ProductGroupFilter from './fields/ProductGroupFilter';
import ProductDepartmentFilter from './fields/ProductDepartmentFilter';
import ItemStatusFilter from './fields/ItemStatusFilter';

function CategoryFilter() {
    return (
        <BaseSearchFilter>
            <Col sm={4} xs={12}>
                <ProductGroupFilter />
            </Col>
            <Col sm={4} xs={9}>
                <ProductDepartmentFilter />
            </Col>
            <Col sm={4} xs={12}>
                <ItemStatusFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(CategoryFilter);
