import React, { Component, useState, useCallback } from 'react';
import { Row, Col, Button, Modal, Input, Spin, Pagination } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { pick, path } from 'ramda';
import debounce from 'debounce';

import { getProductsList } from 'actions/asyncActions';
import FormattedPrice from 'components/common/cards/FormattedPrice';

@withAsyncActions({
    getProductsList: getProductsList
        .withPayload(pick(['q', 'offset']))
        .withParams(props => `${path(['q', 'name'], props)}-${path(['offset'], props)}`)
        .withOptions({
            autoFetch: true,
            dispatchOnUpdate: true,
            autoReset: true
        })
})
class ProductList extends Component {
    render() {
        const { data, meta } = this.props.getProductsList;

        const getPaginationConfig = () => {
            const { _meta = {} } = data;
            const { setOffset } = this.props;

            if (!_meta || _meta.count <= _meta.limit) {
                return false;
            }

            return {
                total: _meta.count || 0,
                pageSize: _meta.limit || 10,
                current: this.props.page,
                defaultCurrent: 0,
                onChange: (page, pageSize) => setOffset((page - 1) * pageSize)
            };
        };

        const pagination = getPaginationConfig();

        return (
            <Spin spinning={meta.pending} delay={0}>
                <div className='product-list'>
                    { data && data.items && data.items.map(product => (
                        <Row key={product.id} onClick={() => this.props.onSelect(product)}>
                            <Col span={24} className='product-title'>
                                {product.name}
                            </Col>
                            <Col span={24}><small>{path(['_embedded', 'productCategory', 'name'], product)}</small></Col>
                            <Col span={24} className='product-other'>
                                <span># {product.ean}</span>
                                <span className='pull-right'><FormattedPrice price={product.price} showRub /></span>
                            </Col>
                        </Row>
                    ))}
                    { meta.success && !data.items.length && (
                        <Row>
                            <b>Нет продуктов</b>
                        </Row>
                    )}
                </div>
                <div className='product-list-pagination'>
                    <Pagination {...pagination} showSizeChanger={false} hideOnSinglePage={true} />
                </div>
            </Spin>
        );
    }
}

const SalesProductModal = ({ modal, params }) => {
    const [offset, setOffset] = useState(0);
    const [filter, setFilter] = useState({
        productDepartment: params.department,
    });

    const onClose = () => modal.onCancel();
    const onSelect = (item) => {
        params.onSelect({
            ...item,
            originalPrice: item.price,
            quantity: 1,
        });
        onClose();
    };
    const onSearch = useCallback(name => {
        setOffset(0);
        setFilter({
            productDepartment: params.department,
            name,
        });
    }, [params.department]);
    const onSearchDebounce = useCallback(() => debounce(onSearch, 300), [onSearch])();

    const page = (offset / 10) + 1;

    return (
        <Modal {...modal} title={null} footer={null} closable={false} width='100%' className='search-modal'>
            <div className="search-toolbar">
                <Input.Search
                    onChange={event => onSearchDebounce(event.target.value)}
                    onSearch={name => onSearch(name)}
                    placeholder='Поиск'
                    autoFocus
                    allowClear
                />
                <Button type='link' onClick={onClose}>Отменить</Button>
            </div>
            <ProductList q={filter} offset={offset} page={page} setOffset={setOffset} onSelect={onSelect} />
        </Modal>
    );
}

export default SalesProductModal;
