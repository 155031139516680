import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'antd';

import { getUser } from 'actions/asyncActions';
import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import PromoterShopFilter from './fields/PromoterShopFilter';
import DateFilter from './fields/DateFilter';

function PromoterVisitsFilter({ user: { id } }) {
    return (
        <BaseSearchFilter disableMobileCollapse={true} disableSearch={true}>
            <Col sm={4} xs={24}>
                <PromoterShopFilter promoterId={id} />
            </Col>
            <Col sm={4} xs={24}>
                <DateFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(
    connect(state => ({ user: getUser.selectData(state) }))(
        PromoterVisitsFilter
    )
);
