import React, { Component } from 'react';
import { Modal, Button, DatePicker, Spin, Tag, Table } from 'antd';
import moment from 'moment';
import { asyncConnect } from 'react-async-client';
import { getSalePlanDepartments, putSalePlanDepartments } from 'actions/asyncActions';
import { withState } from 'recompose';
import { pathOr } from 'ramda';

@withState('date', 'setDate', null)
@asyncConnect({
    getSalePlanDepartments: getSalePlanDepartments
        .withPayload(({ date }) => date ? date.toISOString() : null)
        .withParams('copy')
        .withOptions({ dispatchOnUpdate: true, resetOnUnmount: true }),
    putSalePlanDepartments: putSalePlanDepartments
        .withSuccessHandler(({ close }) => close())
})
export default class FocusCategoriesCopyModal extends Component {
    getColumns = () => {
        return [
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                render: (name, item) => <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='focus-category-color' style={{ background: item.color || '#ccc' }} /> { name }
                </div>
            },
            {
                title: 'Департамент',
                align: 'center',
                key: 'productDepartment',
                dataIndex: ['_embedded', 'productDepartment', 'name']
            },
            {
                title: 'Группы',
                key: 'productGroups',
                dataIndex: ['_embedded', 'productGroups'],
                render: (productGroups, item) => (productGroups || []).map(group => <Tag color="geekblue" key={`${item.id}-${group.id}`}>{ group.name }</Tag>)
            }
        ];
    }

    onChange = value => this.props.setDate(value);

    onCopy = () => {
        const { putSalePlanDepartments, getSalePlanDepartments, params } = this.props;
        const departmentPlans = pathOr([], ['data', 'departmentPlans'], getSalePlanDepartments);

        putSalePlanDepartments.dispatch({
            date: params.toISOString(),
            departmentPlans
        });
    }

    render() {
        const { modal, getSalePlanDepartments, date } = this.props;
        const departmentPlans = pathOr([], ['data', 'departmentPlans'], getSalePlanDepartments);

        return (
            <Modal
                {...modal}
                title='Копировать фокусные категории'
                footer={
                    <Button
                        onClick={this.onCopy}
                        type='primary'
                        disabled={!date || !departmentPlans.length || getSalePlanDepartments.meta.pending} >
                        Копировать
                    </Button>
                }>
                <DatePicker.MonthPicker
                    style={{ width: 180 }}
                    value={date ? moment(date) : undefined}
                    format='MMMM'
                    placeholder='Выберите месяц'
                    onChange={this.onChange}
                    getPopupContainer={trigger => trigger.parentNode} />
                { !date ? null : getSalePlanDepartments.meta.pending ?
                    <div style={{ textAlign: 'center', marginTop: 15 }}><Spin /></div> :
                    <Table
                        style={{ marginTop: 15 }}
                        columns={this.getColumns()}
                        dataSource={departmentPlans}
                        pagination={false}
                        rowKey='id' />
                }
            </Modal>
        );
    }
}
