import React, { Component } from 'react';
import { connect } from 'react-redux';

import { openNetworkModal } from 'actions/modalActions';
import { getNetworks } from 'actions/asyncActions';
import { Button } from 'antd';

import withRole from 'components/common/withRole';
import ListTable from 'components/common/ListTable';
import Menu from 'components/common/Menu';
import NetworkFilter from 'filters/NetworkFilter';
import { shopsMenuItems } from 'menus';
import { EditOutlined } from '@ant-design/icons';
import { DELETE_NETWORK, POST_NETWORK, PUT_NETWORK } from 'constants/actionTypes';

@withRole
@connect(null, { openNetworkModal })
export default class Networks extends Component {
    getColumns = isAdmin => [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, item) => isAdmin ? (
                <span className='span-link' onClick={() => this.props.openNetworkModal(item)}>{text}</span>
            ) : (
                text
            ),
        }
    ];

    getMenuControls() {
        const { openNetworkModal, isAdmin } = this.props;

        return isAdmin && (
            <div>
                <Button type='primary' icon={<EditOutlined />} onClick={() => openNetworkModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        const { isAdmin } = this.props;

        return <div>
            <Menu
                items={shopsMenuItems}
                controls={this.getMenuControls()}
            />
            <ListTable
                filterForm={NetworkFilter}
                action={getNetworks}
                refreshActions={[POST_NETWORK, PUT_NETWORK, DELETE_NETWORK]}
                tableColumns={this.getColumns(isAdmin)}
            />
        </div>;
    }
}
