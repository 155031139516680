import React, { Component } from 'react';
import { connect }from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { contains } from 'ramda';

import ListTable from 'components/common/ListTable';
import Address from 'components/common/cards/Address';
import City from 'components/common/cards/City';
import { BindShopFilterWithParam } from 'filters/BindShopFilter';
import { getShops, getPromoter } from 'actions/asyncActions';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';

const checkBind = (values, data) =>
    values.type === 'supervisorShop' ? contains(values.supervisor, data.supervisors) : contains(values.promoter, data.promoters);

const getInitialFilters = props => {
     switch (props.initialValues.type) {
        case 'promoterShop':
            return { supervisor: props.promoter.supervisor };
        case 'promoterSupervisor':
            return { supervisor: props.initialValues.supervisor };
        default:
            return undefined;
     }
}

const stateToProps = state => ({
    promoter: getPromoter.selectData(state)
});

@connect(stateToProps)
export default class ShopBindTable extends Component {
    columns = [
        {
            title: 'Привязать',
            key: 'bind',
            width: 100,
            dataIndex: 'id',
            render: (id, data) => {
                const { values } = this.props;
                const binded = checkBind(values, data);
                return <Button
                            shape='circle'
                            icon={binded ? <CheckOutlined /> : <PlusOutlined />}
                            onClick={() => this.props.select(id)}
                            disabled={binded} />;
            }
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, item) => <Link to={`/shop/${item.id}`}>{text}</Link>,
        }, {
            title: 'Торговая сеть',
            dataIndex: ['_embedded', 'network', 'name'],
            key: 'network'
        }, {
            title: 'Город',
            dataIndex: ['_embedded', 'city'],
            key: 'city',
            render: city => <City city={city} />
        }, {
            title: 'Адрес',
            key: 'address',
            render: shop => <Address item={shop} />
        }
    ];

    render() {
        return (
            <div className='bind-table'>
                <ListTable
                    filterForm={BindShopFilterWithParam('bindFilter')}
                    action={getShops}
                    initialFilters={getInitialFilters(this.props)}
                    tableColumns={this.columns}
                    param='bindTable' />
            </div>
        );
    }
}
