import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import * as yup from 'yup';

import { VALUE_ALREADY_USED } from 'constants/serverMessages';
import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import Input from 'components/common/form/Input';
import { checkError } from 'utils/error';
import MaskedInput from 'components/common/form/MaskedInput';
import { PHONE } from 'constants/regexp';

const phoneValidation = [
    PHONE,
    { message: 'Неверный формат телефона', excludeEmptyString: true }
];

export default class UserModal extends Component {
    static propTypes = {
        ...modalFormPropTypes,
        additionalFields: PropTypes.element,
        additionalValidation: PropTypes.object
    };

    renderForm = formProps => {
        const { additionalFields } = this.props;

        return (
            <div>
                <Field
                    name='lastName'
                    component={Input}
                    label='Фамилия:' />
                <Field
                    name='firstName'
                    component={Input}
                    label='Имя:' />
                <Field
                    name='middleName'
                    component={Input}
                    label='Отчество:' />
                <Field
                    name='phone'
                    placeholder="+7 (999) 999 99 99"
                    component={MaskedInput}
                    label='Телефон:' />
                <Field
                    name='email'
                    component={Input}
                    label='Email:' />
                { additionalFields }
            </div>
        );
    }

    getValidationSchema() {
        const { additionalValidation } = this.props;

        return yup.object().shape({
            firstName: yup.string().trim().required(),
            lastName: yup.string().trim().required(),
            phone: yup.string().nullable().matches(...phoneValidation),
            email: yup.string().trim().required().email(),
            ...additionalValidation
        });
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                editTitle={'Редактировать'}
                validationSchema={this.getValidationSchema()}
                renderForm={this.renderForm}
                onSubmitFail={checkError('email', VALUE_ALREADY_USED)}
            />
        );
    }
}
