import React, { Component } from 'react';
import { withYMaps, Map, Placemark } from 'react-yandex-maps';

const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 220px)'
};

class MapComponent extends Component {
    static defaultProps = {
        additionalCoords: []
    };

    fitBounds = () => {
        const { lng, lat, additionalCoords } = this.props;

        if (additionalCoords.length) {
            const bounds = [];

            bounds.push([lng, lat]);
            additionalCoords.forEach(marker => {
                bounds.push([marker.longitude, marker.latitude]);
            });

            this.mapRef.setBounds(bounds, { checkZoomRange: true })
        }
    }

    render() {
        const { lng, lat, infoWindow, additionalCoords } = this.props;
        const location = lng && lat ? [lng, lat] : undefined;

        return (
            <Map
                instanceRef={ref => this.mapRef = ref}
                style={containerStyle}
                width='100%'
                height='100%'
                defaultState={{
                    center: location,
                    zoom: 16,
                    controls: ['zoomControl']
                }}
                options={{
                    suppressMapOpenBlock: true,
                    yandexMapDisablePoiInteractivity: true,
                }}
                onLoad={this.fitBounds}
            >
                <Placemark
                    geometry={location}
                    properties={infoWindow && {
                        balloonContentHeader: infoWindow.header,
                        balloonContentBody: infoWindow.content,
                    }}
                />
                { additionalCoords.map((item, index) =>
                    <Placemark
                        key={`additional-coords-${index}`}
                        geometry={[item.longitude, item.latitude ]}
                        properties={item.infoWindow && {
                            balloonContentHeader: item.infoWindow.header,
                            balloonContentBody: item.infoWindow.content,
                        }}
                        options={{
                            preset: 'islands#greenDotIconWithCaption'
                        }}
                    />
                )}
            </Map>
        );
    }
}

export default withYMaps(MapComponent, false);
