import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { path } from 'ramda';

import { getUser } from 'actions/asyncActions';
import PersonName from 'components/common/cards/person/PersonName';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import { ShopOutlined, TeamOutlined } from '@ant-design/icons';

const stateToProps = state => {
    const user = getUser.selectData(state);

    return {
        shops: path(['_embedded', 'shops'], user),
        promoters: path(['_embedded', 'promoters'], user)
    };
}

@connect(stateToProps)
export default class MainSupervisor extends Component {
    shopColumns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: 'Торговая сеть',
            dataIndex: ['_embedded', 'network', 'name'],
            key: 'network'
        }, {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address'
        }
    ];

    promoterColumns = [
        {
            dataIndex: 'lastName',
            key: 'icon',
            width: 40,
            render: (text, promoter) => <PersonPhoto person={promoter} />
        },
        {
            title: 'ФИО',
            dataIndex: 'lastName',
            key: 'fullName',
            render: (lastName, promoter) => <PersonName person={promoter} />
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }
    ];

    render() {
        const { shops, promoters } = this.props;

        return (
            <div className='card'>
                <div className='card-title'>
                    <h3>
                        <ShopOutlined />
                        Магазины
                    </h3>
                </div>
                <div className='card-body card-body-no-padding'>
                    <Table
                        className='table-mobile'
                        columns={this.shopColumns}
                        dataSource={shops}
                        rowKey='id'
                        pagination={false} />
                </div>
                <div className='card-title'>
                    <h3>
                        <TeamOutlined />
                        Консультанты
                    </h3>
                </div>
                <div className='card-body card-body-no-padding'>
                    <Table
                        className='table-mobile'
                        columns={this.promoterColumns}
                        dataSource={promoters}
                        rowKey='id'
                        pagination={false} />
                </div>
            </div>
        );
    }
}
