import React, { Component } from 'react';
import { Field } from 'formik';
import { Row, Col, Form, Radio } from 'antd';
import moment from 'moment';

import DatePicker from 'components/common/form/DatePicker';

export default class OrderDateForm extends Component {
    constructor() {
        super();

        this.state = {
            showDate: false
        };
    }

    onToggleDate = e => {
        const { setFieldValue } = this.props;
        const { value } = e.target;

        this.setState({ showDate: value });

        if (!value) {
            setFieldValue('date', null);
        }
    }

    renderRadioGroup() {
        return (
            <Radio.Group value={this.state.showDate} onChange={this.onToggleDate}>
                <Radio.Button value={false}>Сейчас</Radio.Button>
                <Radio.Button value={true}>На дату</Radio.Button>
            </Radio.Group>
        );
    }

    render() {
        const { additionalFields } = this.props;
        const { showDate } = this.state;

        return (
            <div>
                { additionalFields }
                <Row gutter={15}>
                    <Col sm={8}>
                        <Form.Item label='Выполнить рапоряжение'>
                            { this.renderRadioGroup() }
                        </Form.Item>
                    </Col>
                    { showDate &&
                        <Col sm={8}>
                            <Field
                                name='date'
                                component={DatePicker}
                                label='Дата выполнения'
                                placeholder='Выберите дату'
                                disabledDate={date => date && date.isSameOrBefore(moment().format('YYYYMMDD'))} />
                        </Col>
                    }
                </Row>
            </div>
        );
    }
}
