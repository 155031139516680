import React from 'react';
import { Field } from 'formik';

import RangeMonthPicker from 'components/common/form/RangeMonthPicker';

const RangeMonthFilter = ({ name = 'minMonth', to = 'maxMonth', allowClear = true }) => (
    <Field
        name={name}
        to={to}
        component={RangeMonthPicker}
        allowClear={allowClear} />
)

export default RangeMonthFilter;
