import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withAsyncActions } from 'react-async-client';
import { Alert, Modal } from 'antd';
import { pick } from 'ramda';

import Error from 'components/common/Error';
import { getUser, postCheckin, formAutoOptions } from 'actions/asyncActions';
import ShopCheckinTable from './ShopCheckinTable';
import { isCoordsCorrect } from 'utils/coordsValidate';

const stateToProps = state => ({
    user: getUser.selectData(state)
});

@withRouter
@connect(stateToProps, { postCheckin })
@withAsyncActions(({ params }) => ({
    postCheckin: postCheckin
        .withSuccessHandler(({ modal, history }) => {
            modal.onCancel();
            history.push('/checkin');
        })
        .withOptions(formAutoOptions)
}))
export default class CheckinModal extends Component {
    hasCoordinates = () => isCoordsCorrect(this.props.params)

    componentDidMount() {
        this.props.postCheckin.reset();
    }

    renderForm = () => {
        const { user: { supervisor }, postCheckin, params } = this.props;
        const hasCoordinates = this.hasCoordinates();
        const coordinates = pick(['longitude', 'latitude'], params);

        return (
            <div>
                <ShopCheckinTable supervisor={supervisor} coordinates={coordinates} />
                <Error meta={postCheckin.meta} />
                { !hasCoordinates &&
                    <Alert
                        className='text-center mb15'
                        message='GSP-координаты недоступны. Супервайзер будет оповещен об этом.'
                        type='error' />
                }
            </div>
        );
    }

    render() {
        const { modal } = this.props;

        return (
            <Modal {...modal} title='Выберите магазин' footer={null}>
                { this.renderForm() }
            </Modal>
        );
    }
}
