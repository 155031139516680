import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { contains } from 'ramda';

import ListTable from 'components/common/ListTable';
import PersonName from 'components/common/cards/person/PersonName';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import { defaultPromoterSupervisorFilter, PromoterSupervisorFilterWithParam } from 'filters/PromoterSupervisorFilter';
import { getPromotersList } from 'actions/asyncActions';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';

const checkBind = (values, data) =>
    values.type === 'promoterShop' ? contains(values.shop, data.shops) : data.supervisor === values.supervisor;

export default class PromoterBindTable extends Component {
    columns = [
        {
            title: 'Привязать',
            key: 'bind',
            width: 100,
            dataIndex: 'id',
            render: (id, data) => {
                const { values } = this.props;
                const binded = checkBind(values, data);

                return <Button
                            shape='circle'
                            icon={binded ? <CheckOutlined /> : <PlusOutlined />}
                            onClick={() => this.props.select(id)}
                            disabled={binded} />
            }
        },
        {
            key: 'icon',
            width: 40,
            render: person => <PersonPhoto person={person} />
        },
        {
            title: 'ФИО',
            key: 'fullName',
            render: promoter =>
                <Link to={`/users/promoter/${promoter.id}`}><PersonName person={promoter} /></Link>
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }
    ];

    render() {
        return (
            <div className='bind-table'>
                <ListTable
                    filterForm={PromoterSupervisorFilterWithParam('bindFilter')}
                    initialFilters={defaultPromoterSupervisorFilter}
                    action={getPromotersList}
                    tableColumns={this.columns}
                    param='bindTable' />
            </div>
        );
    }
}
