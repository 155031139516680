import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { withAsyncHandlers } from 'react-async-client';
import { Form } from 'antd';
import * as yup from 'yup';
import { forEachObjIndexed, path, toLower } from 'ramda';
import withFormErrors from './withFormErrors';
import { ERRORS } from 'constants/serverMessages';
import moment from 'moment';

const defaultValidationSchema = yup.object();

const getServerError = (values, e) => {
    const fields = path(['data', 'errors'], e);
    const fieldErrors = {};

    if (fields) {
        forEachObjIndexed((errors, field) => {
            errors.forEach(error => {
                const template = ERRORS[toLower(error.message_template || '')] || ERRORS[toLower(error.message || '')];

                let message = error.message;

                if (template) {
                    let month = path(['{month}'], error.attributes);
                    let date = path(['{date}'], error.attributes);

                    message = template
                        .replace('$property', field)
                        .replace('$value', values[field]);

                    if (month) {
                        message = message.replace('"{month}"', moment(month).format('MMMM YYYY'));
                    }

                    if (date) {
                        message = message.replace('"{date}"', moment(date).format('DD.MM.YYYY'));
                    }

                    if (error.constraints) {
                        error.constraints.forEach((value, index) => {
                            message = message.replace(`$constraint${index + 1}`, value);
                        });
                    }
                }

                fieldErrors[field] = message;
            });
        }, fields);
    }

    return fieldErrors;
};

@withFormErrors
@withFormik({
    validationSchema: props => props.validationSchema || defaultValidationSchema,
    mapPropsToValues: props => props.initialValues,
    handleSubmit: (values, { props: { formAction, mapBeforeSubmit } }) => {
        const dispatch = formAction.dispatch || formAction;
        dispatch(mapBeforeSubmit ? mapBeforeSubmit(values) : values);
    }
})
@withAsyncHandlers({
    formAction: {
        successHandler: props => {
            props.setSubmitting(false);
            props.onSubmitSuccess && props.onSubmitSuccess(props)
        },
        errorHandler: props => {
            props.setErrors(getServerError(props.values, props.formAction.meta.error));
            props.setSubmitting(false);
            props.onSubmitFail && props.onSubmitFail(props);
        },
    }
})
export default class BaseForm extends Component {
    static propTypes = {
        validationSchema: PropTypes.object,
        initialValues: PropTypes.object,
        onSubmitFail: PropTypes.func,
        onSubmitSuccess: PropTypes.func,
        formAction: PropTypes.oneOfType([
            PropTypes.object.isRequired,
            PropTypes.func.isRequired,
        ]),
        formMeta: PropTypes.object,
        renderForm: PropTypes.func.isRequired,
        renderFooter: PropTypes.func,
    }

    componentWillMount() {
        const { setStatus, validationSchema } = this.props;

        if (validationSchema) {
            setStatus({ validationSchema: validationSchema.fields });
        }
    }

    handleSubmit = (e) => {
        const { status } = this.props;

        this.props.setStatus({
            ...status,
            isSubmitStarted: true
        });

        this.props.handleSubmit(e);
    }

    render() {
        const { renderForm : Fields } = this.props;

        return (
            <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Fields {...this.props} handleSubmit={this.handleSubmit} />
            </Form>
        );
    }
}
