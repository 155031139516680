import { all, take, put, takeEvery, select } from 'redux-saga/effects';
import { toSuccess, toError } from 'react-async-client';

import { LOGIN, LOGOUT, GET_USER, POST_REFRESH_TOKEN } from 'constants/actionTypes';
import { handleTokenChange } from 'utils/token';
import { getUser } from 'actions/asyncActions';

function* loginFlow() {
    while (true) {
        yield take([toSuccess(LOGIN)]);
        yield put(getUser());

        yield take([LOGOUT, toError(GET_USER)]);
        const meta = yield select(getUser.selectMeta);
        if (!meta.error) {
            yield put(getUser.reset());
        }
        handleTokenChange(null);
    }
}

export default function* login() {
    yield all([
        loginFlow(),
        takeEvery([toSuccess(LOGIN), toSuccess(POST_REFRESH_TOKEN)], function doRedirect({ payload }) {
            handleTokenChange(payload.token, payload.refresh_token);
        })
    ]);
}
