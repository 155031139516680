import React from 'react';
import { Badge, Tooltip } from 'antd';
import { path } from 'ramda';

import CheckinTimeInfo from './CheckinTimeInfo';

const CheckinTime = ({ checkin = {}, timezoneOffset }) => {
    const inTime = path(['in', 'date'], checkin);
    const outTime = path(['out', 'date'], checkin);

    if (!inTime) {
        return null;
    }

    return !outTime ? (
        <Tooltip title="В магазине">
            <span>
                <Badge className="badge-no-text" status="processing" />
            </span>
        </Tooltip>
    ) : (
        <CheckinTimeInfo checkin={checkin} out={true} timezoneOffset={timezoneOffset} />
    );
}

export default CheckinTime;
