import React from 'react';
import cx from 'classnames';
import { EnvironmentOutlined } from '@ant-design/icons';

export const getAddressString = (item) => `${item.address || ''}${item.addressComment ? `, ${item.addressComment}` : ''}`;

export const getInfoWindow = (shop = {}) => ({
    header: shop.name,
    content: getAddressString(shop),
});

const Address = ({ item, className }) => item ? (
    <span className={cx('address-element', className)}>
        <EnvironmentOutlined />
        {getAddressString(item)}
    </span>
) : null;

export default Address;
