import { pushRollbarError } from 'utils/rollbar';

const rollbarMiddleware = store => next => action => {
    try {
        return next(action);
    } catch(e) {
        pushRollbarError(e);
        throw e;
    }
};

export default rollbarMiddleware;
