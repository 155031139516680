import React, { Component } from 'react';
import { Modal, Button, Alert } from 'antd';
import { getDistance } from 'geolib';

import { GEOPOSITION_ERRORS } from 'constants/geoposition';
import Spinner from 'components/common/Spinner';
import { isCoordsCorrect } from 'utils/coordsValidate';
import { MAX_DISTANCE } from 'constants/geoposition';
import DistanceTag from '../common/cards/DistanceTag';

export default class CoordinatesLoader extends Component {
    constructor() {
        super();

        this.state = {
            latitude: null,
            longitude: null,
            showAcceptButton: false,
            error: null
        };
    }

    componentDidMount() {
        this.reload();
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    onSuccess(coordinates) {
        const { params: { onSuccess }} = this.props;

        onSuccess(coordinates);
    }

    load() {
        if ('geolocation' in navigator) {
            setTimeout(() => this.toggleShowButton(), 5 * 1000);

            navigator.geolocation.getCurrentPosition(
                ({ coords }) => this.setCoordinates(coords.latitude, coords.longitude, { timeout: 10000 }),
                error => this.setState({
                    error: GEOPOSITION_ERRORS[error.code] || `${GEOPOSITION_ERRORS.error}: ${error.code}`
                })
            );
        } else {
            this.setState({
                error: GEOPOSITION_ERRORS.error,
            });
        }

        this.timer = setTimeout(() => this.toggleShowButton(), 10000);
    }

    setCoordinates = (latitude, longitude) => {
        this.setState({ latitude, longitude });
    }

    reload = () => {
        this.setState({ error: null });
        this.toggleShowButton(false);
        this.setCoordinates();
        this.load();
    }

    toggleShowButton = (showAcceptButton = true) => {
        this.setState({ showAcceptButton });
    }

    renderFooter() {
        return <Button type='primary' onClick={this.reload}>Обновить</Button>;
    }

    renderWithCoords = () => {
        const { params: { shopCoords = {}, checkout }} = this.props;
        const { longitude, latitude } = this.state;
        const coords = { longitude, latitude };
        const isCorrect = isCoordsCorrect(coords) && isCoordsCorrect(shopCoords);

        const distance = isCorrect && getDistance(coords, shopCoords, 1);

        if (distance > MAX_DISTANCE) {
            return <div>
                <Alert className='text-center' type='warning' message='Координаты не совпали' />
                <br />
                <p>
                    Расстояние до магазина
                    <DistanceTag className="ml10" distance={distance} />
                </p>
                <Button onClick={() => this.onSuccess(coords)}>
                    { checkout ? 'Все равно выйти' : 'Все равно отметиться' }
                </Button>
            </div>
        } else {
            return <div>
                {isCorrect ?
                    <Alert className='text-center' message='Вы в магазине' type='success' showIcon />
                    : <Alert className='text-center' type='warning' message='Координаты некорректные' />
                }
                <br />
                <Button onClick={() => this.onSuccess(coords)}>
                    { checkout ? 'Выйти' : 'Отметиться' }
                </Button>
            </div>;
        }
    }

    render() {
        const { modal, params: { checkout }} = this.props;
        const { showAcceptButton, error, longitude, latitude } = this.state;

        return (
            <Modal
                {...modal}
                className='text-center coordinates-loader'
                width={350}
                footer={this.renderFooter()}
            >
                { longitude && latitude ? (
                    this.renderWithCoords()
                ) : (
                    <div>
                        {error ? (
                            <div>
                                <Alert className='text-center' type='error' message={error} />
                                <br />
                            </div>
                        ) : (
                            <div>
                                <div>Получаем геопозицию...</div>
                                <Spinner />
                            </div>
                        )}
                        <Button onClick={() => this.onSuccess({})} disabled={!(showAcceptButton || error)}>
                            { checkout ? 'Выйти без координат' : 'Отметиться без координат' }
                        </Button>
                    </div>
                )}
            </Modal>
        );
    }
}
