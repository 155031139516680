import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';

import PromoterBindTable from '../tables/PromoterBindTable';
import ShopBindTable from '../tables/ShopBindTable';
import BindModal from '../BindModal';
import { postInstruction, formAutoOptions } from 'actions/asyncActions';

@withAsyncActions({ postInstruction }, formAutoOptions)
export default class PromoterSupervisorBindModal extends Component {
    getInitialValues() {
        const { params: { id }} = this.props;

        return {
            supervisor: id,
            type: 'promoterSupervisor'
        };
    }

    onSubmitSuccess = ({ values }) => {
        const { dispatch } = this.props.postInstruction;

        dispatch({ ...values, type: 'promoterShop' });
    }

    render() {
        return (
            <BindModal
                {...this.props}
                title='Добавить консультанта'
                initialValues={this.getInitialValues()}
                steps={[
                    { title: 'Выбор консультанта', table: PromoterBindTable, path: 'promoter' },
                    { title: 'Выбор магазина', table: ShopBindTable, path: 'shop' }
                ]}
                onSubmitSuccess={this.onSubmitSuccess}
            />
        );
    }
}
