import React, { Component } from 'react';

import ListTable from 'components/common/ListTable';
import { getSalePlansStat } from 'actions/asyncActions';
import { defaultRegionPlansFilter, RegionPlansFilter } from 'filters/PlansFilter';
import planColumns from '../planColumns';
import CacheDate from './CacheDate';

@planColumns
export default class RegionPlans extends Component {
    getColumns() {
        return [
            {
                title: 'Регион',
                dataIndex: ['_embedded', 'region', 'name'],
                fixed: 'left',
                width: 150,
                key: 'region'
            }, {
                title: 'Сеть',
                dataIndex: ['_embedded', 'network', 'name'],
                fixed: 'left',
                width: 150,
                key: 'network'
            },
            {
                title: 'Проект',
                dataIndex: ['_embedded', 'productDepartment', 'name'],
                fixed: 'left',
                width: 150,
                key: 'productDepartment'
            },
            ...this.props.planColumns
        ];
    }

    render() {
        const { saga, setDate, planDate } = this.props;

        return (
            <ListTable
                tableColumns={this.getColumns()}
                action={getSalePlansStat}
                filterForm={RegionPlansFilter}
                initialFilters={{
                    ...defaultRegionPlansFilter,
                    date: planDate
                }}
                scroll={{ x: 'max-content' }}
                className='th-multi'
                saga={saga}
                setDate={setDate}
                setProductDepartments={this.props.setProductDepartments}
                staticPayload={{ limit: 0 }}
                hidePagination
                bordered
                footer={({ _meta }) => <CacheDate meta={_meta} /> }
            />
        );
    }
}
