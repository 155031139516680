import React, { Component } from 'react';

import TaskForm from './TaskForm';
import HeadBlock from 'components/common/cards/HeadBlock';

export default class CreateTask extends Component {
    render() {
        return (
            <div className='detail-card'>
                <HeadBlock title='Добавление задачи' />
                <TaskForm />
            </div>
        );
    }
}
