import { put, takeEvery } from 'redux-saga/effects';

import { getToken, getRefreshToken } from 'utils/token';
import { login } from 'actions/asyncActions';
import { APP_RUN } from 'constants/actionTypes';

export default function* watchAppRun() {
    yield takeEvery(APP_RUN, function* appRun() {
        const token = getToken();
        const refreshToken = getRefreshToken();

        if (token) {
            yield put(login.success({
                token,
                refresh_token: refreshToken
            }));
        }
    });
}
