import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormItem
export class RadioGroupComponent extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        options: PropTypes.array,
        path: PropTypes.string
    };

    static defaultProps = {
        options: [],
        path: 'id'
    };

    renderOptions() {
        const { options, path } = this.props;

        return options.map(option =>
            <Radio.Button key={option[path]} value={option[path]}>
                { option.name }
            </Radio.Button>
        );
    }

    onChange = e => {
        this.props.onChange(e.target.value);
    }

    render() {
        const { value, className } = this.props;

        return (
            <Radio.Group
                value={value}
                className={className}
                onChange={this.onChange}>
                { this.renderOptions() }
            </Radio.Group>
        );
    }
}

export default withFormikField(RadioGroupComponent);
