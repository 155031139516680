import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getProductDepartments } from 'actions/asyncActions';

const ProductDepartmentFilter = ({ multiple }) => (
    <Field
        name={multiple ? 'productDepartments' : 'productDepartment'}
        component={SearchSelect}
        placeholder='Департамент'
        multiple={multiple}
        allowClear={true}
        action={getProductDepartments} />
)

export default ProductDepartmentFilter;
