import React, { Component } from 'react';
import { Button, Popconfirm, message } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined, EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { pathOr } from 'ramda';
import { asyncConnect } from 'react-async-client';
import moment from 'moment';

import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import { getSalesList, deleteSale, formAutoOptions, importSales } from 'actions/asyncActions';
import { openSaleEditModal, openImportModal } from 'actions/modalActions';
import SalesFilter from 'filters/SalesFilter';
import PersonName from 'components/common/cards/person/PersonName';
import ProductCategory from 'components/common/cards/product/ProductCategory';
import ProductName from 'components/common/cards/product/ProductName';
import ShopName from 'components/common/cards/shop/ShopName';
import Date from 'components/common/cards/Date';
import Price from 'components/common/cards/Price';
import FormattedPrice, { toPriceFormat } from 'components/common/cards/FormattedPrice';
import { POST_SALE, PUT_SALE, DELETE_SALE, IMPORT_SALES } from 'constants/actionTypes';
import { isMobile } from 'constants/screen';
import SalesMobile from './SalesMobile';

@withRole
@asyncConnect({
    deleteSale: deleteSale
        .withSuccessHandler(() => message.success('Продажа успешно удалена.'))
        .withErrorHandler(() => message.error('Не удалось удалить продажу.'))
        .withOptions(formAutoOptions),
}, null, { openSaleEditModal, openImportModal })
class Sales extends Component {
    constructor(props) {
        super(props);

        this.initialFilters = {
            minDate: moment().add(-1, 'months').utc().startOf('day').toDate(),
            maxDate: moment().utc().endOf('day').toDate(),
        };
    }

    getColumns = isClient => [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: date => <Date date={date} />
        }, {
            title: 'Продукт',
            dataIndex: ['_embedded', 'product'],
            key: 'product',
            render: product => <ProductName product={product} />
        }, {
            title: 'Категории',
            dataIndex: '',
            key: 'category',
            render: name => <ProductCategory category={name} />,
            className: 'td-hide-mobile'
        }, {
            title: 'Магазин',
            key: 'shop',
            dataIndex: ['_embedded', 'shop'],
            render: shop => <ShopName shop={shop} />
        }, {
            title: 'Консультант',
            dataIndex: ['_embedded', 'promoter'],
            key: 'promoter',
            render: promoter => <PersonName person={promoter} />,
            hide: this.props.isPromoter
        }, {
            key: 'sale-price',
            render: item => <Price sale={item} />,
            className: 'td-hide-desktop'
        }, {
            title: 'Сумма',
            dataIndex: '',
            key: 'sum',
            render: ({ price, quantity }) => (
                <div>
                    <div className="text-bold"><FormattedPrice price={price * quantity} /></div>
                    <small className="text-nowrap"><FormattedPrice price={quantity} /> &times; <FormattedPrice price={price} /></small>
                </div>
            ),
            className: 'td-hide-mobile'
        },
        {
            align: 'right',
            render: sale => !isClient && (
                <div>
                    <Popconfirm
                        title='Вы уверены, что хотите удалить продажу?'
                        okText='Да'
                        cancelText='Нет'
                        placement='left'
                        onConfirm={() => this.deleteSale(sale.id)}>
                        <Button type='link' icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                    <Button
                        icon={<EditOutlined />}
                        type='link'
                        onClick={() => this.props.openSaleEditModal(sale)}
                    />
                </div>
            )
        }
    ];

    deleteSale = id => {
        this.props.deleteSale.dispatch(id);
    }

    render() {
        const { isSupervisor, isPromoter, isClient, openImportModal, isAdmin } = this.props;

        const filterControls = <div>
            { (isSupervisor || isPromoter) && (
                <div className='pull-right mobile-filter-button'>
                    <Link to='/sales/add'>
                        <Button type='primary' icon={<PlusOutlined />}>
                            Внести продажи
                        </Button>
                    </Link>
                </div>
            )}
            { isAdmin &&
                <Button
                    style={{ marginLeft: 10 }}
                    type='success'
                    icon={<UploadOutlined />}
                    onClick={() => openImportModal({
                        title: 'Импортировать продажи',
                        action: importSales,
                        withoutMonth: true
                    })}>
                    Импорт
                </Button>
            }
        </div>;

        return (
            <div className='table-mobile'>
                <ListTable
                    filterForm={SalesFilter}
                    filterControls={filterControls}
                    action={getSalesList}
                    refreshActions={[POST_SALE, PUT_SALE, DELETE_SALE, IMPORT_SALES]}
                    tableColumns={this.getColumns(isClient)}
                    initialFilters={this.initialFilters}
                    staticPayload={{
                        sort_by: 'date',
                        sort_order: 'desc'
                    }}
                    footer={({ _meta }) => _meta ? <span>Всего продано <strong>{pathOr(0, ['totalQuantity'], _meta)}</strong> шт. на <strong>{toPriceFormat(pathOr(0, ['totalSum'], _meta), true) || 0}</strong> р.</span> : null} />
            </div>
        );
    }
}

export default isMobile() ? SalesMobile : Sales;
