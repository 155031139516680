import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';

import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import FormattedPrice from 'components/common/cards/FormattedPrice';
import { usersMenuItems } from 'menus';
import { getGradesList, importGrades } from 'actions/asyncActions';
import { openGradeModal, openImportModal } from 'actions/modalActions';
import { POST_GRADE, PUT_GRADE, DELETE_GRADE } from 'constants/actionTypes';
import GradesFilter, { defaultFilter } from 'filters/GradesFilter';

@withRole
@connect(null, { openGradeModal, openImportModal })
export default class Grades extends Component {
    columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, item) => (
                <span className='span-link' onClick={() => this.props.openGradeModal(item)}>{text}</span>
            )
        }, {
            title: 'Код',
            dataIndex: 'code',
            key: 'code'
        }, {
            title: 'Зарплата',
            dataIndex: 'salary',
            key: 'salary',
            render: (price) => <FormattedPrice price={price} />
        }
    ];

    getMenuControls() {
        const { openImportModal, openGradeModal } = this.props;

        return (
            <div>
                <Button
                    type='success'
                    icon={<FileExcelOutlined />}
                    onClick={() => openImportModal({
                        title: 'Импортировать должности',
                        action: importGrades,
                        withoutMonth: true
                    })}>
                    Импорт
                </Button>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openGradeModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Menu
                    items={usersMenuItems(this.props.accessToFinances)}
                    controls={this.getMenuControls()} />
                <ListTable
                    filterForm={GradesFilter}
                    initialFilters={defaultFilter}
                    action={getGradesList}
                    refreshActions={[POST_GRADE, PUT_GRADE, DELETE_GRADE]}
                    tableColumns={this.columns} />
            </div>
        );
    }
}
