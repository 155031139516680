import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { Route } from 'react-router-dom';
import { path, pathOr } from 'ramda';

import withRole from 'components/common/withRole';
import HeadBlock from 'components/common/cards/HeadBlock';
import { fullAutoOptions } from 'constants/asyncClient';
import { openShopModal } from 'actions/modalActions';
import { getShop, putShop, deleteShop, postInstruction } from 'actions/asyncActions';
import ShopInfoTab from './ShopInfoTab';
import ShopWorkersTab from './ShopWorkersTab';
import Menu from 'components/common/Menu';
import { shopMenuItems } from 'menus';
import { EditOutlined } from '@ant-design/icons';

const getParams = path(['match', 'params']);

@withRole
@connect(null, { openShopModal })
@withAsyncActions({
    getShop: getShop
        .withPayload(getParams)
        .withOptions(fullAutoOptions),
    putShop: putShop.withSuccessHandler(props => props.getShop.refresh()),
    postInstruction: postInstruction.withSuccessHandler(props => props.getShop.refresh()),
    deleteShop: deleteShop.withSuccessHandler(({ history }) => history.push('/shops')),
})
export default class ShopCard extends Component {
    openEditModal = () => {
        const { getShop: { data }, openShopModal } = this.props;
        openShopModal(data);
    }

    render() {
        const { getShop: { meta, data }, match: { params: { id } }, isAdmin } = this.props;

        const title = pathOr('', ['_embedded', 'network', 'name'], data);
        const headControls = isAdmin && <Button icon={<EditOutlined />} onClick={this.openEditModal}>Редактировать</Button>;

        return (
            <Spin spinning={meta.pending}>
                <div className='detail-card'>
                    <HeadBlock title={title} inscription={data.address} controls={headControls} />
                    <Menu items={shopMenuItems(id)} />
                    <Route path='/shop/:id' exact component={ShopInfoTab} />
                    <Route path='/shop/:id/workers' component={ShopWorkersTab} />
                </div>
            </Spin>
        );
    }
}
