import React, { Component } from 'react';
import moment from 'moment';
import { Row, Col, Button, Dropdown, Radio, Menu, message, InputNumber, Input, Tooltip } from 'antd';
import { any, append, assocPath, compose, concat, filter, find, findIndex, includes, path, pathOr, pick, prop, propEq, range, sortBy, uniqBy } from 'ramda';
import { asyncConnect, toSuccess } from 'react-async-client';
import cx from 'classnames';
import { withState } from 'recompose';
import debounce from 'debounce';

import ListTable, { rowEvenOddClassName } from 'components/common/ListTable';
import TimetableStatusMenu from 'components/common/form/table/TimetableStatusMenu';
import EditInput from 'components/common/form/table/EditInput';
import { getProductDepartments, getSalePlanDepartments, getSalePlans, getSalePlansStat, getUser, importPlans, putSalePlansWithoutRefresh } from 'actions/asyncActions';
import { openPlanPromoterModal, openImportModal, openPlanCommentModal } from 'actions/modalActions';
import { defaultPlansFilter, ShopPlansFilter } from 'filters/PlansFilter';
import PersonName from '../../../common/cards/person/PersonName';
import { EditOutlined, EyeOutlined, FileExcelOutlined, PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { takeEvery } from '@redux-saga/core/effects';
import { DELETE_SALE_PLANS, GET_SALE_PLANS, IMPORT_SALE_PLANS, POST_SALE_PLANS, PUT_SALE_PLANS } from 'constants/actionTypes';
import TIMETABLE_STATUSES from 'constants/timetableStatuses';
import { formAutoOptions, fullAutoOptions } from 'constants/asyncClient';
import FormattedPrice, { toPriceFormat } from '../../../common/cards/FormattedPrice';
import { debounceDelay } from 'constants/debounce';
import withRole from 'components/common/withRole';
import { renderComplete as renderCompleteCommon } from 'constants/plans';
import withPlanDate from '../withPlanDate';
import ExcelDownload from 'components/common/ExcelDownload';
import { SALE_PLANS } from 'constants/urls';
import CacheDate from './CacheDate';

export const dayOfTheWeek = (day, month, year) => {
    return new Date(year, month, day).toLocaleString('ru-RU', {
        weekday: 'short'
    });
};

const isWeekend = (date, month, year) => {
    const day = new Date(year, month, date).getDay();

    return day === 0 || day === 6;
}

export const renderComplete = (departmentPlans, item, factNumber) => {
    const planNumber = pathOr(null, ['amount'], find(propEq('id', item.id), departmentPlans || []));

    return planNumber ? `${(factNumber * 100 / planNumber).toFixed(2)} %` : null;
}

const stateToProps = state => ({
    departmentPlans: pathOr([], ['items', 0, 'departmentPlans'], getSalePlans.withParams('listTable').selectData(state)),
    shops: pathOr([], ['_embedded', 'shops'], getUser.selectData(state)),
    user: getUser.selectData(state),
    productDepartments: getProductDepartments.withParams({ name: 'dict', disableRelations: true }).selectData(state)
});

class PlanInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };

        this.savePlan = debounce(this.savePlan, debounceDelay);
    }

    savePlan = value => {
        const { onChange, item, departmentPlan } = this.props;

        onChange(item, departmentPlan, value);
    }

    onChange = (value) => {
        this.setState({ value });
        this.savePlan(value);
    }

    sumPlans = () => {
        this.onChange(this.props.plansSum);
    }

    render() {
        return this.props.common ?
            <Input.Group compact style={{ display: 'flex', alignItems: 'center' }}>
                <InputNumber value={this.state.value} size='small' onChange={this.onChange} />
                <Tooltip title="Просуммировать фокусные планы">
                    <Button style={{ height: 30 }} icon={<PlusOutlined />} onClick={this.sumPlans} />
                </Tooltip>
            </Input.Group> : <InputNumber value={this.state.value} size='small' onChange={this.onChange} />;
    }
}

@withRole
@withState('salePlans', 'setSalePlans', [])
@withState('productDepartmentsFilter', 'setProductDepartmentsFilter', [])
@withPlanDate
@asyncConnect({
    putSalePlansWithoutRefresh: putSalePlansWithoutRefresh
        .withErrorHandler(() => message.error('Ошибка сохранения данных'))
        .withOptions(formAutoOptions),
    putSalePlansFactWithoutRefresh: putSalePlansWithoutRefresh
        .withParams('fact')
        .withErrorHandler(() => message.error('Ошибка сохранения данных'))
        .withSuccessHandler((props, action) => {
            const item = action.payload;
            const index = findIndex(propEq('id', item.id), props.salePlans);

            props.setSalePlans(compose(
                assocPath([index, 'statistic'], item.statistic),
            )(props.salePlans));
        })
        .withOptions(formAutoOptions),
    getSalePlanDepartments: getSalePlanDepartments
        .withPayload(({ planDate }) => planDate.toISOString())
        .withOptions(fullAutoOptions),
    getSalePlansStatPromShop: getSalePlansStat
        .withPayload(({ planDate }) => ({
            q: {
                date: planDate,
                type: 'promoter_shop'
            },
            limit: 0
        }))
        .withParams('promoter_shop')
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(IMPORT_SALE_PLANS), toSuccess(POST_SALE_PLANS), toSuccess(PUT_SALE_PLANS), toSuccess(DELETE_SALE_PLANS)], function (action) {
                const { getSalePlansStatPromShop } = getProps();

                getSalePlansStatPromShop.refresh();
            })
        })
        .withOptions(fullAutoOptions),
}, stateToProps, { openPlanPromoterModal, openImportModal, openPlanCommentModal })
export default class Plans extends Component {
    state = {
        showTimetable: false,
        mode: 'preview'
    };

    toggleTimetable = () => {
        this.setState(prev => ({ showTimetable: !prev.showTimetable }));
    }

    changeTimetable = (item, day, status, hours) => {
        const index = findIndex(propEq('id', item.id), this.props.salePlans);
        const value = {
            ...item,
            dayReports: assocPath([day], status === 'clean' ? {} : { status, hours }, item.dayReports || [])
        };

        this.props.setSalePlans(assocPath([index], value, this.props.salePlans));
        this.props.putSalePlansFactWithoutRefresh.dispatch(value);
    }

    editPlan = (item, plan, amount) => {
        const index = findIndex(propEq('id', item.id), this.props.salePlans);
        const itemIndex = findIndex(propEq('id', plan.id), item.departmentPlans || []);
        const oldPlan = itemIndex > -1 ? item.departmentPlans[itemIndex] : {};
        const newPlan = { ...oldPlan, ...plan, amount };
        const value = {
            ...item,
            departmentPlans: itemIndex > -1 ? assocPath([itemIndex], newPlan, item.departmentPlans || []) : append(newPlan, item.departmentPlans || [])
        };

        this.props.setSalePlans(assocPath([index], value, this.props.salePlans));
        this.props.putSalePlansWithoutRefresh.dispatch(value);
    }

    editPlanMin = (item, plan, minAmount) => {
        const index = findIndex(propEq('id', item.id), this.props.salePlans);
        const itemIndex = findIndex(propEq('id', plan.id), item.departmentPlans || []);
        const bonusPercents = path(['salePlanBonusOptions', 'percents'], plan);
        const oldPlan = itemIndex > -1 ? item.departmentPlans[itemIndex] : {};
        const newPlan = { ...oldPlan, ...plan, minAmount, bonusPercents };
        const value = {
            ...item,
            departmentPlans: itemIndex > -1 ? assocPath([itemIndex], newPlan, item.departmentPlans || []) : append(newPlan, item.departmentPlans || [])
        };

        this.props.setSalePlans(assocPath([index], value, this.props.salePlans));
        this.props.putSalePlansWithoutRefresh.dispatch(value);
    }

    editCommonPlan = (item, _, amount) => {
        const index = findIndex(propEq('id', item.id), this.props.salePlans);

        this.props.setSalePlans(assocPath([index], {
            ...item, amount
        }, this.props.salePlans));
        this.props.putSalePlansWithoutRefresh.dispatch({
            ...item, amount
        });
    }

    editField = (item, field, value) => {
        const index = findIndex(propEq('id', item.id), this.props.salePlans);

        this.props.setSalePlans(assocPath([index], {
            ...item, [field]: value
        }, this.props.salePlans));
        this.props.putSalePlansWithoutRefresh.dispatch({
            ...item, [field]: value
        });
    }

    changeComment = (item, day, value) => {
        this.props.openPlanCommentModal({
            value,
            onSubmit: comment => {
                const index = findIndex(propEq('id', item.id), this.props.salePlans);
                const newValue = {
                    ...item,
                    dayReports: assocPath([day], { ...pathOr({}, [day], item.dayReports || []), comment }, item.dayReports || [])
                };

                this.props.setSalePlans(assocPath([index], newValue, this.props.salePlans));
                this.props.putSalePlansWithoutRefresh.dispatch(newValue);
            }
        });
    }

    getDepartmentPlans = () => {
        return filter(item => path(['productDepartmentsFilter', 'length'], this.props) ? includes(item.productDepartment, this.props.productDepartmentsFilter) : true, this.props.getSalePlanDepartments.data.departmentPlans || []);
    }

    getFactValue = (promoter, id) => {
        const { getSalePlansStatPromShop: { data } } = this.props;
        const item = find(item => item.promoter === promoter.promoter && item.shop === promoter.shop && item.productDepartment === promoter.productDepartment, data.items || []);
        const sum = item ? path(['salesFact'], find(propEq('id', id), item.departmentPlans || [])) : null;

        return sum;
    }

    getPromoterStat = promoter => {
        const { getSalePlansStatPromShop: { data } } = this.props;
        const item = find(item => item.promoter === promoter.promoter && item.shop === promoter.shop && item.productDepartment === promoter.productDepartment, data.items || []);

        return item || {};
    }

    getIgnoredPromoters = (shop, productDepartment, promoter) => {
        const items = pathOr([], ['children'], find(propEq('shop', shop), this.getDataSource()));

        return filter(item => item.productDepartment === productDepartment && item.id !== promoter, items).map(prop('promoter'));
    }

    getColumns = isClient => {
        const year = this.props.planDate.getFullYear();
        const month = this.props.planDate.getMonth();
        const monthDays = new Date(year, month + 1, 0).getDate();
        const isLoading = this.props.putSalePlansFactWithoutRefresh.meta.pending;

        const addPromoterCol = {
            key: 'add',
            width: 63,
            fixed: 'left',
            render: item => {
                const menu = <Menu>
                    <Menu.ItemGroup title='Проект'>
                        {pathOr([], ['items'], this.props.productDepartments).map(productDepartment =>
                            <Menu.Item
                                onClick={() => this.props.openPlanPromoterModal({
                                    shop: item.shop,
                                    supervisor: item.supervisor,
                                    date: this.props.planDate,
                                    region: item.region,
                                    network: item.network,
                                    productDepartment: productDepartment.id,
                                    ignored: this.getIgnoredPromoters(item.shop, productDepartment.id)
                                })}
                                key={productDepartment.id}>
                                {productDepartment.name}
                            </Menu.Item>
                        )}
                    </Menu.ItemGroup>
                </Menu>;

                return item.promoter ? null : <Dropdown overlay={menu} trigger={['click']}>
                    <Button
                        icon={<UserAddOutlined />}
                        type='primary' />
                </Dropdown>;
            }
        };
        const nameProjectColumns = [
            {
                title: 'Магазин',
                dataIndex: ['_embedded', 'shop', 'name'],
                key: 'shop',
                width: 200,
                fixed: 'left',
                render: (name, item) => {
                    return item.promoter ? {
                        props: {
                            colSpan: 2
                        },
                        children: <div>
                            {this.state.mode === 'preview' ? <PersonName person={path(['_embedded', 'promoter'], item)} /> :
                                <div
                                    className='shop-plans-promoter-link'
                                    onClick={() => this.props.openPlanPromoterModal({ ...item, ignored: this.getIgnoredPromoters(item.shop, item.productDepartment, item.id) })}>
                                    <PersonName person={path(['_embedded', 'promoter'], item)} />
                                </div>
                            }
                        </div>
                    } : name
                }
            }, {
                title: 'Сеть',
                dataIndex: ['_embedded', 'network', 'name'],
                key: 'network',
                width: 120,
                fixed: 'left',
                render: (name, item) => {
                    return item.promoter ? {
                        props: {
                            rowSpan: 0
                        }
                    } : name
                }
            }, {
                title: 'Регион',
                dataIndex: ['_embedded', 'region', 'name'],
                key: 'region',
                width: 120,
                fixed: 'left',
                render: (name, item) => {
                    return item.promoter ? path(['_embedded', 'productDepartment', 'name'], item) : name
                }
            }
        ];
        const commonColumn = {
            title: 'Общий',
            className: 'border-left',
            children: [
                {
                    title: 'План',
                    dataIndex: 'amount',
                    key: 'commonPlan',
                    className: 'border-left mw100',
                    render: (value, props) => props.promoter ? <FormattedPrice price={value} /> : null
                }, {
                    title: 'Факт',
                    dataIndex: 'salesFact',
                    key: 'commonFact',
                    className: 'mw100',
                    render: (value, props) => {
                        if (props.promoter) {
                            const salesFact = this.getPromoterStat(props).salesFact;

                            return salesFact ? toPriceFormat(salesFact) : null;
                        }

                        return null;
                    }
                }, {
                    title: 'Выполнено',
                    key: 'commonComplete',
                    className: 'mw100',
                    render: data => {
                        return data.promoter ? renderCompleteCommon(this.getPromoterStat(data), 'amount', 'salesFact') : null;
                    }
                }
            ]
        };
        const daysHoursColumns = [
            {
                title: 'Кол-во дней',
                children: [
                    {
                        title: 'План',
                        key: 'days-plan',
                        width: 100,
                        align: 'center',
                        render: item => {
                            if (item.promoter) {
                                return isEdit ?
                                    <EditInput
                                        number
                                        value={item.planWorkingDays}
                                        onChange={this.editField}
                                        item={item}
                                        field='planWorkingDays' /> :
                                    item.planWorkingDays;
                            }

                            return null;
                        }
                    },
                    {
                        title: 'Факт',
                        key: 'month',
                        width: 100,
                        align: 'center',
                        className: `th-timetable border-right ${isLoading && 'cell-loading'}`,
                        dataIndex: ['statistic', 'countWorkingDays'],
                    }
                ]
            },
            {
                title: 'Кол-во часов',
                children: [
                    {
                        title: 'План',
                        key: 'hours-plan',
                        width: 100,
                        align: 'center',
                        render: item => {
                            if (item.promoter) {
                                return isEdit ?
                                    <EditInput
                                        number
                                        value={item.planWorkingHours}
                                        onChange={this.editField}
                                        item={item}
                                        field='planWorkingHours' /> :
                                    item.planWorkingHours;
                            }

                            return null;
                        }
                    },
                    {
                        title: 'Факт',
                        key: 'hours-fact',
                        width: 100,
                        align: 'center',
                        className: isLoading && 'cell-loading',
                        dataIndex: ['statistic', 'countWorkingHours'],
                    }
                ]
            }
        ];

        const isEdit = this.state.mode === 'edit';
        const columns = [
            {
                title: 'ФИО / Проект',
                fixed: 'left',
                children: this.state.mode === 'preview' ? nameProjectColumns : append(addPromoterCol, nameProjectColumns),

            },
            ...(this.state.showTimetable ? range(1, monthDays + 1).map((day, index) => ({
                title: <div className='timetable-columns' style={{ textAlign: 'center', fontWeight: 600 }}>
                    {index === 0 && <div className='btn-timetable hide-timetable' style={{ width: (monthDays) * 35 + 1 }} onClick={this.toggleTimetable}>скрыть табель</div>}
                    <div className='day-of-num'>{day}</div>
                    <div className='day-of-week'>{dayOfTheWeek(day, month, year)}</div>
                </div>,
                width: 35,
                key: `day-${day}`,
                dataIndex: [`dayReports`, day - 1],
                className: isWeekend(day, month, year) ? 'weekend-column shop-plans-timetable-day-column' : 'shop-plans-timetable-day-column',
                render: (item = {}, props) => {
                    if (!props.promoter) {
                        return null;
                    }

                    const data = find(propEq('status', path(['status'], item)), TIMETABLE_STATUSES) || {};

                    const cell = (
                        <div className={cx('shop-plans-timetable-day-cell', { 'filled': !!(item.hours || data.short), 'has-comment': !!item.comment })} style={{ backgroundColor: data.hexColor, color: data.fontColor }}>
                            {item.hours || data.short}
                        </div>
                    );

                    return !isClient ? (
                        <TimetableStatusMenu
                            data={data}
                            item={item}
                            row={props}
                            cell={data}
                            day={day}
                            dayReports={props.dayReports}
                            changeComment={this.changeComment}
                            changeTimetable={this.changeTimetable}
                        >
                            {cell}
                        </TimetableStatusMenu>
                    ) : cell;
                }
            })) : [{
                title: <div className='timetable-columns show-timetable-column'>
                    <div className='btn-timetable show-timetable' onClick={this.toggleTimetable}>показать табель</div>
                    { moment(this.props.planDate).format('MMMM') }
                </div>,
                children: daysHoursColumns
            }]),
            ...(this.state.showTimetable ? daysHoursColumns : []),
            {
                title: 'Рабочее время',
                key: 'hours-plan',
                width: 120,
                render: item => {
                    if (item.promoter) {
                        return isEdit ?
                        <EditInput
                            value={item.workingSchedule}
                            onChange={this.editField}
                            item={item}
                            field='workingSchedule' /> :
                        item.workingSchedule;
                    }

                    return null;
                }
            },
            {
                title: 'Перерыв',
                key: 'hours-plan',
                width: isEdit ? 170 : 120,
                render: item => {
                    if (item.promoter) {
                        return isEdit ?
                            <EditInput
                                text
                                value={item.workingBreak}
                                onChange={this.editField}
                                item={item}
                                field='workingBreak' /> :
                            item.workingBreak;
                    }

                    return null;
                }
            },
            ...(isEdit ? [{
                title: 'План',
                className: 'border-left mw100',
                children: [
                    ...this.getDepartmentPlans()
                        .map(departmentPlan => ({
                            title: departmentPlan.name,
                            key: departmentPlan.name,
                            width: 200,
                            align: 'center',
                            className: 'border-left border-right',
                            render: item => item.promoter ? (item.productDepartment === departmentPlan.productDepartment ?
                                departmentPlan.salePlanBonusOptions && departmentPlan.salePlanBonusOptions.enabled ?
                                    <Row gutter={10}>
                                        <Col xs={12}>
                                            <PlanInput
                                                onChange={this.editPlanMin}
                                                value={path(['minAmount'], find(propEq('id', departmentPlan.id), item.departmentPlans || []))}
                                                item={item}
                                                departmentPlan={departmentPlan} />
                                        </Col>
                                        <Col xs={12}>
                                            <PlanInput
                                                onChange={this.editPlan}
                                                value={path(['amount'], find(propEq('id', departmentPlan.id), item.departmentPlans || []))}
                                                item={item}
                                                departmentPlan={departmentPlan} />
                                        </Col>
                                    </Row>
                                    : <PlanInput
                                        onChange={this.editPlan}
                                        value={path(['amount'], find(propEq('id', departmentPlan.id), item.departmentPlans || []))}
                                        item={item}
                                        departmentPlan={departmentPlan} />
                            : null) : null
                        })),
                    {
                        title: 'Общий',
                        key: 'common-plan',
                        width: 200,
                        align: 'center',
                        className: 'border-left border-right',
                        render: item => {
                            if (item.promoter) {
                                return <PlanInput
                                    onChange={this.editCommonPlan}
                                    value={item.amount}
                                    item={item}
                                    plansSum={(item.departmentPlans || []).reduce((res, cur) => res + (cur.amount || 0), 0)}
                                    common />
                            }

                            return null;
                        }
                    }
                ]
            }] : this.getDepartmentPlans().map((item, index) => {
                return {
                    title: item.name,
                    className: 'border-right border-left',
                    children: [
                        {
                            title: 'План',
                            dataIndex: ['departmentPlans'],
                            key: `${item.name}Plan`,
                            render: (departmentPlans, props) => {
                                const value = props.promoter ? path(['amount'], find(propEq('id', item.id), departmentPlans || [])) : path(['amount'], find(propEq('productDepartment', item.productDepartment), departmentPlans || []));
                                return props.promoter ? <FormattedPrice price={value} /> : null;
                            },
                            align: 'center',
                            className: 'border-left mw100'
                        }, {
                            title: 'Факт',
                            dataIndex: ['departmentPlans'],
                            key: `${item.name}Fact`,
                            className: 'mw100',
                            render: (departmentPlans, props) => {
                                if (props.promoter) {
                                    const sum = this.getFactValue(props, item.id);

                                    return sum ? toPriceFormat(sum) : null;
                                }

                                return null;
                            },
                            align: 'center'
                        }, {
                            title: 'Выполнено',
                            key: `${item.name}Complete`,
                            render: data => {
                                return data.promoter ? renderComplete(data.departmentPlans, item, this.getFactValue(data, item.id)) : null
                            },
                            align: 'center',
                            className: 'border-right mw100'
                        }
                    ]
                };
            }))
        ];

        return isEdit ? columns : append(commonColumn, columns);
    }

    toggleMode = e => this.setState({ mode: e.target.value });

    getDataSource = () => {
        let data = filter(item => !item.promoter, this.props.salePlans);

        if (this.props.isSupervisor) {
            data = concat(
                data,
                filter(shop => !any(plan => plan.shop === shop.id && !plan.promoter, data), this.props.shops).map(shop => ({
                    shop: shop.id,
                    network: shop.network,
                    region: shop.region,
                    supervisor: this.props.user.id,
                    _embedded: {
                        shop,
                        network: path(['_embedded', 'network'], shop),
                        region: path(['_embedded', 'region'], shop)
                    }
                }))
            );
        } else {
            const additionalShops = filter(item => !any(propEq('shop', item.shop), data), uniqBy(prop('shop'), this.props.salePlans)).map(pick(['shop', 'network', 'region', 'supervisor', '_embedded']));

            data = concat(data, additionalShops);
        }

        return sortBy(path(['_embedded', 'shop', 'name']), data.map(item => ({
            ...item,
            children: sortBy(path(['_embedded', 'productDepartment', 'name']), filter(i => i.shop === item.shop && i.promoter && (path(['productDepartmentsFilter', 'length'], this.props) ? includes(i.productDepartment, this.props.productDepartmentsFilter) : true), this.props.salePlans))
        })));
    }

    renderPlanTemplateBtn = (_, date) => {
        return <div style={{ textAlign: 'center', marginTop: 15 }}>
            { date ?
                <ExcelDownload block query={{ ...defaultPlansFilter, date }} params={{ template: 1 }} url={SALE_PLANS} title='Скачать шаблон' /> :
                'Выберите месяц для получения шаблона'
            }
        </div>;
    }

    render() {
        const { isClient, getSalePlansStatPromShop } = this.props;

        const filterControls = !isClient && (
            <div className='pull-right mobile-filter-button' style={{ display: 'flex' }}>
                <Button
                    type='success'
                    icon={<FileExcelOutlined />}
                    onClick={() => this.props.openImportModal({
                        title: 'Импортировать планы',
                        action: importPlans,
                        month: this.props.date,
                        renderAdditional: this.renderPlanTemplateBtn
                    })}>
                    Импорт
                </Button>
                <Radio.Group
                    value={this.state.mode}
                    onChange={this.toggleMode}
                    options={[
                        { label: <EyeOutlined />, value: 'preview' },
                        { label: <EditOutlined />, value: 'edit' }
                    ]}
                    optionType='button' />
            </div>
        );

        const saga = function* (getProps) {
            yield takeEvery([GET_SALE_PLANS], () => {
                getProps().setDate(moment(getProps().state.q.date).toDate());
                getProps().setProductDepartmentsFilter(getProps().state.q.productDepartments);
            });

            yield takeEvery([toSuccess(GET_SALE_PLANS)], () => {
                getProps().setSalePlans(getProps().action.data.items || []);
            });
        };

        const dataSource = this.getDataSource();

        return (
            <div className='shop-plans-table'>
                <ListTable
                    tableColumns={dataSource.length ? this.getColumns(isClient) : []}
                    action={getSalePlans}
                    filterForm={ShopPlansFilter}
                    initialFilters={{
                        ...defaultPlansFilter,
                        date: this.props.planDate
                    }}
                    scroll={{ x: 'max-content', y: 'calc(100vh - 330px)' }}
                    className='th-multi plan-table'
                    filterControls={filterControls}
                    setDate={this.props.setPlanDate}
                    setProductDepartmentsFilter={this.props.setProductDepartmentsFilter}
                    refreshActions={[IMPORT_SALE_PLANS, POST_SALE_PLANS, PUT_SALE_PLANS, DELETE_SALE_PLANS]}
                    saga={saga}
                    setSalePlans={this.props.setSalePlans}
                    dataSource={dataSource}
                    staticPayload={{ limit: 0 }}
                    hidePagination
                    rowKey='id'
                    rowClassName={(item, index) => item && item.id ? rowEvenOddClassName(item, index) : ''}
                    bordered
                    {...(path(['data', '_meta', 'factGeneratedAt'], getSalePlansStatPromShop) ? {
                        footer: () => <CacheDate meta={path(['data', '_meta'], getSalePlansStatPromShop)} />
                    } : {})}
                />
            </div>
        );
    }
}
