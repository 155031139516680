import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { path } from 'ramda';
import moment from 'moment';

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormItem
export class RangeMonthPicker extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        to: PropTypes.string,
        placeholder: PropTypes.array
    };

    onChangeRange = range => {
        const { form: { setFieldValue }, field: { name }, to } = this.props;

        if (!range) {
            setFieldValue(name, undefined);
            setFieldValue(to, undefined);
        } else {
            const [fromDate, toDate] = range;

            setFieldValue(name, fromDate.utc().startOf('month'));
            setFieldValue(to, toDate.utc().endOf('month'));
        }
    }

    render() {
        const name = path(['form', 'values', this.props.field.name], this.props);
        const to = path(['form', 'values', this.props.to], this.props);

        return (
            <DatePicker.RangePicker
                value={name && to ? [moment(name).utc(), moment(to).utc()] : []}
                placeholder={['с', 'по']}
                picker='month'
                format={['MMMM', 'MMMM']}
                onChange={this.onChangeRange}
                allowClear={this.props.allowClear} />
        );
    }
}

export default withFormikField(RangeMonthPicker);
