import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { Button } from 'antd';
import { isEmpty } from 'ramda';
import { LoginOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import moment from 'moment';

import { getUser, postCheckin } from 'actions/asyncActions';
import { openCoordinatesLoader } from 'actions/modalActions';

const getParams = (id, coordinates) => ({
    date: moment().utc(true).startOf('day').toDate(),
    shop: id,
    in: {
        date: moment().utc().toDate(),
        coordinates: isEmpty(coordinates) || !coordinates ? null : coordinates
    }
});

const stateToProps = state => ({
    user: getUser.selectData(state)
});

@withRouter
@connect(stateToProps, { openCoordinatesLoader })
@withAsyncActions({
    postCheckin: postCheckin
        .withSuccessHandler(({ history }) => {
            history.push('/checkin');
        })
        .withOptions({ resetOnUnmount: true })
})
export default class ShopCheckinCreate extends Component {
    static propTypes = {
        shopId: PropTypes.string.isRequired,
        openCoordinatesLoader: PropTypes.func.isRequired,
        postCheckin: PropTypes.object.isRequired,
        shopCoords: PropTypes.object
    }

    onSuccess = (coords) => {
        const { postCheckin, shopId } = this.props;

        postCheckin.dispatch(getParams(shopId, coords));
    }

    render() {
        const { openCoordinatesLoader, checkin, shopCoords } = this.props;

        return checkin ? (
            <Button
                style={{ marginTop: 10 }}
                onClick={() => openCoordinatesLoader({ onSuccess: this.onSuccess, shopCoords, setDistance: this.setDistance })}
                type="primary"
                block
                icon={<LoginOutlined />}>
                Чекин
            </Button>
        ) : (
            <Button onClick={() => openCoordinatesLoader({ onSuccess: this.onSuccess, shopCoords })} type="primary">Отметиться</Button>
        );
    }
}
