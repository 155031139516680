import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getCities } from 'actions/asyncActions';

const CityFilter = ({ param, multiple }) => (
    <Field
        name={multiple ? 'cities' : 'city'}
        component={SearchSelect}
        placeholder='Город'
        multiple={multiple}
        allowClear={true}
        action={getCities}
        filter={({ regions, region }) => ({
            [regions && (regions.length > 1) ? 'regions' : 'region']: region || (regions && (regions.length === 1) ? regions[0] :  regions),
        })}
        param={param} />
)

export default CityFilter;
