import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import withRole from 'components/common/withRole';

@withRole
export default class UserRoute extends Component {
    render() {
        const { access, role, redirect, ...rest } = this.props;
        const available = access ? access.includes(role) : true;

        return available ? <Route {...rest} /> : <Redirect to={redirect || '/'} />;
    }
}
