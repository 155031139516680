import { assocPath } from 'ramda';

const FORMATTERS = [
    {
        pattern: /product_categories/,
        decode: data => {
            if (!data.items) return data;

            const items = data.items.map(item =>
                assocPath(['_embedded', 'productGroup', 'productDepartment'], item.productDepartment, item)
            );
            return { ...data, items };
        }
    },
    {
        pattern: /calendar/,
        decode: data => {
            if (!data.holidays || !data.holidays.length) return data;

            return {
                ...data,
                holidays: data.holidays.map(date => date.split('T')[0]),
            };
        }
    }
];

export default FORMATTERS;
