import React from 'react';
import { useState } from 'react';

import FormServerErrorsContext from '../../contexts/FormServerErrorsContext';

const withFormErrors = WrappedComponent => props => {
    const [errors, setErrors] = useState(null);

    return <FormServerErrorsContext.Provider value={{
        errors,
        setErrors
    }}>
        <WrappedComponent {...props} />
    </FormServerErrorsContext.Provider>
};

export default withFormErrors;
