import React from 'react';

import Range from './Range';
import Date from './Date';

export default function RangeDate({ from, to }) {
    return (
        <Range
            from={<Date date={from} />}
            to={to && <Date date={to} />}
        />
    );
}
