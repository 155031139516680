import React from 'react';

import BackButton from '../BackButton';

const HeadBlock = ({ title, inscription, controls, avatar, hideBack }) => (
    <h1 className="head-bar">
        { !hideBack && <div className="back-block"><BackButton /></div> }
        { avatar && (
            <div className="avatar-block">
                { avatar }
            </div>
        )}
        { inscription ? (
            <div className="title-block">
                <span className="title-text">{title}</span>
                <br/>
                <div className="title-inscription">{inscription}</div>
            </div>
        ) : (
            <span className="title-block">
                {title}
            </span>
        )}
        <div className="edit-block">
            { controls }
        </div>
    </h1>
);

export default HeadBlock;
