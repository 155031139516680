export const fullAutoOptions = {
    dispatchOnMount: true,
    dispatchOnUpdate: true,
    resetOnUnmount: true
};

export const autoFetchOptions = {
    dispatchOnMount: true
};

export const formAutoOptions = {
    resetOnUnmount: true
};
