import React from 'react';
import { Collapse } from 'antd';

import { isMobile } from 'constants/screen';

export default function MobileCollapse({ header = 'Показать', children }) {
    return isMobile() ? (
        <Collapse bordered={false}>
            <Collapse.Panel header={header}>
                {children}
            </Collapse.Panel>
        </Collapse>
    ) : (
        <div>{children}</div>
    );
}
