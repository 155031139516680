import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';

import { getClient, postClientPhoto, deleteClientPhoto } from 'actions/asyncActions';
import { openClientModal } from 'actions/modalActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { PUT_CLIENT } from 'constants/actionTypes';
import createPutSaga from 'utils/putSagaCreator';
import UserHeadBar from 'components/common/cards/user/UserHeadBar';
import UserInfo from 'components/common/cards/user/UserInfo';
import withRole from 'components/common/withRole';

@withRole
@connect(null, { openClientModal })
@withAsyncActions({
    getClient: getClient
        .withPayload(path(['match', 'params']))
        .withOptions(fullAutoOptions)
        .withSaga(createPutSaga(PUT_CLIENT, 'getClient'))
})
export default class ClientCard extends Component {
    render() {
        const { getClient, getClient: { data }, openClientModal, isAdmin } = this.props;

        return (
            <div className="detail-card user-card">
                <UserHeadBar actionResult={getClient} tagText="Клиент" tagColor="red" edit={() => openClientModal(data)} />
                <UserInfo
                    user={data}
                    postPhotoAction={postClientPhoto}
                    deletePhotoAction={deleteClientPhoto}
                    refresh={() => getClient.refresh()}
                    disabledPhotoEdit={!isAdmin} />
            </div>
        );
    }
}
