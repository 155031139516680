import { pathOr } from 'ramda';

export const renderComplete = (data, plan, fact, withoutPercent) => {
    const planNumber = pathOr(null, plan.split('.'), data);
    const factNumber = pathOr(0, fact.split('.'), data);

    return planNumber ? (withoutPercent ? (factNumber * 100 / planNumber).toFixed(0) : `${(factNumber * 100 / planNumber).toFixed(2)} %`) : null;
}

export const PLAN_STATUS = [
    { id: 1, name: 'Выполнено' },
    { id: 2, name: 'Не выполнено' }
];
