import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { path, isEmpty, pathOr } from 'ramda';

import withRoles from 'components/common/withRoles';
import PersonName from '../person/PersonName';
import InfoBlock from '../InfoBlock';
import Photo from './Photo';
import { InfoCircleOutlined, SolutionOutlined } from '@ant-design/icons';
import { renderRegions } from '../../../scenes/users/lists/MarketingCoordinators';

@withRoles
export default class UserInfo extends Component {
    static propTypes = {
        user: PropTypes.object,
        postPhotoAction: PropTypes.func.isRequired,
        deletePhotoAction: PropTypes.func.isRequired,
        refresh: PropTypes.func,
        disabledPhotoEdit: PropTypes.bool,
        isClient: PropTypes.bool,
    };

    renderField(title, fieldPath) {
        const { user } = this.props;
        const field = path(fieldPath.split('.'), user);

        return field &&
            <Row>
                <Col span={5} xs={8}>{title}</Col>
                <Col span={19} xs={16}>{field}</Col>
            </Row>;
    }

    render() {
        const { user, isClient, postPhotoAction, deletePhotoAction, refresh, disabledPhotoEdit } = this.props;
        const supervisor = path(['_embedded', 'supervisor'], user);
        const photo = path(['_embedded', 'photo', 0], user);

        return (
            <div className="info">
                <InfoBlock iconType={<InfoCircleOutlined />} title="Основная информация">
                    <Row>
                        <Col sm={18} xs={24}>
                            { this.renderField('Фамилия', 'lastName') }
                            { this.renderField('Имя', 'firstName') }
                            { this.renderField('Отчество', 'middleName') }
                            { supervisor &&
                                <Row>
                                    <Col span={5} xs={8}>Супервайзер</Col>
                                    <Col span={19} xs={16}>
                                        <Link to={`/users/supervisor/${supervisor.id}`}>
                                            <PersonName person={supervisor} />
                                        </Link>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        { !isEmpty(user) &&
                            <Col sm={6} xs={24}>
                                <Photo
                                    user={user}
                                    photo={photo}
                                    postAction={postPhotoAction}
                                    deleteAction={deletePhotoAction}
                                    refresh={refresh}
                                    disabled={disabledPhotoEdit} />
                            </Col>
                        }
                    </Row>
                </InfoBlock>
                { !isClient && (
                        <InfoBlock iconType={<SolutionOutlined />} title="Контакты">
                        <Row>
                            <Col sm={18}>
                                { this.renderField('Регион', '_embedded.region.name') }
                                { !!(user.regions || []).length &&
                                    <Row>
                                        <Col span={5} xs={8}>Регионы</Col>
                                        <Col span={19} xs={16}>{renderRegions(pathOr([], ['_embedded', 'regions'], user))}</Col>
                                    </Row>
                                }
                                { this.renderField('Город', '_embedded.city.name') }
                                { this.renderField('Должность', '_embedded.grade.0.name') }
                                { this.renderField('Телефон', 'phone') }
                                { this.renderField('Email', 'email') }
                            </Col>
                        </Row>
                    </InfoBlock>
                )}
            </div>
        );
    }
}
