import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Field } from 'formik';

import {
    postAdmin,
    putAdmin,
    formAutoOptions,
} from 'actions/asyncActions';
import SwitchInput from 'components/common/form/SwitchInput';
import withRole from 'components/common/withRole';
import UserModal from './UserModal';

@withRole
@withAsyncActions({
    postAction: postAdmin,
    putAction: putAdmin
}, formAutoOptions)
export default class AdminModal extends Component {
    getAdminField() {
        return <Field
            name='accessToFinances'
            component={SwitchInput}
            label='Финансы:' />;
    }

    render() {
        return <UserModal
            {...this.props}
            mapBeforeSubmit={(values) => ({
                ...values,
                accessToFinances: !!values.accessToFinances,
            })}
            createTitle={'Добавить администратора'}
            additionalFields={this.props.isSuperAdmin && this.getAdminField()}
        />;
    }
}
