import React from 'react';
import { Field } from 'formik';

import Select from 'components/common/form/Select';
import { PHOTO_REPORTS_TYPES } from 'constants/photoReports';

const PhotoReportFilter = () => (
    <Field
        name='type'
        component={Select}
        placeholder='Тип отчета'
        options={PHOTO_REPORTS_TYPES}
        allowClear={true}
        path='id' />
)

export default PhotoReportFilter;
