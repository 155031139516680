import React, { Component } from 'react';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';
import * as yup from 'yup';
import { Button, message } from 'antd';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import Input from 'components/common/form/Input';
import NumberInput from 'components/common/form/NumberInput';
import {
    postGrade,
    putGrade,
    deleteGrade,
    putGradeRestore,
    formAutoOptions,
} from 'actions/asyncActions';

const validationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    code: yup.string().trim().required(),
    salary: yup.number().required()
});

@withAsyncActions({
    postAction: postGrade,
    putAction: putGrade,
    deleteAction: deleteGrade,
    putProductsRestore: putGradeRestore
        .withSuccessHandler(({ close }) => {
            message.success('Продукт успешно перемещен из архива');
            close();
        })
        .withErrorHandler(() => message.error('Не удалось переместить продукт из архива'))
        .withOptions({ resetOnUnmount: true })
}, formAutoOptions)
export default class BrandModal extends Component {
    static propTypes = modalFormPropTypes;

    renderForm = formProps => {
        return (
            <div>
                <Field
                    name='name'
                    component={Input}
                    label='Название:'
                    placeholder='Введите название' />
                <Field
                    name='code'
                    component={Input}
                    label='Код:'
                    placeholder='Введите код' />
                <Field
                    name='salary'
                    component={NumberInput}
                    min={0}
                    label='Зарплата:'
                    placeholder='Введите зарплату' />
            </div>
        );
    }

    restore = () => {
        this.props.putProductsRestore.dispatch(this.props.params.id);
    }

    renderFooterControls = () => {
        return path(['status'], this.props.params) === 'archive' &&
            <Button onClick={this.restore}>
                Разархивировать
            </Button>;
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                createTitle={'Добавить должность'}
                editTitle={'Редактировать должность'}
                deleteBtnText={'Удалить должность'}
                validationSchema={validationSchema}
                renderForm={this.renderForm}
                footerControls={this.renderFooterControls}
                hideSubmit={path(['status'], this.props.params) === 'archive'}
            />
        );
    }
}
