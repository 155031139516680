import React, { Component } from 'react';
import { Col, Input, Row, Popover } from 'antd';
import { path, isEmpty } from 'ramda';

import withFormItem from './withFormItem';
import withFormikField from './withFormikField';
import SearchMap from './SearchMap';
import { isMobile } from 'constants/screen';
import { CloseOutlined, GlobalOutlined } from '@ant-design/icons';

const clearExcess = (coords) => {
    const isEmptyLongitude = !coords.longitude || isEmpty(coords.longitude);
    const isEmptyLatitude = !coords.latitude || isEmpty(coords.latitude);

    return isEmptyLongitude && isEmptyLatitude ? null : coords;
};

class InputCoordinatesComponent extends Component {
    state = {
        longitude: path(['value', 'longitude'], this.props),
        latitude: path(['value', 'latitude'], this.props),
        mapOpened: false
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            longitude: path(['value', 'longitude'], nextProps),
            latitude: path(['value', 'latitude'], nextProps)
        });
    }

    closeMap = () => this.setState({ mapOpened: false })

    openMap = () => this.setState({ mapOpened: true })

    onChangeLongitude = ({ target: { value } }) => {
        this.onChange({
            longitude: value,
            latitude: this.state.latitude
        });

        this.setState({ longitude: value });
    }

    onChangeLatitude = ({ target: { value } }) => {
        this.onChange({
            longitude: this.state.longitude,
            latitude: value
        });

        this.setState({ latitude: value });
    }

    onChange = (value) => {
        const { form, onChange } = this.props;
        onChange(clearExcess(value), form);
    }

    renderMap = () => {
        const { onChange, searchBoxValue, coordinates } = this.props;
        return(
            <SearchMap
                onChange={onChange}
                searchBoxValue={searchBoxValue}
                coordinates={coordinates}
                closeMap={this.closeMap}
                loadingElement={<div className="map-loading-elem" />}
                containerElement={<div className="map-container" />}
                mapElement={<div className="map-element" />} />
        )
    }

    renderTitle = () => {
        return (
            <div className="map-title">
                <span>Найдите адрес на карте</span>
                <CloseOutlined className="close" onClick={this.closeMap} />
            </div>
        )
    }

    render() {
        return (
            <div className="input-coordinates">
                <Row gutter={10}>
                    <Col xs={20} sm={7}>
                        <Input
                            value={this.state.latitude}
                            placeholder="Широта"
                            onChange={this.onChangeLatitude} />
                    </Col>
                    <Col xs={20} sm={7}>
                        <Input
                            value={this.state.longitude}
                            placeholder="Долгота"
                            onChange={this.onChangeLongitude} />
                    </Col>
                    <Col xs={4} sm={2}>
                        <Popover
                            arrowPointAtCenter={true}
                            overlayClassName="map-popover"
                            content={this.state.mapOpened && this.renderMap()}
                            title={this.renderTitle()}
                            visible={this.state.mapOpened}
                            trigger="click"
                            placement={isMobile() ? 'top' : 'topRight'} >
                            <GlobalOutlined
                                className="map-icon"
                                onClick={this.openMap} />
                        </Popover>
                    </Col>
                </Row>
            </div>

        )
    }
}

export default withFormikField(withFormItem(InputCoordinatesComponent));
