import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAsyncActions } from 'react-async-client';
import { Route } from 'react-router-dom';

import { path } from 'ramda';

import SupervisorInfoCard from './supervisor/SupervisorInfoCard';
import SupervisorShopsCard from './supervisor/SupervisorShopsCard';
import SupervisorPromotersCard from './supervisor/SupervisorPromotersCard';
import { getSupervisor } from 'actions/asyncActions';
import { openSupervisorModal, openDismissalModal } from 'actions/modalActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { PUT_SUPERVISOR } from 'constants/actionTypes';
import createPutSaga from 'utils/putSagaCreator';
import UserHeadBar from 'components/common/cards/user/UserHeadBar';

@connect(null, { openSupervisorModal, openDismissalModal })
@withAsyncActions({
    getSupervisor: getSupervisor
        .withPayload(path(['match', 'params']))
        .withOptions(fullAutoOptions)
        .withSaga(createPutSaga(PUT_SUPERVISOR, 'getSupervisor'))
})
export default class SupervisorCard extends Component {
    render() {
        const { getSupervisor, getSupervisor: { data }, openSupervisorModal, openDismissalModal } = this.props;

        return (
            <div className="detail-card user-card">
                <UserHeadBar
                    actionResult={getSupervisor}
                    tagText="Супервайзер"
                    tagColor="green"
                    edit={() => openSupervisorModal(data)}
                    del={() => openDismissalModal(data)}
                />
                <Route path='/users/supervisor/:id' exact render={props => <SupervisorInfoCard {...props} action={getSupervisor} />} />
                <Route path='/users/supervisor/:id/shops' component={SupervisorShopsCard} />
                <Route path='/users/supervisor/:id/promoters' exact component={SupervisorPromotersCard} />
            </div>
        );
    }
}
