import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions } from 'react-async-client';
import { Steps, Button, message } from 'antd';
import { pathOr } from 'ramda';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import { postInstruction, formAutoOptions } from 'actions/asyncActions';
import OrderDateForm from 'components/common/form/OrderDateForm';
import { checkError } from 'utils/error';
import { DATE_IN_PAST, ERRORS } from 'constants/serverMessages';

@withAsyncActions({
    postAction: postInstruction
}, formAutoOptions)
export default class BindModal extends Component {
    static propTypes = {
        ...modalFormPropTypes,
        initialValues: PropTypes.object,
        title: PropTypes.string,
        steps: PropTypes.arrayOf(
            PropTypes.shape({
                table: PropTypes.func.isRequired,
                title: PropTypes.string.isRequired,
                path: PropTypes.string.isRequired
            })
        )
    };

    static defaultProps = {
        steps: []
    };

    constructor(props) {
        super(props);

        this.state = {
            step: 0
        };
    }

    renderForm = formProps => {
        const { step } = this.state;
        const steps = this.getSteps(formProps);

        return (
            <div>
                <Steps current={step}>
                    { steps.map((step, index) =>
                        <Steps.Step key={index} title={step.title} />
                    )}
                </Steps>
                { steps[step].component }
            </div>
        );
    }

    getSteps(formProps) {
        const { steps } = this.props;

        return [
            ...steps.map(step => ({
                title: step.title,
                component: <step.table {...formProps} select={id => this.select(id, formProps, step.path)} />
            })),
            {
                title: 'Выбор распоряжения',
                component: <OrderDateForm {...formProps} />
            }
        ];
    }

    select = (id, { setFieldValue }, path) => {
        setFieldValue(path, id);
        this.setState(prev => ({ step: prev.step + 1 }));
    }

    goBack = ({ setFieldValue }, path) => {
        setFieldValue(path, null);
        this.setState(prev => ({ step: prev.step - 1 }));
    }

    renderBackButton = formProps => {
        const { steps } = this.props;
        const { step } = this.state;
        const path = pathOr(null, [step - 1, 'path'], steps);

        return step ? <Button onClick={() => this.goBack(formProps, path)}>Назад</Button> : null;
    }

    onSubmitSuccess = props => {
        if (props.formAction.data.status === 'fail') {
            message.error(ERRORS[props.formAction.data.errorMessage]);
        }

        this.props.onSubmitSuccess && this.props.onSubmitSuccess(props);
    }

    render() {
        const { title, initialValues } = this.props;
        const { step } = this.state;

        return (
            <ModalForm
                {...this.props}
                createTitle={title}
                initialValues={initialValues}
                renderForm={this.renderForm}
                footerControls={this.renderBackButton}
                disabled={!step}
                onSubmitFail={checkError('date', DATE_IN_PAST)}
                onSubmitSuccess={this.onSubmitSuccess}
            />
        );
    }
}
