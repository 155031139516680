import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getProductCategories } from 'actions/asyncActions';

const ProductCategoryFilter = ({ values = {}, multiple }) => (
    <Field
        name={multiple ? 'productCategories' : 'productCategory'}
        component={SearchSelect}
        placeholder='Линейка'
        multiple={multiple}
        allowClear={true}
        filter={({ productGroup, productGroups, productDepartment, productDepartments }) => ({
            [productGroups && (productGroups.length > 1) ? 'productGroups' : 'productGroup']:
                productGroup || (productGroups && (productGroups.length === 1) ? productGroups[0] :  productGroups),
            [productDepartments && (productDepartments.length > 1) ? 'productDepartments' : 'productDepartment']:
                productDepartment || (productDepartments && (productDepartments.length === 1) ? productDepartments[0] :  productDepartments),
        })}
        action={getProductCategories} />
)

export default ProductCategoryFilter;
