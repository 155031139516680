import React from 'react';

import InstructionsBase from './InstructionsBase';
import { InstructionFilter } from 'filters/InstructionFilter';

const AllInstructions = () => (
    <InstructionsBase
        filterForm={InstructionFilter} />
);

export default AllInstructions;
