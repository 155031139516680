import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getShops } from 'actions/asyncActions';
import { renderShopItem } from './ShopFilter';

const PromoterShopFilter = ({ promoterId }) => (
    <Field
        name='shop'
        component={SearchSelect}
        placeholder='Магазин'
        allowClear={true}
        action={getShops}
        filter={{ promoter: promoterId }}
        renderItem={renderShopItem}
        param='filter' />
)

export default PromoterShopFilter;
