export const TASK_TYPES = [
    { value: true, name: 'Общая' },
    { value: false, name: 'Для магазина' }
];

export const TASK_PRIORITIES = [
    { value: 'high', name: 'Высокий', color: 'red' },
    { value: 'medium', name: 'Обычный', color: 'yellow' },
    { value: 'low', name: 'Низкий', color: 'green' }
];

export const TASK_STATUSES = [
    { id: 1, name: 'Выполнено' },
    { id: 2, name: 'В процессе' },
    { id: 3, name: 'Не выполнено' }
];
