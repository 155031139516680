import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormItem
export class MonthPickerComponent extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        placeholder: PropTypes.string,
        allowClear: PropTypes.bool
    };

    render() {
        const { value, onChange, placeholder, allowClear } = this.props;

        return (
            <DatePicker.MonthPicker
                value={value ? moment(value) : undefined}
                format='MMMM'
                placeholder={placeholder}
                onChange={value => onChange(value ? value.utc().startOf('month') : null)}
                allowClear={allowClear}
                getPopupContainer={trigger => trigger.parentNode} />
        );
    }
}

export default withFormikField(MonthPickerComponent);
