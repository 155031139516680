import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Popconfirm } from 'antd';
import { contains, path } from 'ramda';
import { withAsyncHandlers } from 'react-async-client';

import Error from './Error';
import BaseForm from './form/BaseForm';
import { STATUSES_TO_IGNORE } from 'constants/serverMessages';

export const modalFormPropTypes = {
    postAction: PropTypes.object,
    putAction: PropTypes.object,
    deleteAction: PropTypes.object,
    modal: PropTypes.object.isRequired,
    params: PropTypes.object,
    onSubmitSuccess: PropTypes.func,
    initialValues: PropTypes.object,
    successButtonTitle: PropTypes.string
};

const isShowError = (modalAction) => !contains(path(['meta', 'error', 'status'], modalAction), STATUSES_TO_IGNORE);

@withAsyncHandlers({
    deleteAction: {
        successHandler: props => props.modal.onCancel()
    }
})
export default class ModalForm extends Component {
    static propTypes = {
        ...modalFormPropTypes,
        createTitle: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element
        ]),
        editTitle: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element
        ]),
        validationSchema: PropTypes.object,
        renderForm: PropTypes.func.isRequired,
        footerControls: PropTypes.func,
        disabled: PropTypes.bool
    };

    getItemId = () => path(['params', 'id'], this.props)

    isEdit = () => (!!this.getItemId() && this.props.putAction) || this.props.isEdit;

    modalFormSuccessHandler = formProps => {
        this.props.modal.onCancel();
        this.props.onSubmitSuccess && this.props.onSubmitSuccess(formProps);
    }

    onRemove = () => {
        const id = this.getItemId();
        this.props.deleteAction.dispatch(id);
    }

    renderModalForm = formProps => {
        const { modal, createTitle, editTitle, postAction, putAction, deleteAction, renderForm } = this.props;
        const isEdit = this.isEdit();

        const title = isEdit ? editTitle : createTitle;
        const modalAction = isEdit ? putAction : postAction;
        const footer = this.renderFooter(formProps, isEdit);

        const showError = isShowError(modalAction);

        return (
            <Modal wrapClassName="vertical-center-modal" {...modal} title={title} footer={footer}>
                {renderForm(formProps)}
                { showError && <Error meta={modalAction.meta} />}
                { deleteAction && <Error meta={deleteAction.meta} /> }
            </Modal>
        );
    }

    renderFooter = (formProps, isEdit) =>  {
        const { deleteAction, footerControls, disabled, successButtonTitle, deleteBtnText, deletePopconfirm, hideSubmit } = this.props;

        return (
            <div>
                { footerControls && <div className={hideSubmit ? '' : 'pull-left'}>{ footerControls(formProps) }</div> }
                { !hideSubmit &&
                    <Fragment>
                        { isEdit && deleteAction &&
                            (deletePopconfirm ?
                                <Popconfirm
                                    title={deletePopconfirm}
                                    okText='Да'
                                    cancelText='Нет'
                                    onConfirm={this.onRemove}>
                                    <Button type='danger' style={{ float: 'left' }}>{deleteBtnText || 'Архивировать'}</Button>
                                </Popconfirm> :
                                <Button type='danger' onClick={this.onRemove}>{deleteBtnText || 'Архивировать'}</Button>
                            )
                        }
                        <Button disabled={formProps.isSubmitting || disabled} type='primary' onClick={formProps.handleSubmit}>
                            { successButtonTitle || (isEdit ? 'Сохранить' : 'Добавить') }
                        </Button>
                    </Fragment>
                }
            </div>
        );
    }

    render() {
        const { params, validationSchema, postAction, putAction, initialValues, onSubmitFail, mapBeforeSubmit } = this.props;

        return (
            <BaseForm
                initialValues={initialValues || params}
                validationSchema={validationSchema}
                formAction={this.isEdit() ? putAction : postAction}
                onSubmitSuccess={this.modalFormSuccessHandler}
                renderForm={this.renderModalForm}
                onSubmitFail={onSubmitFail}
                mapBeforeSubmit={mapBeforeSubmit}
            />
        );
    }
}
