import { all } from 'redux-saga/effects';
import { getAsyncSagas } from 'react-async-client';

import login from './login';
import appRun from './appRun';
import error from './error';

export default function* rootSaga() {
    yield all([
        ...getAsyncSagas(),
        login(),
        appRun(),
        error()
    ]);
}
