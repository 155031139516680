import React from 'react';

import { Field } from 'formik';

import DatePicker from 'components/common/form/DatePicker';

const DateFilter = () => (
    <Field
        name="date"
        component={DatePicker}
        placeholder="Дата" />
)

export default DateFilter;
