import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import * as yup from 'yup';

import withRole from 'components/common/withRole';
import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import SearchSelect from 'components/common/form/SearchSelect';
import NumberInput from 'components/common/form/NumberInput';
import DatePicker from 'components/common/form/DatePicker';
import {
    putSale,
    deleteSale,
    getPromotersList,
    getProductsList,
    formAutoOptions,
} from 'actions/asyncActions';
import { checkError } from 'utils/error';
import { getNeedBlock } from 'utils/sales';
import { UNABLE_ADD_SALE_WITHOUT_CHECKIN } from 'constants/serverMessages';

@withRole
@withRouter
@withAsyncActions({
    putAction: putSale
        .withSuccessHandler(() => message.success('Продажа успешно обновлена.'))
        .withOptions(formAutoOptions),
    deleteAction: deleteSale
})
export default class SalesModal extends Component {
    static propTypes = modalFormPropTypes;

    renderForm = () => {
        const { isPromoter } = this.props;
        const isBlockSale = isPromoter && getNeedBlock();

        return (
            <div>
                { !isPromoter &&
                    <Field
                        name='promoter'
                        component={SearchSelect}
                        action={getPromotersList}
                        label='Консультант:'
                        placeholder='Выберите консультанта'
                        allowClear={true}
                        namePath={['lastName', 'firstName', 'middleName']}
                        disabled
                    />
                }
                <Field
                    name='date'
                    component={DatePicker}
                    label='Дата:'
                    disabled
                />
                <Field
                    name='product'
                    component={SearchSelect}
                    action={getProductsList}
                    label='Продукт:'
                    disabled
                 />
                <Field
                    name='quantity'
                    component={NumberInput}
                    label='Количество:'
                />
                <Field
                    name='price'
                    component={NumberInput}
                    label='Цена:'
                    disabled={isBlockSale}
                />
            </div>
        );
    }

    getValidationSchema() {
        return yup.object().shape({
            price: yup.number().positive().required(),
            quantity: yup.number().positive().required()
        });
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                editTitle={'Редактировать продажу'}
                isEdit
                validationSchema={this.getValidationSchema()}
                renderForm={this.renderForm}
                onSubmitFail={checkError('date', UNABLE_ADD_SALE_WITHOUT_CHECKIN)}
                deleteBtnText='Удалить'
                deletePopconfirm='Вы уверены, что хотите удалить продажу?'
            />
        );
    }
}
