import Rollbar from 'rollbar';

import { getUser } from 'actions/asyncActions';

export default function initRollbar(store) {
    window._rollbarConfig = {
        accessToken: 'f9a4a84c68d741a5b884276b19d47190',
        captureUncaught: true,
        payload: {
            environment: window.location.host,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: process.env.REACT_APP_BUILD_NUMBER || 0,
                    guest_uncaught_frames: true
                }
            }
        },
        ignoredMessages: [
            'ResizeObserver loop limit exceeded.',
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
            'ResizeObserver loop completed with undelivered notifications',
            'Script error.',
            'Script error'
        ],
        transform: payload => {
            const user = getUser.selectData(store.getState());
            payload.person = {
                id: user.id,
                username: `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`,
                email: user.email
            };
        }
    };

    window._version = {
        version: process.env.REACT_APP_BUILD_NUMBER || 0,
        date: process.env.REACT_APP_DATE
    };

    Rollbar.init(window._rollbarConfig);
}

export const isRollbarInit = process.env.NODE_ENV === 'production' && process.env.ROLLBAR_HOST;

export const pushRollbarError = error => {
    if (isRollbarInit) {
        if (!error.origin) {
            Rollbar.error(error.message, error);
        }

        if (/5[01][0-9]/.test(error.status)) {
            Rollbar.error('Server Error', error);
        }
    }
};
