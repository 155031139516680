import React, { Component } from 'react';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import * as yup from 'yup';
import { path } from 'ramda';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import Input from 'components/common/form/Input';
import SearchSelect from 'components/common/form/SearchSelect';
import Text from 'components/common/form/Text';
import InputCoordinates from 'components/common/form/InputCoordinates';
import CascadeSelect from 'components/common/form/CascadeSelect';
import { COORD } from 'constants/regexp';

import {
    postShop,
    putShop,
    getNetworks,
    getRegions,
    getCities,
    formAutoOptions,
    deleteShop,
} from 'actions/asyncActions';
import { withRouter } from 'react-router';

yup.addMethod(yup.object, 'coords', function () {
    return this
        .test({
            name: 'coords',
            exclusive: true,
            message: 'Некорректный формат координат',
            test: async function (value) {
                if (!value) {
                    return true;
                }

                if (!value.longitude && !value.latitude) {
                    return true
                }

                return COORD.test(value.longitude) && COORD.test(value.latitude);
            }
        })
});

const validationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    code: yup.string().trim().required(),
    network: yup.string().required(),
    city: yup.string().required(),
    address: yup.string().trim().required(),
    coordinates: yup.object().nullable().coords(),
    addressComment: yup.string().nullable(),
});

const getSearchBoxString = (city, address) => city && address ? `${city}, ${address}` : null;

@withRouter
@withAsyncActions({
    postAction: postShop.withOptions(formAutoOptions),
    putAction: putShop.withOptions(formAutoOptions),
    deleteAction: deleteShop
        .withSuccessHandler(({ history }) => history.goBack())
        .withOptions(formAutoOptions),
})
export default class ShopModal extends Component {
    static propTypes = modalFormPropTypes;

    getCascaderFields() {
        return [
            { name: 'region', action: getRegions, placeholder: 'Выберите регион', label: 'Регион:' },
            { name: 'city', action: getCities, placeholder: 'Выберите город', label: 'Город:' }
        ];
    }

    renderForm = formProps => {
        const { address, coordinates } = formProps.values;
        const cityName = path(['_embedded', 'city', 'name'], formProps.values);
        const searchBoxValue = getSearchBoxString(cityName, address);
        return (
            <div>
                <Field
                    name='name'
                    component={Input}
                    label='Название:'
                    placeholder='Введите название' />
                <Field
                    name='code'
                    component={Input}
                    label='Код ТТ:'
                    placeholder='Введите код' />
                <Field
                    name='city'
                    component={CascadeSelect}
                    label='Регион/Город:'
                    placeholder='Выберите город'
                    allowClear={true}
                    fields={this.getCascaderFields()} />
                <Field
                    name='network'
                    component={SearchSelect}
                    label='Сеть:'
                    action={getNetworks} />
                <Field
                    name='address'
                    component={Input}
                    label='Адрес:' />
                <Field
                    name='coordinates'
                    label='Координаты:'
                    coordinates={coordinates}
                    searchBoxValue={searchBoxValue}
                    component={InputCoordinates} />
                <Field
                    name='addressComment'
                    component={Text}
                    label='Комментарий к адресу:' />
            </div>
        );
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                editTitle='Редактировать магазин'
                createTitle='Добавить магазин'
                validationSchema={validationSchema}
                renderForm={this.renderForm}
                deletePopconfirm='Вы уверены, что хотите удалить магазин?'
                deleteBtnText='Удалить'
            />
        );
    }
}
