export const PHOTO_REPORTS_OMP_TYPE = 'omp';
export const PHOTO_REPORTS_DMP_TYPE = 'dmp';

export const PHOTO_REPORTS_TYPES = [
    {
        id: PHOTO_REPORTS_OMP_TYPE,
        name: 'Фото ОМП До/После'
    },
    {
        id: PHOTO_REPORTS_DMP_TYPE,
        name: 'Фото ДМП'
    },
];

export const PHOTO_REPORTS_MAP = {
    [PHOTO_REPORTS_OMP_TYPE]: PHOTO_REPORTS_TYPES[0].name,
    [PHOTO_REPORTS_DMP_TYPE]: PHOTO_REPORTS_TYPES[1].name,
};

export const PHOTO_REPORTS_LABEL_MAP = {
    [PHOTO_REPORTS_OMP_TYPE]: 'Фото До',
    [PHOTO_REPORTS_DMP_TYPE]: 'Фото ДМП',
};

export const PHOTO_REPORTS_LABEL_ADDITIONAL_MAP = {
    [PHOTO_REPORTS_OMP_TYPE]: 'Фото После',
    [PHOTO_REPORTS_DMP_TYPE]: 'Фото дополнительного оборудования',
};

export const PHOTO_REPORTS_LABEL_COMMENT_MAP = {
    [PHOTO_REPORTS_OMP_TYPE]: 'Комментарий к Фото До',
    [PHOTO_REPORTS_DMP_TYPE]: 'Комментарий к фото ДМП (Торцы, Острова, Корзины)',
};

export const PHOTO_REPORTS_LABEL_ADDITIONAL_COMMENT_MAP = {
    [PHOTO_REPORTS_OMP_TYPE]: 'Комментарий к Фото После',
    [PHOTO_REPORTS_DMP_TYPE]: 'Комментарий к фото дополнительного оборудования',
};
