export const ADMIN = 'ROLE_ADMIN';
export const SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const CLIENT = 'ROLE_CLIENT';
export const MARKETING_COORDINATOR = 'ROLE_MARKETING_COORDINATOR';
export const SUPERVISOR = 'ROLE_SUPERVISOR';
export const PROMOTER = 'ROLE_PROMOTER';

export const noPromoter = [ADMIN, MARKETING_COORDINATOR, CLIENT, SUPERVISOR ];
export const userLinks = [ADMIN, CLIENT, MARKETING_COORDINATOR];
export const userLinksNoClient = [ADMIN, MARKETING_COORDINATOR];

export const ROLES = {
    [ADMIN]: { name: 'Администратор', color: 'purple' },
    [CLIENT]: { name: 'Клиент', color: 'red' },
    [MARKETING_COORDINATOR]: { name: 'Account Manager', color: 'orange' },
    [SUPERVISOR]: { name: 'Супервайзер', color: 'green', type: 'supervisor' },
    [PROMOTER]: { name: 'Консультант', color: 'blue', type: 'promoter' }
};

export const USER_TYPES = [
    {
        value: 'promoter',
        name: 'Консультант'
    }, {
        value: 'supervisor',
        name: 'Супервайзер'
    }, {
        value: 'stuff',
        name: 'Сотрудник'
    }
];
