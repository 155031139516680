import React from 'react';
import { path } from 'ramda';

import HeadBlock from '../HeadBlock';
import RangeDate from '../RangeDate';
import PriorityTag from './PriorityTag';

const TaskHeadBlock = ({ task = {}, ...props }) => {
    const { priority, startDate, endDate } = task;
    const regionName = path(['_embedded', 'region', 'name'], task);
    const shopName = path(['_embedded', 'shop', 'name'], task);
    const cityName = path(['_embedded', 'city', 'name'], task);
    const networkName = path(['_embedded', 'network', 'name'], task);

    const title = shopName ? shopName : (
        <span>{cityName || networkName ? `${cityName} ${networkName}` : regionName || 'Общая задача'}</span>
    );

    const inscription = task.id && (
        <div>
            <PriorityTag priority={priority} />
            <RangeDate from={startDate} to={endDate} />
        </div>
    );

    return <HeadBlock title={title} inscription={inscription} {...props} />;
}

export default TaskHeadBlock;
