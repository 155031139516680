import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { asyncConnect } from 'react-async-client';

import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import { getPhotoReports } from 'actions/asyncActions';
import PhotoReportsFilter from 'filters/PhotoReportsFilter';
import PersonName from 'components/common/cards/person/PersonName';
import ShopName from 'components/common/cards/shop/ShopName';
import Date from 'components/common/cards/Date';
import { POST_PHOTO_REPORT, PUT_PHOTO_REPORT } from 'constants/actionTypes';
import { PHOTO_REPORTS_MAP } from 'constants/photoReports';
import { openPhotoReportModal } from 'actions/modalActions';
import { isMobile } from 'constants/screen';
import PhotoReportsMobile from './PhotoReportsMobile';

@withRole
@asyncConnect({}, null, { openPhotoReportModal })
class PhotoReports extends Component {
    constructor(props) {
        super(props);

        this.initialFilters = {
            minDate: moment().add(-1, 'months').utc().startOf('day').toDate(),
            maxDate: moment().utc().endOf('day').toDate(),
        };
    }

    getColumns = isClient => [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: date => <Date date={date} />
        }, {
            title: 'Сеть',
            key: 'network',
            dataIndex: ['_embedded', 'network', 'name'],
        }, {
            title: 'Магазин',
            key: 'shop',
            dataIndex: ['_embedded', 'shop'],
            render: shop => <ShopName shop={shop} />
        },
        {
            title: 'Тип отчета',
            dataIndex: ['type'],
            key: 'type',
            render: type => PHOTO_REPORTS_MAP[type] || type
        },
        {
            title: 'Консультант',
            dataIndex: ['_embedded', 'promoter'],
            key: 'promoter',
            render: promoter => <PersonName person={promoter} />,
            hide: this.props.isPromoter
        },
        {
            align: 'right',
            render: report => !isClient && (
                <div>
                    <Button
                        icon={<EyeOutlined />}
                        type='link'
                        onClick={() => this.props.isPromoter
                            ? this.props.history.push(`/photo/edit/${report.id}`)
                            : this.props.openPhotoReportModal(report)}
                    />
                </div>
            )
        }
    ];

    render() {
        const { isPromoter, isClient } = this.props;

        const filterControls = isPromoter && (
            <div className='pull-right mobile-filter-button'>
                <Link to='/photo/add'>
                    <Button type='primary' icon={<PlusOutlined />}>
                        Добавить
                    </Button>
                </Link>
            </div>
        );

        return (
            <div className='table-mobile'>
                <ListTable
                    filterForm={PhotoReportsFilter}
                    filterControls={filterControls}
                    action={getPhotoReports}
                    refreshActions={[POST_PHOTO_REPORT, PUT_PHOTO_REPORT]}
                    tableColumns={this.getColumns(isClient)}
                    initialFilters={this.initialFilters}
                    staticPayload={{
                        sort_by: 'date',
                        sort_order: 'desc'
                    }}
                />
            </div>
        );
    }
}

export default isMobile() ? PhotoReportsMobile : PhotoReports;
