import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';

import DocumentDownload from 'components/scenes/documents/DocumentDownload';
import { openPreviewModal } from 'actions/modalActions';
import { getPhotoUrl } from 'utils/photo';

const TaskFiles = ({ files = [], openPreviewModal }) => (
    <Row gutter={10} className='task-files-block'>
        <Col span={12}>
            {files.map((file) => file && !file.id ? (
                <p key={file}>
                    <DocumentDownload id={file}>{file}</DocumentDownload>
                </p>
            ) : (
                <p key={file.id}>
                    <DocumentDownload id={file.id}>{file.name}{' '}</DocumentDownload>
                    { file.image && (
                        <span className='span-link' onClick={() => openPreviewModal(getPhotoUrl(file.id))}><EyeOutlined /></span>
                    )}
                </p>
            ))}

        </Col>
    </Row>
);

export default connect(null, { openPreviewModal })(TaskFiles);
