import React from 'react';
import qs from 'qs';

import { getToken } from 'utils/token';
import { FILE_DOWNLOAD } from 'constants/urls';

const DocumentDownload = ({ id, children }) => {
    const stringifiedUrl = FILE_DOWNLOAD.stringify({ id });
    const stringifiedQuery = qs.stringify({ token: getToken() });
    const downloadUrl = `/api${stringifiedUrl}?${stringifiedQuery}`;
    return <a href={downloadUrl} download>{children}</a>;
};

export default DocumentDownload;
