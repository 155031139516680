import React, { Component } from 'react';
import { message } from 'antd';
import { Modal } from 'antd';

import { modalFormPropTypes } from 'components/common/ModalForm';
import UploadFile from 'components/common/form/UploadFile';
import { FILE } from 'constants/urls';
import { getActionUrl } from 'utils/http';

export default class DocumentModal extends Component {
    static propTypes = modalFormPropTypes;

    onChangeUpload = info => {
        const { status, name } = info.file;
        const { params, modal } = this.props;
        switch (status) {
            case 'done':
                const file = info.file.response;
                message.success(`${name} файл успешно загружен.`);
                params.onAdd(file);
                modal.onCancel();
                break;
            case 'error':
                message.error(`Ошибка загрузки файла ${name}.`);
                break;
            default:
        }
    }

    render() {
        const { modal } = this.props;

        return (
            <Modal {...modal} title={'Прикрепить документ'} footer={null}>
                <UploadFile
                    onChangeUpload={this.onChangeUpload}
                    name="file"
                    url={getActionUrl(FILE)}
                />
            </Modal>
        );
    }
}
