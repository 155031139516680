import React from 'react';
import { Modal } from 'antd';

const PreviewModal = ({ modal, params }) => (
    <Modal {...modal} title='Фотография' footer={null} className='preview-modal'>
        <img src={params} alt='' />
    </Modal>
);

export default PreviewModal;
