import React from 'react';
import { Col } from 'antd';

import withFilterForm from './base/withFilterForm';
import BaseSearchFilter from './base/BaseSearchFilter';
import TaskPriorityFilter from './fields/TaskPriorityFilter';
import BeforeDateFilter, { StartDateFilter } from './fields/BeforeDateFilter';
import RegionFilter from './fields/RegionFilter';
import CityFilter from './fields/CityFilter';
import NetworkFilter from './fields/NetworkFilter';

function TasksFilter() {
    return (
        <BaseSearchFilter searchFieldName='text'>
            <Col sm={3} xs={24}>
                <TaskPriorityFilter />
            </Col>
            <Col sm={3} xs={12}>
                <StartDateFilter />
            </Col>
            <Col sm={3} xs={12}>
                <BeforeDateFilter />
            </Col>
            <Col sm={3} xs={12}>
                <RegionFilter />
            </Col>
            <Col sm={3} xs={12}>
                <CityFilter />
            </Col>
            <Col sm={3} xs={24}>
                <NetworkFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(TasksFilter);

export const defaultCommonTasksFilter = {
    common: true
};
