import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';

import TaskForm from './TaskForm';
import HeadBlock from 'components/common/cards/HeadBlock';
import Spinner from 'components/common/Spinner';
import { getTask } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';

@withAsyncActions({
    getTask: getTask
        .withPayload(path(['match', 'params', 'id']))
        .withOptions(fullAutoOptions)
})
export default class EditTask extends Component {
    render() {
        const { data, meta: { success }} = this.props.getTask;

        return (
            <div className='detail-card'>
                <HeadBlock title='Редактирование задачи' />
                { success ? <TaskForm data={data} /> : <Spinner /> }
            </div>
        );
    }
}
