import React, { Component } from 'react';
import { connect } from 'react-redux';

import UserInfo from 'components/common/cards/user/UserInfo';
import { getPromoter, postPromoterPhoto, deletePromoterPhoto } from 'actions/asyncActions';
import { promoterMenuItems } from 'menus';
import Menu from 'components/common/Menu';
import withRole from 'components/common/withRole';

const stateToProps = state => ({
    promoter: getPromoter.selectData(state)
});

@withRole
@connect(stateToProps)
export default class PromoterInfoCard extends Component {
    render() {
        const { promoter, promoter: { enabled }, match: { params }, action, isAdmin } = this.props;

        return (
            <div>
                { enabled && <Menu items={promoterMenuItems(params.id)} /> }
                <UserInfo
                    user={promoter}
                    postPhotoAction={postPromoterPhoto}
                    deletePhotoAction={deletePromoterPhoto}
                    refresh={() => action.refresh()}
                    disabledPhotoEdit={!isAdmin} />
            </div>
        );
    }
}
