import React, { Component } from 'react';
import { Avatar, Button, Popconfirm, message } from 'antd';
import { last } from 'ramda';

import { getDocuments, deleteDocument } from 'actions/asyncActions';

import { openDocumentModal } from 'actions/modalActions';
import ListTable from 'components/common/ListTable';
import SearchFilter from 'filters/SearchFilter';
import DocumentDownload from './DocumentDownload';
import withRole from 'components/common/withRole';
import { DeleteOutlined, FileExcelOutlined, FilePdfOutlined, FilePptOutlined, FileTextOutlined, FileUnknownOutlined, PlusOutlined } from '@ant-design/icons';
import { DELETE_DOCUMENT, POST_DOCUMENT } from 'constants/actionTypes';
import { asyncConnect } from 'react-async-client';

const iconFileTypes = {
    'pdf': <FilePdfOutlined />,
    'txt': <FileTextOutlined />,
    'xlsx': <FileExcelOutlined />,
    'ppt': <FilePptOutlined />,
    'pptm': <FilePptOutlined />,
    'pptx': <FilePptOutlined />,
    'xls': <FileExcelOutlined />,
    'xml': <FileExcelOutlined />
};

const getIconFileType = (name) => iconFileTypes[last(name.split('.'))];
const getIconString = (name) => !getIconFileType(name) ? <FileUnknownOutlined /> : getIconFileType(name);

@withRole
@asyncConnect({
    deleteDocument: deleteDocument
        .withErrorHandler(() => message.error('Ошибка удаления документа', 3))
}, null, { openDocumentModal })
export default class Documents extends Component {
    columns = [
        {
            title: '',
            dataIndex: ['items', 'name'],
            key: 'avatar',
            className: 'td-avatar',
            render: (text, item) => (<Avatar icon={getIconString(item.name)} className={getIconString(item.name)} />)
        },
        {
            title: 'Название',
            dataIndex: ['items', 'name'],
            key: 'name',
            render: (text, item) => (<DocumentDownload id={item.file}>{item.name}</DocumentDownload>)
        },
        {
            title: '',
            key: 'delete',
            width: 50,
            render: item => (this.props.isAdmin &&
                <Popconfirm
                    arrowPointAtCenter
                    placement='topRight'
                    title='Вы действительно хотите удалить документ?'
                    onConfirm={() => this.props.deleteDocument.dispatch(item.id)}>
                    <DeleteOutlined className='action-icon' />
                </Popconfirm>
            )
        }
    ];

    getMenuControls() {
        const { openDocumentModal, isAdmin } = this.props;

        return isAdmin && (
            <div className='pull-right'>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openDocumentModal()}>Загрузить</Button>
            </div>
        );
    }

    render() {
        return (
            <div className="layout-wrap">
                <div className='documents table-file table-mobile'>
                    <ListTable
                        tableColumns={this.columns}
                        action={getDocuments}
                        filterForm={SearchFilter}
                        filterControls={this.getMenuControls()}
                        refreshActions={[POST_DOCUMENT, DELETE_DOCUMENT]}
                    />
                </div>
            </div>
        );
    }
}
