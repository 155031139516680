import React, { Component } from 'react';
import { Avatar } from 'antd';
import { head, path } from 'ramda';

import ListTable from 'components/common/ListTable';
import Date from 'components/common/cards/Date';
import PersonLink from 'components/common/cards/person/PersonLink';
import TaskText from 'components/common/cards/task/TaskText';
import TaskFiles from 'components/common/cards/task/TaskFiles';
import TaskProgressFilter from 'filters/TaskProgressFilter';
import { getTaskReports } from 'actions/asyncActions';

export default class TaskProgress extends Component {
    columns = [
        {
            dataIndex: ['_embedded', 'promoter', 'lastName'],
            key: 'icon',
            width: 40,
            render: text => <Avatar>{head(text)}</Avatar>
        }, {
            title: 'ФИО',
            dataIndex: ['_embedded', 'promoter'],
            key: 'promoter',
            render: promoter => <PersonLink person={promoter} />
        }, {
            title: 'Регион',
            dataIndex: ['_embedded', 'region', 'name'],
            key: 'region'
        }, {
            title: 'Торговая сеть',
            dataIndex: ['_embedded', 'network', 'name'],
            key: 'network'
        }, {
            title: 'Магазин',
            dataIndex: ['_embedded', 'shop', 'name'],
            key: 'shop'
        }, {
            title: 'Дата выполнения',
            dataIndex: 'date',
            key: 'date',
            render: date => <Date date={date} />
        }
    ];

    getInitialFilters() {
        const { id } = this.props.match.params;

        return { task: id };
    }

    renderExpandedRow = data => {
        return (
            <div>
                <span>Ответ:</span>
                <TaskText text={data.text} />
                <TaskFiles files={path(['_embedded', 'files'], data)} />
            </div>
        );
    }

    render() {
        return (
            <div>
                <ListTable
                    tableColumns={this.columns}
                    action={getTaskReports}
                    filterForm={TaskProgressFilter}
                    initialFilters={this.getInitialFilters()}
                    expandedRowRender={this.renderExpandedRow} />
            </div>
        );
    }
}
