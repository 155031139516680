import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormItem
export class SwitchInputComponent extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        placeholder: PropTypes.string,
        prefix: PropTypes.node,
        type: PropTypes.string,
    };

    onChange = value => {
        this.props.onChange(value);
    }

    render() {
        const { name, value, disabled } = this.props;

        return (
            <Switch
                id={name}
                checked={!!value}
                onChange={this.onChange}
                disabled={disabled}
            />
        );
    }
}

export default withFormikField(SwitchInputComponent);
