import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout, Menu, Dropdown } from 'antd';
import { matchPath, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { any, find, prop } from 'ramda';

import { isMobile, leftMenuWidth, leftMenuCollapsedWidth } from 'constants/screen';
import LEFT_MENU_ITEMS from 'constants/leftMenuItems';
import withRole from 'components/common/withRole';
import PersonName from 'components/common/cards/person/PersonName';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import { logout } from 'actions/appActions';
import { getUser } from 'actions/asyncActions';
import { DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';

const stateToProps = state => ({
    user: getUser.selectData(state)
});

@withRouter
@withRole
@connect(stateToProps, { logout })
export default class LeftMenu extends Component {
    static propsTypes = {
        role: PropTypes.string,
        collapsed: PropTypes.bool,
        handleCollapse: PropTypes.func,
    };

    getSelectedLink(items) {
        const { location } = this.props;

        const selected = find(item => {
            const additionalRoutes = item.routes || [];

            return any(path => matchPath(location.pathname, {
                path,
                ...item.matchOptions
            }), [item.route, ...additionalRoutes]);
        }, items);

        return selected && [
            prop('route', selected)
        ];
    }

    renderMenuItems() {
        const { role } = this.props;
        const items = LEFT_MENU_ITEMS.filter(item => !item.hide && (!item.roles || (item.roles && item.roles.includes(role))));

        return (
            <Menu selectable={false} selectedKeys={this.getSelectedLink(items)} mode='inline' theme="dark" onClick={this.onMenuItemClick}>
                {items.map(item =>
                    <Menu.Item key={item.route}>
                        <Link to={item.route}>
                            { item.icon }<span>{item.name}</span>
                        </Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    }

    getProfileMenu() {
        return (
            <Menu className='dropdown-custom-link' onClick={this.onMenuItemClick}>
                <Menu.Item key='0'>
                    <Link to='/profile'><UserOutlined /><span>&nbsp;&nbsp;Профиль</span></Link>
                </Menu.Item>
                <Menu.Item key='1'>
                    <span className='span-link' onClick={this.props.logout}><PoweroffOutlined /><span>&nbsp;&nbsp;Выйти</span></span>
                </Menu.Item>
            </Menu>
        );
    }

    onMenuItemClick = () => {
        if (isMobile()) {
            this.closeMenu();
        }
    }

    onCollapse = () => {
        this.props.handleCollapse();
    }

    closeMenu = () => {
        this.props.handleCollapse({ collapsed: true });
    }

    render() {
        const { user, collapsed } = this.props;

        return (
            <div className='left-menu-wrapper'>
                <div className='mobile-left-menu-toggle'>
                    <span className='span-link' onClick={this.onCollapse}>{ collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined /> }</span>
                    <div className="logo-wrap">
                        <div className="logo" />
                    </div>
                </div>
                <Layout.Sider
                    width={leftMenuWidth}
                    className='left-menu'
                    collapsible
                    collapsedWidth={leftMenuCollapsedWidth}
                    collapsed={collapsed}
                    onCollapse={this.onCollapse}>
                    <div className="logo" />
                    <div className='profile-block'>
                        <Dropdown overlay={this.getProfileMenu()} trigger={['click']}>
                            <span className='span-link ant-dropdown-link'>
                                <span className='avatar-block'>
                                    <PersonPhoto size='large' person={user} />
                                </span>
                                <br/>
                                <span className='name-block'>
                                    <PersonName person={user} /> <DownOutlined />
                                </span>
                            </span>
                        </Dropdown>
                    </div>
                    { this.renderMenuItems() }
                </Layout.Sider>
                { isMobile() && !collapsed && <div className='backdrop' onClick={this.closeMenu}></div> }
            </div>
        );
    }
}
