import React from 'react';
import { NavLink } from 'react-router-dom';
import { Popover, Tag, Tooltip } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { contains, map, isEmpty, take } from 'ramda';
import cx from 'classnames';

import { ROLES, SUPERVISOR } from 'constants/roles';
import PersonPhoto from 'components/common/cards/person/PersonPhoto';
import PersonName from 'components/common/cards/person/PersonName';
import PersonLink from 'components/common/cards/person/PersonLink';
import withRoles from 'components/common/withRoles';

const getContent = item => (
    <div>
        { item.phone && <p><PhoneOutlined />{item.phone}</p> }
        { item.email && <p><MailOutlined />{item.email}</p> }
    </div>
);

const getColor = item => ROLES[item.roles[0]]['color'];
const getRoleName = item => ROLES[item.roles[0]]['name'];
const getType = item => ROLES[item.roles[0]]['type'];
const isSupervisor = item => contains(SUPERVISOR, item.roles);

const getTitle = item => (
    <div>
        <span className="title-popover">
            <PersonName person={item} />
        </span>
        <span className="tag-popover">
            <Tag color={getColor(item)}>{getRoleName(item)}</Tag>
        </span>
    </div>
);

const CollaboratorList = ({ list, max = 5, shopId, isClient }) => !isEmpty(list) && (
    <div className="column-list-team">
        { map((item) => (
            <PersonLink person={item} type={getType(item)} key={item.id}>
                <Popover
                    arrowPointAtCenter
                    placement="topRight"
                    content={isClient ? null : getContent(item)}
                    title={getTitle(item)}>
                        <span className={cx('team-user-avatar', { 'team-super': isSupervisor(item) })}>
                            <PersonPhoto person={item} size="small" />
                        </span>
                </Popover>
            </PersonLink>
        ), take(max, list)) }
        { list.length > max &&
            <div>
                <Tooltip title="Показать всех">
                    <NavLink className="other-team" to={`/shop/${shopId}/workers`}>{`+${list.length - max}`}</NavLink>
                </Tooltip>
            </div>
        }
    </div>
);

export default withRoles(CollaboratorList);
