import React from 'react';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import { getTimeZoneOffsetString } from '../../scenes/geography/Cities';
import { Tooltip } from 'antd';

const Time = ({ time, timezoneOffset }) =>
    time ? (
        timezoneOffset ? <Tooltip title={`Часовой пояс: ${getTimeZoneOffsetString(timezoneOffset)}`}>
            <span><ClockCircleOutlined /> {moment(time).utc().add(timezoneOffset, 'minutes').format('HH:mm')}</span>
        </Tooltip> : <span><ClockCircleOutlined /> {moment(time).format('HH:mm')}</span>
    ) : null;

export default Time;
