import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

import { getSalePlanDepartments, getSalePlansStat } from 'actions/asyncActions';
import { defaultShopPlansFilter } from 'filters/PlansFilter';
import { POST_SALE } from 'constants/actionTypes';
import { asyncConnect } from 'react-async-client';
import createPutSaga from 'utils/putSagaCreator';
import { fullAutoOptions } from 'constants/asyncClient';
import { Plan, PlanBonus } from '../main/MainPromoter';
import { path } from 'ramda';
import moment from 'moment';

@asyncConnect({
    getSalePlansStat: getSalePlansStat
        .withPayload(({ checkin }) => ({
            q: {
                type: defaultShopPlansFilter.type,
                date: moment.utc().toDate(),
                shop: checkin.shop,
            },
            limit: 0
        }))
        .withSaga(createPutSaga(POST_SALE, 'getSalePlansStat'))
        .withOptions(fullAutoOptions),
    getSalePlanDepartments: getSalePlanDepartments
        .withPayload(() => moment.utc().toISOString())
        .withOptions(fullAutoOptions),
})
export default class ShopCheckinPlans extends Component {
    static propTypes = {
        checkin: PropTypes.object
    };

    render() {
        const { getSalePlansStat, getSalePlanDepartments } = this.props;
        const isBonusPlan = !!path([0, 'salePlanBonusOptions', 'enabled'], getSalePlanDepartments.data.departmentPlans || []);
        const PlanComponent = isBonusPlan ? PlanBonus : Plan;

        return <div>
            { (getSalePlansStat.data.items || []).map((item, index) => {
                return <Col xs={24} lg={12} key={`plan-${index}`}>
                    <h2 className="title-item">{ path(['_embedded', 'shop', 'name'], item) }</h2>
                    <h3 className='title-item-sub'>{ path(['_embedded', 'productDepartment', 'name'], item) }</h3>
                    <PlanComponent item={item} departmentPlans={getSalePlanDepartments.data.departmentPlans || []} />
                </Col>;
            })}
        </div>
    }
}
