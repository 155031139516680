import React, { Component } from 'react';

import withRole from 'components/common/withRole';
import MainSupervisor from './MainSupervisor';
import MainPromoter from './MainPromoter';

@withRole
export default class Main extends Component {
    render() {
        const { isPromoter, isSupervisor } = this.props;

        return (
            <div className='main'>
                { isPromoter && <MainPromoter /> }
                { isSupervisor && <MainSupervisor /> }
            </div>
        );
    }
}
