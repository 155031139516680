import React, { Component } from 'react';
import { Button, DatePicker, message, Popconfirm, Spin } from 'antd';
import { asyncConnect, toSuccess } from 'react-async-client';
import { withState } from 'recompose';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { find, groupBy, mapObjIndexed, path, pathOr, prop, propEq, toPairs, sortBy } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { DeleteOutlined } from '@ant-design/icons';

import { deleteSales, getSalesList, importSales } from 'actions/asyncActions';
import { formAutoOptions, fullAutoOptions } from 'constants/asyncClient';
import { PlusOutlined, ShopOutlined, UploadOutlined } from '@ant-design/icons';
import SalesFilter from 'filters/SalesFilter';
import { openSaleEditModal, openImportModal } from 'actions/modalActions';
import { DELETE_SALE, DELETE_SALES, IMPORT_SALES, POST_SALE, PUT_SALE } from 'constants/actionTypes';
import { toPriceFormat } from '../../common/cards/FormattedPrice';
import withRole from '../../common/withRole';

@withRole
@withState('date', 'setDate', {
    minDate: moment().subtract(7, 'days').startOf('day'),
    maxDate: moment().endOf('day')
})
@withState('filter', 'setFilter', {})
@asyncConnect({
    deleteSales: deleteSales
        .withErrorHandler(() => message.error('Не удалось удалить продажи'))
        .withOptions(formAutoOptions),
    getSalesList: getSalesList
        .withPayload(({ filter, date }) => ({
            q: {
                ...filter,
                ...date
            },
            limit: 200
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([
                toSuccess(POST_SALE),
                toSuccess(PUT_SALE),
                toSuccess(DELETE_SALE),
                toSuccess(IMPORT_SALES),
                toSuccess(DELETE_SALES)
            ], function () {
                const { getSalesList } = getProps();

                getSalesList.refresh();
            })
        })
        .withOptions(fullAutoOptions)
}, null, { openSaleEditModal, openImportModal })
export default class SalesMobile extends Component {
    state = {
        filterOpened: false
    };

    getSum = sum => {
        return toPriceFormat(sum, true);
    }

    deleteSales = sales => {
        this.props.deleteSales.dispatch(sales.map(prop('id')));
    }

    renderSales = () => {
        const { getSalesList: { data } } = this.props;
        const itemsByDay = groupBy(prop('dateString'), (data.items || []).map(item => ({ ...item, dateString: moment(item.date).format('DD.MM.YYYY') })));
        const shopItemsByDay = sortBy(prop(0), toPairs(itemsByDay).map(([date, items]) => ([date, toPairs(groupBy(prop('shop'), items))])));
        const sumForDays = mapObjIndexed(items => (items || []).reduce((res, cur) => res + (cur.sum || 0), 0), itemsByDay);
        const amountForDays = mapObjIndexed(items => (items || []).reduce((res, cur) => res + (cur.quantity || 0), 0), itemsByDay);

        return <div>
            <div className='title-item'>Всего продано <strong>{pathOr(0, ['_meta', 'totalQuantity'], data)}</strong> шт. на <strong>{toPriceFormat(pathOr(0, ['_meta', 'totalSum'], data), true) || 0} ₽</strong></div>
            {shopItemsByDay.map(([date, items]) =>
                <div key={`date-${date}`}>
                    <div className='line-date'>
                        <div className='title-item'>{date}</div>
                        <div>
                            <span className='title-count'>{amountForDays[date]} шт. на</span>
                            <span className='total-sum'><strong>{this.getSum(sumForDays[date])}₽</strong></span>
                        </div>
                    </div>
                    {items.map(([shop, shopItems]) =>
                        <div className='sale-list' key={`shop-${date}-${shop}`}>
                            {shopItems.map((shopItem, index) =>
                                <div className='sale-item' key={`shop-item-${date}-${shop}-${shopItem.id}`} onClick={() => this.props.openSaleEditModal(shopItem)}>
                                    <div className="sale-title">
                                        <div>{path(['_embedded', 'product', 'name'], shopItem)}</div>
                                        <small>#{path(['_embedded', 'product', 'ean'], shopItem)}</small>
                                    </div>
                                    <div className='price-item'>
                                        <div className='price'><strong>{this.getSum(shopItem.sum)}₽</strong></div>
                                        {shopItem.quantity > 1 && <div className='sum'>{this.getSum(shopItem.price)} x {shopItem.quantity}</div>}
                                    </div>
                                    <svg className='arrow-right' width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.69236 4.84592L3.99658 7.5417C3.74936 7.78892 3.34492 7.78892 3.09798 7.5417L2.8727 7.3167C2.62577 7.07004 2.62577 6.66532 2.8727 6.41839L4.93005 4.36104L2.8727 2.30342C2.62577 2.05648 2.62577 1.65176 2.8727 1.4051L3.09798 1.1801C3.34492 0.932885 3.74936 0.932885 3.99658 1.1801L6.69236 3.87645C6.82511 4.00892 6.88417 4.18695 6.87433 4.36104C6.88417 4.53514 6.82511 4.71289 6.69236 4.84592Z" fill="white" fill-opacity="0.8" />
                                    </svg>
                                </div>
                            )}
                            <div className='shop-name'>
                                <span><ShopOutlined /> {path(['_embedded', 'shop', 'name'], find(propEq('shop', shop), data.items || []))}</span>
                                <Popconfirm
                                    title={`Вы уверены, что хотите удалить продажи за ${date}?`}
                                    okText='да'
                                    cancelText='нет'
                                    onConfirm={() => this.deleteSales(shopItems)}
                                    placement='leftTop'>
                                    <Button type='text' icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>;
    }

    renderFilter = () => {
        return <div style={{ marginBottom: 15 }}>
            <SalesFilter setFilters={this.props.setFilter} initialValues={this.props.filter} isMobile />
            { this.props.isAdmin &&
                <Button
                    type='success'
                    block
                    icon={<UploadOutlined />}
                    onClick={() => this.props.openImportModal({
                        title: 'Импортировать продажи',
                        action: importSales,
                        withoutMonth: true
                    })}>
                    Импорт
                </Button>
            }
        </div>;
    };

    toggleFilter = () => this.setState(prev => ({ filterOpened: !prev.filterOpened }));

    onChangeDate = range => {
        const [fromDate, toDate] = range;

        this.props.setDate({
            minDate: fromDate.startOf('day'),
            maxDate: toDate.endOf('day')
        });
    }

    render() {
        const { getSalesList: { meta }, date } = this.props;

        return <div className='layout-wrap'>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 15, marginTop: 5 }}>
                <DatePicker.RangePicker
                    dropdownClassName='range-sales'
                    style={{ width: '100%' }}
                    value={[date.minDate, date.maxDate]}
                    placeholder={['с', 'по']}
                    onChange={this.onChangeDate}
                    allowClear={false} />
                <Button style={{ marginLeft: 10, height: 40 }} onClick={this.toggleFilter}>
                    <img src='/filter.svg' alt='filter-icon' />
                </Button>
                <Link to='/sales/add'>
                    <Button className='fab-btn' type='primary' shape="circle" icon={<PlusOutlined />} size="large" />
                </Link>
            </div>
            {this.state.filterOpened && this.renderFilter()}
            {meta.pending ?
                <div style={{ textAlign: 'center' }}><Spin /></div> :
                this.renderSales()
            }
        </div>
    }
}
