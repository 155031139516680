import { ADMIN, CLIENT, MARKETING_COORDINATOR } from 'constants/roles';

export const productMenuItems = [
    {
        name: 'Продукты',
        route: '/products'
    }, {
        name: 'Линейки',
        route: '/categories'
    }, {
        name: 'Группы',
        route: '/groups'
    }, {
        name: 'Департаменты',
        route: '/departments'
    }, {
        name: 'Бренды',
        route: '/brands'
    }
];

export const shopsMenuItems = [
    {
        name: 'Магазины',
        route: '/shops'
    }, {
        name: 'Сети',
        route: '/networks'
    }
];

export const usersMenuItems = (accessToFinances) => [
    {
        name: 'Консультанты',
        route: '/users/promoters'
    }, {
        name: 'Супервайзеры',
        route: '/users/supervisors'
    }, {
        name: 'Account Manager',
        route: '/users/marketing-coordinators',
        roles: [ADMIN, CLIENT]
    }, {
        name: 'Клиенты',
        route: '/users/clients',
        roles: [ADMIN]
    }, {
        name: 'Администраторы',
        route: '/users/admins',
        roles: [ADMIN]
    }, {
        name: 'Должности',
        route: '/users/grades',
        roles: [ADMIN]
    }
];

export const supervisorMenuItems = id => ([
    {
        name: 'Информация',
        route: `/users/supervisor/${id}`
    }, {
        name: 'Магазины',
        route: `/users/supervisor/${id}/shops`
    }, {
        name: 'Консультанты',
        route: `/users/supervisor/${id}/promoters`
    }
]);

export const promoterMenuItems = id => ([
    {
        name: 'Информация',
        route: `/users/promoter/${id}`
    }, {
        name: 'Магазины',
        route: `/users/promoter/${id}/shops`
    }
]);

export const shopMenuItems = id => {
    return [
        {
            name: 'Информация',
            route: `/shop/${id}`
        },
        {
            name: 'Сотрудники',
            route: `/shop/${id}/workers`
        }
    ];
};

export const taskMenuItems = [
    {
        name: 'Общие',
        route: '/tasks/common'
    },
    {
        name: 'Магазины',
        route: '/tasks/shops'
    }
];

export const taskCardMenuItems = id => ([
    {
        name: 'Задача',
        route: `/task/${id}`
    }, {
        name: 'Прогресс выполнения',
        route: `/task/${id}/progress`
    }
]);

export const geographyMenuItems = [
    {
        name: 'Регионы',
        route: '/regions'
    },
    {
        name: 'Города',
        route: '/cities'
    }
];

export const instructionMenuItems = [
    {
        name: 'Выполненные сегодня',
        route: '/instructions/today'
    },
    {
        name: 'Запланированные',
        route: '/instructions/planned'
    },
    {
        name: 'Все',
        route: '/instructions/all'
    }
];

export const planMenuItems = base => ([
    {
        name: 'Общие',
        route: base,
        roles: [ADMIN, CLIENT]
    },
    {
        name: 'Сети',
        route: `${base}/networks`,
        roles: [ADMIN, CLIENT]
    },
    {
        name: 'Регионы',
        route: `${base}/regions`,
        roles: [ADMIN, CLIENT, MARKETING_COORDINATOR]
    },
    {
        name: 'Супервайзеры',
        route: `${base}/supervisors`
    },
    {
        name: 'Магазины',
        route: `${base}/shops`
    },
    {
        name: 'Консультанты',
        route: `${base}/promoters`
    },
    {
        name: 'Планы',
        route: `${base}/plans`
    }
]);

export const reportMenuItems = (base, accessToFinances) => ([
    {
        name: 'Табель',
        route: `${base}/table`,
    },
    {
        name: 'Финансы',
        route: `${base}/finance`,
        roles: [ADMIN],
        hide: !accessToFinances,
    },
]);

export const filterClientColumns = (isClient, columns) => isClient ? (
    columns.filter(({ key }) => (key !== 'email') && (key !== 'phone'))
) : columns;
