import React from 'react';

import withRole from 'components/common/withRole';

import PersonName from './PersonName';
import AccessLink from '../AccessLink';
import { userLinks } from 'constants/roles';

const PersonLink = ({ person = {}, type = 'promoter', children }) => (
    person ? <AccessLink to={`/users/${type}/${person.id}`} access={userLinks}>
        { children ? children : <PersonName person={person} /> }
    </AccessLink> : null
)

export default withRole(PersonLink);
