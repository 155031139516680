import React, { Component } from 'react';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import * as yup from 'yup';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import Input from 'components/common/form/Input';
import {
    postNetwork,
    putNetwork,
    formAutoOptions,
} from 'actions/asyncActions';

const validationSchema = yup.object().shape({
    name: yup.string().trim().required(),
});

@withAsyncActions({
    postAction: postNetwork,
    putAction: putNetwork
}, formAutoOptions)
export default class NetworkModal extends Component {
    static propTypes = modalFormPropTypes;

    renderForm = formProps => {
        return (
            <div>
                <Field
                    name='name'
                    component={Input}
                    label='Название:'
                    placeholder='Введите название' />
            </div>
        );
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                createTitle={'Добавить сеть'}
                editTitle={'Редактировать сеть'}
                validationSchema={validationSchema}
                renderForm={this.renderForm}
            />
        );
    }
}
