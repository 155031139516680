const USER_STATUSES = [
    {
        value: null,
        name: 'Все'
    }, {
        value: true,
        name: 'Активные'
    }, {
        value: false,
        name: 'Неактивные'
    }
];

export default USER_STATUSES;

export const USER_ITEM_STATUSES = [
    { type: 'active', name: 'Активные' },
    { type: 'archive', name: 'Неактивные' },
    { type: 'all', name: 'Все' },
];
