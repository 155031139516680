import React, { Component } from 'react';
import { propEq, has } from 'ramda';
import { Popconfirm } from 'antd';
import { withAsyncActions } from 'react-async-client';

import ListTable from 'components/common/ListTable';
import Date from 'components/common/cards/Date';
import Instruction from 'components/common/cards/Instruction';
import { getInstructions, deleteInstruction, formAutoOptions } from 'actions/asyncActions';
import { INSTRUCTION_BIND_TYPES, INSTRUCTION_STATUSES } from 'constants/instructions';
import Menu from 'components/common/Menu';
import { instructionMenuItems } from 'menus';
import { DeleteOutlined } from '@ant-design/icons';
import { DELETE_INSTRUCTION } from 'constants/actionTypes';

@withAsyncActions({ deleteInstruction }, formAutoOptions)
export default class Instructions extends Component {
    columns = [
        {
            title: 'Тип распоряжения',
            dataIndex: 'type',
            key: 'type',
            render: (type, item) => INSTRUCTION_BIND_TYPES(has('unbind', item) ? item.unbind : item.supervisor)[type]
        }, {
            title: 'Распоряжение',
            key: 'text',
            render: instruction => <Instruction instruction={instruction} />
        }, {
            title: 'Дата выполнения',
            dataIndex: 'date',
            key: 'date',
            render: date => <Date date={date} />,
            hide: this.props.hideDate
        }, {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: status => INSTRUCTION_STATUSES.find(propEq('type', status)).name
        }, {
            key: 'delete',
            render: ({ executedAt, id }) => !executedAt && (
                <Popconfirm
                    arrowPointAtCenter
                    placement='topRight'
                    title='Вы действительно хотите удалить распоряжение?'
                    onConfirm={() => this.deleteInstruction(id)}>
                    <DeleteOutlined className='action-icon' />
                </Popconfirm>
            ),
            hide: this.props.hideDelete
        }
    ];

    deleteInstruction(id) {
        const { dispatch } = this.props.deleteInstruction;

        dispatch(id);
    }

    render() {
        const { filterForm, initialFilters } = this.props;

        return (
            <div>
                <Menu items={instructionMenuItems} />
                <ListTable
                    tableColumns={this.columns}
                    action={getInstructions}
                    filterForm={filterForm}
                    initialFilters={initialFilters}
                    refreshActions={[DELETE_INSTRUCTION]} />
            </div>
        );
    }
}
