import React from 'react';
import { Tag } from 'antd';
import { propEq } from 'ramda';

import { TASK_PRIORITIES } from 'constants/tasks';

const PriorityTag = ({ priority }) => {
    const data = TASK_PRIORITIES.find(propEq('value', priority));

    return data ? <Tag color={data.color}>{data.name} приоритет</Tag> : null;
}

export default PriorityTag;
