import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import { Button } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';

import TaskProgress from './TaskProgress';
import TaskText from 'components/common/cards/task/TaskText';
import TaskFiles from 'components/common/cards/task/TaskFiles';
import TaskHeadBlock from 'components/common/cards/task/TaskHeadBlock';
import Menu from 'components/common/Menu';
import withRole from 'components/common/withRole';
import { taskCardMenuItems } from 'menus';
import { getTask } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';
import { EditOutlined } from '@ant-design/icons';

@withRole
@withAsyncActions({
    getTask: getTask
        .withPayload(path(['match', 'params', 'id']))
        .withOptions(fullAutoOptions)
})
export default class TaskCard extends Component {
    render() {
        const { match: { params: { id }}, getTask: { data }, isPromoter, isClient } = this.props;

        const { text } = data;
        const controls = (!isClient && !isPromoter) && (
            <Link to={`/task/${id}/edit`}>
                <Button>
                    <EditOutlined />
                    <span>Редактировать</span>
                </Button>
            </Link>
        );

        return (
            <div className='detail-card'>
                <TaskHeadBlock task={data} controls={controls} />
                <Menu items={taskCardMenuItems(id)} />
                <Route path={`/task/:id`} exact render={props => (
                    <div>
                        <TaskText {...props} text={text} />
                        <TaskFiles {...props} files={path(['_embedded', 'files'], data)} />
                    </div>
                )} />
                <Route path={`/task/:id/progress`} component={TaskProgress} />
            </div>
        );
    }
}
