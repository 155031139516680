import React, { Component } from 'react';
import { connect } from 'react-redux';
import { any, head } from 'ramda';

import { getUser } from 'actions/asyncActions';
import {
    ADMIN,
    SUPER_ADMIN,
    CLIENT,
    MARKETING_COORDINATOR,
    SUPERVISOR,
    PROMOTER
} from 'constants/roles';

const stateToProps = state => {
    const user = getUser.selectData(state);

    return {
        role: head((user.roles || []).filter(role => role !== SUPER_ADMIN)),
        isSuperAdmin: any(role => role === SUPER_ADMIN, user.roles || []),
        accessToFinances: user.accessToFinances || {},
    };
};

const withRoles = WrappedComponent => {
    return (
        @connect(stateToProps)
        class extends Component {
            isAdmin() {
                return this.props.role === ADMIN;
            }

            isClient() {
                return this.props.role === CLIENT;
            }

            isMarketingCoordinator() {
                return this.props.role === MARKETING_COORDINATOR;
            }

            isSupervisor() {
                return this.props.role === SUPERVISOR;
            }

            isPromoter() {
                return this.props.role === PROMOTER;
            }

            render() {
                return <WrappedComponent
                    {...this.props}
                    isAdmin={this.isAdmin()}
                    isClient={this.isClient()}
                    isMarketingCoordinator={this.isMarketingCoordinator()}
                    isSupervisor={this.isSupervisor()}
                    isPromoter={this.isPromoter()}
                />;
            }
        }
    );
}

export default withRoles;
