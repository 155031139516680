import { takeEvery, put } from 'redux-saga/effects';

import { pushRollbarError } from 'utils/rollbar';
import { REQUEST_ERROR } from 'constants/regexp';
import { logout } from 'actions/appActions';

export default function* error() {
    yield takeEvery((action) => REQUEST_ERROR.test(action.type), function* (action) {
        const error = action.payload;
        const sessionIsOver = error.status === 401;

        pushRollbarError(action.payload);

        if (sessionIsOver) {
            yield put(logout());
        }
    });
}
