import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button } from 'antd';
import { Link } from 'react-router-dom';
import { head } from 'ramda';

import ListTable from 'components/common/ListTable';
import Spinner from 'components/common/Spinner';
import withSelectedRows from 'components/common/withSelectedRows';
import withRole from 'components/common/withRole';
import PersonName from 'components/common/cards/person/PersonName';
import { getSupervisor, getPromotersList } from 'actions/asyncActions';
import { openPromoterSupervisorBindModal, openUnbindModal } from 'actions/modalActions';
import { supervisorMenuItems } from 'menus';
import Menu from 'components/common/Menu';
import PromoterSupervisorFilter from 'filters/PromoterSupervisorFilter';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { POST_INSTRUCTION, POST_INSTRUCTIONS } from 'constants/actionTypes';

const stateToProps = state => ({
    supervisor: getSupervisor.selectData(state),
    success: getSupervisor.selectMeta(state).success
});

@withRole
@withSelectedRows
@connect(stateToProps, { openPromoterSupervisorBindModal, openUnbindModal })
export default class SupervisorPromotersCard extends Component {
    columns = [
        {
            dataIndex: 'lastName',
            key: 'icon',
            width: 40,
            render: text => <Avatar>{head(text)}</Avatar>
        }, {
            title: 'ФИО',
            key: 'fullName',
            render: promoter =>
                <Link to={`/users/promoter/${promoter.id}`}><PersonName person={promoter} /></Link>
        }, {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone'
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        }
    ];

    getUnbindOptins = () => {
        const { selected } = this.props;

        return {
            items: selected.map(item => ({
                promoter: item.id,
                supervisor: null,
                type: 'promoterSupervisor'
            }))
        };
    }

    renderControls() {
        const { openPromoterSupervisorBindModal, openUnbindModal, supervisor, selected } = this.props;

        return (
            <div>
                <Button type='danger' onClick={() => openUnbindModal(this.getUnbindOptins())} disabled={!selected.length}><MinusOutlined /> Отвязать</Button>
                <Button type='primary' onClick={() => openPromoterSupervisorBindModal(supervisor)}><PlusOutlined /> Привязать</Button>
            </div>
        );
    }

    render() {
        const { match: { params }, rowSelection, supervisor, success, resetSelected, isAdmin } = this.props;

        return (
            <div>
                <Menu
                    items={supervisorMenuItems(params.id)}
                    controls={isAdmin && this.renderControls()} />
                { success ?
                    <ListTable
                        tableColumns={this.columns}
                        action={getPromotersList}
                        rowSelection={isAdmin && rowSelection}
                        refreshActions={[POST_INSTRUCTIONS, POST_INSTRUCTION]}
                        initialFilters={{ supervisor: supervisor.id }}
                        filterForm={PromoterSupervisorFilter}
                        onSuccess={resetSelected} />
                    : <Spinner />
                }
            </div>
        );
    }
}
