import React from 'react';
import { Field } from 'formik';

import Select from 'components/common/form/Select';
import { INSTRUCTION_TYPES } from 'constants/instructions';

const InstructionTypeFilter = () => (
    <Field
        name='type'
        component={Select}
        placeholder='Тип распоряжения'
        allowClear={true}
        options={INSTRUCTION_TYPES}
        path='type' />
);

export default InstructionTypeFilter;
