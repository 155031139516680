import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getSupervisorsList } from 'actions/asyncActions';

const SupervisorFilter = ({ param, values = {}, multiple }) => (
    <Field
        name={multiple ? 'supervisors' : 'supervisor'}
        component={SearchSelect}
        placeholder='Супервайзер'
        multiple={multiple}
        allowClear={true}
        action={getSupervisorsList}
        namePath={['lastName', 'firstName', 'middleName']}
        filter={({ shop, shops }) => ({
            [shops && (shops.length > 1) ? 'shops' : 'shop']: shop || (shops && (shops.length === 1) ? shops[0] :  shops),
        })}
        param={param} />
)

export default SupervisorFilter;
