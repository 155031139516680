import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { getAsyncReducers } from 'react-async-client';

import modals from './modals';
import { history } from '../store/configureStore';

const rootReducers = () => combineReducers({
    ...getAsyncReducers(),
    router: connectRouter(history),
    modals
});

export default rootReducers;
