import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import withRole from 'components/common/withRole';
import UserRoute from 'components/common/UserRoute';
import Clients from './lists/Clients';
import ClientCard from './cards/ClientCard';
import MarketingCoordinators from './lists/MarketingCoordinators';
import MarketingCoordinatorCard from './cards/MarketingCoordinatorCard';
import Supervisors from './lists/Supervisors';
import SupervisorCard from './cards/SupervisorCard';
import Promoters from './lists/Promoters';
import PromoterCard from './cards/PromoterCard';
import { ADMIN, CLIENT } from 'constants/roles';
import Admins from './lists/Admins';
import AdminCard from './cards/AdminCard';
import Grades from './lists/Grades';

@withRole
export default class Users extends Component {
    render() {
        return (
            <Switch>
                <UserRoute path='/users/clients' component={Clients} access={[ADMIN]} />
                <UserRoute path='/users/client/:id' component={ClientCard} access={[ADMIN]} />
                <UserRoute path='/users/admins' component={Admins} access={[ADMIN]} />
                <UserRoute path='/users/admin/:id' component={AdminCard} access={[ADMIN]} />
                <UserRoute path='/users/grades' component={Grades} access={[ADMIN]} />
                <UserRoute path='/users/marketing-coordinators' component={MarketingCoordinators} access={[ADMIN, CLIENT]} />
                <UserRoute path='/users/marketing-coordinator/:id' component={MarketingCoordinatorCard} access={[ADMIN, CLIENT]} />
                <UserRoute path='/users/supervisors' component={Supervisors} />
                <UserRoute path='/users/supervisor/:id' component={SupervisorCard} />
                <UserRoute path='/users/promoters' component={Promoters} />
                <UserRoute path='/users/promoter/:id' component={PromoterCard} />
                <Route render={() => <Redirect to='/users/promoters' />} />
            </Switch>
        );
    }
}
