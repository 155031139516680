import React from 'react';
import { compose, split, splitEvery, flatten, reverse, join, intersperse } from 'ramda';

export const toPriceFormat = (value, float, showZero) => {
    if (!value) {
        return showZero ? '0' : '';
    }
    const temp = split('.', parseFloat(value).toFixed(2).toString());
    const floatPart = parseInt(temp[1] || 0, 10) || 0;
    const mainPart = compose(
        reverse,
        join(''),
        flatten,
        intersperse('\u202F'),
        splitEvery(3),
        reverse
    )(temp[0]);
    return floatPart && float ? `${mainPart}.${floatPart}` : mainPart;
};

const FormattedPrice = ({ price, showRub, showZero }) => price || showZero ? (
    <span className="formatted-price">{toPriceFormat(price, true, showZero)}{showRub && price ? ' ₽' : ''}</span>
) : null;

export default FormattedPrice;
