import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Field } from 'formik';
import * as yup from 'yup';

import {
    postSupervisor,
    putSupervisor,
    formAutoOptions,
} from 'actions/asyncActions';
import UserModal from './UserModal';
import CascadeSelect from 'components/common/form/CascadeSelect';
import SearchSelect from 'components/common/form/SearchSelect';
import {
    getRegions,
    getCities,
    getGradesList,
} from 'actions/asyncActions';

@withAsyncActions({
    postAction: postSupervisor,
    putAction: putSupervisor
}, formAutoOptions)
export default class SupervisorModal extends Component {
    getCascaderFields() {
        return [
            { name: 'region', action: getRegions, placeholder: 'Выберите регион', label: 'Регион:' },
            { name: 'city', action: getCities, placeholder: 'Выберите город', label: 'Город:' }
        ];
    }

    getAdditionalFields() {
        return <div>
            <Field
                name='city'
                component={CascadeSelect}
                label='Регион/Город:'
                placeholder='Выберите город'
                allowClear={true}
                fields={this.getCascaderFields()} />
            <Field
                name='grade'
                component={SearchSelect}
                action={getGradesList}
                label='Должность:'
                placeholder='Выберите должность'
                allowClear={true} />
        </div>;
    }

    getAdditionalValidation() {
        return {
            city: yup.string().required()
        };
    }

    render() {
        return <UserModal
            {...this.props}
            createTitle={'Добавить супервайзера'}
            additionalFields={this.getAdditionalFields()}
            additionalValidation={this.getAdditionalValidation()} />;
    }
}
