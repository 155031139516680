import React, { Component } from 'react';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import * as yup from 'yup';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import Input from 'components/common/form/Input';
import SearchSelect from 'components/common/form/SearchSelect';
import {
    postProductGroup,
    putProductGroup,
    deleteProductGroup,
    getProductDepartments,
    formAutoOptions,
} from 'actions/asyncActions';

const validationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    productDepartment: yup.string().required()
});

@withAsyncActions({
    postAction: postProductGroup,
    putAction: putProductGroup,
    deleteAction: deleteProductGroup,
}, formAutoOptions)
export default class GroupModal extends Component {
    static propTypes = modalFormPropTypes;

    renderForm = formProps => {
        return (
            <div>
                <Field
                    name='name'
                    component={Input}
                    label='Название:'
                    placeholder='Введите название' />
                <Field
                    name='productDepartment'
                    component={SearchSelect}
                    action={getProductDepartments}
                    label='Департамент:'
                    placeholder='Выберите департамент'
                    allowClear={true} />
            </div>
        );
    }

    render() {
        return (
            <ModalForm
                {...this.props}
                createTitle={'Добавить группу'}
                editTitle={'Редактировать группу'}
                validationSchema={validationSchema}
                renderForm={this.renderForm}
            />
        );
    }
}
