import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { withAsyncActions } from 'react-async-client';
import * as yup from 'yup';

import ModalForm, { modalFormPropTypes } from 'components/common/ModalForm';
import Input from 'components/common/form/Input';
import DatePicker from 'components/common/form/DatePicker';
import TextEditor from 'components/common/form/TextEditor';

import {
    postNews,
    putNews,
    deleteNews,
    getNews
} from 'actions/asyncActions';

const validationSchema = yup.object().shape({
    name: yup.string().trim().required(),
    description: yup.string().trim().required()
});

const stateToProps = state => ({ news: getNews.selectData(state) });

@connect(stateToProps)
@withAsyncActions({
    postAction: postNews,
    putAction: putNews,
    deleteAction: deleteNews
})
export default class NewsModal extends Component {
    static propTypes = modalFormPropTypes;

    renderForm = formProps => {
        return (
            <div>
                <Field
                    name='name'
                    component={Input}
                    label='Название:'
                    placeholder='Введите название' />
                <Field
                    name='publishedAt'
                    component={DatePicker}
                    label="Дата:"
                />
                <Field
                    name='description'
                    placeholder="Введите текст новости"
                    component={TextEditor}
                    label='Текст:'
                />
            </div>
        );
    }

    render() {
        const params = { ...this.props.params, ...this.props.news };
        return (
            <ModalForm
                {...this.props}
                params={params}
                createTitle={'Добавить новость'}
                editTitle={'Редактировать новость'}
                validationSchema={validationSchema}
                renderForm={this.renderForm}
            />
        );
    }
}
