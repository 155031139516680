import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import VersionCheck from '@experium/index-version-check';
import { SagaProvider } from 'react-async-client';
import ruRU from 'antd/lib/locale-provider/ru_RU';

import '@experium/index-version-check/lib/styles/index.css';

import { appRun } from 'actions/appActions';

import SecureLayout from './components/layout/SecureLayout';
import { history, sagaMiddleware } from './store/configureStore';
import ScrollToTop from 'components/ScrollToTop';

@connect(null, { appRun })
export default class App extends Component {
    componentWillMount() {
        this.props.appRun();
    }
    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <ConfigProvider locale={ruRU}>
                <SagaProvider sagaMiddleware={sagaMiddleware}>
                    <Provider store={this.props.store}>
                        <VersionCheck />
                        <ConnectedRouter history={history}>
                            <ScrollToTop>
                                <Switch>
                                    <SecureLayout />
                                </Switch>
                            </ScrollToTop>
                        </ConnectedRouter>
                    </Provider>
                </SagaProvider>
            </ConfigProvider>
        );
    }
}
