import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { path, pathOr, prop } from 'ramda';

import TaskHeadBlock from 'components/common/cards/task/TaskHeadBlock';
import TaskText from 'components/common/cards/task/TaskText';
import TaskFiles from 'components/common/cards/task/TaskFiles';
import InfoBlock from 'components/common/cards/InfoBlock';
import { getTaskReport } from 'actions/asyncActions';
import { fullAutoOptions } from 'constants/asyncClient';

@withAsyncActions({
    getTaskReport: getTaskReport
        .withPayload(path(['match', 'params', 'id']))
        .withOptions(fullAutoOptions)
})
export default class TaskCard extends Component {
    render() {
        const { data: report } = this.props.getTaskReport;
        const task = pathOr({}, ['_embedded', 'task'], report);
        const taskFiles = pathOr([], ['_embedded', 'task', '_embedded', 'files'], report);
        const files = pathOr([], ['_embedded', 'files'], report);
        const taskText = prop('text', task);

        return (
            <div className='detail-card'>
                <TaskHeadBlock task={task} />
                <TaskText text={taskText} />
                <TaskFiles files={taskFiles} />
                <InfoBlock title='Результат задачи'>
                    <TaskText text={report.text} />
                    <TaskFiles files={files} />
                </InfoBlock>
            </div>
        );
    }
}
