import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

import withFormItem from './withFormItem';
import withFormikField, { formFieldPropTypes } from './withFormikField';

@withFormItem
export class DatePickerComponent extends Component {
    static propTypes = {
        ...formFieldPropTypes,
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
        disabledDate: PropTypes.func
    };

    onChange = date => {
        this.props.onChange(date ? moment.utc(date).startOf('day') : null);
    }

    render() {
        const { value, placeholder, disabledDate, disabled } = this.props;

        return (
            <DatePicker
                value={value ? moment(value) : undefined}
                placeholder={placeholder}
                onChange={this.onChange}
                getPopupContainer={trigger => trigger.parentNode}
                disabled={disabled}
                disabledDate={disabledDate} />
        );
    }
}

export default withFormikField(DatePickerComponent);
