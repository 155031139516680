import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { findIndex, propEq } from 'ramda';

import withRole from './withRole';

@withRole
@withRouter
export default class MenuComponent extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                route: PropTypes.string.isRequired
            })
        ),
        controls: PropTypes.node
    };

    static defaultProps = {
        items: []
    };

    onSelectMenuItem = e => {
        const { history } = this.props;

        if (Number(e.key) !== this.getSelected()) {
            history.replace(this.getItems()[e.key].route);
        }
    }

    getSelected() {
        const { location: { pathname }} = this.props;

        return findIndex(propEq('route', pathname), this.getItems());
    }

    getParsedSelected = () => {
        return [this.getSelected().toString()];
    }

    getItems() {
        const { items, role } = this.props;

        return items.filter(item => !item.hide ? !item.roles || item.roles.includes(role) : false);
    }

    renderMenuItems() {
        return this.getItems().map((item, index) =>
            <Menu.Item key={index}>{item.name}</Menu.Item>
        );
    }

    render() {
        const { controls, items } = this.props;

        return (
            <div className='menu'>
                { !!items.length && (
                    <Menu mode='horizontal' onClick={this.onSelectMenuItem} selectedKeys={this.getParsedSelected()}>
                        {this.renderMenuItems()}
                    </Menu>
                )}
                <div className='menu-controls'>
                    { controls }
                </div>
            </div>
        );
    }
}
