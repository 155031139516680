import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { concat } from 'ramda';

import { openShopModal, openImportModal } from 'actions/modalActions';
import { getUser, getShops, importShops } from 'actions/asyncActions';
import { Button } from 'antd';

import withRole from 'components/common/withRole';
import ListTable from 'components/common/ListTable';
import Menu from 'components/common/Menu';
import Address from 'components/common/cards/Address';
import City from 'components/common/cards/City';
import ShopFilter from 'filters/ShopFilter';
import { shopsMenuItems } from 'menus';

import CollaboratorList from './CollaboratorList';
import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { DELETE_SHOP, IMPORT_SHOPS, POST_SHOP, PUT_SHOP } from 'constants/actionTypes';

@withRole
@connect(state => ({
    user: getUser.selectData(state)
}), { openShopModal, openImportModal })
export default class Shops extends Component {
    columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, item) => <Link to={`/shop/${item.id}`}>{text}</Link>,
        }, {
            title: 'Код ТТ',
            dataIndex: 'code',
            key: 'code'
        }, {
            title: 'Торговая сеть',
            dataIndex: ['_embedded', 'network', 'name'],
            key: 'network'
        }, {
            title: 'Город',
            dataIndex: ['_embedded', 'city'],
            key: 'city',
            render: city => <City city={city} />
        }, {
            title: 'Адрес',
            key: 'address',
            render: shop => <Address item={shop} />
        }, {
            title: 'Сотрудники',
            key: 'collaborators',
            render: ({ _embedded : { supervisors, promoters }, id }) => (
                <CollaboratorList shopId={id} list={concat(supervisors || [], promoters || [])} />
            )
        }
    ];

    getMenuControls() {
        const { openShopModal, openImportModal, isAdmin } = this.props;

        return isAdmin && (
            <div>
                <Button
                    type='success'
                    icon={<FileExcelOutlined />}
                    onClick={() => openImportModal({
                        title: 'Импортировать магазины',
                        action: importShops,
                        withoutMonth: true
                    })}>
                    Импорт
                </Button>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openShopModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        const { user } = this.props;
        const initialFilters = {
            region: user.region
        };

        return <div>
            <Menu
                items={shopsMenuItems}
                controls={this.getMenuControls()}
            />
            <ListTable
                filterForm={ShopFilter}
                action={getShops}
                initialFilters={initialFilters}
                refreshActions={[POST_SHOP, PUT_SHOP, DELETE_SHOP, IMPORT_SHOPS]}
                tableColumns={this.columns}
            />
        </div>;
    }
}
