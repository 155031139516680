import React from 'react';
import { Field } from 'formik';

import SearchSelect from 'components/common/form/SearchSelect';
import { getRegions } from 'actions/asyncActions';

const RegionFilter = ({ param, multiple }) => (
    <Field
        name={multiple ? 'regions' : 'region'}
        component={SearchSelect}
        placeholder='Регион'
        multiple={multiple}
        allowClear={true}
        action={getRegions}
        param={param} />
)

export default RegionFilter;
