import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'antd';

import { getUser } from 'actions/asyncActions';
import { CHECKIN } from 'constants/urls';
import withFilterForm from './base/withFilterForm';
import withRole from 'components/common/withRole';
import ExcelDownload from 'components/common/ExcelDownload';
import BaseSearchFilter from './base/BaseSearchFilter';
import RegionFilter from './fields/RegionFilter';
import NetworkFilter from './fields/NetworkFilter';
import ShopFilter from './fields/ShopFilter';
import SupervisorFilter from './fields/SupervisorFilter';
import CityFilter from './fields/CityFilter';
import RangeFilter from './fields/RangeFilter';

function CheckinsFilter({ isPromoter, isSupervisor, isMarketingCoordinator, user, values }) {
    const shopFilters = isMarketingCoordinator ? { region: user.region } : null;

    const controls = isPromoter ? null : (
        <Col sm={2} xs={24} className="controls-block">
            <ExcelDownload query={values} url={CHECKIN} />
        </Col>
    );

    return (
        <BaseSearchFilter controls={controls}>
            { !isMarketingCoordinator &&
                <Col sm={6} xs={24}>
                    <RegionFilter />
                </Col>
            }
            <Col sm={6} xs={12}>
                <CityFilter filter={shopFilters} />
            </Col>
            <Col sm={8} xs={12}>
                <NetworkFilter />
            </Col>
            <Col sm={8} xs={isSupervisor ? 21 : 24}>
                <ShopFilter filter={shopFilters} />
            </Col>
            { !isSupervisor &&
                <Col sm={8} xs={21}>
                    <SupervisorFilter />
                </Col>
            }
            <Col sm={8} xs={12}>
                <RangeFilter />
            </Col>
        </BaseSearchFilter>
    );
}

export default withFilterForm(
    withRole(
        connect(state => ({ user: getUser.selectData(state) }))(
            CheckinsFilter
        )
    )
);
