import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { PlusOutlined, FileExcelOutlined } from '@ant-design/icons';

import { getCities, importCities } from 'actions/asyncActions';

import { openCityModal, openImportModal } from 'actions/modalActions';
import Menu from 'components/common/Menu';
import ListTable from 'components/common/ListTable';
import withRole from 'components/common/withRole';
import { geographyMenuItems } from 'menus';
import CitiesFilter from 'filters/CitiesFilter';
import { DELETE_CITIES, POST_CITIES, PUT_CITIES, IMPORT_CITIES } from 'constants/actionTypes';

export const getTimeZoneOffsetString = timeZoneOffset => {
    const hours = Math.floor(Math.abs(timeZoneOffset) / 60);
    const minutes = Math.abs(timeZoneOffset) - hours * 60;

    return `UTC${timeZoneOffset < 0 ? '-' : '+'}${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
}

@withRole
@connect(null, { openCityModal, openImportModal })
export default class Cities extends Component {
    getColumns = isAdmin => [
        {
            title: 'Название',
            dataIndex: ['name'],
            key: 'name',
            render: (text, item) => isAdmin ? (
                <span className='span-link' onClick={() => this.props.openCityModal(item)}>{item.name}</span>
            ) : text
        },
        {
            title: 'Регион',
            dataIndex: ['_embedded', 'region', 'name'],
            key: 'region'
        },
        {
            title: 'Часовой пояс',
            dataIndex: 'timeZoneOffset',
            key: 'timeZoneOffset',
            render: timeZoneOffset => timeZoneOffset ? getTimeZoneOffsetString(timeZoneOffset) : null
        }
    ];

    getMenuControls() {
        const { isAdmin, openCityModal, openImportModal } = this.props;

        return isAdmin && (
            <div>
                <Button
                    type='success'
                    icon={<FileExcelOutlined />}
                    onClick={() => openImportModal({
                        title: 'Импортировать города',
                        action: importCities,
                        withoutMonth: true
                    })}>
                    Импорт
                </Button>
                <Button type='primary' icon={<PlusOutlined />} onClick={() => openCityModal()}>Создать</Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                <Menu items={geographyMenuItems} controls={this.getMenuControls()}  />
                <ListTable
                    tableColumns={this.getColumns(this.props.isAdmin)}
                    action={getCities}
                    filterForm={CitiesFilter}
                    refreshActions={[POST_CITIES, PUT_CITIES, DELETE_CITIES, IMPORT_CITIES]}
                />
            </div>
        );
    }
}
