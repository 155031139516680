import React, { Fragment, useRef } from 'react';
import { Button, message } from 'antd';
import qs from 'qs';

import { FileExcelOutlined } from '@ant-design/icons';
import { last, omit } from 'ramda';
import { getJWTToken } from 'utils/token';

function download(file, callback, hide) {
    var request = new XMLHttpRequest();
    request.responseType = 'blob';
    request.open('GET', file);
    request.setRequestHeader('Authorization', getJWTToken());

    request.onreadystatechange = function () {
        if (request.readyState === 4) {
            if (request.status === 200) {
                callback(request.response);
                hide();
            } else {
                hide();
                message.error('Ошибка загрузки.');
            }
        }
    };

    request.send();
}

function save(link, file, fileName) {
    var url = URL.createObjectURL(file);
    link.current.href = url;
    link.current.download = `${fileName}.xlsx`;
    link.current.click();
}

const ExcelDownload = ({ className, query, url, block, title, params = {} }) => {
    const link = useRef(null);

    const stringifiedUrl = url.stringify();
    const stringifiedQuery = qs.stringify({ ...params, q: query ? JSON.stringify(omit(['_embedded'], query)) : null  });
    const downloadUrl = `/api${stringifiedUrl}.xlsx?${stringifiedQuery}`;
    const fileName = last(stringifiedUrl.split('/'));

    const downloadExcel = () => {
        const hide = message.loading('Загрузка...', 0);

        download(downloadUrl, file => save(link, file, fileName), hide);
    };

    return <Fragment>
        {/* eslint-disable-next-line */}
        <a ref={link} blob />
        <Button className={className} onClick={downloadExcel} shape={block ? 'default' : 'circle'} type='success' block={block} icon={<FileExcelOutlined />}>{title}</Button>
    </Fragment>;
}

export default ExcelDownload;
