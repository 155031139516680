import React from 'react';
import { Modal } from 'antd';

import Map from 'components/common/Map';

const MapModal = ({ modal, params: { latitude, longitude, title, additionalCoords = [] }}) => (
    <Modal {...modal} title={title} footer={null}>
        <Map lng={longitude} lat={latitude} additionalCoords={additionalCoords} />
    </Modal>
);

export default MapModal;
